import { Badge } from "react-bootstrap";
import creationApi from "../../app/services/secured/creation";
import AppSecured from "../../AppSecured";
import Urls from "../../assets/js/Urls";
import { Category } from "../../types";
import { PageHeader } from "../App/PageHeader";
import { LoadingBlock } from "../misc/Blocks";
import { ButtonIcon } from "../misc/Buttons";
import TableGenelead from "../misc/Table";

const CategoryList: React.FC = () => {
    const catReq = creationApi.useGetCategoriesQuery();
    return <>
        <AppSecured>
            <PageHeader action={<ButtonIcon
                    code={"plus-circle"}
                    size={"sm"}
                    variant={"primary"}
                    href={Urls.categories.edit('new')}
                >
                    Nouvelle catégorie / thème
                    </ButtonIcon>} />
            <LoadingBlock data={catReq}>
                <TableGenelead header={[
                    { name: 'Nom', icon: 'palette' },
                    { name: 'Type', icon: 'tags', align: 'center'},
                    { name: 'SEO', icon: 'people' },
                ]} hasActions={true}>
                    {catReq.data?.list.map(c => <CategoryRow key={Math.random()} cat={c} />)}
                </TableGenelead>
            </LoadingBlock>
        </AppSecured>
    </>
}

const CategoryRow:React.FC<{cat: Category}> = ({cat}) => {
    return <tr>
        <td>{
            <div className='category-theme d-flex justify-content-between' style={{background: cat.data.color_bg}} >
                <div>{cat.name}</div><div><small>[{cat.slug}]</small></div>
            </div>
        }</td>
        <td className='text-center'>{
            cat.type === 2 ? <Badge bg='secondary'>Thème</Badge>:<Badge bg='secondary'>Catégorie</Badge>
        }</td>
        <td>
            {cat.data.seo_title && <Badge bg='primary' className='alt-primary me-1'>Title - {cat.data.seo_title}</Badge>}
            {cat.data.seo_desc && <Badge bg='primary' className='alt-primary'>Desc - {cat.data.seo_title}</Badge>}
        </td>
        <td className='text-end'>
            <ButtonIcon href={Urls.categories.edit(cat.id.toString())} code='pen' size='lg' variant="primary" className='alt-primary' />
        </td>
    </tr>
}

export default CategoryList;