import { Alert, Modal } from "react-bootstrap";
import { formSuccessRedirect } from "../../app/actions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import manageApi from "../../app/services/secured/manage";
import { getModalData, isModalOpened, ModalTypes } from "../../app/slice/modal";
import { Company } from "../../types";
import { IfTrue } from "../misc/Blocks";
import { Icon, LoadingButton } from "../misc/Buttons";
import SimpleForm from "../misc/SimpleForm";
import ModalCloseButton from "../Modals/ModalCloseButton";

const ModalDeleteCompany = () => {
    const dispatch = useAppDispatch();
    const isOpened = useAppSelector(isModalOpened(ModalTypes.DeleteCompany));
    const data = useAppSelector<{id: number, name: string}>(getModalData);

    const [deleteCompany, deleteCompanyRes] = manageApi.useDeleteCompanyMutation();
    dispatch(formSuccessRedirect({isModal: true, data: deleteCompanyRes, message:'Succès suppression du client.'}));
    return <IfTrue condition={isOpened && data}>
        <SimpleForm onSubmit={() => deleteCompany({id: data.id})}>
            <Modal.Body>
                <Alert variant="danger">
                    <Alert.Heading><Icon code='exclamation' /> Suppression du client {data?.name}</Alert.Heading>
                    La suppression du client s'appliquera également à l'ensemble des comptes clients associés. <br />
                    Êtes vous sûr de vouloir supprimer le client ?
                </Alert>
            </Modal.Body>
            <Modal.Footer>
                <ModalCloseButton />
                <LoadingButton variant="danger" data={deleteCompanyRes}>Supprimer</LoadingButton>
            </Modal.Footer>
        </SimpleForm>
    </IfTrue>
}

export default ModalDeleteCompany;