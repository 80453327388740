import { useState } from "react";
import { Button, FormControl, FormLabel, Modal } from "react-bootstrap"
import { formSuccessRedirect } from "../../../app/actions";
import { useAppDispatch, useAppSelector } from "../../../app/hooks"
import manageApi from "../../../app/services/secured/manage";
import { getModalData, isModalOpened, ModalTypes } from "../../../app/slice/modal"
import { RefreshLeadsProps } from "../../../types";
import { IfTrue } from "../../misc/Blocks";
import { LoadingButton } from "../../misc/Buttons";
import SimpleForm from "../../misc/SimpleForm";
import ModalCloseButton from "../../Modals/ModalCloseButton";

const ModalRefreshLeads = () => {
    const dispatch = useAppDispatch();
    const isOpened = useAppSelector(isModalOpened(ModalTypes.LeadsPending));
    const data = useAppSelector<RefreshLeadsProps>(getModalData);
    const [hasDate, setHasDate] = useState(false);

    const [refreshLeads, refreshLeadsRes] = manageApi.useRefreshLeadsMutation();
    dispatch(formSuccessRedirect({isModal: true, data: refreshLeadsRes, message: "Succès passage leads dans la file d'attente."}));

    return <>
        <IfTrue condition={isOpened && data}>
            <SimpleForm onSubmit={(json) => refreshLeads({company: data.company_id, form: data.form_id, gateway: data.gateway_id, data: json as any})}>
            <Modal.Header>
                Repasser les leads dans la file d'attente
            </Modal.Header>
            <Modal.Body>
                <FormLabel>Sélectionnez une date de départ</FormLabel>
                <FormControl type='date' name='from' onChange={(e) => {
                    const date = e.target.value;
                    const dateObj = new Date(date);
                    const today = new Date();
                    setHasDate(date && dateObj < today ? true:false);
                }} />
            </Modal.Body>
            <Modal.Footer>
                <ModalCloseButton />
                {hasDate && <LoadingButton data={refreshLeadsRes}>Valider</LoadingButton>}
            </Modal.Footer>
            </SimpleForm>
        </IfTrue>
    </>
}

export default ModalRefreshLeads;