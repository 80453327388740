import { useCallback, useEffect, useState } from "react";
import { Accordion, Button, Col, Container, FormControl, FormSelect, InputGroup, ListGroup, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { ButtonIcon } from "../../misc/Buttons";
import { DesignElement, Icon } from "../Design";
import { addCol, deleteCol, deleteRow, duplicateRow, editRowDesign, getCurrentRow, getRow, GlobalStyle, isEditingRow, LandingV2, moveColumn, moveRow, setColSize, setRowDesign, setRowGlobalDesign, validateBlock } from "../landing-v2";
import { HideInPreview, HideInViews } from "../LPGenerator";
import { DisplayController } from "../Tools/Display";
import { MarginPadding } from "../Tools/MarginPadding";
import { ColumnContainer } from "./Column";

export const RowContainer: React.FC<{ index: number, size: number }> = props => {

   const row = useAppSelector(getRow(props.index));
   const isRowDesign = useAppSelector(isEditingRow(props.index));

   const style: GlobalStyle = {};
   style.padding = row.style.padding ? row.style.padding : '';
   style.margin = row.style.margin ? row.style.margin : '';
   style.alignItems = row.style.alignItems ? row.style.alignItems : '';
   style.minHeight = row.style.minHeight ? row.style.minHeight : '';

   let className = 'row-container';
   if (isRowDesign) { className += ' focus-animation' }

   const containerStyle: { position: string, padding?: string } = { position: 'relative' };
   if (row.style.backgroundSize === 'fluid') {
      containerStyle.padding = '0';
   }

   return <Container className={className} style={containerStyle as any} fluid={row.style.backgroundSize === 'fluid' ? true : false}>
      <Row style={style}>
         {row?.childs.map((c, i) =>
            <ColumnContainer key={`column-container-${i}`} size={c.size as number} row={props.index} col={i} />)
         }
      </Row>

      <HideInPreview>
         <HideInViews views={['row-design', 'block-design']}>
            <RowTools index={props.index} size={props.size} />
         </HideInViews>
      </HideInPreview>
   </Container>
}

const RowTools: React.FC<{ index: number, size: number }> = props => {
   const row = useAppSelector(getRow(props.index));
   const dispatch = useAppDispatch();

   const confirmDelete = () => {
      let rowIsEmpty = true;
      row.childs.map(col => { if (col.childs.length > 0) rowIsEmpty = false });
      if (!rowIsEmpty) {
         if (window.confirm("La ligne n'est pas vide. Êtes-vous certain de vouloir la supprimer ?") === true)
            dispatch(deleteRow({ row: props.index }))
      } else dispatch(deleteRow({ row: props.index }))
   }
   return <div className='row-actions d-flex align-items-center justify-content-center'>
      {props.index > 0 && <>
         <Button size='sm' variant='secondary' onClick={() => dispatch(moveRow({ row: props.index, direction: -1 }))}><Icon code='chevron-up' /></Button>
      </>}
      {props.index < props.size - 1 && <>
         <Button size='sm' variant='secondary' className='ms-1' onClick={() => dispatch(moveRow({ row: props.index, direction: 1 }))}><Icon code='chevron-down' /></Button>
      </>}
      <Button size='sm' variant='primary' className='ms-1'
         onClick={() => dispatch(editRowDesign({ row: props.index }))}><Icon code='paint-bucket' /></Button>

      <Button size='sm' variant='info' className='ms-1'
         onClick={() => dispatch(duplicateRow(props.index))}><Icon code='files' /></Button>

      <Button size='sm' variant='danger' className='ms-1'
         onClick={() => confirmDelete()}
      ><Icon code='x' /></Button>
   </div>
}

export const RowDesign: React.FC = () => {
   const dispatch = useAppDispatch();
   const row = useAppSelector(getCurrentRow);

   const handleKeyPress = useCallback((event: any) => {
      if (event.keyCode === 13) { dispatch(validateBlock()); }
   }, []);

   useEffect(() => {
      document.addEventListener('keydown', handleKeyPress);
      return () => {
         document.removeEventListener('keydown', handleKeyPress);
      };
   }, [handleKeyPress]);

   return <>
      <h2 id='tools-title'>Conteneur</h2>
      <div id='tools-wrapper'>
         <Button className='validate-btn' variant='' onClick={() => dispatch(validateBlock())}>Valider</Button>
         <hr style={{ color: 'white' }} />
         <Accordion className='design-accordion'>
            <ColumnEditor />
            {row?.style && <>

               <DesignElement eventKey="3" title='Design'>
                  <span className='text-muted'>Hauteur</span>
                  <FormSelect defaultValue={row?.style.minHeight} size='sm' className='mb-2'
                     onChange={(e) => dispatch(setRowDesign({ option: 'minHeight', value: e.target.value }))}>
                     <option value=''>Contenu</option>
                     <option value='100vh'>Pleine page</option>
                  </FormSelect>

                  <span className='text-muted'>Taille</span>
                  <ListGroup horizontal className='w-100 mb-2'>
                     <ListGroup.Item className='text-center' action active={row?.style.backgroundSize === 'container' || !row?.style.backgroundSize}
                        onClick={() => dispatch(setRowDesign({ option: 'backgroundSize', value: 'container' }))}>
                        <Icon code='arrows-angle-contract' />
                     </ListGroup.Item>
                     <ListGroup.Item className='text-center' action active={row?.style.backgroundSize === 'fluid'}
                        onClick={() => dispatch(setRowDesign({ option: 'backgroundSize', value: 'fluid' }))}>
                        <Icon code='arrows-angle-expand' />
                     </ListGroup.Item>
                  </ListGroup>

                  <span className='text-muted'>Display</span>
                  <DisplayController forceFlex={true} style={row.style} onChange={(style) => {
                     dispatch(setRowGlobalDesign(style));
                  }} />
               </DesignElement>
            </>}
            <DesignElement eventKey='4' title='Marge interne'>
               <MarginPadding init={row && row?.style?.padding ? row.style.padding : ''} option='Marge interne (padding)' className='mt-2'
                  callback={(value: string) => dispatch(setRowDesign({ option: 'padding', value: value }))} />
            </DesignElement>
            <DesignElement eventKey='5' title='Marge interne'>
               <MarginPadding init={row && row?.style?.margin ? row.style.margin : ''} option='Marge externe (margin)' className='mt-2'
                  callback={(value: string) => dispatch(setRowDesign({ option: 'margin', value: value }))} />
            </DesignElement>
         </Accordion>
      </div>
   </>
}

const ColumnEditor = () => {

   const dispatch = useAppDispatch();
   const row = useAppSelector(getCurrentRow);
   const [cols, setCols] = useState<Array<LandingV2>>(row ? [...row.childs] : []);
   const [sizes, setSizes] = useState(0);

   useEffect(() => {
      if (row && cols !== row.childs) setCols(row.childs);
   }, [row])

   useEffect(() => {
      let nb = 0;
      cols.map(c => {
         if (typeof (c.size) === 'number') {
            nb += c.size;
         }
      });
      setSizes(nb);
   }, [cols])

   const delCol = (col: number) => {
      const isColEmpty = row?.childs[col] && row?.childs[col].childs.length > 0 ? false : true;
      if (!isColEmpty) {
         if (window.confirm("Cette colonne n'est pas vide. Êtes-vous certain de vouloir la supprimer ?") === true)
            dispatch(deleteCol(col))
      } else dispatch(deleteCol(col))
   }

   return <>
      <Accordion.Item eventKey="1">
         <Accordion.Header>Colonnes</Accordion.Header>
         <Accordion.Body>
            <Row>
               {cols?.map((col, i) => <Col sm={12} key={i} className='mt-2'>
                  <div className=''>
                     <div className='d-flex align-items-center justify-content-between'>
                        {i > 0 && <>
                           <ButtonIcon code='chevron-left' variant='' size='sm' onClick={() => dispatch(moveColumn({ col: i, direction: -1 }))} />
                        </>}
                        {i + 1 < cols.length && <>
                           <ButtonIcon code='chevron-right' variant='' size='sm' onClick={() => dispatch(moveColumn({ col: i, direction: 1 }))} />
                        </>}
                        <ButtonIcon code='trash' variant='' size='sm' onClick={() => delCol(i)} />
                     </div>
                  </div>

                  <InputGroup size='sm'>
                     <InputGroup.Text>C[{i + 1}]</InputGroup.Text>
                     <FormControl key={col?.size ? `${col?.size} ${i}` : ''} type='number' min={0} max={12}
                        defaultValue={col.size}
                        onChange={(e) => {
                           const newCols = [...cols];
                           newCols[i] = { ...newCols[i], size: parseInt(e.target.value) };
                           dispatch(setColSize({ col: i, size: parseInt(e.target.value) }));
                        }} />
                  </InputGroup>
               </Col>)}
               <Col sm={4} className='mt-1 d-flex align-items-center justify-content-center'>
                  <ButtonIcon code='plus' variant='secondary' size='lg' onClick={() => dispatch(addCol())} />
               </Col>
            </Row>
         </Accordion.Body>
      </Accordion.Item>
   </>
}