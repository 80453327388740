import ApiUrls from "../../../assets/js/ApiUrls";
import { LoginReturn, UserBasicData } from "../../../types";
import { StorageManager } from "../storage";
import securedApi from "./securedApi";

const userApi = securedApi.injectEndpoints({
    endpoints: (build) => ({
        logout: build.mutation<LoginReturn, void>({
            query: () => ({
                url: ApiUrls.logout,
                method: "POST",
            }),
        }),
        profile: build.query<UserBasicData, void>({
            query: () => ApiUrls.me,
        }),
    })
})

export default userApi;