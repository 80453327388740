import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {getCustomHeaders} from "../../../assets/js/ApiUrls";

const publicApi = createApi({
    baseQuery: fetchBaseQuery({
        prepareHeaders: (headers, store) => getCustomHeaders(headers, false),
        baseUrl: process.env.REACT_APP_API_DOMAIN
    }),
    endpoints: () => ({}),
});

export default publicApi;