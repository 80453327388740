const ACCESS_TOKEN_KEY = "access_token";

export const StorageManager = {
    get: ():string => window.localStorage.getItem(ACCESS_TOKEN_KEY) as string,
    set: (token:string):void => {
        window.localStorage.setItem(ACCESS_TOKEN_KEY, token);
    },
    clear: ():void => {
        window.localStorage.removeItem(ACCESS_TOKEN_KEY);
    },
}