import { useEffect, useLayoutEffect } from "react";
import { LandingMedia, ReduxRtkApiResult } from "../types";
import { useAppDispatch } from "./hooks";
import { redirect } from "./slice/app";
import { resetMedia, selectMedia } from "./slice/media";
import { modalClose } from "./slice/modal";
import { addNotification } from "./slice/notifs";
import { AppThunk } from "./store";

export const SuccessNotif:React.FC<{req: ReduxRtkApiResult, message?: string}> = ({req, message='success'}) => {
    const dispatch = useAppDispatch();
    useLayoutEffect( () => {
        if (req.isSuccess) {
            dispatch(addNotification({type:'success', message:message}));
        }
    }, [req.isSuccess])
    return <></>
}

export const selectNewMediaForCategory = (props:LandingMedia):AppThunk => dispatch => {
    dispatch(selectMedia(props));
    dispatch(modalClose());
    setTimeout(() => dispatch(resetMedia()), 500);
}

type formSuccessRedirectProps = {
    redirectUrl?: string,
    data: ReduxRtkApiResult,
    message?: string,
    isModal?: boolean,
}

export const formSuccessRedirect = (props:formSuccessRedirectProps):AppThunk => dispatch => {
    useEffect( () => {
        if (props.data.isSuccess) {
            dispatch(addNotification({type:'success', message: props.message ? props.message : 'success'}));
            if (!props.isModal) dispatch(redirect(props?.redirectUrl as string));
            else dispatch(modalClose());
        }
    }, [props.data])
}