import { useRef, useState } from "react";
import { Badge, Button, FormCheck, FormControl, InputGroup } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useAppDispatch } from "../../app/hooks";
import creationApi from "../../app/services/secured/creation";
import { modalFieldShow } from "../../app/slice/modal";
import AppSecured from "../../AppSecured";
import { Field, FieldType } from "../../types";
import { PageHeader } from "../App/PageHeader";
import { LoadingBlock } from "../misc/Blocks";
import { ButtonIcon, Icon } from "../misc/Buttons";
import SimpleForm from "../misc/SimpleForm";
import TableGenelead from "../misc/Table";

const FieldList = () => {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState({});
    const [showQuizz, setShowQuizz] = useState(false);
    const fields = creationApi.useGetFieldsQuery(search);
    return <>
        <AppSecured>
            <PageHeader action={
                <ButtonIcon onClick={() => { dispatch(modalFieldShow({})) }} variant='primary' code='plus-circle' size='sm'>
                    Nouveau champ
                </ButtonIcon>
            }>
                <div className='d-flex align-items-center'>
                    <SimpleForm onSubmit={(query) => setSearch({ query: query.keyword as string })}>
                        <InputGroup size='sm'>
                            <FormControl placeholder='Recherche mot clé' name='keyword' />
                            <Button type='submit'><Icon code='search' /></Button>
                        </InputGroup>
                    </SimpleForm>
                </div>
                <div className="ms-3">
                    <FormCheck onChange={() => setShowQuizz(!showQuizz)} label='Afficher les quizz' type='switch'
                        className='fs-16' />
                </div>
            </PageHeader>
            <LoadingBlock data={fields}>
                {fields.isSuccess && <>
                    <TableGenelead header={[
                        { name: 'nom', icon: 'file', width:'20%' },
                        { name: 'code', icon: 'qr-code', align: 'center', },
                        { name: 'type', icon: 'receipt' },
                    ]} hasActions={true}>
                        {
                            fields.data
                                .filter(f => { if (f.type === 'Q' && showQuizz || f.type !== 'Q') return true; })
                                .map(f => <FormFieldRow key={f.id} {...f} />)
                        }
                    </TableGenelead>
                </>}
            </LoadingBlock>
        </AppSecured>
    </>
}

const FormFieldRow: React.FC<Field> = field => {
    const dispatch = useAppDispatch();
    return <tr>
        <td> {field.title} </td>
        <td className='text-center'>{field.code}</td>
        <td>
            <div className="d-flex align-items-center">
                <div className='me-2'>
                    <Badge bg='primary' className='fs-12 alt-primary'><FormattedMessage id={FieldType[field.type] ? FieldType[field.type]  : '404'} /></Badge>
                </div>
                {field.type === 'C' && field.choices.length > 0 && <div>
                    {field.choices.map(c => <Badge key={c.code} bg='secondary' className='me-1 fs-12 mb-1'>{c.title}</Badge>)}
                </div>
                }
            </div>
        </td>
        <td className='text-end'>
            {field.type !== 'Q' &&
                <ButtonIcon code={"pen"} size={"lg"} variant={"primary"} className='alt-primary'
                    onClick={() => dispatch(modalFieldShow(field))} />}
        </td>
    </tr>
}

export default FieldList;