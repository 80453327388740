import { Litepicker } from "litepicker";
import "litepicker/dist/plugins/ranges";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { DateTimeOptions } from "../../types";
import { Icon } from "./Buttons";
import { DateInit, DatePeriod, ReportingPeriodObject } from "./DatePeriod";

export const formatDateForRequest = (date: Date) => {
    return date.toISOString().substr(0, 10);
}

type DatePickerProps = {
    onChange: (from: string, to: string) => void,
    style?: object,
    className?: string,
    manual?: ReportingPeriodObject,
}

const DatePicker: React.FC<DatePickerProps> = props => {
    const [period, setPeriod] = useState([DateInit.from, DateInit.to])

    useEffect(() => {
        if (props.manual?.from && props.manual?.to) {
            setPeriod([new Date(props.manual.from), new Date(props.manual.to)]);
        }
    }, [props.manual])

    const intl = useIntl();
    const callback = () => {
        const d = new Date().getFullYear();
        const last_year = d - 1;

        const customRanges = {
            [intl.formatMessage({ id: "datepicker-today" })]: [new Date(), new Date()],
            [intl.formatMessage({ id: "datepicker-yesterday" })]: DatePeriod.Yesterday,
            [intl.formatMessage({ id: "datepicker-this-week" })]: DatePeriod.ThisWeek,
            [intl.formatMessage({ id: "datepicker-this-month" })]: DatePeriod.ThisMonth,
            [intl.formatMessage({ id: "datepicker-previous-month" })]: DatePeriod.PreviousMonth,
            [intl.formatMessage({ id: "datepicker-this-year" })]: DatePeriod.ThisYear,
            [intl.formatMessage({ id: "datepicker-last-year" })]: DatePeriod.PreviousYear,
        };

        new Litepicker({
            element: document.getElementById("litepicker") as HTMLElement,
            plugins: ["ranges"],
            lang: "fr-FR",
            ranges: {
                customRanges,
            },
            setup: picker => {
                picker.on("selected", () => {
                    // @ts-ignore
                    const selectedFrom = picker.getStartDate().toJSDate();
                    // @ts-ignore
                    const selectedTo = picker.getEndDate().toJSDate();

                    selectedFrom.setHours(new Date().getHours());
                    selectedTo.setHours(new Date().getHours());

                    setPeriod([selectedFrom, selectedTo]);
                })
            },
        })
    };

    useEffect(callback, [true]);

    useEffect(() => {
        props.onChange(formatDateForRequest(period[0]), formatDateForRequest(period[1]));
    }, [period])

    const calendarOptions: DateTimeOptions = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };

    return <div id='datepicker' className={`feedcast-card px-3 py-2 ${props.className}`}>
        <Icon code='calendar' className="me-1" />
        <span id="litepicker" className="p-2">
            {new Date(period[0]).toLocaleDateString('fr-FR', calendarOptions)}
            <span className='ms-2 me-2'>-</span>
            {new Date(period[1]).toLocaleDateString('fr-FR', calendarOptions)}
        </span>
        {/* <span className="bi bi-caret-down ms-2" style={{
            cursor: "pointer",
        }}>
        </span> */}
    </div>
};

export default DatePicker;