import React from "react";
import { Modal } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import userApi from "../../app/services/secured/user";
import { isModalOpened, ModalTypes } from "../../app/slice/modal";
import { LoadingButton } from "../misc/Buttons";
import ModalCloseButton from "./ModalCloseButton";

const ModalLogout: React.FC = () => {
    const dispatch = useAppDispatch();
    const [logout, logoutRes] = userApi.useLogoutMutation();
    // if (logoutRes.isSuccess) { dispatch(logoutSuccess()) }
    return <>
        {useAppSelector( isModalOpened(ModalTypes.Logout)) && <>
            <Modal.Header>
                <Modal.Title><FormattedMessage id='logout' /></Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <FormattedMessage id='logoutConfirm' />
            </Modal.Body>
            <Modal.Footer>
                <ModalCloseButton />
                <LoadingButton data={logoutRes} variant="danger" onClick={logout} >
                    <FormattedMessage id='logout' />
                </LoadingButton>
            </Modal.Footer>
        </>
        }
    </>
}

export default ModalLogout;