import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import adminApi from "../services/secured/admin";
import creationApi from "../services/secured/creation";

type NewNotification = {
    type: "success" | "danger" | "primary" | "warning",
    message: string,
};

export type Notification = NewNotification & {
    id: number
};

const initialState: Array<Notification> = [];

const notifications = createSlice({
    name: "notifications",
    reducers: {
        addNotification: (state, action: PayloadAction<NewNotification>) => {
            state.push({
                id: Math.random(),
                type: action.payload.type,
                message: action.payload.message
            });
        },
        removeNotification: (state, action: PayloadAction<number>) => {
            return state.filter((n: Notification) => n.id !== action.payload);
        },
        clearNotifications: (state) => {
            return [];
        }
    },
    initialState,
    extraReducers: (builder) => {
        builder
            .addMatcher(adminApi.endpoints.updateSettings.matchFulfilled,
                (state) => {
                    state = addNotif(state, 'success', 'Succès de la mise à jour de la configuration.');
                })
    }
});

function addNotif(appState: any, type: string, message: string) {
    return appState.push({
        id: Math.random(),
        type: type,
        message: message
    });
}

export const { addNotification, removeNotification, clearNotifications } = notifications.actions;
export const notificationReducer = notifications.reducer;