import {IntlProvider} from 'react-intl';
import {Route, Routes} from 'react-router-dom';
import fr from './assets/js/translations/fr';
import Urls from './assets/js/Urls';
import KeysList from './components/Admin/LinkKeys/KeysList';
import Configuration from './components/Admin/Settings';
import UserProfile from './components/Admin/Users/UserEdit';
import UserList from './components/Admin/Users/UserList';
import AppRedirect from './components/App/AppRedirect';
import Forgot from './components/Auth/Forgot';
import Login from './components/Auth/Login';
import Refresh from './components/Auth/Refresh';
import CaptainVerify from './components/CaptainVerify/CaptainVerify';
import CategoryEdit from './components/Categories/CategoryEdit';
import CategoryList from './components/Categories/CategoryList';
import CompanyInfo from './components/Companies/CompanyInfo';
import CompanyList from './components/Companies/CompanyList';
import CustomRecord from './components/Companies/CustomRecord';
import CoregEdit from './components/Coregistration/CoregEdit';
import Coregistration from './components/Coregistration/Coregistration';
import Databases from './components/Databases/Databases';
import FacebookForms from './components/Facebook/FacebookForms';
import FbInstall from './components/Facebook/FbInstall';
import FieldList from './components/Fields/FieldList';
import FormEdit from './components/Forms/FormEdit';
import FormList from './components/Forms/FormList';
import LandingEdit from './components/Landings/LandingEdit';
import LandingList from './components/Landings/LandingList';
import LPGenerator from './components/LandingsV2/LPGenerator';
import LeadList from './components/Leads/LeadList';
import LeadsExportList from './components/Leads/LeadsExportList';
import Webservices from './components/Leads/Webservices';
import MediaList from './components/Medias/MediaList';
import Reporting from './components/Reporting/ReportingList';
import SiteEdit from './components/Sites/SiteEdit';
import SiteLanding from './components/Sites/SiteLanding';
import SiteList from './components/Sites/SiteList';
import Dev from "./components/Admin/Dev";

const App = () => <>
    <IntlProvider locale="fr" defaultLocale="fr" messages={fr}>
        <AppRedirect/>
        <Routes>
            {/* AUTH */}
            <Route path={Urls.login} element={<Login/>}/>
            <Route path={Urls.forgot} element={<Forgot/>}/>
            <Route path={Urls.refresh(':token')} element={<Refresh/>}/>

            {/* MANAGE */}
            <Route path={Urls.companies.list} element={<CompanyList/>}/>
            <Route path={Urls.companies.view(':company_id')} element={<CompanyInfo/>}/>
            <Route path={Urls.companies.customRecord(':company_id')} element={<CustomRecord/>}/>
            <Route path={Urls.leads.list} element={<LeadList/>}/>
            <Route path={Urls.dash} element={<Reporting/>}/>
            <Route path={Urls.leads.export} element={<LeadsExportList/>}/>
            <Route path={Urls.webservices} element={<Webservices/>}/>


            {/* CREATION */}
            <Route path={Urls.landing.list} element={<LandingList/>}/>
            <Route path={Urls.landing.edit(':id')} element={<LandingEdit/>}/>
            <Route path={Urls.landing.duplicate(':id')} element={<LandingEdit/>}/>
            <Route path={Urls.landing.editv2(':id')} element={<LPGenerator/>}/>

            <Route path={Urls.fields} element={<FieldList/>}/>
            <Route path={Urls.forms.list} element={<FormList/>}/>
            <Route path={Urls.forms.edit(':id')} element={<FormEdit/>}/>

            <Route path={Urls.categories.list} element={<CategoryList/>}/>
            <Route path={Urls.categories.edit(':id')} element={<CategoryEdit/>}/>
            <Route path={Urls.medias.list} element={<MediaList/>}/>

            {/* DIFFUSION */}
            <Route path={Urls.sites.list} element={<SiteList/>}/>
            <Route path={Urls.sites.edit(':id')} element={<SiteEdit/>}/>
            <Route path={Urls.sites.landings(':id')} element={<SiteLanding/>}/>

            <Route path={Urls.fb.forms} element={<FacebookForms/>}/>
            <Route path={Urls.fb.install} element={<FbInstall/>}/>

            {/* DATA */}
            <Route path={Urls.captainVerify} element={<CaptainVerify/>}/>
            <Route path={Urls.databases.list} element={<Databases/>}/>
            <Route path={Urls.coregistration.list} element={<Coregistration/>}/>
            <Route path={Urls.coregistration.edit(':id')} element={<CoregEdit/>}/>

            {/* ADMIN */}
            <Route path={Urls.users.list} element={<UserList/>}/>
            <Route path={Urls.users.edit(':id')} element={<UserProfile/>}/>
            <Route path={Urls.linkKeys.list} element={<KeysList/>}/>
            <Route path={Urls.settings} element={<Configuration/>}/>
            <Route path={Urls.dev} element={<Dev/>}/>

        </Routes>
    </IntlProvider>
</>

export default App;