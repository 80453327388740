import { useState, useEffect } from "react";
import { Alert, Card, Row, Col, FormControl, FormLabel, FormCheck, Button, Badge } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import creationApi from "../../app/services/secured/creation";
import globalApi from "../../app/services/secured/global";
import { Coregistration, LinkedFormstoCoreg } from "../../types";
import { FormInputGroup } from "../misc/Blocks";
import { ButtonIcon } from "../misc/Buttons";

export const CoregForm: React.FC<Partial<Coregistration>> = coreg => {
    const forms = globalApi.useGetFormsListQuery();
    const formsList = forms.data;

    const [query, setQuery] = useState('');

    const [filtered, setFiltered] = useState<Array<LinkedFormstoCoreg>>([]);
    const [formsLinked, setFormsLinked] = useState<Array<LinkedFormstoCoreg>>([]);
    const [resSelect, setResSelect] = useState([]);

    const filterForms = (e: any) => {
        const current_query = e.target.value.toLowerCase();
        setQuery(current_query);

        if (current_query.length < 3) { setFiltered([]) }
        else if (formsList) {
            setFiltered(sortForms(formsList, formsLinked, current_query))
        }
    }

    useEffect(() => {
        if (formsList && query) setFiltered(sortForms(formsList, formsLinked, query));
    }, [formsLinked])

    useEffect(() => {
        if (coreg && formsList)
            setFormsLinked(formsList.filter(f => {
                if (coreg.formCodes) {
                    return coreg.formCodes.map(fl => fl).indexOf(f.code) > -1
                }
            }));
    }, [coreg, formsList])

    return <>
        {/* <Alert>
            <Alert.Heading>Rechercher un formulaire Genelead</Alert.Heading>
            Vous pouvez rechercher le <b>code</b> du formulaire souhaité. Afin que la recherche soit efficace, veuillez renseigner :
            <ul className='m-0'>
                <li>Au minimum 3 lettres</li>
                <li>Ajoutez plus de lettre pour affiner votre recherche</li>
            </ul>
        </Alert> */}

        <Card>
            <Card.Header>
                <Row className='d-flex align-items-center'>
                    <Col>
                        <FormInputGroup label='Nom'>
                            <FormControl type='text' name='name' placeholder='Name' defaultValue={coreg.name} required />
                        </FormInputGroup>
                    </Col>
                    <Col>
                        <FormInputGroup label='Paiement'>
                            <FormControl type='number' step='0.01' name='payout' placeholder='Paiement' defaultValue={coreg.payout} required />
                        </FormInputGroup>
                    </Col>
                    <Col md={2}>
                        <FormLabel>Générer un AccessToken?</FormLabel>
                        <FormCheck type='switch' name='refresh-api-token' />
                    </Col>
                </Row>
            </Card.Header>
            <Card.Body>

                {/* <FormInputGroup label='Rechercher un formulaire...'>
                    <FormControl type='text' placeholder='Formulaire' onChange={filterForms} />
                </FormInputGroup> */}
                {
                    formsList && <>
                        <Typeahead
                            id='select-landing-apge'
                            options={formsList
                                .filter(f => formsLinked.findIndex(ff => ff.id === f.id) === -1)
                                .map(f => { return { label: '[' + f.code + '] ' + f.name, code: f.code, name: f.name, id:f.id } })}
                            selected={resSelect}
                            placeholder='Rechercher un formulaire'
                            onChange={(s) => {
                                if (s[0]) {
                                    const val = s[0] as {code: string, name: string, id: number}
                                    const newFields = [...formsLinked];
                                    newFields.push({
                                        id: val.id,
                                        code: val.code,
                                        name: val.name,
                                    });
                                    setFormsLinked(newFields);
                                    setResSelect([]);
                                }
                            }}
                        />
                    </>
                }
                <Row className='mt-4'>
                    <Col>
                        <FormLabel>Formulaires reliés</FormLabel>
                        <div className='d-flex'>
                            {
                                formsLinked.map((ff, i) => <FormLinked key={ff.id} {...ff} index={i}
                                    deleteForm={() => {
                                        setFormsLinked([...formsLinked].filter(f => f.code != ff.code))
                                    }} />)
                            }
                        </div>
                    </Col>
                </Row>
            </Card.Body>
        </Card>

    </>
}

const FormLinked: React.FC<LinkedFormstoCoreg & { deleteForm: () => void, index: number }> = form => {
    return <div className='form-field-badge p-2 me-2 d-flex align-items-center'>
        [{form.id}] {form.name}
        <ButtonIcon className='ms-1' code={"trash"} size={"sm"} variant={"danger"} onClick={form.deleteForm} />
        <input type="hidden" name={`formCodes[${form.index}]`} value={form.code} />
    </div>
}


const sortForms = (forms: Array<LinkedFormstoCoreg>, formsLinked: Array<LinkedFormstoCoreg>, query: string) => {
    return [...forms]
        .filter(f => formsLinked.map(fl => fl.code).indexOf(f.code) == -1)
        .map(f => ({
            match: `${f.code} ${f.name}`.toLowerCase(),
            id: f.id,
            code: f.code,
            name: f.name,
        }))
        .filter(f => f.match.indexOf(query) > -1)
}
