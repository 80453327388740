
import { useEffect } from "react"
import { Badge, Modal } from "react-bootstrap"
import { formSuccessRedirect } from "../../app/actions"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import creationApi from "../../app/services/secured/creation"
import { isModalOpened, modalClose, ModalTypes } from "../../app/slice/modal"
import Urls from "../../assets/js/Urls"
import { LandingInfos } from "../LandingsV2/Components/Properties"
import { getLandingInfos, resetLP } from "../LandingsV2/landing-v2"
import { IfTrue } from "../misc/Blocks"
import { LoadingButton } from "../misc/Buttons"
import SimpleForm from "../misc/SimpleForm"
import ModalCloseButton from "../Modals/ModalCloseButton"

const ModalNewLanding = () => {
    const dispatch = useAppDispatch();
    const isOpened = useAppSelector(isModalOpened(ModalTypes.NewLandingV2));
    const [createLanding, createLandingRes] = creationApi.useCreateLandingV2Mutation();

    useEffect(() => {
        if (isOpened) dispatch(resetLP());
    }, [isOpened])

    dispatch(formSuccessRedirect({
        redirectUrl: createLandingRes.data && Urls.landing.editv2(createLandingRes.data.id as number), 
        data: createLandingRes,
        message: "Succès création landing page. Vous avez été redirigé vers l'édition."
    }));

    useEffect(() => {
        if (createLandingRes.isSuccess) dispatch(modalClose());
    }, [createLandingRes.isSuccess])

    const lInfos = useAppSelector(getLandingInfos);

    return <IfTrue condition={isOpened}>
        <SimpleForm onSubmit={(json) => createLanding(json)}>
            <Modal.Header>
                <h3 className='m-0'>Création Landing Page <Badge bg='danger'>V2</Badge></h3>
            </Modal.Header>
            <Modal.Body key={lInfos.id}>
                <LandingInfos />
                <input type='hidden' name='status' value='4' />
            </Modal.Body>
            <Modal.Footer>
                <ModalCloseButton />
                <LoadingButton data={createLandingRes}>Créer</LoadingButton>
            </Modal.Footer>
        </SimpleForm>
    </IfTrue>
}

export default ModalNewLanding;