import { useState } from "react"
import { Col, Form, Row } from "react-bootstrap"
import { User } from "../../../types"
import { FormInputGroup } from "../../misc/Blocks"

const UserRoles:Record<string, string> = {
    'ROLE_ADMIN': "Administrateur",
    'ROLE_SUPPORT': "Support",
};

export const UserForm: React.FC<Partial<User>> = user => {
    return <>
        <Row className='d-flex align-items-start'>
            <Col lg={9}>
                <FormInputGroup label='Email'>
                    <Form.Control type='text' name='email' placeholder='Email' defaultValue={user?.email} required />
                </FormInputGroup>
                <FormInputGroup label='Nom' className='mt-2'>
                    <Form.Control type='text' name='lastName' placeholder='Nom' defaultValue={user?.last_name} required />
                </FormInputGroup>
                <FormInputGroup label='Prénom' className='mt-2'>
                    <Form.Control type='text' name='firstName' placeholder='Prénom' defaultValue={user?.first_name} required />
                </FormInputGroup>
            </Col>
            
            <Col>
                <Form.Check type='switch' name='active' value={1} defaultChecked={user?.active}
                    label='Utilisateur actif' />

                <Form.Label className='mt-5'><b>Rôles</b></Form.Label>
                {Object.keys(UserRoles).map((role) => 
                    <Form.Check type='switch' key={Math.random()} name='roles[]' value={role} label={UserRoles[role]}
                        defaultChecked={user?.roles?.includes(role)}
                    />)}
            </Col>
        </Row>
    </>
}