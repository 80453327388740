import 'chart.js/auto';
import { useEffect, useState } from 'react';
import { Alert, Button, ButtonGroup } from 'react-bootstrap';
import { Chart } from 'react-chartjs-2';
import { useAppSelector } from '../../app/hooks';
import { getReportingData } from './reporting';
import { getChartData } from './ReportingClass';

const ReportingChart: React.FC = () => {
    const reporting = useAppSelector(getReportingData);
    const [dataCharts, setDataCharts] = useState({ volume: {}, cpl: {} });
    const [chartType, setChartType] = useState('date');

    useEffect(() => {
        if (reporting) {
            if (chartType === 'date') {
                const volumeLeads = getChartData(reporting, 'leads', 'date');
                const cpl = getChartData(reporting, 'cpl', 'date');
                setDataCharts({ ...dataCharts, volume: volumeLeads, cpl: cpl });
            } else {
                const volume = getChartData(reporting, 'leads', 'source');
                const cpl = getChartData(reporting, 'cpl', 'source');
                setDataCharts({ ...dataCharts, volume, cpl });
            }
        }
    }, [reporting, chartType])

    const dataKeys = Object.keys(dataCharts.volume);
    return <>
        <ButtonGroup className='mb-1'>
            <Button onClick={() => setChartType('date')}>Date</Button>
            <Button onClick={() => setChartType('sources')}>Sources</Button>
        </ButtonGroup>
        {
            dataKeys.length > 0 ? <>
                {chartType === 'sources' && <>
                    <Chart
                        type={'bar'}
                        options={{
                            responsive: true,
                            scales: {
                                y: {
                                    type: 'linear',
                                    display: true,
                                    position: 'left',
                                    beginAtZero: true,
                                },
                                y1: {
                                    type: 'linear',
                                    display: true,
                                    position: 'right',
                                    beginAtZero: true
                                }
                            }
                        }}
                        data={{
                            labels: dataKeys.map(k => k), // dates
                            datasets: [
                                {
                                    label: 'Volume Leads',
                                    data: Object.values(dataCharts.volume)?.map(v => v),
                                    backgroundColor: '#271E4BCC',
                                    borderColor: '#271E4BCC',
                                    yAxisID: 'y'
                                },
                                {
                                    label: 'CPL',
                                    data: Object.values(dataCharts.cpl)?.map(v => v),
                                    backgroundColor: 'rgb(255, 99, 132)',
                                    borderColor: 'rgb(255, 99, 132)',
                                    yAxisID: 'y1',
                                },
                            ],
                        }}

                    />
                </>}

                {chartType === 'date' && <>

                    <Chart
                        key={'line'}
                        type={'line'}
                        datasetIdKey='id'
                        options={{
                            responsive: true,
                            scales: {
                                y: {
                                    type: 'linear',
                                    display: true,
                                    position: 'left',
                                    beginAtZero: true,
                                },
                                y1: {
                                    type: 'linear',
                                    display: true,
                                    position: 'right',
                                    beginAtZero: true
                                }
                            }
                        }}
                        data={{
                            labels: dataKeys.map(k => new Date(k).toLocaleDateString('fr-FR')), // dates
                            datasets: [
                                {
                                    label: 'Volume Leads',
                                    data: Object.values(dataCharts.volume)?.map(v => v),
                                    backgroundColor: '#271E4BCC',
                                    borderColor: '#271E4BCC',
                                    yAxisID: 'y'
                                },
                                {
                                    label: 'CPL',
                                    data: Object.values(dataCharts.cpl)?.map(v => v),
                                    backgroundColor: 'rgb(255, 99, 132)',
                                    borderColor: 'rgb(255, 99, 132)',
                                    yAxisID: 'y1',
                                },
                            ],
                        }}
                    />
                </>}
            </>
                : <Alert>Aucune données pour la recherche effectuée.</Alert>
        }
    </>
}

export default ReportingChart;