import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ActionCreators } from 'redux-undo';
import creationApi from "../../app/services/secured/creation";
import {convertCheckPhoneOptions, LANDING_STATUSES} from "../../app/slice/landing";
import { AppThunk, RootState } from "../../app/store";
import { Choice, Database, FieldTypesString, Landing, LandingField, LandingQuizz, LandingQuizzAnswers } from "../../types";

export type View = 'page-design' | 'row-design' | 'block-design' | 'content-design' | 'modal-design' | 'info';
export type FieldType = FieldTypesString | 'post-optin' | 'optin';
export type FormStep = 'landing' | 'modal';
export type ContentType = 'landing' | 'modal' | 'text' | 'image' | 'video' | 'form' | 'code'
   | 'socials' | 'quizz' | 'question' | 'explanation' | 'answers' | 'row' | 'col' | 'cta'
   | Socials;
// export type ContainerLabel = '8-4' | '4-8' | '12' | '4-4-4' | '6-6' | 'Footer';
export type ModalSize = '' | 'sm' | 'xl' | 'lg';
export type PageSize = 'container' | 'fluid';
export type Socials = 'fb' | 'twitter' | 'linkedin' | 'instagram';

export const Container: LandingV2 = { childs: [], type: 'col', style: {} };

export type LandingV2 = {
   type: ContentType,
   childs: Array<LandingV2>,
   size?: number | ModalSize | PageSize,
   value?: string,
   style: GlobalStyle,
   attributes?: Attributes,
   fields?: Array<Fields>,
   questions?: Array<LandingQuizz & { isEdit?: boolean }>,
   mobileOrder?: string,
};

export type Fields = Partial<Pick<LandingField, 'title' | 'code' | 'mandatory' | 'validation_pattern' | 'helper'>> & {
   type: FieldType,
   display?: 'radio' | 'select' | 'checkbox' | 'button' | 'autocomplete',
   content?: string,
   isCheckable?: boolean,
   choices?: Array<Partial<Choice>>

   // front
   isEdit?: boolean,
}

export type Attributes = {
   cta?: string,
   postOptin?: string,
   title?: string,
   hasTitle?: boolean,
   postQuizz?: string,
   alt?: string,
}

export type Position = {
   row: number | null,
   col: number | null
   block: number | null,
}

export type LandingStep = 'landing' | 'modal' | 'info'

export type FrontLanding = {
   lp: LandingV2,
   modal: LandingV2,
   infos: LandingInfo,

   step: LandingStep,
   view: View,
   isPreview: boolean,
   isDuplicate: boolean,
   isDraft: boolean,

   current: Position,
   formPos: Position,
   quizzPos: Position,
}

export type LandingInfo = {
   id: number,
   category: number,
   code: string,
   company: number,
   created: number,
   description: string,
   dispatchDelay: number,
   minObjective: number,
   name: string,
   objective: number,
   status: number,
   theme: number,
   visible: boolean,
   seo_title: string,
   seo_desc: string,
   seo_img: string,
   share_title: string,
   share_desc: string,
   rs_share: boolean,
   rs_connect: boolean,
   options: {
      check_global_duplicate: boolean,
      database: Database | null,
      emailDatabase: number,
      emailValidation: boolean,
      phoneValidation: boolean,
      recaptcha: boolean,
      checkOptions: number | Array<number>,
   },
   register: LandingRegister,
   site: { domain: string, id: number },
}

export type LandingRegister = {
   isSensitiveData: boolean,
   objectives: string,
   recipientId: number,
   retentionMonth: number,
   sensitiveDataDetail: string,
   subcontractors: string,
   target: string,
   transfertCountries: Array<string>,
   timestamps: {
      created: number,
      updated: number
   }
};

export type GlobalStyle = {
   backgroundImage?: string,
   padding?: string,
   backgroundColor?: string,
   border?: string,
   borderRadius?: string,
   display?: string,
   alignItems?: string,
   justifyContent?: string,
   overflow?: string,
   margin?: string,
   height?: string,
   pageColor?: string,
   main_color?: string,
   secondary_color?: string,
   labels_display?: string,
   flexDirection?: FlexDirectionType,
   backgroundPosition?: string,
   backgroundSize?: string,
   backgroundRepeat?: string,
   backgroundHeight?: string,
   backgroundWidth?: string,
   backgroundOffset?: string,
   backgroundBlendMode?: string,
   minHeight?: string,
   colPadding?: string,
   textAlign?: any,
   color?: string,
   fontSize?: string,
}

export type FlexDirectionType = 'row' | 'inherit' | 'initial' | '-moz-initial' | 'revert' | 'unset' | 'column' | 'column-reverse' | 'row-reverse' | undefined;
type GlobalStyleValue = string | FlexDirectionType;

export type LandingAPI_V2 = Pick<Landing, 'id' | 'options' | 'visible' | 'status' | 'objective' | 'name' | 'minObjective'
   | 'dispatchDelay' | 'description' | 'code' | 'created' | 'site' | 'register' | 'fields' | 'quizz'> &
{
   category: number,
   company: number,
   theme: number,
   data: {
      //new datas
      lp: LandingV2,
      modal: LandingV2,
      version: 'v1' | 'v2',

      //old datas
      share_title: string,
      share_desc: string,
      rs_share: boolean,
      rs_connect: boolean,
      recaptcha: boolean,
      seo_title: string,
      seo_desc: string,
      seo_img: string,
   }
}

const initialState: FrontLanding = {
   isPreview: false,
   isDuplicate: false,
   isDraft: false,
   lp: {
      type: "landing",
      childs: [],
      style: { flexDirection: 'row' },
   },
   modal: {
      type: "modal",
      childs: [],
      style: { flexDirection: 'row' },
   },
   step: "landing",
   view: "page-design",
   current: {
      row: null,
      col: null,
      block: null
   },
   formPos: {
      row: null,
      col: null,
      block: null,
   },
   quizzPos: {
      row: null,
      col: null,
      block: null,
   },

   infos: {
      id: 0,
      category: 0,
      code: '',
      company: 0,
      created: new Date().getTime(),
      description: '',
      dispatchDelay: 0,
      minObjective: 0,
      name: '',
      objective: 0,
      status: 0,
      theme: 0,
      visible: false,
      rs_connect: false,
      rs_share: false,
      seo_desc: '',
      seo_title: '',
      seo_img: '',
      share_desc: '',
      share_title: '',
      options: {
         check_global_duplicate: false,
         database: null,
         emailDatabase: 0,
         emailValidation: false,
         phoneValidation: false,
         recaptcha: false,
         checkOptions: [0, 0],
      },
      register: {
         isSensitiveData: false,
         objectives: '',
         recipientId: 0,
         retentionMonth: 12,
         sensitiveDataDetail: '',
         subcontractors: '',
         target: '',
         transfertCountries: [],
         timestamps: {
            created: 0,
            updated: 0
         }
      },
      site: { domain: '', id: 0 },
   }
}

export const LpGenerator = createSlice({
   name: 'landingv2',
   initialState,
   reducers: {
      setInit: (state: FrontLanding, action: PayloadAction<FrontLanding>) => {
         state.current = action.payload.current;
         state.formPos = action.payload.formPos;
         state.isPreview = action.payload.isPreview;
         state.lp = action.payload.lp;
         state.modal = action.payload.modal;
         state.quizzPos = action.payload.quizzPos;
         state.step = action.payload.step;
         state.view = action.payload.view;
      },
      setStep: (state: FrontLanding, action: PayloadAction<LandingStep>) => {
         state.step = action.payload;
         state.current = { row: null, col: null, block: null }

         switch (action.payload) {
            case 'landing': state.view = 'page-design'; break;
            case 'modal': state.view = 'modal-design'; break;
            case 'info': state.view = 'info'; break;
         }
      },
      setIsPreview: (state: FrontLanding, action: PayloadAction<boolean>) => {
         state.view = state.step === 'landing' ? 'page-design' : 'modal-design';
         state.isPreview = action.payload;
         state.current = { row: null, col: null, block: null }
      },
      validateBlock: (state: FrontLanding) => {
         state.view = state.step === 'landing' ? 'page-design' : 'modal-design';
         state.current = { row: null, col: null, block: null }
         saveLpDraft(state);
      },
      setPageDesign: (state: FrontLanding, action: PayloadAction<{ option: keyof GlobalStyle, value: GlobalStyleValue }>) => {
         const node = getNode(state);
         if (!node.style) node.style = {};
         if (action.payload.option !== 'flexDirection') node.style = { ...node.style, [`${action.payload.option}`]: action.payload.value }
         else node.style = { ...node.style, [`${action.payload.option}`]: action.payload.value as FlexDirectionType }
         saveLpDraft(state);
      },
      setPageSize: (state: FrontLanding, action: PayloadAction<PageSize>) => {
         state.lp.size = action.payload;
         saveLpDraft(state);
      },
      addRow: (state: FrontLanding, action: PayloadAction<number>) => {
         const colSize = Math.floor(12 / action.payload);
         const cols: Array<LandingV2> = [];
         let sizeLeft = 12;
         if (action.payload > 0) {
            for (var i = 0; i < action.payload; i++) {
               sizeLeft -= colSize;
               const colS = sizeLeft > 0 && i === action.payload - 1 ? colSize + sizeLeft : colSize;
               cols.push({ ...Container, size: colS });
            }
            getNode(state).childs.push({ type: 'row', childs: cols, style: {} });
            saveLpDraft(state);
         }
      },
      addRowTemplate: (state: FrontLanding, action: PayloadAction<Array<LandingV2>>) => {
         getNode(state).childs.push({ type: 'row', childs: action.payload, style: { flexDirection: 'row' } });
         saveLpDraft(state);
      },
      moveRow: (state: FrontLanding, action: PayloadAction<{ row: number, direction: number }>) => {
         const index = action.payload.row;
         const newIndex = index + action.payload.direction;
         const node = getNode(state);

         const tmp = node.childs[newIndex];
         node.childs[newIndex] = node.childs[index];
         node.childs[index] = tmp;

         state.formPos = findForm(state);
         state.quizzPos = findQuizz(state);
         saveLpDraft(state);
      },
      deleteRow: (state: FrontLanding, action: PayloadAction<{ row: number }>) => {
         const node = getNode(state);
         node.childs = node.childs.filter((l, i) => i !== action.payload.row);
         state.formPos = findForm(state);
         state.quizzPos = findQuizz(state);
         saveLpDraft(state);
      },
      duplicateRow: (state: FrontLanding, action: PayloadAction<number>) => {
         const node = getNode(state);
         const row = node.childs.find((r, i) => i === action.payload);
         if (row) {
            node.childs.splice(action.payload++, 0, row);
            state.formPos = findForm(state);
            state.quizzPos = findQuizz(state);
            saveLpDraft(state);
         }
      },
      setRowDesign: (state: FrontLanding, action: PayloadAction<{ option: keyof GlobalStyle, value: GlobalStyleValue }>) => {
         if (state.current.row !== null) {
            if (action.payload.option !== 'flexDirection') {
               getNode(state).childs[state.current.row].style[action.payload.option] = action.payload.value;
            } else {
               getNode(state).childs[state.current.row].style[action.payload.option] = action.payload.value as FlexDirectionType;
            }
            saveLpDraft(state);
         }
      },
      setRowGlobalDesign: (state: FrontLanding, action: PayloadAction<GlobalStyle>) => {
         if (state.current.row !== null) {
            getNode(state).childs[state.current.row].style = action.payload;
            saveLpDraft(state);
         }
      },
      setColSize: (state: FrontLanding, action: PayloadAction<{ col: number, size: number }>) => {
         if (state.current.row !== null) {
            const node = getNode(state).childs[state.current.row];
            node.childs[action.payload.col].size = action.payload.size;
            saveLpDraft(state);
         }
      },
      editBlock: (state: FrontLanding, action: PayloadAction<{ row: number, col: number }>) => {
         state.view = 'block-design';
         state.current.row = action.payload.row;
         state.current.col = action.payload.col;
      },
      addContent: (state: FrontLanding, action: PayloadAction<ContentType>) => {
         if (state.current.row !== null && state.current.col !== null) {
            const node = getNode(state);
            const nodeContent = node.childs[state.current.row].childs[state.current.col];

            let nodeIndex = null;
            if (nodeContent?.childs) {
               const newN = [...nodeContent.childs];
               nodeIndex = newN.push({ type: action.payload, childs: [], style: { flexDirection: 'row' } }) - 1;
               nodeContent.childs = newN;
            } else {
               nodeContent.childs = [{ type: action.payload, childs: [], style: { flexDirection: 'row' } }];
               nodeIndex = 0;
            }

            if (nodeIndex !== null) {
               if (action.payload === 'form') {
                  state.formPos.row = state.current.row;
                  state.formPos.col = state.current.col;
                  state.formPos.block = nodeIndex;
               }
               else if (action.payload === 'quizz') {
                  state.quizzPos.row = state.current.row;
                  state.quizzPos.col = state.current.col;
                  state.quizzPos.block = nodeIndex;
               }
            }
            saveLpDraft(state);
         }
      },
      moveContent: (state: FrontLanding, action: PayloadAction<{ block: number, direction: number }>) => {
         const index = action.payload.block;
         const newIndex = index + action.payload.direction;
         if (state.current.row !== null && state.current.col !== null) {
            const node = getNode(state).childs[state.current.row].childs[state.current.col];
            const tmp = node.childs[newIndex];
            node.childs[newIndex] = node.childs[index];
            node.childs[index] = tmp;
         }

         state.formPos = findForm(state);
         saveLpDraft(state);
      },
      deleteContent: (state: FrontLanding, action: PayloadAction<{ block: number }>) => {
         if (state.current.row !== null && state.current.col !== null) {
            getNode(state).childs[state.current.row].childs[state.current.col].childs =
               getNode(state).childs[state.current.row].childs[state.current.col].childs.filter((c, i) => i !== action.payload.block);
            state.formPos = findForm(state);
            saveLpDraft(state);
         }
      },
      setTextContent: (state: FrontLanding, action: PayloadAction<{ block: number, value: string }>) => {
         if (state.current.row !== null && state.current.col !== null) {
            const node = getNode(state).childs[state.current.row].childs[state.current.col];
            const index = action.payload.block;
            node.childs[index] = { ...node.childs[index], value: action.payload.value, type: 'text' };
            saveLpDraft(state);
         }
      },
      setImage: (state: FrontLanding, action: PayloadAction<{ block: number, url: string }>) => {
         if (state.current.row !== null && state.current.col !== null) {
            const node = getNode(state).childs[state.current.row].childs[state.current.col];
            const index = action.payload.block;
            node.childs[index] = { ...node.childs[index], value: action.payload.url, type: 'image', style: { height: '90', flexDirection: 'row' } };
            saveLpDraft(state);
         }
      },
      setVideo: (state: FrontLanding, action: PayloadAction<{ block: number, embed: string }>) => {
         if (state.current.row !== null && state.current.col !== null) {
            const node = getNode(state).childs[state.current.row].childs[state.current.col];
            const index = action.payload.block;
            node.childs[index] = { ...node.childs[index], value: action.payload.embed, type: 'video' };
            saveLpDraft(state);
         }
      },
      setContentValue: (state: FrontLanding, action: PayloadAction<{ index: number, value: string }>) => {
         if (state.current.row !== null && state.current.col !== null) {
            const node = getNode(state).childs[state.current.row].childs[state.current.col];
            node.childs[action.payload.index] = { ...node.childs[action.payload.index], value: action.payload.value };
            saveLpDraft(state);
         }
      },
      setContent: (state: FrontLanding, action: PayloadAction<{ index: number, node: LandingV2 }>) => {
         if (state.current.row !== null && state.current.col !== null) {
            const node = getNode(state).childs[state.current.row].childs[state.current.col];
            node.childs[action.payload.index] = action.payload.node;
            saveLpDraft(state);
         }
      },
      editContentDesign: (state: FrontLanding, action: PayloadAction<{ block: number }>) => {
         state.current.block = null;
         state.view = 'content-design';
         state.current.block = action.payload.block;
      },
      editRowDesign: (state: FrontLanding, action: PayloadAction<{ row: number }>) => {
         state.view = 'row-design';
         state.current.row = action.payload.row;
      },
      validateContent: (state: FrontLanding) => {
         state.view = 'block-design';
         state.current.block = null;
      },
      setColumnDesign: (state: FrontLanding, action: PayloadAction<GlobalStyle>) => {
         if (state.current.row !== null && state.current.col !== null) {
            const node = getNode(state).childs[state.current.row].childs[state.current.col];
            node.style = action.payload;
            saveLpDraft(state);
         }
      },
      setBlockDesign: (state: FrontLanding, action: PayloadAction<{ option: keyof GlobalStyle, value: string }>) => {
         if (state.current.row !== null && state.current.col !== null && action.payload.value && action.payload.option) {
            const nodeOptions = getNode(state).childs[state.current.row].childs[state.current.col];

            if (!nodeOptions.style) nodeOptions.style = {};
            if (action.payload.option !== 'flexDirection') {
               nodeOptions.style[action.payload.option] = action.payload.value;
            } else {
               nodeOptions.style[action.payload.option] = action.payload.value as FlexDirectionType;
            }
            saveLpDraft(state);
         }
      },
      setContentDesign: (state: FrontLanding, action: PayloadAction<{ option: keyof GlobalStyle, value: string }>) => {
         if (state.current.row !== null && state.current.col !== null && state.current.block !== null) {
            const node = getNode(state).childs[state.current.row].childs[state.current.col].childs[state.current.block].style;
            if (action.payload.option !== 'flexDirection') {
               node[action.payload.option] = action.payload.value;
            } else {
               node[action.payload.option] = action.payload.value as FlexDirectionType;
            }
            saveLpDraft(state);
         }
      },
      setFormDesign: (state: FrontLanding, action: PayloadAction<{ option: keyof GlobalStyle, value: string }>) => {
         const formNode = getFormNode(state);
         if (formNode) {
            if (action.payload.option !== 'flexDirection') {
               formNode.style[action.payload.option] = action.payload.value;
            } else {
               formNode.style[action.payload.option] = action.payload.value as FlexDirectionType;
            }
            saveLpDraft(state);
         }
      },
      setModalSize: (state: FrontLanding, action: PayloadAction<ModalSize>) => {
         state.modal.size = action.payload;
         saveLpDraft(state);
      },
      addField: (state: FrontLanding, action: PayloadAction<{ type: FieldType, code?: string, title?: string, isEdit?: boolean, choices?: Array<Choice>, mandatory?: boolean, }>) => {
         const formNode = getFormNode(state);
         if (formNode) {
            const form = formNode.fields ? [...formNode.fields] : [];
            const type = action.payload.type;

            let obj: Fields = {
               type,
               isEdit: typeof (action.payload.isEdit) !== 'undefined' ? action.payload.isEdit : true,
               code: action.payload.code,
               title: action.payload.title,
               choices: action.payload.choices,
               mandatory: action.payload.mandatory,
            };

            switch (type) {
               case 'C':
                  obj.display = 'radio';
                  break;
               case 'B':
                  obj.choices = [{ title: 'Vrai', code: 'true', is_valid_answer: true }, { title: 'Faux', code: 'false', is_valid_answer: false }];
                  break;
            }

            action.payload.code !== 'email' ? form.push(obj) : form.unshift(obj);
            formNode.fields = form;
            saveLpDraft(state);
         }
      },
      addChoice: (state: FrontLanding, action: PayloadAction<{ fieldIndex: number }>) => {
         const formNode = getFormNode(state);
         if (formNode && formNode.fields) {
            const node = formNode.fields[action.payload.fieldIndex].choices;
            if (node) {
               node.push({});
               saveLpDraft(state);
            }
         }
      },
      deleteChoice: (state: FrontLanding, action: PayloadAction<{ fieldIndex: number, choiceIndex: number }>) => {
         const formNode = getFormNode(state);
         if (formNode && formNode.fields) {
            const node = formNode.fields[action.payload.fieldIndex].choices;
            if (node) {
               formNode.fields[action.payload.fieldIndex].choices = node.filter((c, i) => i !== action.payload.choiceIndex);
               saveLpDraft(state);
            }
         }
      },
      setField: (state: FrontLanding, action: PayloadAction<{ fieldIndex: number, field: Fields }>) => {
         const formNode = getFormNode(state);
         if (formNode && formNode.fields) {
            formNode.fields[action.payload.fieldIndex] = action.payload.field;
            saveLpDraft(state);
         }
      },
      moveField: (state: FrontLanding, action: PayloadAction<{ fieldIndex: number, direction: number }>) => {
         const formNode = getFormNode(state);
         if (formNode && formNode.fields) {
            const node = formNode.fields[action.payload.fieldIndex];
            const newIndex = action.payload.fieldIndex + action.payload.direction;
            const tmp = formNode.fields[newIndex];
            if (node) {
               formNode.fields[newIndex] = node;
               formNode.fields[action.payload.fieldIndex] = tmp;
               saveLpDraft(state);
            }
         }
      },
      deleteField: (state: FrontLanding, action: PayloadAction<{ fieldIndex: number }>) => {
         const formNode = getFormNode(state);
         if (formNode && formNode.fields) {
            formNode.fields = formNode.fields.filter((f, i) => i !== action.payload.fieldIndex);
            saveLpDraft(state);
         }
      },
      setChoice: (state: FrontLanding, action: PayloadAction<{ fieldIndex: number, choiceIndex: number, choice: Choice }>) => {
         const formNode = getFormNode(state);
         if (formNode && formNode.fields) {
            const node = formNode.fields[action.payload.fieldIndex].choices;
            if (node) {
               node[action.payload.choiceIndex] = action.payload.choice;
               if (action.payload.choice.default === true) {
                  node.filter((c, i) => i !== action.payload.choiceIndex).map(c => c.default = false);
                  saveLpDraft(state);
               }
            }
         }
      },
      setFieldIsEdit: (state: FrontLanding, action: PayloadAction<{ fieldIndex: number, isFieldEdit: boolean }>) => {
         const formNode = getFormNode(state);
         if (formNode && formNode.fields) {
            formNode.fields[action.payload.fieldIndex].isEdit = action.payload.isFieldEdit;
         }
      },
      setFormData: (state: FrontLanding, action: PayloadAction<{ option: keyof Attributes, value: string | 'classic' | 'extended' | boolean }>) => {
         const formNode = getFormNode(state);
         if (formNode) {
            if (!formNode.attributes) { formNode.attributes = {}; }
            if (action.payload.option === 'hasTitle') { formNode.attributes[action.payload.option] = action.payload.value as boolean; }
            else { formNode.attributes[action.payload.option] = action.payload.value as string; }
            saveLpDraft(state);
         }
      },
      setModalTitle: (state: FrontLanding, action: PayloadAction<string>) => {
         if (!state.modal.attributes) state.modal.attributes = {};
         state.modal.attributes.title = action.payload;
         saveLpDraft(state);
      },
      setColumnOrder: (state: FrontLanding, action: PayloadAction<string>) => {
         if (state.current.row !== null && state.current.col !== null) {
            getNode(state).childs[state.current.row].childs[state.current.col].mobileOrder = action.payload;
            saveLpDraft(state);
         }
      },
      addQuestion: (state: FrontLanding) => {
         const quizz = getQuizzNode(state);
         if (quizz) {
            if (!quizz.questions) quizz.questions = [];
            quizz.questions.push({ answers: [], question: '', explanation: '', isEdit: true });
            saveLpDraft(state);
         }
      },
      moveQuestion: (state: FrontLanding, action: PayloadAction<{ questionIndex: number, direction: number }>) => {
         const quizz = getQuizzNode(state);
         if (quizz && quizz.questions) {
            const toMove = quizz.questions[action.payload.questionIndex];
            const newIndex = action.payload.questionIndex + action.payload.direction;
            const cache = quizz.questions[newIndex];
            quizz.questions[newIndex] = toMove;
            quizz.questions[action.payload.questionIndex] = cache;
            saveLpDraft(state);
         }
      },
      setQuestion: (state: FrontLanding, action: PayloadAction<{ qIndex: number, option: 'question' | 'explanation', value: string }>) => {
         const quizz = getQuizzNode(state);
         if (quizz && quizz.questions) {
            quizz.questions[action.payload.qIndex][action.payload.option] = action.payload.value;
            saveLpDraft(state);
         }
      },
      setQuestionIsEdit: (state: FrontLanding, action: PayloadAction<{ questionIndex: number, isEdit: boolean }>) => {
         const quizz = getQuizzNode(state);
         if (quizz && quizz.questions) {
            quizz.questions[action.payload.questionIndex].isEdit = action.payload.isEdit;
            saveLpDraft(state);
         }
      },
      addAnswer: (state: FrontLanding, action: PayloadAction<{ nodeIndex: number }>) => {
         const quizz = getQuizzNode(state);
         if (quizz && quizz.questions) {
            quizz.questions[action.payload.nodeIndex].answers.push({
               answer: "",
               good: false,
               code: quizz.questions[action.payload.nodeIndex].answers.length,
            });
            saveLpDraft(state);
         }
      },
      setAnswer: (state: FrontLanding, action: PayloadAction<{ nodeIndex: number, answerIndex: number, answer: LandingQuizzAnswers }>) => {
         const quizz = getQuizzNode(state);
         if (quizz && quizz.questions) {
            quizz.questions[action.payload.nodeIndex].answers[action.payload.answerIndex] = action.payload.answer;
            saveLpDraft(state);
         }
      },
      deleteAnswer: (state: FrontLanding, action: PayloadAction<{ nodeIndex: number, answerIndex: number }>) => {
         const quizz = getQuizzNode(state);
         if (quizz && quizz.questions) {
            quizz.questions[action.payload.nodeIndex].answers = quizz.questions[action.payload.nodeIndex].answers.filter((a, i) => i !== action.payload.answerIndex)
            saveLpDraft(state);
         }
      },
      deleteQuestion: (state: FrontLanding, action: PayloadAction<{ questionIndex: number }>) => {
         const quizz = getQuizzNode(state);
         if (quizz && quizz.questions) {
            quizz.questions = quizz.questions.filter((q, i) => i !== action.payload.questionIndex);
            saveLpDraft(state);
         }
      },
      setModalModel: (state: FrontLanding, action: PayloadAction<'classic' | 'extended'>) => {
         if (!state.modal.style) { state.modal.style = {}; }
         state.modal.style = { ...state.modal.style, display: action.payload };
         if (action.payload === 'extended') state.modal.size = 'xl';
         saveLpDraft(state);
      },
      setModalStyle: (state: FrontLanding, action: PayloadAction<GlobalStyle>) => {
         state.modal.style = action.payload;
         saveLpDraft(state);
      },
      setQuizzKey: (state: FrontLanding, action: PayloadAction<string>) => {
         const quizz = getQuizzNode(state);
         if (quizz) {
            quizz.value = action.payload;
            saveLpDraft(state);
         }
      },
      deleteCol: (state: FrontLanding, action: PayloadAction<number>) => {
         if (state.current.row !== null) {
            const node = getNode(state);
            node.childs[state.current.row].childs = node.childs[state.current.row].childs.filter((col, i) => i !== action.payload);
            saveLpDraft(state);
         }
      },
      addCol: (state: FrontLanding) => {
         if (state.current.row !== null) {
            getNode(state).childs[state.current.row].childs.push({ ...Container, size: 1 });
            saveLpDraft(state);
         }
      },
      setLandingInfo: (state: FrontLanding, action: PayloadAction<LandingInfo>) => {
         state.infos = action.payload;
      },
      setQuizzEnd: (state: FrontLanding, action: PayloadAction<string>) => {
         const quizz = getQuizzNode(state);
         if (quizz) {
            if (!quizz.attributes) quizz.attributes = {};
            quizz.attributes.postQuizz = action.payload;
            saveLpDraft(state);
         }
      },
      setPageStyle: (state: FrontLanding, action: PayloadAction<GlobalStyle>) => {
         state.lp.style = action.payload;
         saveLpDraft(state);
      },
      moveColumn: (state: FrontLanding, action: PayloadAction<{ col: number, direction: number }>) => {
         if (state.current.row !== null) {
            const node = getNode(state);
            const iMove = node.childs[state.current.row].childs.findIndex((c, i) => i === action.payload.col);
            if (iMove > -1) {
               const newI = iMove + action.payload.direction;
               const tmp = node.childs[state.current.row].childs.find((c, j) => j === newI);
               if (tmp) {
                  node.childs[state.current.row].childs[newI] = node.childs[state.current.row].childs[iMove];
                  node.childs[state.current.row].childs[iMove] = tmp;
                  state.formPos = findForm(state);
                  state.quizzPos = findQuizz(state);
                  saveLpDraft(state);
               }
            }
         }
      },
      handleSocials: (state: FrontLanding, action: PayloadAction<{ block: number, active: boolean, rs: Socials }>) => {
         if (state.current.row !== null && state.current.col !== null) {
            const node = getNode(state);
            const block = node.childs[state.current.row].childs[state.current.col].childs[action.payload.block];
            if (action.payload.active) { block.childs.push({ type: action.payload.rs, style: {}, childs: [] }); }
            else { block.childs = block.childs.filter(f => f.type !== action.payload.rs); }
            saveLpDraft(state);
         }
      },
      resetLP: (state: FrontLanding) => {
         state.infos = initialState.infos;
         state.formPos = initialState.formPos;
         state.current = initialState.current;
         state.isPreview = initialState.isPreview;
         state.lp = initialState.lp;
         state.modal = initialState.modal;
         state.quizzPos = initialState.quizzPos;
         state.step = initialState.step;
         state.view = initialState.view;
      },
      setRegister: (state, action: PayloadAction<LandingRegister>) => {
         state.infos.register = action.payload;
         saveLpDraft(state);
      },
      setIsDuplicate: (state, action: PayloadAction<boolean>) => {
         state.isDuplicate = action.payload;
         if (action.payload === true) {
            delLPDraft(state.infos.id);
         }
      }
   },
   extraReducers: (builder) => {
      builder
         .addMatcher(creationApi.endpoints.getLanding.matchFulfilled,
            (state, action: PayloadAction<Landing>) => {

               if (action.payload.data.version === 'v2') {
                  const draft = !state.isDuplicate ? getLPDraft(action.payload.id) : null;
                  if (!draft) {
                     state.isDraft = false;
                     state.lp = action.payload.data.lp ? action.payload.data.lp : { type: 'landing', childs: [], style: {} };
                     state.modal = action.payload.data.modal ? action.payload.data.modal : { type: 'landing', childs: [], style: {} };
                     state.formPos = findForm(state);
                     state.quizzPos = findQuizz(state);
                  }
                  else {
                     const dObj = JSON.parse(draft) as FrontLanding;
                     state.current = dObj.current;
                     state.formPos = dObj.formPos;
                     state.quizzPos = dObj.quizzPos;
                     state.isDraft = true;
                     state.isPreview = false;
                     state.lp = dObj.lp;
                     state.modal = dObj.modal;
                     state.step = dObj.step;
                     state.view = 'page-design';
                  }

                  // from server
                  state.infos.id = action.payload.id;
                  state.infos.name = action.payload.name;
                  state.infos.code = action.payload.code;
                  state.infos.status = action.payload.status;
                  state.infos.options.database = action.payload.options.database ? action.payload.options.database : null;
                  state.infos.options.emailDatabase = action.payload.options.database ? action.payload.options.database.id : 0;

                  state.infos.company = action.payload.company?.id ? action.payload.company.id : 0;
                  state.infos.category = action.payload.category?.id ? action.payload.category.id : 0;
                  state.infos.theme = action.payload.theme?.id ? action.payload.theme.id : 0;
                  state.infos.site.domain = action.payload.site.domain;

                  state.infos.options.phoneValidation = action.payload.options.phoneValidation;
                  state.infos.options.emailValidation = action.payload.options.emailValidation;
                  state.infos.rs_connect = action.payload.data.rs_connect ? true : false;
                  state.infos.rs_share = action.payload.data.rs_share ? true : false;
                  state.infos.seo_title = action.payload.data.seo_title ? action.payload.data.seo_title : '';
                  state.infos.seo_desc = action.payload.data.seo_desc ? action.payload.data.seo_desc : '';
                  state.infos.seo_img = action.payload.data.seo_img ? action.payload.data.seo_img : '';
                  state.infos.share_title = action.payload.data.share_title;
                  state.infos.share_desc = action.payload.data.share_desc;
                  state.infos.options.recaptcha = action.payload.data.recaptcha ? true : false;
                  state.infos.options.check_global_duplicate = action.payload.options.check_global_duplicate ? true : false;

                  state.infos.options.checkOptions = convertCheckPhoneOptions(action.payload.options.checkOptions ? action.payload.options.checkOptions as number : 0);

                  if (action.payload.register) {
                     state.infos.register.isSensitiveData = action.payload.register.isSensitiveData;
                     state.infos.register.objectives = action.payload.register.objectives;
                     state.infos.register.recipientId = action.payload.register.recipientId;
                     state.infos.register.retentionMonth = action.payload.register.retentionMonth;
                     state.infos.register.sensitiveDataDetail = action.payload.register.sensitiveDataDetail;
                     state.infos.register.subcontractors = action.payload.register.subcontractors;
                     state.infos.register.target = action.payload.register.target;
                     state.infos.register.timestamps = action.payload.register.timestamps;
                     state.infos.register.transfertCountries = action.payload.register.transfertCountries;
                  }

                  // if duplicate, reset RGPD & status
                  if (state.isDuplicate) {
                     state.infos.register = initialState.infos.register;
                     state.infos.status = LANDING_STATUSES.DRAFT;

                  }
               }
            }
         )
         .addMatcher(creationApi.endpoints.saveLandingV2.matchFulfilled,
            (state: FrontLanding) => {
               delLPDraft(state.infos.id);
               state.isPreview = false;
               state.isDuplicate = false;
               state.isDraft = false;
               state.current = { row: null, col: null, block: null };
               state.formPos = { row: null, col: null, block: null };
               state.quizzPos = { row: null, col: null, block: null };
               state.lp = initialState.lp;
               state.modal = initialState.modal;
               state.step = initialState.step;
               state.view = initialState.view;
               state.infos = initialState.infos;
            })
         .addMatcher(creationApi.endpoints.createLandingV2.matchFulfilled,
            (state: FrontLanding) => {
               state.isDuplicate = false;
            })
   }
})

export const undoLPAction = (): AppThunk => dispatch => {
   dispatch(ActionCreators.undo());
}
export const redoLPAction = (): AppThunk => dispatch => {
   dispatch(ActionCreators.redo());
}

export const saveLpDraft = (lp: FrontLanding) => {
   if (lp.infos.id && !lp.isDuplicate) {
      lp.isDraft = true;
      return localStorage.setItem('lp-' + lp.infos.id, JSON.stringify(lp));
   }
   return null;
}

export const getLPDraft = (id: number) => {
   return localStorage.getItem('lp-' + id.toString());
}

export const delLPDraft = (id: number) => {
   // console.log('delete!');
   return localStorage.removeItem('lp-' + id.toString());
}

export const getLandingState = (state: RootState) => state.landingv2.present;
export const getNode = (state: FrontLanding) => state.step === 'landing' ? state.lp : state.modal;
export const getPageDesign = (state: RootState) => state.landingv2.present.lp.style;
export const getLP = (state: RootState) => state.landingv2.present.lp;
export const getView = (state: RootState): View => state.landingv2.present.view;

export const getModal = (state: RootState) => state.landingv2.present.modal;
export const getStep = (state: RootState) => state.landingv2.present.step;
export const getIsPreview = (state: RootState): boolean => state.landingv2.present.isPreview;

export const getModalContent = (state: RootState) => state.landingv2.present.modal.childs;

export const findForm = (state: FrontLanding) => {
   const formPos: Position = { row: null, col: null, block: null };
   state.lp.childs.map((row, rowIndex) => {
      row.childs.map((col, colIndex) => {
         col.childs.map((block, blockIndex) => {
            if (block.type === 'form') {
               formPos.row = rowIndex;
               formPos.col = colIndex;
               formPos.block = blockIndex;
            }
         })
      })
   });

   return formPos;
}

export const findQuizz = (state: FrontLanding) => {
   const quizzPos: Position = { row: null, col: null, block: null };
   state.modal.childs.map((row, rowIndex) => {
      row.childs.map((col, colIndex) => {
         col.childs.map((block, blockIndex) => {
            if (block.type === 'quizz') {
               quizzPos.row = rowIndex;
               quizzPos.col = colIndex;
               quizzPos.block = blockIndex;
            }
         })
      })
   });

   return quizzPos;
}

// Formulaire
export const getForm = (state: RootState) => getFormNode(state.landingv2.present);
export const getFormNode = (state: FrontLanding) => state.formPos.row !== null && state.formPos.col !== null && state.formPos.block !== null
   ? state.lp.childs[state.formPos.row].childs[state.formPos.col].childs[state.formPos.block] : null;

export const getFormStyle = (state: RootState) => getFormNode(state.landingv2.present)?.style;
export const getFormFields = (state: RootState) => getFormNode(state.landingv2.present)?.fields ? getFormNode(state.landingv2.present)?.fields : [];
export const getFormAttributes = (state: RootState) => getFormNode(state.landingv2.present)?.attributes;
export const getFormColumnIsEdit = (state:RootState) => state.landingv2.present.formPos.row === state.landingv2.present.current.row && state.landingv2.present.current.col === state.landingv2.present.formPos.col;

export const getChoices = (fieldIndex: number) => (state: RootState) => {
   const formNode = getFormNode(state.landingv2.present);
   return formNode?.fields ? formNode.fields[fieldIndex].choices : [];
}
export const getField = (fieldIndex: number) => (state: RootState) => {
   const formNode = getFormNode(state.landingv2.present);
   return formNode?.fields ? formNode.fields[fieldIndex] : null;
}
export const getFormLength = (state: RootState): number => {
   const formNode = getFormNode(state.landingv2.present);
   return formNode?.fields ? formNode.fields.length : 0;
}
export const hasQuizz = (state: RootState) => state.landingv2.present.quizzPos.row !== null && state.landingv2.present.quizzPos.col !== null && state.landingv2.present.quizzPos.block !== null ? true : false;
export const hasForm = (state: RootState) => state.landingv2.present.formPos.row !== null && state.landingv2.present.formPos.col !== null && state.landingv2.present.formPos.block !== null ? true : false;
export const getQuizzNode = (state: FrontLanding) => state.quizzPos.row !== null && state.quizzPos.col !== null && state.quizzPos.block !== null
   ? state.modal.childs[state.quizzPos.row]?.childs[state.quizzPos.col]?.childs[state.quizzPos.block] : null;

export const getQuizz = (state: RootState) => getQuizzNode(state.landingv2.present)?.questions;
export const getQuestion = (qIndex: number) => (state: RootState) => {
   const node = getQuizzNode(state.landingv2.present);
   return node?.questions ? node.questions[qIndex] : null;
}

export const getColumn = (row: number, col: number) =>
   (state: RootState) => getNode(state.landingv2.present).childs[row].childs[col];

export const getColumnContent = (row: number, col: number) =>
   (state: RootState) => getNode(state.landingv2.present).childs[row].childs[col].childs;

export const getCurrentRow = (state: RootState) => state.landingv2.present.current.row !== null
   ? getNode(state.landingv2.present).childs[state.landingv2.present.current.row]
   : null;

export const getCurrentColumn = (state: RootState) =>
   state.landingv2.present.current.row !== null && state.landingv2.present.current.col !== null
      ? getNode(state.landingv2.present).childs[state.landingv2.present.current.row].childs[state.landingv2.present.current.col]
      : null;

export const getCurrentContent = (state: RootState) => state.landingv2.present.current.row !== null
   && state.landingv2.present.current.col !== null && state.landingv2.present.current.block !== null
   ? getNode(state.landingv2.present).childs[state.landingv2.present.current.row].childs[state.landingv2.present.current.col].childs[state.landingv2.present.current.block]
   : null;

export const isEditingColumn = (row: number, col: number) => (state: RootState): boolean =>
   state.landingv2.present.current.row === row && state.landingv2.present.current.col === col
      ? true : false

export const getContent = (pos: Position) => (state: RootState) => pos.row !== null && pos.col !== null && pos.block !== null
   ? getNode(state.landingv2.present).childs[pos.row].childs[pos.col].childs[pos.block]
   : null;

export const isEditingContent = (row: number, col: number, block: number) => (state: RootState): boolean =>
   state.landingv2.present.current.row === row && state.landingv2.present.current.col === col
      && state.landingv2.present.current.block === block ? true : false

export const getRow = (rowIndex: number) => (state: RootState) => getNode(state.landingv2.present).childs[rowIndex];

export const isEditingRow = (rowIndex: number) => (state: RootState) =>
   state.landingv2.present.view === 'row-design' && state.landingv2.present.current.row === rowIndex ? true : false;

export const getModalModel = (state: RootState) => state.landingv2.present.modal.style.display;

export const getQuizzKey = (state: RootState) => getQuizzNode(state.landingv2.present)?.value;
export const getQuizzLength = (state: RootState) => getQuizzNode(state.landingv2.present)?.questions?.length;

export const getLandingInfos = (state: RootState): LandingInfo => state.landingv2.present.infos;
export const getQuizzEnd = (state: RootState) => getQuizzNode(state.landingv2.present)?.attributes?.postQuizz;
export const getLandingRegister = (state: RootState) => state.landingv2.present.infos.register;
export const getIsDuplicate = (state: RootState) => state.landingv2.present.isDuplicate;

export default LpGenerator.reducer;
export const { setInit, setStep, setIsPreview, addRow, moveRow, editBlock, addContent, setTextContent, moveContent, setImage, setVideo,
   deleteContent, validateBlock, editContentDesign, validateContent, setPageDesign, setBlockDesign,
   setContentDesign, setFormDesign, setModalSize, setRowDesign, deleteRow, addField, addChoice, setFormData, setModalTitle,
   deleteChoice, deleteField, moveField, setChoice, setField, setFieldIsEdit, setColumnOrder, addQuestion, setAnswer, addAnswer,
   moveQuestion, setQuestionIsEdit, setQuestion, deleteAnswer, editRowDesign, deleteQuestion, setModalModel,
   setModalStyle, setQuizzKey, deleteCol, addCol, addRowTemplate, setLandingInfo, duplicateRow, setQuizzEnd,
   setColumnDesign, setPageStyle, setPageSize, setContentValue, setContent, moveColumn, handleSocials, resetLP, setRegister,
   setColSize, setIsDuplicate, setRowGlobalDesign } = LpGenerator.actions;