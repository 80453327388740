import { useEffect, useState } from "react";
import { Card, Col, FormCheck, FormControl, FormLabel, FormSelect, InputGroup, Row } from "react-bootstrap";
import manageApi from "../../../app/services/secured/manage";
import { GatewayAPI, GatewayFormsProps, PIPEDRIVE_GATEWAY } from "../../../types";
import { BlockError, FormInputGroup } from "../../misc/Blocks";
import { BtnIconLoad } from "../../misc/Buttons";
import SimpleForm from "../../misc/SimpleForm";
import { PendingLeadsButton } from "../AccountExports";

const Pipedrive: React.FC<GatewayFormsProps> = gateway => {
    const [isNew, setIsNew] = useState(true);
    const config = gateway.config as PIPEDRIVE_GATEWAY;

    useEffect(() => {
        if (gateway.id) setIsNew(false);
    }, [gateway])

    const [gatewayAPI, callGatewayAPI] = manageApi.useHandleFormGatewayMutation();
    return <>
        <SimpleForm onSubmit={(json) => {
            gatewayAPI(isNew
                ? { company: gateway.company, form: gateway.form, gateway: '', data: json as PIPEDRIVE_GATEWAY } as GatewayAPI
                : { company: gateway.company, form: gateway.form, gateway: gateway.id, data: json as PIPEDRIVE_GATEWAY } as GatewayAPI
            )
        }}>
            <FormControl type='hidden' name='gateway_code' value='pipedrive' />
            <Card className='mt-3'>
                <Card.Header>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex align-items-center'>
                            <FormCheck id='activate_api' type='switch' label='Activer export' name='enabled' defaultChecked={gateway.enabled} />
                            {!isNew && gateway.id && <PendingLeadsButton company_id={gateway.company} form_id={gateway.form} gateway_id={gateway.id} />}
                        </div>
                        <h6 className='text-end m-0'>Pipedrive</h6>
                    </div>
                </Card.Header>
                <Card.Body>
                    <BlockError data={callGatewayAPI} />
                    <Row>
                        <FormInputGroup as={Col} label='Support (Lead ads)'>
                            <FormSelect size='sm' name='config[leadads_support]' defaultValue={config?.leadads_support}>
                                <option value="">-</option>
                                <option value="orixa-lead-ads">orixa-lead-ads</option>
                                <option value="start-lead-ads">start-lead-ads</option>
                                <option value="feedcast-lead-ads">feedcast-lead-ads</option>
                            </FormSelect>
                        </FormInputGroup>
                        <FormInputGroup as={Col} label='Support (SEO)'>
                            <FormSelect size='sm' name='config[seo_support]' defaultValue={config?.seo_support}>
                                <option value="">-</option>
                                <option value="orixa-site">orixa-site</option>
                                <option value="orixa-lp">orixa-lp</option>
                                <option value="start-site">start-site</option>
                                <option value="start-lp">start-lp</option>
                                <option value="feedcast-site">feedcast-site</option>
                                <option value="feedcast-lp">feedcast-lp</option>
                                <option value="fundraising-site">fundraising-site</option>
                            </FormSelect>
                        </FormInputGroup>
                    </Row>

                    <div className='mt-3 text-end'>
                        <BtnIconLoad data={callGatewayAPI}>Enregister</BtnIconLoad>
                    </div>
                </Card.Body>
            </Card>
        </SimpleForm>
    </>
}

export default Pipedrive;