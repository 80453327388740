import { Alert, Card, Col, FormControl, FormLabel, ListGroup, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import diffusionApi from "../../../app/services/secured/diffusion";
import globalApi from "../../../app/services/secured/global";
import { LANDING_STATUSES } from "../../../app/slice/landing";
import { Icon } from "../../misc/Buttons";
import { getIsDuplicate, getLandingInfos, getLandingState, setLandingInfo } from "../landing-v2";
import Options, { SocialsOptions } from "./Options";
import RGPD from "./RGPD";
import creationApi from "../../../app/services/secured/creation";
import { modalLinkLandingShow } from "../../../app/slice/modal";
import { addNotification } from "../../../app/slice/notifs";

const LandingProperties = () => {
    const isDuplicate = useAppSelector(getIsDuplicate);
    return <div id='infos' className='mt-3 pe-5 ps-5'>
        {isDuplicate && <>
            <Alert variant="danger">
                <Alert.Heading as="h3">Duplication</Alert.Heading>
                La landing page est en duplication. Modifiez le code unique de la LP afin de pouvoir la dupliquer.
            </Alert>
        </>}

        <div className='mb-3'>
            <LandingStatus/>
        </div>

        <Row>
            <Col>
                <Card>
                    <Card.Header as='h4' className='info-title'>
                        <Icon code='person'/> Informations
                    </Card.Header>
                    <Card.Body>
                        <LandingInfos/>
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <div className='info-title p-2'>
                    <h4 className='m-0'><Icon code='wrench'/> Options</h4>
                </div>
                <Options/>
            </Col>
        </Row>

        <Row className='mt-2 mb-4'>
            <Col>
                <div className='info-title p-2'>
                    <h4 className='m-0'><Icon code='wrench'/> SEO et Réseaux</h4>
                </div>
                <SocialsOptions/>
            </Col>
            <Col>
                <div className='info-title p-2'>
                    <h4 className='m-0'><Icon code='building'/> RGPD</h4>
                </div>
                <RGPD/>
            </Col>
        </Row>

    </div>
}

export const LandingInfos = () => {
    return <>
        <LandingName/>
        <CompanySelect/>
        <CategorySelect/>
        <ThemeSelect/>
        <DomainSelect/>
    </>
}

export const LandingStatus = () => {
    const dispatch = useAppDispatch();
    const lInfos   = useAppSelector(getLandingInfos);
    const lpState  = useAppSelector(getLandingState);

    const [ getC ]           = creationApi.useLazyGetLandingCustomersQuery();
    const [ saveNewLanding ] = creationApi.useSaveLandingV2Mutation();

    async function setLandingLive() {

        // check if LP has customers linked
        const query        = await getC({ id: lInfos.id });
        const cData        = query.data;
        const hasCustomers = (cData?.filter(c => c.enabled).length || 0) > 0;

        if (!hasCustomers) {
            saveNewLanding({ id: lInfos.id, landing: lpState });
            dispatch(modalLinkLandingShow(lInfos.id));
            dispatch(addNotification({ type: 'warning', message: 'Aucun compte client relié' }));
        } else {
            dispatch(setLandingInfo({ ...lInfos, status: LANDING_STATUSES.LIVE }));
        }
    }

    return <div className='d-flex align-items-center justify-content-center mt-3'>
        <ListGroup horizontal>
            <ListGroup.Item action className='text-center' active={lInfos.status === LANDING_STATUSES.LIVE}
                            onClick={() => setLandingLive()}>
                <Icon code='broadcast'/>
                <div>En ligne</div>
            </ListGroup.Item>
            <ListGroup.Item action className='text-center' active={lInfos.status === LANDING_STATUSES.DRAFT}
                            onClick={() => dispatch(setLandingInfo({ ...lInfos, status: LANDING_STATUSES.DRAFT }))}>
                <Icon code='file'/>
                <div>Brouillon</div>
            </ListGroup.Item>
            <ListGroup.Item action className='text-center' active={lInfos.status === LANDING_STATUSES.BIN}
                            onClick={() => dispatch(setLandingInfo({ ...lInfos, status: LANDING_STATUSES.BIN }))}>
                <Icon code='trash'/>
                <div>Supprimé</div>
            </ListGroup.Item>
        </ListGroup>
    </div>
}

const LandingName = () => {
    const lInfos      = useAppSelector(getLandingInfos);
    const dispatch    = useAppDispatch();
    const isDuplicate = useAppSelector(getIsDuplicate);
    let classN        = 'mt-1';
    if (isDuplicate) {
        classN += ' is-invalid';
    }
    const makeCode = ( name: string ) => {
        return name
            .toLowerCase()
            .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            .replace(/[^a-zA-Z0-9- ]/g, '')
            .trim()
            .replace(/\s/g, '-')
            .replace(/\-\-+/g, '-')
    }
    return <div>
        <FormLabel className='m-0 mt-3'>Nom landing</FormLabel>

        <FormControl type='text' placeholder='Nom landing page' defaultValue={lInfos.name}
                     onChange={( e ) => dispatch(setLandingInfo({ ...lInfos, name: e.target.value }))} name='name'/>

        <FormLabel className={'m-0 mt-1'}>Code unique</FormLabel>
        <FormControl type='text' size='sm' className={classN} placeholder='Code landing'
                     defaultValue={lInfos.code ? lInfos.code : makeCode(lInfos.name)}
                     onBlur={( e ) => dispatch(setLandingInfo({ ...lInfos, code: e.target.value }))} name='code'/>
        <span className={'text-muted small'}>
            Code unique définissant l'URL de la LP (ex: https://www.mes-soutiens.com/lp/<b>acf-enfants-5</b>.html) <br/>
            Uniquement en minuscules, sans caractères spéciaux ni espaces.
        </span>
    </div>
}

const CompanySelect = () => {
    const lInfos    = useAppSelector(getLandingInfos);
    const dispatch  = useAppDispatch();
    const companies = globalApi.useGetCompaniesListQuery();
    const selComp   = companies.data?.find(c => c.id === lInfos.company);
    return <>
        {companies.data && <>
            <FormLabel className='m-0 mt-3'>Client</FormLabel>
            <Typeahead key={lInfos.company} id='select-company' placeholder='Sélectionnez un client'
                       options={companies.data.map(c => {
                           return { label: c.name, id: c.id }
                       })}
                       defaultSelected={selComp ? [ { label: selComp.name, id: selComp.id } ] : []}
                       onChange={( s ) => {
                           if (s[0]) {
                               const val = s[0] as { id: number };
                               dispatch(setLandingInfo({ ...lInfos, company: val.id }));
                           }
                       }}
            />
            <input type='hidden' name='company' value={lInfos.company}/>
        </>}
    </>
}

const CategorySelect = () => {
    const lInfos     = useAppSelector(getLandingInfos);
    const dispatch   = useAppDispatch();
    const categories = globalApi.useGetCategoriesListQuery();
    const selCat     = categories.data?.find(c => c.id === lInfos.category);
    return <>
        {categories.data && <>
            <FormLabel className='m-0 mt-3'>Catégorie</FormLabel>
            <Typeahead key={lInfos.category} id='select-category' placeholder='Sélectionnez une catégorie'
                       options={categories.data.filter(c => c.type === 1).map(c => {
                           return { label: c.name, id: c.id }
                       })}
                       defaultSelected={selCat ? [ { label: selCat?.name, id: selCat?.id } ] : []}
                       onChange={( s ) => {
                           if (s[0]) {
                               const val = s[0] as { id: number };
                               dispatch(setLandingInfo({ ...lInfos, category: val.id }));
                           }
                       }}
            />
            <input type='hidden' name='category' value={lInfos.category}/>
        </>}
    </>
}

const ThemeSelect = () => {
    const lInfos     = useAppSelector(getLandingInfos);
    const dispatch   = useAppDispatch();
    const categories = globalApi.useGetCategoriesListQuery();
    const selTheme   = categories.data?.find(t => t.id === lInfos.theme);
    return <>
        {categories.data && <>
            <FormLabel className='m-0 mt-3'>Thème</FormLabel>
            <Typeahead key={lInfos.theme} id='select-category' placeholder='Sélectionnez un thème'
                       options={categories.data.filter(c => c.type === 2).map(c => {
                           return { label: c.name, id: c.id }
                       })}
                       defaultSelected={selTheme ? [ { label: selTheme?.name, id: selTheme?.id } ] : []}
                       onChange={( s ) => {
                           if (s[0]) {
                               const val = s[0] as { id: number };
                               dispatch(setLandingInfo({ ...lInfos, theme: val.id }));
                           }
                       }}
            />
            <input type='hidden' name='theme' value={lInfos.theme}/>
        </>}
    </>
}

const DomainSelect = () => {
    const lInfos   = useAppSelector(getLandingInfos);
    const dispatch = useAppDispatch();
    const domains  = diffusionApi.useGetSiteListQuery();
    const selDom   = domains.data?.find(d => d.domain === lInfos.site.domain);
    return <>
        {domains.data && <>
            <FormLabel className='m-0 mt-3'>Domaine</FormLabel>
            <Typeahead key={lInfos.theme} id='select-category' placeholder='Sélectionnez un domaine'
                       options={domains.data.map(d => {
                           return { label: d.domain, id: d.id }
                       })}
                       defaultSelected={selDom ? [ { label: selDom.domain, id: selDom.id } ] : []}
                       onChange={( s ) => {
                           if (s[0]) {
                               const val = s[0] as { label: string, id: number };
                               dispatch(setLandingInfo({ ...lInfos, site: { domain: val.label, id: val.id } }));
                           }
                       }}
            />
            <input type='hidden' name='site' value={lInfos.site.domain}/>
        </>}
    </>
}

export default LandingProperties;