import { useAppDispatch } from "../../../../app/hooks";
import { EditorV2 } from "../../../misc/TinymceEditor";
import { setTextContent } from "../../landing-v2";

export const TextContent: React.FC<{ content: string, block: number, isEdit: boolean }> = ({ content, block, isEdit }) => {
   const dispatch = useAppDispatch();
   return <>
      {isEdit
         ? <EditorV2 initVal={content} onBlur={(e: string) => { dispatch(setTextContent({ block: block, value: e })) }} />
         : <div dangerouslySetInnerHTML={{ __html: content }} />}
   </>
}