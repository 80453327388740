import { useEffect, useState } from "react";
import { Badge, Button, Card, Col, FormCheck, FormControl, FormLabel, FormSelect, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useParams } from "react-router-dom";
import { formSuccessRedirect } from "../../app/actions";
import { useAppDispatch } from "../../app/hooks";
import creationApi from "../../app/services/secured/creation";
import AppSecured from "../../AppSecured";
import Urls from "../../assets/js/Urls";
import { Field, FormStatuses, GeneleadForm } from "../../types";
import { FormButtons, FormInputGroup } from "../misc/Blocks";
import { ButtonIcon } from "../misc/Buttons";
import SimpleForm from "../misc/SimpleForm";

const FormEdit = () => {
    const { id } = useParams<{ id: string }>();
    const [req, res] = creationApi.useLazyGetFormQuery();

    useEffect(() => {
        if (id && id !== 'new') req({ id: parseInt(id) });
    }, [id])

    return <>
        <AppSecured>
            <Card className='genelead-card mt-4'>
                <Card.Header>
                    Formulaire
                </Card.Header>
                <Card.Body>
                    {id && id !== 'new' && res.data
                        ? <SimpleFormEdit data={res.data} />
                        : <SimpleFormNew />}
                </Card.Body>
            </Card>
        </AppSecured>
    </>
}

const SimpleFormNew: React.FC = () => {
    const dispatch = useAppDispatch();
    const [create, resCreate] = creationApi.useCreateFormMutation();
    dispatch(formSuccessRedirect({ data: resCreate, redirectUrl: Urls.forms.list, message: 'Succès création du formulaire' }));

    return <>
        <SimpleForm onSubmit={(json) => { create(json as GeneleadForm); }}>
            <FormForm />
            <FormButtons cancelUrl={Urls.forms.list} data={resCreate} btnTxt='Créer' />
        </SimpleForm>
    </>
}

const SimpleFormEdit: React.FC<{ data: GeneleadForm }> = props => {
    const dispatch = useAppDispatch();
    const [update, resUpdate] = creationApi.useUpdateFormMutation();
    dispatch(formSuccessRedirect({ data: resUpdate, redirectUrl: Urls.forms.list, message: 'Succès édition du formulaire' }));

    return <>
        <SimpleForm onSubmit={(json) => { update({ id: props.data.id, form: json as GeneleadForm }); }}>
            <FormForm data={props.data} />
            <FormButtons cancelUrl={Urls.forms.list} data={resUpdate} btnTxt='Modifier' />
        </SimpleForm>
    </>
}

type FormEditFields = Partial<Field> & { match?: string, mandatory?: boolean };

const FormForm: React.FC<{ data?: GeneleadForm }> = props => {

    const fieldsReq = creationApi.useGetFieldsQuery({});
    const fields = fieldsReq.data;

    const [formFields, setFormFields] = useState<Array<FormEditFields>>([]);
    const [resSelect, setResSelect] = useState([]);

    useEffect(() => {
        if (props.data) {
            setFormFields(props.data.fields.map(field => {
                return { code: field.field.code, title: field.field.title, mandatory: field.mandatory }
            }));
        }
    }, [props])

    return <>
        <Row>
            <Col md={8}>
                <FormInputGroup label='Nom'>
                    <FormControl type='text' name='name' placeholder='Nom' defaultValue={props.data?.name} />
                </FormInputGroup>
            </Col>
            <Col>
                <FormInputGroup label='Status'>
                    <FormSelect defaultValue={props.data?.status?.code} name='formStatus'>
                    {/*<FormSelect defaultValue={props.data?.status?.code} placeholder='status' name='formStatus'>*/}
                        {FormStatuses.map((f, i) => <option key={i} value={f}>{f}</option>)}
                    </FormSelect>
                </FormInputGroup>
            </Col>
            <Col>
                <FormLabel>Doublon email autorisés ?</FormLabel>
                <FormCheck type='switch' name='allowDuplicates' defaultChecked={props.data?.allowDuplicates ? true : false} />
            </Col>
        </Row>
        <hr />
        <Row>
            {
                fields && <>
                    <Typeahead
                        id='select-field'
                        options={fields
                            .filter(f => formFields.findIndex(ff => ff.code === f.code) === -1)
                            .map(f => { return { label: '[' + f.code + ']' + f.title, code: f.code, title: f.title } })}
                        placeholder='Recherchez un champ'
                        selected={resSelect}
                        onChange={(s) => {
                            if (s[0]) {
                                const val = s[0] as {label: string, code: string, title: string}
                                const newFields = [...formFields]
                                newFields.push({ code: val.code, title: val.title, mandatory: true });
                                setFormFields(newFields);
                                setResSelect([]);
                            }
                        }}
                    />
                </>
            }
        </Row>
        <Row className='mt-4'>
            <Col className="d-flex flex-wrap">
                {formFields.map(ff =>
                    <FormField key={Math.random()} {...ff}
                        deleteField={() => setFormFields([...formFields].filter(f => f.code != ff.code))}
                    />)}
            </Col>
        </Row>
    </>
}

const FormField: React.FC<FormEditFields & { deleteField: () => void }> = field => {
    return <div className='form-field-badge me-2 d-flex align-items-center mb-1 p-2'>
        <FormCheck name={`newFields[${field.code}]`} type='switch' defaultChecked={field.mandatory ? true : false} />
        {field.title}
        <ButtonIcon className='ms-1 ' code={"trash"} size={"sm"} variant={"danger"} onClick={field.deleteField} />
    </div>
}

export default FormEdit;