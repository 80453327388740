import { Form, FormLabel, Modal } from "react-bootstrap"
import { formSuccessRedirect } from "../../app/actions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import creationApi from "../../app/services/secured/creation";
import { getModalData, isModalOpened, ModalTypes } from "../../app/slice/modal";
import { IfTrue } from "../misc/Blocks";
import ModalCloseButton from "../Modals/ModalCloseButton";

const ModalChangeImg = () => {
    const dispatch = useAppDispatch();
    const isOpened = useAppSelector(isModalOpened(ModalTypes.ChangeImg))
    const imgId = useAppSelector(getModalData);

    const [replaceMedia, replaceMediaRes] = creationApi.useReplaceMediaMutation();

    const replaceImg = (e:any) => {
        const media = new FormData(e.target.form);
        replaceMedia({id: imgId, media: media});
    }

    dispatch(formSuccessRedirect({isModal: true, data: replaceMediaRes}));
    return <IfTrue condition={isOpened && imgId}>
        <Modal.Header>
            <Modal.Title>Modifier image</Modal.Title>
        </Modal.Header>
        <Modal.Body>
            <Form>
                <FormLabel>Sélectionnez une nouvelle image :</FormLabel>
                <Form.Control type='file' name='media' onChange={replaceImg} />
            </Form>
        </Modal.Body>
        <Modal.Footer>
            <ModalCloseButton />
        </Modal.Footer>
    </IfTrue>
}

export default ModalChangeImg;