import { Alert, Col, FormControl, Modal, Row } from "react-bootstrap"
import { formSuccessRedirect } from "../../app/actions"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import diffusionApi from "../../app/services/secured/diffusion"
import { getModalData, isModalOpened, ModalTypes } from "../../app/slice/modal"
import { FbForm, FbSyncUpdate } from "../../types"
import { BlockError, IfTrue } from "../misc/Blocks"
import { Icon, LoadingButton } from "../misc/Buttons"
import SimpleForm from "../misc/SimpleForm"
import ModalCloseButton from "../Modals/ModalCloseButton"


const ModalFbSynchro = () => {
    const dispatch = useAppDispatch();
    const isOpened = useAppSelector(isModalOpened(ModalTypes.FbSyncro));
    const data: Pick<FbForm, 'facebookFormId'> = useAppSelector(getModalData);

    const [update, updateRes] = diffusionApi.useUpdateSyncroMutation();
    dispatch(formSuccessRedirect({isModal: true, data:updateRes}));

    return <>
        <IfTrue condition={isOpened && data.facebookFormId}>
            <SimpleForm onSubmit={(form) => {
                update({facebookFormId: data.facebookFormId, form: form as FbSyncUpdate});
            }}>
                <Modal.Header>
                    Synchronisation formulaire
                </Modal.Header>
                <Modal.Body>
                    <BlockError data={updateRes} />
                    <Alert variant='info' className='text-center'>
                        Veuillez définir la plage sur laquelle vous souhaitez resynchroniser le formulaire
                    </Alert>
                    <Row>
                        <Col className='d-flex align-items-center fs-20'>
                            <Col md={1} className='text-center'><Icon code='calendar-minus' /></Col>
                            <Col><FormControl name='from' type='date' placeholder='from' /></Col>
                        </Col>

                        <Col className='d-flex align-items-center fs-20 mt-1'>
                            <Col md={1} className='text-center'><Icon code='calendar-plus' /></Col>
                            <Col>
                                <FormControl name='to' type='date' placeholder='to' />
                            </Col>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <ModalCloseButton />
                    <LoadingButton data={updateRes} type='submit'>Modifier</LoadingButton>
                </Modal.Footer>
            </SimpleForm>
        </IfTrue>
    </>
}

export default ModalFbSynchro;