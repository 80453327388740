import {useEffect, useMemo, useState} from "react";
import {Alert, Card, Col, Container, FormCheck, FormControl, FormLabel, ListGroup, Row} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import creationApi from "../../../app/services/secured/creation";
import {ContentWordings, getLanding, setLandingData, SpecificWordings} from "../../../app/slice/landing";
import {LandingDesignData, LandingModalTypes} from "../../../types";
import {DataField} from "../LandingEdit";
import {LoadingBlock} from "../../misc/Blocks";

const LandingAction = () => {
    const dispatch = useAppDispatch();
    const landing = useAppSelector(getLanding);
    const templates = creationApi.useGetTemplatesQuery();

    const [variables, setVariables] = useState<Array<string>>([]);

    useEffect(() => {
        const varTmp = templates.data?.modals.find(m => m.id === landing.data.modal_version)?.variables
            .filter(v => SpecificWordings[v as keyof LandingDesignData] ? false : true)
            .filter(v => ContentWordings.default.includes(v as keyof LandingDesignData) ? false : true);
        setVariables(varTmp ? varTmp : []);

        if (landing.data.modal_version === "redirect" && !landing.data.redirect_time) {
            dispatch(setLandingData({...landing.data, redirect_time: 3000}));
        }

    }, [templates, landing.data.modal_version])

    const vFields = useMemo(() => {
        return variables?.map(v => <DataField key={Math.random()} field={v as keyof LandingDesignData} size={6}/>);
    }, [variables]);

    return <>
        <Container className='text-center'>
            <h6>Type d'action post-formulaire</h6>
            <div className='d-flex justify-content-center'>
                <LoadingBlock data={templates}>
                    <ListGroup horizontal style={{cursor: 'pointer'}} defaultActiveKey={landing.data.modal_version}
                               key={templates.data?.modals.length}>
                        {templates.data?.modals.map(m =>
                            <ListGroup.Item key={m.id} eventKey={m.id}
                                            onClick={() => dispatch(setLandingData({
                                                ...landing.data,
                                                modal_version: m.id.toString() as LandingModalTypes
                                            }))}>
                                {m.code}</ListGroup.Item>)}
                    </ListGroup>
                </LoadingBlock>
            </div>
        </Container>

        {variables.length
            ? <>
                <Card className='mt-4'>
                    <Card.Header as="h4"> Wordings </Card.Header>
                    <Card.Body>
                        <Row>{vFields}</Row>
                    </Card.Body>
                </Card>
            </>
            : <Alert variant='warning' className='mt-3'>Aucun wording spécifique pour la page de remerciement
                choisie.</Alert>
        }

        <Card className='mt-4'>
            <Card.Header className='d-flex align-items-center justify-content-between'>
                <h4 className='m-0'>Options</h4>
                <FormCheck type='switch' label='Afficher le logo Mes Soutiens' className='fs-16'
                           defaultChecked={landing.data.logo}
                           onChange={(e) => dispatch(setLandingData({...landing.data, logo: e.target.checked}))}
                />
            </Card.Header>
            <Card.Body>

                <FormLabel className='fs-18'>URL de redirection</FormLabel>
                <FormControl type='text' defaultValue={landing.data.redirect_url}
                             onChange={(e) => dispatch(setLandingData({
                                 ...landing.data,
                                 redirect_url: e.target.value
                             }))}/>

                <Row className='mt-2'>
                    <Col>
                        <FormLabel className='fs-18'>Lien de retour home</FormLabel>
                        <FormControl type='text' defaultValue={landing.data.comeback_link}
                                     onChange={(e) => dispatch(setLandingData({
                                         ...landing.data,
                                         comeback_link: e.target.value
                                     }))}/>
                    </Col>
                    <Col>
                        <FormLabel className='fs-18'>Texte du lien de retour home</FormLabel>
                        <FormControl type='text' defaultValue={landing.data.comeback_text}
                                     onChange={(e) => dispatch(setLandingData({
                                         ...landing.data,
                                         comeback_text: e.target.value
                                     }))}/>
                    </Col>
                    {landing.data.modal_version === "redirect" && <Col>
                        <FormLabel className={'fs-18'}>Durée redirection (millisecondes)</FormLabel>
                        <FormControl type={'number'} defaultValue={landing.data.redirect_time}
                                     onChange={(e) => dispatch(setLandingData({
                                         ...landing.data,
                                         redirect_time: parseInt(e.target.value)
                                     }))}/>
                    </Col>}
                </Row>
            </Card.Body>
        </Card>
    </>
}

export default LandingAction;