import { Modal } from "react-bootstrap";
import { useAppSelector } from "../../app/hooks";
import { isAnyModalOpened } from "../../app/slice/modal";
import ModalDatabase from "../Databases/ModalDatabase";
import ModalEditLink from "../Admin/LinkKeys/ModalEditKeyLink";
import ModalLogout from "./ModalLogout";
import ModalMedias from "../Medias/ModalMedia";
import ModalField from "../Fields/ModalField";
import ModalFbSynchro from "../Facebook/ModalFbSynchro";
import ModalFbLink from "../Facebook/ModalFbLink";
import ModalFbSearchPage from "../Facebook/ModalFbSearchPage";
import ModalReportingTemplate from "../Reporting/ModalReportingTemplate";
import ModalNewAccount from "../Companies/ModalNewAccount";
import ModalExportEmail from "../Companies/ModalExportEmails";
import ModalExportEmailGlobal from "../Companies/ModalExportEmailsGlobal";
import ModalRefreshLeads from "../Companies/ApiForms/ModalRefreshLeads";
import ModalDeleteCompany from "../Companies/ModalDeleteCompany";
import ModalEnableCompany from "../Companies/ModalEnableCompany";
import ModalDeleteAccount from "../Companies/ModalDeleteAccount";
import ModalNewLanding2 from "../Landings/ModalNewLandingV2";
import ModalLinkAccount from "../Landings/ModalLinkAccount";
import ModalChangeImg from "../Medias/ModalChangeImg";
import ModalNewLanding from "../Landings/ModalNewLanding";
import ModalExportLandingFilter from "../Landings/ModalExportLandingFilter";

const ModalContainer = () => {
    const show = useAppSelector(isAnyModalOpened);
    return <>
        <Modal show={show}>
            <ModalLogout />
            <ModalEditLink />
            <ModalDatabase />
            <ModalFbSynchro />
            <ModalFbSearchPage />
            <ModalReportingTemplate />
            <ModalNewAccount />
            <ModalExportEmail />
            <ModalRefreshLeads />
            <ModalDeleteCompany />
            <ModalEnableCompany />
            <ModalDeleteAccount />
            <ModalExportEmailGlobal />
            <ModalNewLanding2 />
            <ModalNewLanding />
            <ModalChangeImg />
        </Modal>
        <ModalMedias />
        <ModalFbLink />
        <ModalLinkAccount />
        <ModalField />
        <ModalExportLandingFilter />
    </>
}

export default ModalContainer;