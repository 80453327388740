import { useState } from "react";
import { Badge } from "react-bootstrap";
import { useAppDispatch } from "../../../app/hooks";
import adminApi from "../../../app/services/secured/admin";
import { modalEditKeyLinkShow } from "../../../app/slice/modal";
import AppSecured from "../../../AppSecured";
import { KeyLink } from "../../../types";
import { PageHeader } from "../../App/PageHeader";
import { LoadingBlock } from "../../misc/Blocks";
import { ButtonIcon } from "../../misc/Buttons";
import { PaginationGenelead } from "../../misc/Pagination";
import TableGenelead from "../../misc/Table";

const KeysList: React.FC = () => {
    const [search, setSearch] = useState({ page: 1 });
    const keysReq = adminApi.useGetKeysListQuery(search);
    const dispatch = useAppDispatch();

    return <>
        <AppSecured>
            <LoadingBlock data={keysReq}>
                <PageHeader action={<ButtonIcon
                    code={"plus-circle"}
                    size={"sm"}
                    variant={"primary"}
                    onClick={() => dispatch(modalEditKeyLinkShow({ isNew: true }))}>
                        Nouvelle clé de liaison
                    </ButtonIcon>
                }>
                    {keysReq.isSuccess && <>
                        <PaginationGenelead
                            className='m-0 ms-3' size='sm'
                            pages={{ current: keysReq.data.page, total: keysReq.data.total_pages }}
                            search={(page: number) => { setSearch({ ...search, page }) }}
                        />
                    </>}
                </PageHeader>

                <TableGenelead header={[
                    { icon: 'key', name: 'key', align: 'center' },
                    { icon: 'bookmarks', name: 'name' },
                    { icon: 'code-slash', name: 'type', align: 'center' },
                    { icon: 'globe', name: 'url' },
                ]}
                    hasActions={true}>
                    {keysReq.data?.list.map((l: KeyLink) => <KeyRow key={Math.random()} keyLink={l} />)}
                </TableGenelead>

                {keysReq.isSuccess && <>
                    <PaginationGenelead
                        className='m-0 ms-3'
                        pages={{ current: keysReq.data.page, total: keysReq.data.total_pages }}
                        search={(page: number) => { setSearch({ ...search, page }) }}
                    />
                </>}
            </LoadingBlock>
        </AppSecured>
    </>
}

const KeyRow: React.FC<{ keyLink: KeyLink }> = ({ keyLink }) => {
    const dispatch = useAppDispatch();
    let variant = '';
    switch (keyLink.type) {
        case 'casestudy': variant = 'success'; break;
        case 'ebook': variant = 'warning'; break;
        case 'replay': variant = 'info'; break;
    }

    return <tr>
        <td className='text-center'><Badge className="alt-primary">{keyLink.identifier}</Badge></td>
        <td>{keyLink.name}</td>
        <td className='text-center'><Badge bg={variant} pill>{keyLink.type}</Badge></td>
        <td><small><a href={keyLink.url} target='_blank'>{keyLink.url}</a></small></td>
        <td><ButtonIcon  code={"pen"} size={"lg"} variant={"primary"} className='alt-primary' onClick={() => 
            dispatch(modalEditKeyLinkShow({ identifier: keyLink.identifier }))} />
        </td>
    </tr>
}

export default KeysList;