import { Row, Col } from "react-bootstrap"

export const PageHeader:React.FC<{action?: any, children?:any}> = ({action, children}) => {
    return <Row className='page-header d-flex align-items-center justify-content-between mb-2'>
        <Col sm={8}>
            <div className="d-flex align-items-center">
                {children}
            </div>
        </Col>
        <Col className='action' sm={4}>
            {action}
        </Col>
    </Row>
}