import ApiUrls from "../../../assets/js/ApiUrls";
import { CaptainVerifyReturn, CompanyDatabases, Coregistration, CoregistrationForm, Database, ListData, PhoneNumber } from "../../../types";
import securedApi from "./securedApi";
import { jsonToFormData, SimpleFormData } from "../../../components/misc/SimpleForm";

const dataApi = securedApi.injectEndpoints({
    endpoints: (build) => ({

        captainVerify: build.mutation<Array<CaptainVerifyReturn>, Array<PhoneNumber>>({
            query: (form) => ({
                url: ApiUrls.captainVerify,
                method: 'POST',
                body: jsonToFormData(form),
            })
        }),

        // DATABASES
        getDatabaseList: build.query<ListData<CompanyDatabases>, {page?: number, company_id?: string|number}>({
            query:(params) => ({
                url: ApiUrls.databases.list,
                params: params
            }),
            providesTags: ['Databases'],
            transformResponse: (res:any) => {
                //const sampleRes = databaseSample;
                let newRes:Array<CompanyDatabases> = [];
                res.map((r:any) => {
                    const newBase = {
                        id: r.id,
                        name: r.name,
                        access_token: r.access_token,
                        emails: r.emails,
                        forms: r.forms,
                        emailDatabaseHistories: r.emailDatabaseHistories,
                        company: r.company
                    };

                    const company = newRes.find(c => c.id === r.company.id);
                    if (company) {
                        const companyIndex = newRes.indexOf(company);
                        return newRes[companyIndex].databases.push(newBase);
                    } else {
                        return newRes.push({
                            id: r.company.id,
                            name: r.company.name,
                            databases: [newBase]
                        })
                    }
                })
                
                newRes.sort((a, b) => a.name.localeCompare(b.name));
                const newRtn:ListData<CompanyDatabases> = {
                    has_next_page: false,
                    page: 1,
                    total: 4,
                    total_pages: 1,
                    list: newRes,
                }
                return newRtn;
            }
        }),
        getDatabase: build.query<Database, Pick<CompanyDatabases, 'id'>>({
            query: (args) => ApiUrls.databases.edit(args.id)
        }),
        updateDatabase: build.mutation<void, Pick<Database, 'id'|'name'>>({
            query: (args) => ({
                url: ApiUrls.databases.edit(args.id),
                method: 'POST',
                body: jsonToFormData(args)
            }),
            invalidatesTags: ['Databases']
        }),
        createDatabase: build.mutation<void, Database>({
            query: (form) => ({
                url: ApiUrls.databases.list,
                method: 'POST',
                body: jsonToFormData(form)
            }),
            invalidatesTags: ['Databases']
        }),
        deleteDatabase: build.mutation<void, Pick<Database, 'id'>>({
            query: (args) => ({
                url: ApiUrls.databases.edit(args.id),
                method: 'DELETE',
            }),
            invalidatesTags: ['Databases']
        }),

        // CO REGISTRATION
        getCoregList: build.query<Array<Coregistration>, void>({
            query: () => ApiUrls.coreg.list,
            providesTags: ['Coregistration']
        }),
        getCoreg: build.query<Coregistration, Pick<Coregistration, 'id'>>({
            query: (params) => ApiUrls.coreg.edit(params.id.toString())
        }),
        createCoreg: build.mutation<Coregistration, CoregistrationForm>({
            query: (form) => ({
                url: ApiUrls.coreg.list,
                method: 'POST',
                body: jsonToFormData(form)
            }),
            invalidatesTags: ['Coregistration']
        }),
        updateCoreg: build.mutation<Coregistration, Pick<Coregistration, 'id'> & {form: CoregistrationForm}>({
            query: (args) => ({
                url: ApiUrls.coreg.edit(args.id.toString()),
                method: 'POST',
                body: jsonToFormData(args.form)
            }),
            invalidatesTags: ['Coregistration']
        })
    })
})

export default dataApi;
