import ApiUrls from "../../../assets/js/ApiUrls";
import { jsonToFormData } from "../../../components/misc/SimpleForm";
import { KeyLink, ListData, Pagination, Setting, User } from "../../../types";
import securedApi from "./securedApi";

const adminApi = securedApi.injectEndpoints({
    endpoints: (build) => ({
        // USERS
        getUsersList: build.query<Array<User>, void>({
            query: () => ApiUrls.users.list,
            providesTags: ['Users'],
        }),
        getUser: build.query<User, Pick<User, 'id'>>({
            query: (args) => ApiUrls.users.edit(args.id)
        }),
        updateUser: build.mutation<void, Pick<User, 'id'> & {form: User}>({
            query: (args) => ({
                url: ApiUrls.users.edit(args.id),
                method: 'POST',
                body: jsonToFormData(args.form)
            }),
            invalidatesTags: ['Users'],
        }),
        createUser: build.mutation<void, User>({
            query: (form) => ({
                url: ApiUrls.users.list,
                method: 'POST',
                body: jsonToFormData(form)
            }),
            invalidatesTags: ['Users'],
        }),

        // KEYS LIST
        getKeysList: build.query<ListData<KeyLink>, Pagination>({
            query: (page) => ({
                url: ApiUrls.linkKeys.list,
                params: page,
            }),
            providesTags: ["KeysLink"]
        }),
        getKeyLink: build.query<KeyLink, Pick<KeyLink, 'identifier'>>({
            query: (args) => ApiUrls.linkKeys.edit(args.identifier)
        }),
        updateKeyLink: build.mutation<boolean, Pick<KeyLink, 'identifier'> & {form: KeyLink}>({
            query: (args) => ({
                url: ApiUrls.linkKeys.edit(args.identifier),
                method: 'POST',
                body: jsonToFormData(args.form)
            }),
            invalidatesTags: ["KeysLink"]
        }),
        createKeyLink: build.mutation<boolean, KeyLink>({
            query: (form) => ({
                url: ApiUrls.linkKeys.new,
                method: 'POST',
                body: jsonToFormData(form)
            }),
            invalidatesTags: ["KeysLink"]
        }),

        // SETTINGS
        getSettings: build.query<Setting, void>({
            query: () => ApiUrls.settings,
            providesTags: ["Settings"],
        }),
        updateSettings: build.mutation<void, Setting>({
            query: (form) => ({
                url: ApiUrls.settings,
                method: 'POST',
                body: jsonToFormData(form),
            }),
            invalidatesTags: ["Settings"]
        })
    })
})

export default adminApi;