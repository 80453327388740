import { Card, Col, Form, Row } from "react-bootstrap";
import { selectNewMediaForCategory, SuccessNotif } from "../../app/actions";
import { useAppDispatch } from "../../app/hooks";
import creationApi from "../../app/services/secured/creation";
import { modalChangeImgShow } from "../../app/slice/modal";
import AppSecured from "../../AppSecured";
import ApiUrls from "../../assets/js/ApiUrls";
import { LandingMedia } from "../../types";
import { BlockError, LoadingBlock } from "../misc/Blocks";
import { ButtonIcon, Icon } from "../misc/Buttons";

const MediaList: React.FC = () => {
    return <>
        <AppSecured>
            <MediaBlock display={'cards'} />
        </AppSecured>
    </>
}

export const MediaBlock: React.FC<{ display: string }> = ({ display }) => {
    const list = creationApi.useGetMediasQuery();
    const dispatch = useAppDispatch();

    return <>
        <LoadingBlock data={list}>
            <Card className='mt-4'>
                <Card.Header>
                    <Form>
                        <div className='d-flex align-items-center'>
                            <Icon code='upload' className='fs-30 me-3' />
                            <MediaUploader />
                        </div>
                    </Form>
                </Card.Header>
                <Card.Body>
                    <Row className='d-flex align-items-center'>
                        {
                            list.data?.list?.map(
                                l => {
                                    return <Col className='col-pick-img' key={Math.random()} md={2}>
                                        
                                        {
                                            display !== 'modal'   
                                                ? <MediaCard display={display}  {...l} />
                                                : <div style={{cursor: 'pointer'}} onClick={() => { dispatch(selectNewMediaForCategory({ ...l })); }}>
                                                    <MediaCard display={display}  {...l} />
                                                </div>
                                        }
                                    </Col>
                                })}
                    </Row>
                </Card.Body>
            </Card>
        </LoadingBlock>
    </>
}

const MediaCard: React.FC<LandingMedia & { display: string }> = (props) => {
    const dispatch = useAppDispatch();
    const imgUrl = ApiUrls.front.media(props.id.toString(), props.extension);
    return <>
        <Card className='media-card'>
            {props.display !== 'modal'
                ? <a href={imgUrl} target='_blank'>
                    <Card.Img variant='top' src={imgUrl} />
                </a>
                : <Card.Img variant='top' src={imgUrl} />
            }
            <Card.Body>
                <div className='d-flex justify-content-between align-items-center'>
                    <div>
                        {props.id}.{props.extension}
                    </div>
                    {
                        props.display === 'cards' && <>
                            <div>
                                <ButtonIcon code='arrow-left-right' variant='primary' size='sm' onClick={() => dispatch(modalChangeImgShow(props.id))} />
                            </div>
                        </>
                    }
                    <div>
                        <small>({new Number(props.size / 1024 / 1024).toFixed(1)}mb)</small>
                    </div>
                </div>
            </Card.Body>
        </Card>
    </>
}

const MediaUploader = () => {
    const [upload, uploadRes] = creationApi.useUploadMediaMutation();
    const uploadImg = (e: any) => {
        const form = new FormData(e.currentTarget.form);
        upload(form);
    }
    return <>
        <SuccessNotif req={uploadRes} />
        <BlockError data={uploadRes} />
        <Form.Control type='file' name='media' onChange={uploadImg} />
    </>
}

export default MediaList;