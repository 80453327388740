import { GlobalStyle } from "../landing-v2";

const GenerateStyle = (requiredStyles:Array<keyof GlobalStyle>, nodeStyle: GlobalStyle):GlobalStyle => {
   const style:GlobalStyle = {};

   style.backgroundRepeat = 'no-repeat';
   style.border = nodeStyle.border ? nodeStyle.border : '0px solid #000000';
   style.backgroundColor = nodeStyle.backgroundColor ? nodeStyle.backgroundColor : '';
   style.backgroundImage = nodeStyle.backgroundImage ? 'url(' + nodeStyle.backgroundImage + ')' : '';
   style.backgroundPosition = nodeStyle.backgroundPosition && requiredStyles.includes('backgroundPosition') ? 'center ' + nodeStyle.backgroundPosition : 'center center';
   style.backgroundPosition = nodeStyle.backgroundPosition + ' ' + (nodeStyle.backgroundOffset ? nodeStyle.backgroundOffset + 'px ' : '');
   style.backgroundBlendMode = 'multiply';
   style.minHeight = nodeStyle.minHeight && requiredStyles.includes('minHeight') ? nodeStyle.minHeight : '';
   style.display = nodeStyle.display && requiredStyles.includes('display') ? nodeStyle.display : '';
   style.alignItems = nodeStyle.alignItems && requiredStyles.includes('alignItems') ? nodeStyle.alignItems : '';
   style.justifyContent = nodeStyle.justifyContent && requiredStyles.includes('justifyContent') ? nodeStyle.justifyContent : '';
   style.flexDirection = nodeStyle.flexDirection && requiredStyles.includes('flexDirection') ? nodeStyle.flexDirection : 'row';

   if (style.backgroundSize === 'manual') {
      style.backgroundSize = nodeStyle.backgroundWidth + 'px ' + nodeStyle.backgroundHeight + 'px';
   } else {
      style.backgroundSize = nodeStyle.backgroundSize ? nodeStyle.backgroundSize : 'contain';
      delete style.backgroundWidth;
      delete style.backgroundHeight;
   }

   style.padding = nodeStyle.padding && requiredStyles.includes('padding') ? nodeStyle.padding : '0px';
   style.borderRadius = nodeStyle.borderRadius && requiredStyles.includes('borderRadius') ? nodeStyle.borderRadius + 'px' : '';
   style.color = nodeStyle.color && requiredStyles.includes('color') ? nodeStyle.color : '';

   style.main_color = nodeStyle.main_color ? nodeStyle.main_color : '#000000';
   style.secondary_color = nodeStyle.secondary_color ? nodeStyle.secondary_color : '#000000';

   return style;
}

export default GenerateStyle;