import { Alert, Modal } from "react-bootstrap";
import { formSuccessRedirect } from "../../app/actions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import manageApi from "../../app/services/secured/manage";
import { getModalData, isModalOpened, ModalTypes } from "../../app/slice/modal";
import { Company, Customer } from "../../types";
import { IfTrue } from "../misc/Blocks";
import { Icon, LoadingButton } from "../misc/Buttons";
import SimpleForm from "../misc/SimpleForm";
import ModalCloseButton from "../Modals/ModalCloseButton";

const ModalDeleteAccount = () => {
    const dispatch = useAppDispatch();
    const isOpened = useAppSelector(isModalOpened(ModalTypes.DeleteAccount));
    const data = useAppSelector<{company: Pick<Company, 'id'>, account: Pick<Customer, 'id'>}>(getModalData);

    const [deleteAccount, deleteAccountRes] = manageApi.useDeleteAccountMutation();
    dispatch(formSuccessRedirect({data: deleteAccountRes, isModal: true, message:"Succès suppression du compte."}));
    return <IfTrue condition={isOpened && data}>
        <SimpleForm onSubmit={() => deleteAccount({company: {id: data.company.id}, account: {id: data.account.id}})}>
            <Modal.Body>
                <Alert variant='danger'>
                    <Alert.Heading><Icon code='exclamation' /> Désactivation compte</Alert.Heading>
                    Êtes-vous certain de vouloir désactiver ce compte ?
                </Alert>
            </Modal.Body>
            <Modal.Footer>
                <ModalCloseButton />
                <LoadingButton variant='danger' data={deleteAccountRes}>Désactiver</LoadingButton>
            </Modal.Footer>
        </SimpleForm>
    </IfTrue>
}

export default ModalDeleteAccount;