import ApiUrls from "../../../assets/js/ApiUrls";
import {
    Category,
    Company,
    Country,
    CustomerList,
    Database,
    FbCampaignList,
    FieldConverter,
    LandingForm,
    Lead,
    ListData
} from "../../../types";
import securedApi from "./securedApi";

const globalApi = securedApi.injectEndpoints({
    endpoints: (build) => ({
        getCompaniesList: build.query<Array<Company>, {query?: string}|void>({
            query: (params) => ApiUrls.list.companies,
            transformResponse: (r:Array<Company>) => {
                return r.sort((a, b) => a.name.localeCompare(b.name));
            },
            providesTags: ['Companies']
        }),
        getDatabasesList: build.query<Array<Database>, {query?: string}|void>({
            query: (params) => ApiUrls.list.databases,
            transformResponse: (r:Array<Database>) => {
                return r.sort((a, b) => a.name.localeCompare(b.name));
            },
            providesTags: ['Databases']
        }),
        getLeads: build.query<ListData<Lead>, any>({
            query: (search) => ({
                url: ApiUrls.leads.list,
                params: search
            }),
        }),
        getGateways: build.query<Array<{id: number, name: string}>, void>({
            query: () => ApiUrls.list.gateways,
        }),
        getCampaigns: build.query<Array<FbCampaignList>, void>({
            query: () => ApiUrls.list.campaigns,
            providesTags: ['Campaigns']
        }),
        getFormsList: build.query<Array<LandingForm>, void>({
            query: () => ApiUrls.list.forms,
            providesTags: ['Forms']
        }),
        getFieldConverters: build.query<Array<FieldConverter>, void>({
            query: () => ApiUrls.list.fieldConverters,
            providesTags: ['FieldConverters']
        }),
        getCategoriesList: build.query<Array<Category>, void>({
            query: () => ApiUrls.list.categories,
        }),
        getCountries: build.query<Array<Country>, void>({
            query: () => ApiUrls.list.countries
        }),
        getCustomers: build.query<Array<CustomerList>, void>({
            query: () => ApiUrls.list.customers,
        })
    })
})

export default globalApi;