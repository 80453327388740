import { useEffect, useLayoutEffect, useState } from "react";
import { Badge, Breadcrumb, BreadcrumbItem, Col, Nav, Row, Tab } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch } from "../../app/hooks";
import manageApi from "../../app/services/secured/manage";
import { modalCompanyExportEmailsShow, modalCompanyExportEmailsGlobal, modalNewCompanyAccountShow } from "../../app/slice/modal";
import AppSecured from "../../AppSecured";
import Urls from "../../assets/js/Urls";
import { Company, Customer } from "../../types";
import { LoadingBlock } from "../misc/Blocks";
import { ButtonIcon, Icon } from "../misc/Buttons";
import ExportPane from "./AccountExports";
import FormsPane from "./AccountForms";
import InformationsPane from "./AccountInfo";

const CompanyInfo = () => {
    const dispatch = useAppDispatch();
    const { company_id } = useParams<{ company_id: string }>();

    const [getCompany, company] = manageApi.useLazyGetCompanyQuery();

    useEffect(() => {
        if (company_id) getCompany({ id: parseInt(company_id) })
    }, [company_id])

    // view from webservice link
    const [params, setParams] = useSearchParams();
    const viewAccount = params.get('account');
    const viewForm = params.get('form');
    const viewGateway = params.get('gateway');

    const [showAccount, setShowAccount] = useState<number>(0);
    const [showPane, setShowPane] = useState('forms');

    useLayoutEffect(() => {
        if (company.isSuccess) {
            if (viewAccount) {
                const customer = company.data.customers.find(c => c.id === parseInt(viewAccount));
                customer && setShowAccount(customer.id);
                setShowPane('exports')
            } else {
                setShowAccount(company.data.customers[0].id)
            }
        }
    }, [viewAccount, company])

    
    return <>
        <AppSecured>
            <LoadingBlock data={company}>
                <Row className=' mt-4 mb-3'>
                    <Col sm={6}>
                        <div className='d-flex'>
                            <h1><Icon code='building' /> {company.data?.name}</h1>
                            <h3>[{company.data?.id}]</h3>
                        </div>
                        <Breadcrumb>
                            <BreadcrumbItem href={Urls.companies.list}>Clients</BreadcrumbItem>
                            <BreadcrumbItem active>{company.data?.name}</BreadcrumbItem>
                        </Breadcrumb>
                    </Col>

                    <Col className='ms-4 d-flex justify-content-end align-items-start'>
                        <ButtonIcon code={"send"} size={"sm"} variant={""} className='alt-primary btn-icon btn-padding'
                            onClick={() => dispatch(modalCompanyExportEmailsGlobal(company.data as Company))}>
                            Export emails globalisé</ButtonIcon>

                        <ButtonIcon code={"send"} size={"sm"} variant={""} className='alt-primary btn-icon btn-padding ms-2'
                            onClick={() => dispatch(modalCompanyExportEmailsShow(company.data as Company))}>
                            Export emails</ButtonIcon>

                        <ButtonIcon code={"filetype-csv"} size={"sm"} variant={""} className='alt-primary ms-2 btn-padding'
                            href={Urls.companies.customRecord(company_id as string)} >
                            Dessin d'enregistrement</ButtonIcon>

                        <ButtonIcon onClick={() => dispatch(modalNewCompanyAccountShow(company_id as string))}
                            code='person-plus' variant='primary' size='sm' className='btn-padding ms-1'>
                            Nouveau compte
                        </ButtonIcon>
                    </Col>
                </Row>

                <Tab.Container key={showAccount} defaultActiveKey={showAccount}>
                    <Row>
                        <Col sm={12}>
                            <Nav variant="pills" fill style={{ cursor: 'pointer' }}>
                                {company.data && company.data.customers.map((c, i) => <AccountsNav key={c.id} eventKey={c.id} {...c}
                                    onClick={() => setShowAccount(c.id)}
                                    isActive={c.id === showAccount ? true:false} />)}
                            </Nav>
                        </Col>
                        <Col sm={12} className='mt-3'>
                            <Tab.Content>
                                {company.data && company.data.customers.map((c, i) =>
                                    <AccountPane defaultKey={showPane} company={company.data?.id as number}
                                        key={c.id} eventKey={c.id} {...c} formSelected={viewForm} gatewaySelected={viewGateway}
                                    />)}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </LoadingBlock>
        </AppSecured>
    </>
}

const AccountsNav: React.FC<Customer & { eventKey: number, onClick:() => void, isActive: boolean }> = account => {
    return <>
        <Nav.Item className='company-account-item' onClick={() => account.onClick()}>
            <Nav.Link eventKey={account.eventKey} active={account.isActive}>
                <div className=''>
                    <div>
                        <div>{account.firstName} {account.lastName}</div>
                        <div style={{fontSize: '0.6rem'}}>{account.email}</div>
                    </div>
                    <div>
                    {account.admin && <Badge bg='primary'>Admin</Badge>}
                    {account.apiToken && <Badge bg='success' className='ms-1'>API</Badge>}
                    {!account.enabled && <Badge bg='danger'>Désactivé</Badge>}
                    </div>
                </div>
            </Nav.Link>
        </Nav.Item>
    </>
}

type AccountPaneProps = Customer & {
    eventKey: number,
    company: number,
    defaultKey: string,
    formSelected: string | null,
    gatewaySelected: string | null
}

const AccountPane: React.FC<AccountPaneProps> = account => {
    const dispatch = useAppDispatch();
    const [getAccount, customerAccount] = manageApi.useLazyGetAccountQuery();
    const params = { company_id: account.company, account_id: account.id };

    const callback = () => {
        getAccount(params);
    }

    useEffect(() => {
        getAccount(params);
    }, [])

    
    const [mountExport, setMountExport] = useState({ account: 0, show: false });

    useEffect(() => {
        if (account.formSelected && account.gatewaySelected) {
            setMountExport({ account: account.id, show: true });
        }
    }, [account.formSelected, account.gatewaySelected])

    return <>
        <LoadingBlock data={customerAccount}>
            {customerAccount.isSuccess && <>
                <Tab.Pane eventKey={account.eventKey} className='account-tabs'>
                    <Tab.Container id="left-tabs-example" defaultActiveKey={account.defaultKey} >
                        <Row>
                            <Col sm={12}>
                                <div className='d-flex justify-content-center'>
                                    <Nav className="nav-tabs fs-16" style={{ cursor: 'pointer' }}>
                                        <Nav.Item>
                                            <Nav.Link eventKey="forms"><Icon code='grid-1x2' /> Landing Pages</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item onClick={() => setMountExport({ account: account.id, show: true })}>
                                            <Nav.Link eventKey="exports"><Icon code='send' /> Exports</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="infos"><Icon code='person' /> Informations</Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </div>
                            </Col>
                            <Col sm={12}>
                                <Tab.Content>
                                    <Tab.Pane eventKey="forms">
                                        <FormsPane {...customerAccount.data} callback={callback} company={account.company} />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="exports">
                                        {mountExport.account === account.id &&
                                            mountExport.show &&
                                            <ExportPane formSelected={account.formSelected}
                                                gatewaySelected={account.gatewaySelected}
                                                {...customerAccount.data} company={account.company} />}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="infos">
                                        <InformationsPane {...customerAccount.data} company={account.company} />
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container>
                </Tab.Pane>
            </>}
        </LoadingBlock>
    </>
}

export default CompanyInfo;