import { useEffect, useState } from "react";
import { Card, Col, FormCheck, FormControl, FormSelect, InputGroup, Row } from "react-bootstrap";
import manageApi from "../../../app/services/secured/manage";
import { GatewayAPI, GatewayFormsProps, MAILER_GATEWAY } from "../../../types";
import { BlockError, FormInputGroup } from "../../misc/Blocks";
import { BtnIconLoad } from "../../misc/Buttons";
import SimpleForm from "../../misc/SimpleForm";
import { PendingLeadsButton } from "../AccountExports";

const MailerGenelead:React.FC<GatewayFormsProps> = gateway => {
    const [isNew, setIsNew] = useState(true);
    const config = gateway.config as MAILER_GATEWAY;

    useEffect(() => {
        if (gateway.id) setIsNew(false);
    }, [gateway])

    const [gatewayAPI, callGatewayAPI] = manageApi.useHandleFormGatewayMutation();

    return <>
        <SimpleForm onSubmit={(json) => {
            gatewayAPI(isNew 
                ? {company: gateway.company, form: gateway.form, gateway: '', data: json as MAILER_GATEWAY} as GatewayAPI
                : {company: gateway.company, form: gateway.form, gateway: gateway.id, data: json as MAILER_GATEWAY} as GatewayAPI
            )
        }}>
            <FormControl type='hidden' name='gateway_code' value='mailer' />
            <Card className='mt-3'>
                <Card.Header>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex'>
                            <div className='d-flex align-items-center'>
                                <FormCheck id='activate_api' type='switch' label='Activer export' name='enabled' 
                                    defaultChecked={gateway.enabled} />
                                <FormCheck id='activate_mail' type='switch' label='Envoyer au mail du lead' 
                                    name='config[send_lead_email]' defaultChecked={config?.send_lead_email === "0" ? false:true} className='ms-3' />
                                
                                {!isNew && gateway.id && <PendingLeadsButton company_id={gateway.company} 
                                    form_id={gateway.form} gateway_id={gateway.id} />}
                            </div>  
                        </div>
                        <h6 className='text-end m-0'>Mailer Genelead</h6>
                    </div>
                </Card.Header>
                <Card.Body>
                    <BlockError data={callGatewayAPI} />
                    <Row>
                        <FormInputGroup label='Sujet' as={Col}>
                            <FormControl size='sm' type='text' placeholder='sujet' name='config[subject]' 
                                defaultValue={config?.subject} required />
                        </FormInputGroup>
                        <FormInputGroup label='Template' as={Col}>
                            <FormSelect name='config[template]' defaultValue={config?.template}>
                                <option value="contact">Contact orixa</option>
                                <option value="mail-lp">Mail LP</option>
                                <option value="mail-lp-fundraising">Mail LP Fundraising</option>
                            </FormSelect>
                        </FormInputGroup>
                    </Row>

                    <Row>
                        <FormInputGroup label='Destinataire' as={Col}>
                            <FormControl size='sm' type='email' placeholder='destinataire' name='config[to]' 
                                defaultValue={config?.to} required/>
                        </FormInputGroup>

                        <FormInputGroup label='Expéditeur' as={Col}>
                            <FormSelect size='sm' name='config[from]' defaultValue={config?.from} required>
                                <option value="contact@orxmail.fr">contact@orxmail.fr</option>
                                <option value="contact@genelead.fr">contact@genelead.fr</option>
                            </FormSelect>
                        </FormInputGroup>
                        <FormInputGroup label='Répondre à' as={Col}>
                            <FormControl size='sm' type='email' placeholder='answer' name='config[reply_to]' 
                                defaultValue={config?.reply_to} />
                        </FormInputGroup>
                    </Row>

                    <div className='mt-3 text-end'>
                        <BtnIconLoad data={callGatewayAPI}>Enregister</BtnIconLoad>
                    </div>
                </Card.Body>
            </Card>
        </SimpleForm>
    </>
}

export default MailerGenelead;