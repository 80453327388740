import { useEffect, useState } from "react";
import { Button, ButtonGroup, Card, Col, Container, Row } from "react-bootstrap";
import AppSecured from "../../AppSecured";

import Drawer from 'react-modern-drawer'
import 'react-modern-drawer/dist/index.css'
import { ButtonIcon, Icon } from "../misc/Buttons";

// <iframe className="airtable-embed" src=""
//         frameBorder="0" onmousewheel="" width="100%" height="533"
//         style="background: transparent; border: 1px solid #ccc;"></iframe>

const Dev = () => {
    useEffect(() => {
        (function () {
            var s   = document.createElement("script");
            s.src   = "https://static.airtable.com/js/embed/embed_snippet_v1.js";
            s.async = true;
            document.getElementsByTagName("head")[0].appendChild(s);
        })();
    }, []);

    const [ open, setOpen ] = useState(false);

    return <>
        <AppSecured>
            <div className={"d-flex align-items-center mt-3"}>
                <h1><Icon code={'code'}/> Développement</h1>
                <ButtonIcon className={"ms-3"} code={"plus"} size={"sm"} variant={"primary"}
                            onClick={() => setOpen(true)}>
                    Nouvelle demande
                </ButtonIcon>
            </div>
            <Row className={'h-100'}>
                <Col md={12}>
                    <Card className={"mb-3"} style={{height:'500px'}}>
                        <Card.Header as={"h3"}>Roadmap</Card.Header>
                        <iframe style={{ width: '100%', height: '100%' }}
                                src={"https://airtable.com/embed/shrQDPFNaRjEDlcO5?backgroundColor=yellowLight"}/>
                    </Card>
                </Col>

                <Col md={12} className={'h-100'}>
                    <Card className={'h-100'}>
                        <Card.Header as={"h3"}>Demandes</Card.Header>
                        <iframe className="airtable-embed" style={{ width: '100%', height: '100%' }}
                                src="https://airtable.com/embed/shr729BCX4NVJIhIv?backgroundColor=yellowLight&viewControls=on"></iframe>
                    </Card>
                </Col>

                <Drawer
                    open={open}
                    onClose={() => setOpen(!open)}
                    direction={'right'}
                    size={400}
                >
                    <iframe style={{ height: '100%', width: '100%' }}
                            src="https://airtable.com/embed/shr4Vu5quXjsGOfbM?backgroundColor=purple"></iframe>
                </Drawer>
            </Row>
        </AppSecured>
    </>
}

export default Dev;