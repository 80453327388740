import { useState } from "react";
import { Badge, Button, FormControl, InputGroup } from "react-bootstrap";
import { FormattedDate } from "react-intl";
import { useAppDispatch } from "../../app/hooks";
import diffusionApi from "../../app/services/secured/diffusion";
import { modalFbSearchPageShow, modalFbSyncShow } from "../../app/slice/modal";
import AppSecured from "../../AppSecured";
import Urls from "../../assets/js/Urls";
import { FbForm } from "../../types";
import { PageHeader } from "../App/PageHeader";
import { LoadingBlock } from "../misc/Blocks";
import { ButtonIcon, Icon } from "../misc/Buttons";
import { PaginationGenelead } from "../misc/Pagination";
import SimpleForm from "../misc/SimpleForm";
import TableGenelead from "../misc/Table";

const FacebookForms: React.FC = () => {
    const dispatch = useAppDispatch();
    const [search, setSearch] = useState<{ query: string, page: number }>({ query: '', page: 1 });
    const fbforms = diffusionApi.useGetFbFormsQuery(search);
    return <>
        <AppSecured>
            <PageHeader action={<>
                <ButtonIcon code={"plus"} size={"sm"} variant={"primary"} className='me-1'
                    onClick={() => dispatch(modalFbSearchPageShow())}>
                    Relier formulaire</ButtonIcon>
                <ButtonIcon href={Urls.fb.install} code={"facebook"} size={"sm"} variant={"primary"} className='alt-primary'>
                    Compte Facebook</ButtonIcon>
            </>}>
                <SimpleForm onSubmit={(query) => setSearch({ query: query.keyword as string, page: 1 })}>
                    <InputGroup size='sm'>
                        <FormControl placeholder='Recherche mot clé' name='keyword' />
                        <Button type='submit'><Icon code='search' /></Button>
                    </InputGroup>
                </SimpleForm>
            </PageHeader>

            <LoadingBlock data={fbforms}>
                <TableGenelead hasActions={true} header={[
                    { name: 'Formulaire Facebook', icon: 'facebook' },
                    { name: 'Code Landing Page', icon: 'grid-1x2' },
                    { name: 'Dernier import', icon: 'calendar', align: 'center' },
                    { name: 'Période', icon: 'calendar', align: 'center' },
                    { name: 'Leads', icon: 'person', align: 'center' },
                    { name: 'Doublons', icon: 'people', align: 'center' },
                    { name: "Infos", icon: 'list' },
                ]}>
                    {fbforms.data?.list.map(f => <FbFormRow key={Math.random()} {...f} />)}
                </TableGenelead>
            </LoadingBlock>
        </AppSecured>
    </>
}

const FbFormRow: React.FC<FbForm> = fbform => {
    const dispatch = useAppDispatch();
    return <tr>
        <td>{fbform.facebookFormName}</td>
        <td><Badge>{fbform.formId}</Badge> {fbform.form.name}</td>
        <td className='text-center'><FormattedDate value={fbform.stats.last * 1000} /> </td>
        <td className='text-center'><FormattedDate value={fbform.stats.period_from * 1000} /> - <FormattedDate value={fbform.stats.period_to * 1000} /></td>
        <td className='text-end'>{fbform.stats.total ? fbform.stats.total : ''}</td>
        <td className='text-end'>{fbform.stats.duplicates ? fbform.stats.duplicates : ''}</td>
        <td>
            <small>
                {fbform.stats.missing ? <div className='alert-warning'>Champs manquants : {fbform.stats.missing}</div> : ''}
                {fbform.stats.errors ? <div className='alert-danger'>Erreurs : {fbform.stats.errors}</div> : ''}
            </small>
        </td>
        <td className='text-end'>
            <ButtonIcon code={"arrow-repeat"} size={"lg"} variant={"primary"} className='mt-2'
                onClick={() => dispatch(modalFbSyncShow({ facebookFormId: fbform.facebookFormId }))} />
        </td>
    </tr>
}

export default FacebookForms;