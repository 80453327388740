import { useCallback, useEffect } from "react";
import { Accordion, Button } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { DesignElement, Icon } from "../../Design";
import { deleteContent, editContentDesign, getColumn, getContent, getCurrentContent, getView, GlobalStyle, isEditingContent, moveContent, Position, setContentDesign, validateContent } from "../../landing-v2";
import { MarginPadding } from "../../Tools/MarginPadding";
import { FormDesign } from "../Form";
import { CTADesign } from "./CTA";
import { ImageDesign } from "./Image";
import { SocialsDesign } from "./Socials";

export const ContentActions: React.FC<{ pos: Position, size: number }> = ({ pos, size }) => {
   const { row, col, block } = pos;
   const dispatch = useAppDispatch();
   const moveField = (direction: number) => {
      if (block !== null) {
         dispatch(moveContent({ block, direction }))
      }
   };
   return <>
      {block !== null && <>
         <div className='move-content-wrapper'>
            <div className='d-flex align-items-center justify-content-center'>
               <Button variant='' onClick={() => dispatch(editContentDesign({ block }))} size='sm'>
                  <Icon code='paint-bucket' /></Button>

               {block > 0 && <Button variant='' onClick={() => moveField(-1)} size='sm'><Icon code='chevron-up' /></Button>}
               {block + 1 < size && <Button variant='' onClick={() => moveField(1)} size='sm'><Icon code='chevron-down' /></Button>}
            </div>
         </div>
      </>}
   </>
}

export const ContentWrapper: React.FC<{ children?: any, row: number, col: number, block: number, isEdit: boolean }> = ({ children, row, col, block, isEdit }) => {
   const dispatch = useAppDispatch();
   const column = useAppSelector(getColumn(row, col));
   const contentData = useAppSelector(getContent({ row, col, block }));

   const contentDesign: GlobalStyle = {};
   contentDesign.margin = contentData?.style.margin ? contentData.style.margin : '';
   contentDesign.textAlign = contentData?.style.textAlign ? contentData.style.textAlign : '';

   // spécifique pour image
   contentDesign.height = contentData?.type === 'image' && contentData?.style.display === 'manual' && contentData?.style.height
      ? contentData.style.height + 'px'
      : '';

   const isCurrentFocus = useAppSelector(isEditingContent(row, col, block));
   const view = useAppSelector(getView);

   const confirmDelete = () => () => {
      if (window.confirm('Êtes-vous certain de vouloir supprimer ce contenu?') === true)
         dispatch(deleteContent({ block: block }))
   }

   let className = 'content-wrapper';
   if (isCurrentFocus) className += ' focus-animation'

   return <span className={className}>
      <div style={contentDesign}>
         {children}
      </div>

      {isEdit && view === 'block-design' && <div>
         <div className='delete-content-button-wrapper'>
            <Button variant='' onClick={confirmDelete()} size='sm'>
               <Icon code='trash3' /></Button>
         </div>
         <ContentActions pos={{ row, col, block }} size={column.childs.length} />
      </div>}
   </span>
}

export const ContentDesign = () => {
   const dispatch = useAppDispatch();
   const content = useAppSelector(getCurrentContent);
 
   const handleKeyPress = useCallback((event: any) => {
     if (event.keyCode === 13) { dispatch(validateContent()); }
   }, []);
 
   useEffect(() => {
     document.addEventListener('keydown', handleKeyPress);
     return () => {
       document.removeEventListener('keydown', handleKeyPress);
     };
   }, [handleKeyPress]);
 
   return <div>
     <div id='tools-title'>
       <h2 className='m-0'>Contenu</h2><small><FormattedMessage id={content?.type} /></small>
     </div>
     <div id='tools-wrapper'>
       <Button className='validate-btn' variant='' onClick={() => dispatch(validateContent())}>Valider</Button>
 
       {content?.type === 'form' && <FormDesign />}
 
       <Accordion className='design-accordion' defaultActiveKey={'3'}>
         <DesignElement eventKey='1' title='Marge interne'>
           <MarginPadding init={content && content?.style?.padding ? content.style.padding : ''} option='Marge interne (padding)' className='mt-2'
             callback={(value: string) => dispatch(setContentDesign({ option: 'padding', value: value }))} />
         </DesignElement>
         <DesignElement eventKey='2' title='Marge externe'>
           <MarginPadding init={content && content?.style?.margin ? content.style.margin : ''} option='Marge externe (margin)' className='mt-2'
             callback={(value: string) => dispatch(setContentDesign({ option: 'margin', value: value }))} />
         </DesignElement>
         {content?.type === 'image' && <ImageDesign />}
         {content?.type === 'cta' && <CTADesign />}
         {content?.type === 'socials' && <SocialsDesign />}
       </Accordion>
     </div>
     
   </div>
 }