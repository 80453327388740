import {useState} from "react";
import {Alert, Button, FormSelect, Modal} from "react-bootstrap";
import {useAppSelector} from "../../app/hooks";
import {StorageManager} from "../../app/services/storage";
import {getModalData, isModalOpened, ModalTypes} from "../../app/slice/modal";
import ApiUrls from "../../assets/js/ApiUrls";
import {Company, CompanyDatabases, Database} from "../../types";
import {SimpleDateInit} from "../misc/DatePeriod";
import DatePicker from "../misc/DatePicker";
import SimpleForm, {SimpleFormData} from "../misc/SimpleForm";
import ModalCloseButton from "../Modals/ModalCloseButton";
import dataApi from "../../app/services/secured/data";
import {LoadingBlock} from "../misc/Blocks";

const ModalExportEmailGlobal = () => {
    const isOpened = useAppSelector(isModalOpened(ModalTypes.CompanyExportEmailsGlobal));
    const data = useAppSelector<Company>(getModalData);
    const databases = dataApi.useGetDatabaseListQuery({});

    const [range, setRange] = useState<Array<string>>([SimpleDateInit.from, SimpleDateInit.to]);

    const generateLink = (formData:SimpleFormData) => {
        const link = new URL(ApiUrls.companies.downloadAllEmails(data.id, formData.database, StorageManager.get()), process.env.REACT_APP_API_DOMAIN);
        link.searchParams.set("from", range[0]);
        link.searchParams.set("to", range[1]);

        window.open(link,"_blank","noreferrer");
    }

    return <>
        {isOpened && <>
            <SimpleForm onSubmit={(json) => {generateLink(json)}}>
                <Modal.Header>
                    {data.name} | Export des emails global
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex flex-column align-items-center'>
                        <Alert variant='info' className="w-100">
                            Veuillez sélectionner les éléments suivant pour en extraire les leads :
                                <ul className="mb-0">
                                    <li>une période</li>
                                    <li>une base email</li>
                                </ul>
                        </Alert>
                        {/*<DatePicker className="w-100" onChange={(from, to) => setRange([from, to]) } />*/}
                        <DatePicker className="w-100" onChange={(from, to) => setRange([from, to]) } />
                        <LoadingBlock data={databases}>
                            <div className="my-3">
                                <FormSelect key={Math.random()} name="database">
                                    <option value="">Base repoussoir</option>
                                    {databases.data && databases.data.list
                                        .map((d: CompanyDatabases) => d.databases.map((db: Database) => <option
                                            key={Math.random()} value={db.id}>{d.name} - {db.name}</option>))
                                        .flat()
                                    }
                                </FormSelect>
                            </div>
                        </LoadingBlock>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <ModalCloseButton />
                    {databases.isSuccess && <Button type='submit'>Extraire</Button>}
                </Modal.Footer>
            </SimpleForm>
        </>}
    </>
}

export default ModalExportEmailGlobal;