import { ListGroup } from "react-bootstrap";
import { Icon } from "../Design";
import { GlobalStyle } from "../landing-v2";

type DisplayControllerProps = {
   style: GlobalStyle,
   onChange: (style: GlobalStyle) => void,
   forceFlex?: boolean,
 }
 
 export const DisplayController: React.FC<DisplayControllerProps> = ({ style, onChange, forceFlex }) => {
   const dir = style.flexDirection;
   return <>
     <ListGroup horizontal>
       {!forceFlex && <>
         <ListGroup.Item action active={style.display === 'block'}
           onClick={() => onChange({ ...style, display: 'block' })}>
           Classique
         </ListGroup.Item>
       </>}
       <ListGroup.Item action active={style.display === 'flex' || forceFlex}
         onClick={() => onChange({ ...style, display: 'flex' })}>
         Flex
       </ListGroup.Item>
     </ListGroup>
 
     {(style.display === 'flex' || forceFlex) && <>
       <ListGroup horizontal className='mt-1'>
         <ListGroup.Item action active={style.flexDirection === 'row'} className='text-center'
           onClick={() => onChange({...style, flexDirection: 'row'})}>
           <Icon code='distribute-horizontal' />
         </ListGroup.Item>
         <ListGroup.Item action active={style.flexDirection === 'column'} className='text-center'
           onClick={() => onChange({...style, flexDirection: 'column'})}>
           <Icon code='distribute-vertical' />
         </ListGroup.Item>
       </ListGroup>
 
       <ListGroup horizontal className='mt-1'>
         <ListGroup.Item action active={style.alignItems === 'start'} className='text-center'
           onClick={() => onChange({...style, alignItems: 'start'})}>
             {dir === 'row'
               ? <Icon code='align-top' />
               : <Icon code='align-start' />}
         </ListGroup.Item>
         <ListGroup.Item action active={style.alignItems === 'center'} className='text-center'
           onClick={() => onChange({...style, alignItems: 'center'})}>
           {dir === 'row'
               ? <Icon code='align-middle' />
               : <Icon code='align-center' />}
         </ListGroup.Item>
         <ListGroup.Item action active={style.alignItems === 'end'} className='text-center'
           onClick={() => onChange({...style, alignItems: 'end'})}>
           {dir === 'row'
               ? <Icon code='align-bottom' />
               : <Icon code='align-end' />}
         </ListGroup.Item>
         <ListGroup.Item action active={style.alignItems === 'stretch'} className='text-center'
           onClick={() => onChange({...style, alignItems: 'stretch'})}>
           {dir === 'row'
               ? <Icon code='arrows-expand' />
               : <Icon code='arrows-expand' className='rotate-icon' />}
         </ListGroup.Item>
       </ListGroup>
 
       <ListGroup horizontal className='mt-1'>
         <ListGroup.Item action active={style.justifyContent === 'start'} className='text-center'
           onClick={() => onChange({...style, justifyContent: 'start'})}>
           {dir === 'row'
               ? <Icon code='align-start' />
               : <Icon code='align-top' />}
         </ListGroup.Item>
         <ListGroup.Item action active={style.justifyContent === 'center'} className='text-center'
           onClick={() => onChange({...style, justifyContent: 'center'})}>
           {dir === 'row'
               ? <Icon code='align-center' />
               : <Icon code='align-middle' />}
         </ListGroup.Item>
         <ListGroup.Item action active={style.justifyContent === 'end'} className='text-center'
           onClick={() => onChange({...style, justifyContent: 'end'})}>
           {dir === 'row'
               ? <Icon code='align-end' />
               : <Icon code='align-bottom' />}
         </ListGroup.Item>
         <ListGroup.Item action active={style.justifyContent === 'stretch'} className='text-center'
           onClick={() => onChange({...style, justifyContent: 'stretch'})}>
           {dir === 'row'
               ? <Icon code='arrows-expand'  className='rotate-icon' />
               : <Icon code='arrows-expand' />}
         </ListGroup.Item>
       </ListGroup>
     </>}
   </>
 }