import { useEffect, useRef, useState } from "react";
import {Alert, Col, Row, Image, Button, FormCheck} from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import creationApi from "../../../app/services/secured/creation";
import {getLanding, getLandingMedias, setLandingData, setMedia} from "../../../app/slice/landing";
import Media, { getMedia, selectMedia } from "../../../app/slice/media";
import { getModalData, modalMediasShow } from "../../../app/slice/modal";
import ApiUrls from "../../../assets/js/ApiUrls";
import { Landing, LandingMedia } from "../../../types";
import { ButtonIcon, Icon } from "../../misc/Buttons";

const LandingMedias = () => {
    const dispatch = useAppDispatch();
    const landing = useAppSelector(getLanding);
    const templates = creationApi.useGetTemplatesQuery();

    const medias = templates.data?.modals
        .filter(m => m.code === landing.data.modal_version)
        .map(o => o.medias)
        .concat(templates.data.pages.filter(p => landing.enabledTemplates.indexOf(p.id as number) > - 1).map(o => o.medias))
        .reduce((p, c) => {
            [...c].forEach(key => {
                if (p.indexOf(key) === -1) p.push(key);
            })

            return p;
        }, []);

    medias && !medias.includes('rs') && medias.push('rs');

    return <>
        <Alert variant="info">
            <Row>
                <Col>
                    <h5>Logo</h5>
                    <InfoBlock formats="png, jpg" formats_min="80px de hauteur et 150px de largeur" max_size="300Ko" />
                </Col>
                <Col>
                    <h5>Image de fond</h5>
                    <InfoBlock formats="png, jpg" formats_min="16:9, idéal : 1920x1080" max_size="300Ko" />
                </Col>
                <Col>
                    <h5>Partage réseaux sociaux</h5>
                    <InfoBlock formats="png, jpg" formats_min="1200x630 / minimum: 600x315" max_size="300Ko" />
                </Col>
            </Row>
        </Alert>

        <Row>
            {medias?.map(m => <MediaBlock code={m} key={Math.random()} onClick={(code: string, media: LandingMedia) => {
                dispatch(setMedia({ code: code, media: media }));
            }} />)}
        </Row>
        <Row>
            <Col>
                <FormCheck type='switch' label='Utiliser un Logo blanc ?'
                           defaultChecked={landing.data.use_white_logo ? true : false}
                           onChange={(e) => dispatch(setLandingData({ ...landing.data, use_white_logo: e.target.checked }))} />
            </Col>
        </Row>
    </>
}

const MediaBlock: React.FC<{ code: string, onClick: (code: string, media: LandingMedia) => void }> = ({ code, onClick }) => {
    const medias = useAppSelector(getLandingMedias);
    const dispatch = useAppDispatch();
    const selectedMedia = useAppSelector<LandingMedia>(getMedia);
    const [current, setCurrent] = useState('');

    useEffect(() => {
        if (selectedMedia.id && current === code) {
            onClick(code, selectedMedia);
        }
    }, [selectedMedia])

    return <Col>
        <div className='d-flex align-items-center'>
            <div>
                <Button className='mb-2 alt-primary' size={"lg"} variant={"primary"}
                    onClick={(e) => { dispatch(modalMediasShow()); setCurrent(code) }}>
                    <Icon code='images' />
                </Button>
            </div>
            <h6 className='ms-3'><FormattedMessage id={code} /></h6>
        </div>
        <div>
            {medias && <Image rounded fluid src={ApiUrls.front.media(
                medias[`${code}`]?.id.toString() as string, medias[`${code}`]?.extension as string)} />}
        </div>
    </Col>
}

const InfoBlock: React.FC<{ formats: string, formats_min: string, max_size: string }> = ({ formats, formats_min, max_size }) => {
    return <>
        <b>Format de fichier acceptés</b> : {formats} <br />
        <b>Format conseillés:</b> {formats_min} <br />
        <b>Poid max</b> : {max_size}
    </>
}

export default LandingMedias;