const Urls = {
    login: '/login',
    forgot: '/forgot',
    refresh: (token:string) => `/refresh-password/${token}.html`,
    dash: '/',
    landing: {
        list: '/dash/landings',
        landingTemplate: (domain:string, slug:string, template:string|number) => `///${domain}/${slug}/${template}/index.html`,
        edit: (code: string|number) => `/dash/landings/${code}`,
        editv2: (id: string|number) => `/dash/landings/v2/${id}`,
        duplicate: (code: string|number) => `/dash/landings/${code}?duplicate=true`,
        duplicatev2: (id: string|number) => `/dash/landings/v2/${id}?duplicate=true`,
        v2: `/dash/landings/v2`,
    },
    settings: '/dash/settings',
    users: {
        list: "/dash/users",
        edit: (user:string) => `/dash/users/${user}`,
    },
    linkKeys: {
        list: "/dash/link-keys",
        new: "/dash/link-keys/new",
        edit: (identifier:string) => `/dash/link-keys/${identifier}`,
        delete: (identifier:string) => `/dash/link-keys/${identifier}`,
    },
    captainVerify: '/dash/captain-verify',
    categories: {
        list: '/dash/categories',
        edit: (id:string) => `/dash/categories/${id}`,
    },
    medias: {
        list: '/dash/medias',
    },
    sites: {
        list: "/dash/sites",
        edit: (site:string) => `/dash/sites/${site}`,
        landings: (site: string) => `/dash/sites/${site}/landings`
    },
    databases: {
        list: '/dash/databases',
        loader: (accessToken:string) => `${process.env.REACT_APP_API_DOMAIN}/databases/${accessToken}.html`,
    },
    companies: {
        list: '/dash/companies',
        view: (company_id: string|number) => `/dash/companies/${company_id}`,
        editAccount: (company: string|number, account:string|number) => `/dash/companies/${company}/accounts/${account}`,
        customRecord: (company_id:string|number) => `/dash/companies/${company_id}/custom`,
        //viewWebservice: (company:string|number)
        apiDoc: (token:string) => `${process.env.REACT_APP_API_DOMAIN}/api/leads/download/documentation.html?access_token=${token}`,
    },
    leads: {
        list: '/dash/leads',
        export: '/dash/leads/export',
    },
    forms: {
        list: '/dash/forms',
        edit: (id: string) => `/dash/forms/${id}`,
    },
    fields: '/dash/fields',
    coregistration: {
        list:'/dash/co-registration',
        edit: (id:string) => `/dash/co-registration/${id}`,
        doc: (token:string) => `${process.env.REACT_APP_API_DOMAIN}/api/coreg/partner/documentation.html?access_token=${token}`,
    },
    fb: {
        forms: '/dash/fbforms',
        install: '/dash/fbforms/install',
    },
    webservices: '/dash/webservices',
    dev: "/dash/dev",
}

export default Urls;