import { useState } from "react";
import { Badge, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { FormattedNumber } from "react-intl";
import { useAppDispatch } from "../../app/hooks";
import dataApi from "../../app/services/secured/data";
import globalApi from "../../app/services/secured/global";
import { modalDatabaseShow } from "../../app/slice/modal";
import AppSecured from "../../AppSecured";
import Urls from "../../assets/js/Urls";
import { CompanyDatabases, Database } from "../../types";
import { PageHeader } from "../App/PageHeader";
import { LoadingBlock } from "../misc/Blocks";
import { ButtonIcon, Icon } from "../misc/Buttons";
import { PaginationGenelead } from "../misc/Pagination";
import TableGenelead from "../misc/Table";

const Databases: React.FC = () => {
    const [search, setSearch] = useState({});
    const databases = dataApi.useGetDatabaseListQuery(search);
    const dispatch = useAppDispatch();

    const changePage = (page: number) => { setSearch({ ...search, page }) }
    const companies = globalApi.useGetCompaniesListQuery();
    const [company, setCompany] = useState(0);

    return <>
        <AppSecured>
            <PageHeader action={
                <ButtonIcon variant='primary' size='sm' code='cloud-plus'
                    onClick={() => dispatch(modalDatabaseShow({ isNew: true }))}>
                    Nouvelle base client
                </ButtonIcon>
            }>
                {
                    companies.isSuccess && <>
                        <Icon code='search' className='fs-18 me-2' />
                        <Typeahead
                            id='search-database'
                            options={companies.data?.map(c => { return { label: c.name, id: c.id } })}
                            placeholder='Client'
                            onChange={(s) => {
                                if (s[0]) {
                                    const val = s[0] as {label:string, id:number};
                                    setCompany(val.id);
                                } else setCompany(0);
                            }}
                        />
                    </>
                }

            </PageHeader>

            {databases.isSuccess && <>
                <PaginationGenelead
                    size='sm'
                    pages={{ current: databases.data.page, total: databases.data.total_pages }}
                    search={changePage}
                />
            </>}
            <LoadingBlock data={databases}>
                <TableGenelead header={[
                    { name: 'Client', icon: 'person', width: '20%' },
                    { name: 'Base', icon: 'server' },
                ]}>
                    {databases.isSuccess &&
                        databases.data.list.filter(d => company ? d.id === company : true).map((d: CompanyDatabases) => <CompanyRow key={Math.random()} {...d} />)}
                </TableGenelead>

                {
                    databases.isSuccess && <>
                        <PaginationGenelead
                            className='m-0 ms-3'
                            pages={{ current: databases.data.page, total: databases.data.total_pages }}
                            search={changePage}
                        />
                    </>
                }
            </LoadingBlock>

        </AppSecured>
    </>
}

// Row
const CompanyRow: React.FC<CompanyDatabases> = props => {
    const dispatch = useAppDispatch();
    return <tr>
        <td>
            <div className="d-flex justify-content-between align-items-center">
                <span><b>{props.name}</b></span>
                <ButtonIcon code={"cloud-plus"} size={"sm"} variant={"primary"} className='me-1 alt-primary'
                    onClick={() => dispatch(modalDatabaseShow({ isNew: true, company: props }))} />
            </div>
        </td>
        <td className=''>
            {
                props.databases.map(d => <DatabaseButton key={Math.random()} {...d} />)
            }
        </td>
    </tr>
}

const DatabaseButton: React.FC<Database> = props => {
    const dispatch = useAppDispatch();
    return <>
        {props.id && <>
            <OverlayTrigger overlay={
                <Tooltip>
                    <Icon code='envelope' className='me-1' />
                    <b><FormattedNumber value={props.emails} /></b>
                </Tooltip>
            }>
                <div className='database-button d-flex me-2 mt-1 justify-content-between align-items-center'>
                    <div>
                        <div className='d-flex align-items-center me-2'>
                            <Icon code='server' className='me-2' />
                            <div>{props.name}</div>
                        </div>
                        <div>
                            {
                                props.forms.length > 0 &&
                                props.forms.map(f => <Badge key={Math.random()} className='me-1 alt-primary'>{<>{f}</>}</Badge>)
                            }

                        </div>
                    </div>
                    <div className="d-flex align-items-center">
                        <ButtonIcon target='_blank' href={Urls.databases.loader(props.access_token)} code={"receipt"} size={"sm"}
                            variant={"secondary"} className='me-1' />

                        <ButtonIcon code={"pencil"} size={"sm"} variant={"primary"} className=''
                            onClick={() => dispatch(modalDatabaseShow({ database: props }))} />
                    </div>
                </div>
            </OverlayTrigger>
        </>
        }</>
}

export default Databases;