import { useEffect, useState } from "react";
import { Alert, Col, FormCheck, FormControl, FormLabel, ListGroup, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import diffusionApi from "../../../app/services/secured/diffusion";
import globalApi from "../../../app/services/secured/global";
import {
    getLanding,
    isDuplicate,
    isLive,
    isNewLanding,
    isQuizz,
    LANDING_STATUSES,
    setIsQuizz,
    setLanding,
    setLandingStatus
} from "../../../app/slice/landing";
import { LandingAPI } from "../../../types";
import { IfTrue } from "../../misc/Blocks";
import { Icon } from "../../misc/Buttons";
import creationApi from "../../../app/services/secured/creation";
import { addNotification } from "../../../app/slice/notifs";
import { modalLinkLandingShow } from "../../../app/slice/modal";

const LandingInfo: React.FC = () => {
    const dispatch                        = useAppDispatch();
    const landing                         = useAppSelector(getLanding);
    const [ landingName, setLandingName ] = useState<string>(landing.name ? landing.name : '');
    const duplicate                       = useAppSelector(isDuplicate);

    const isNew = useAppSelector(isNewLanding);

    const makeCode = ( name: string ) => {
        return name
            .toLowerCase()
            .normalize("NFD").replace(/[\u0300-\u036f]/g, "")
            .replace(/[^a-zA-Z0-9- ]/g, '')
            .trim()
            .replace(/\s/g, '-')
            .replace(/\-\-+/g, '-')
    }

    return <>
        <LandingStatus/>

        <IfTrue condition={duplicate}>
            <Alert variant='warning' className='text-center'>
                Cette LP est en copie. Éditez l'identifiant unique afin de pouvoir créer une nouvelle landing page.
            </Alert>
        </IfTrue>

        <Row className='d-flex align-items-center mb-1'>
            <Col sm={2} className='text-end'><FormLabel className='m-0'>Landing</FormLabel></Col>
            <Col sm={10}>
                <FormControl placeholder='Nom de la landing page' name='name' value={landingName}
                             onBlur={( e ) => {
                                 dispatch(setLanding({ ...landing, name: e.target.value }))
                             }}
                             onChange={( e ) => {
                                 setLandingName(e.target.value);
                             }}/>
            </Col>
        </Row>

        <Row className='d-flex align-items-center mb-1'>
            <Col sm={2} className='text-end'><FormLabel className='m-0'>Identifiant / Slug URL</FormLabel></Col>
            <Col sm={10}>
                <FormControl type='text' placeholder='Identifiant' name='code'
                             defaultValue={landing.code && !isNew ? landing.code : makeCode(landingName)}
                             onBlur={( e ) => {
                                 landing.code && dispatch(setLanding({ ...landing, code: e.target.value }));
                             }}
                />
                <div className='text-end'>
                    <FormLabel className='text-muted'>50 caractères max.</FormLabel>
                </div>
            </Col>
        </Row>

        <CompanySelect/>
        <CategorySelect/>
        <ThemeSelect/>
        <DomainSelect/>
    </>
}

const LandingStatus: React.FC = () => {
    const landing         = useAppSelector(getLanding);
    const dispatch        = useAppDispatch();
    const setStatus       = ( status: number ) => () => {
        dispatch(setLandingStatus(status));
    }
    const landingHasQuizz = useAppSelector(isQuizz);

    const [ saveLanding ] = creationApi.useSaveLandingMutation();

    const [ getC ] = creationApi.useLazyGetLandingCustomersQuery();


    async function setLandingLive() {
        const query        = await getC({ id: landing.id });
        const cData        = query.data;
        const hasCustomers = (cData?.filter(c => c.enabled).length || 0) > 0;

        if (!hasCustomers) {
            saveLanding({ id: landing.id, landing: landing });
            dispatch(addNotification({ type: 'warning', message: 'Aucun compte client relié' }));
            dispatch(modalLinkLandingShow(landing.id));
        } else {
            dispatch(setLandingStatus(8));
        }
    }

    return <>
        {landing?.code && <>
            <Row>
                <Col className='d-flex align-items-center mb-4 justify-content-center'>
                    <ListGroup horizontal className='ms-2' defaultActiveKey={landing?.status?.toString()}
                               style={{ cursor: 'pointer' }}>
                        <ListGroup.Item eventKey={'8'} onClick={() => setLandingLive()}><Icon
                            code='broadcast'/> Live</ListGroup.Item>
                        <ListGroup.Item eventKey={'4'} onClick={setStatus(4)}><Icon
                            code='box2'/> Brouillon</ListGroup.Item>
                        <ListGroup.Item eventKey={'2'} onClick={setStatus(2)}><Icon code='trash'/> Bin</ListGroup.Item>
                    </ListGroup>
                </Col>
            </Row>
            <div className='fs-16 d-flex align-items-center mb-4 justify-content-center'>
                <FormCheck label='Quizz' type='switch' checked={landingHasQuizz}
                           disabled={landing.quizz.length > 0 ? true : false}
                           onChange={( e ) => dispatch(setIsQuizz(e.target.checked))}/>
            </div>
        </>}
    </>
}

const CompanySelect: React.FC = () => {
    const landing                               = useAppSelector(getLanding);
    const isNew                                 = useAppSelector(isNewLanding);
    const dispatch                              = useAppDispatch();
    const companies                             = globalApi.useGetCompaniesListQuery();
    const [ company, setCompany ]               = useState(landing.company);
    const [ selectCompany, setSelectedCompany ] = useState<Array<{ label: string, value: number }>>([]);

    useEffect(() => {
        if (landing && landing.company && !isNew) {
            const c_name = companies.data?.find(c => c.id === landing.company)?.name;
            setSelectedCompany([ { label: c_name ? c_name : '', value: landing.company } ]);
        }
    }, [ landing ])

    useEffect(() => {
        if (companies.isSuccess) {
            const companyNode = companies.data.find(c => c.id === company);
            if (companyNode)
                dispatch(setLanding({
                    ...landing,
                    company: companyNode.id
                } as LandingAPI))
        }
    }, [ company, companies.isSuccess ])

    return <>
        {
            companies.isSuccess && <>
                <Row className='d-flex align-items-center mb-1'>
                    <Col sm={2} className='text-end'><FormLabel className='m-0'>Client</FormLabel></Col>
                    <Col sm={10}>
                        <Typeahead id='select-company' placeholder='Sélectionnez un client'
                                   key={selectCompany[0] && selectCompany[0].label}
                                   defaultSelected={selectCompany}
                                   options={companies.data?.map(c => {
                                       return { label: c.name, value: c.id }
                                   })}
                                   onChange={( s ) => {
                                       if (s[0]) {
                                           const val = s[0] as { value: number };
                                           setCompany(val.value);
                                       }
                                   }}
                        />
                        <FormControl type='hidden' name='company' value={company}/>
                    </Col>
                </Row>
            </>
        }
    </>
}

const CategorySelect: React.FC = () => {
    const landing                                 = useAppSelector(getLanding);
    const isNew                                   = useAppSelector(isNewLanding);
    const dispatch                                = useAppDispatch();
    const categories                              = globalApi.useGetCategoriesListQuery();
    const [ category, setCategory ]               = useState(landing.category);
    const [ selectCategory, setSelectedCategory ] = useState<Array<{ label: string, value: number }>>([]);

    useEffect(() => {
        if (landing.category && !isNew) {
            const c_name = categories.data?.find(c => c.id === landing.category)?.name;
            setSelectedCategory([ { label: c_name ? c_name : '', value: landing.category } ]);
        }
    }, [ landing ])

    useEffect(() => {
        if (categories.isSuccess) {
            const categorynode = categories.data.find(c => c.id === category);
            if (categorynode)
                dispatch(setLanding({ ...landing, category: categorynode.id } as LandingAPI))
        }
    }, [ category, categories.isSuccess ])

    return <>
        {
            categories.isSuccess && <>
                <Row className='d-flex align-items-center mb-1'>
                    <Col sm={2} className='text-end'><FormLabel className='m-0'>Catégorie</FormLabel></Col>
                    <Col sm={10}>
                        <Typeahead id='select-category' placeholder='Sélectionnez une catégorie'
                                   key={selectCategory[0] && selectCategory[0].label}
                                   defaultSelected={selectCategory}
                                   options={categories.data.filter(c => c.type === 1).map(c => {
                                       return { label: c.name, value: c.id }
                                   })}
                                   onChange={( s ) => {
                                       if (s[0]) {
                                           const val = s[0] as { value: number };
                                           setCategory(val.value);
                                       }
                                   }}
                        />
                        <FormControl key={category} type='hidden' name='category' value={category}/>
                    </Col>
                </Row>
            </>
        }
    </>
}

const ThemeSelect: React.FC = () => {
    const landing                           = useAppSelector(getLanding);
    const isNew                             = useAppSelector(isNewLanding);
    const dispatch                          = useAppDispatch();
    const categories                        = globalApi.useGetCategoriesListQuery();
    const [ selectTheme, setSelectedTheme ] = useState<Array<{ label: string, value: number }>>([]);
    const [ theme, setTheme ]               = useState(landing.theme);

    useEffect(() => {
        if (landing.theme && !isNew) {
            const t_name = categories.data?.find(c => c.id === landing.theme)?.name;
            setSelectedTheme([ { label: t_name ? t_name : '', value: landing.theme } ]);
        }
    }, [ landing ])

    useEffect(() => {
        if (categories.isSuccess) {
            const themeNode = categories.data.find(c => c.id === theme);
            if (themeNode) {
                dispatch(setLanding({ ...landing, theme: themeNode.id } as LandingAPI))
            }
        }
    }, [ theme, categories.isSuccess ])
    return <>
        {
            categories.isSuccess && <>
                <Row className='d-flex align-items-center mb-1'>
                    <Col sm={2} className='text-end'><FormLabel className='m-0'>Thème</FormLabel></Col>
                    <Col sm={10}>
                        <Typeahead id='select-category' placeholder='Sélectionnez un thème'
                                   key={selectTheme[0] && selectTheme[0].label}
                                   defaultSelected={selectTheme}
                                   options={categories.data.filter(c => c.type === 2).map(c => {
                                       return { label: c.name, value: c.id }
                                   })}
                                   onChange={( s ) => {
                                       if (s[0]) {
                                           const val = s[0] as { value: number };
                                           setTheme(val.value);
                                       }
                                   }}
                        />
                        <FormControl key={theme} type='hidden' name='theme' value={theme}/>
                    </Col>
                </Row>
            </>
        }
    </>
}

const DomainSelect: React.FC = () => {
    const landing                             = useAppSelector(getLanding);
    const isNew                               = useAppSelector(isNewLanding);
    const dispatch                            = useAppDispatch();
    const domains                             = diffusionApi.useGetSiteListQuery();
    const [ domain, setDomain ]               = useState(landing.site?.domain ? landing.site?.domain : '');
    const [ selectDomain, setSelectedDomain ] = useState<Array<{ label: string }>>([]);

    useEffect(() => {
        if (landing.site && !isNew)
            setSelectedDomain([ { label: landing.site?.domain } ])
    }, [ landing ])

    useEffect(() => {
        if (domains.isSuccess) {
            const domainNode = domains.data.find(d => d.domain === domain);
            if (domainNode)
                dispatch(setLanding({ ...landing, site: { domain: domainNode.domain } } as LandingAPI))
        }
    }, [ domain, domains.isSuccess ])
    return <>
        {
            domains.isSuccess && <>
                <Row className='d-flex align-items-center mb-1'>
                    <Col sm={2} className='text-end'><FormLabel className='m-0'>Domaine</FormLabel></Col>
                    <Col sm={10}>
                        <Typeahead id='select-category' placeholder='Sélectionnez un domaine'
                                   key={selectDomain[0] && Math.random()}
                                   defaultSelected={selectDomain}
                                   options={domains.data.map(d => {
                                       return { label: d.domain, value: d.id }
                                   })}
                                   onChange={( s ) => {
                                       if (s[0]) {
                                           const val = s[0] as { label: string, value: number };
                                           setDomain(val.label);
                                       }
                                   }}
                        />
                        <FormControl key={domain} type='hidden' name='site' value={domain}/>
                    </Col>
                </Row>
            </>
        }
    </>
}

export default LandingInfo;