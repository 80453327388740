import {useCallback, useEffect, useRef} from "react";
import {Accordion, Button, ButtonGroup, Col, FormCheck, FormControl} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {DesignControl, DesignElement, DesignTitle, Icon} from "../Design";
import {
   addContent,
   editBlock,
   getColumn,
   getColumnContent,
   getCurrentColumn,
   getStep,
   getView,
   GlobalStyle,
   hasForm,
   hasQuizz,
   isEditingColumn,
   setBlockDesign,
   setColumnDesign,
   setColumnOrder,
   validateBlock
} from "../landing-v2";
import {BackgroundImageController} from "../Tools/Background";
import {DisplayController} from "../Tools/Display";
import GenerateStyle from "../Tools/GenerateStyle";
import {MarginPadding} from "../Tools/MarginPadding";
import {CTAContent} from "./Content/CTA";
import {ImageContent} from "./Content/Image";
import {SocialsContent} from "./Content/Socials";
import {TextContent} from "./Content/Text";
import {VideoContent} from "./Content/Video";
import {ContentWrapper} from "./Content/Wrapper";
import {FormContent} from "./Form";
import {QuizzContent} from "./Quizz";
import {CustomCode} from "./Content/CustomCode";

export const ColumnContainer: React.FC<{ row: number, col: number, size: number }> = ({row, col, size}) => {
    const dispatch = useAppDispatch();

    const view = useAppSelector(getView);
    const isEditing = useAppSelector(isEditingColumn(row, col));
    const column = useAppSelector(getColumn(row, col));
    const columnData = useAppSelector(getColumnContent(row, col));
    const colRef = useRef<HTMLDivElement>(null);

    const style = GenerateStyle(['padding', 'backgroundImage', 'backgroundPosition', 'backgroundSize', 'backgroundColor',
        'borderRadius', 'display', 'alignItems', 'justifyContent', 'flexDirection'], column.style);

    const colStyle: { height: string, padding?: string } = {
        height: !columnData.length ? '50px' : '',
    };

    if (parseInt(column.style.colPadding ? column.style.colPadding : '0')) {
        colStyle.padding = '0px';
    }

    let wrapperClass = 'column-wrapper h-100';
    if (isEditing && view === 'block-design') wrapperClass += ' focus-animation';

    // click for edit
    const handleClick = useCallback(() => {
        dispatch(editBlock({row, col}));
    }, []);

    useEffect(() => {
        colRef.current?.addEventListener('click', handleClick)
        return () => {
            document.removeEventListener('click', handleClick);
        };
    }, [handleClick]);

    return <>
        <Col sm={12} md={size} className={'column order-md-0 order-' + column.mobileOrder} style={colStyle}
             ref={colRef}>
            <div className={wrapperClass}>
                <div style={style} className='column-content sm-no-padding h-100'>
                    {columnData?.map((d, j) => {
                        let content;
                        switch (d.type) {
                            case 'text':
                                content = <TextContent isEdit={isEditing} block={j} content={d.value ? d.value : ' '}/>
                                break;
                            case 'image':
                                content = <ImageContent isEdit={isEditing} pos={{row, col, block: j}} node={d}
                                                        url={d.value ? d.value : ''} code={'image-' + row + '-' + col}/>
                                break;
                            case 'video':
                                content = <VideoContent isEdit={isEditing} block={j} embed={d.value ? d.value : ''}/>
                                break;
                            case 'form':
                                content = <FormContent isEdit={isEditing}/>
                                break;
                            case 'socials':
                                content = <SocialsContent isEdit={isEditing} block={j} node={d}/>
                                break;
                            case 'quizz':
                                content = <QuizzContent isEdit={isEditing}/>
                                break;
                            case 'cta':
                                content = <CTAContent node={d} isEdit={isEditing} index={j}/>;
                                break;
                            case 'code':
                                content = <CustomCode content={d.value ? d.value : ''} block={j} isEdit={isEditing}/>
                                break;
                        }

                        return <ContentWrapper key={`content-${row}-${col}-${j}`} row={row} col={col} isEdit={isEditing}
                                               block={j}>
                            {content}
                        </ContentWrapper>
                    })}
                </div>
            </div>
        </Col>
    </>
}

export const ColumnDesign = () => {
    const dispatch = useAppDispatch();
    const column = useAppSelector(getCurrentColumn);

    const lpHasForm = useAppSelector(hasForm);
    const modalHasQuizz = useAppSelector(hasQuizz);
    const step = useAppSelector(getStep);

    const handleKeyPress = useCallback((event: any) => {
        if (event.keyCode === 13) {
            dispatch(validateBlock());
        }
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [handleKeyPress]);

    return <>
        <h2 id='tools-title'>Colonne</h2>
        <div id='tools-wrapper'>
            <Button className='validate-btn' variant='' onClick={() => dispatch(validateBlock())}>Valider</Button>

            <DesignTitle title='Ajouter contenu' code='plus-circle'/>
            <div className='d-flex flex-column justify-content-center align-items-center'>
                <ButtonGroup className='add-content-group'>
                    <Button onClick={() => dispatch(addContent('text'))}>
                        <Icon code='body-text'/>
                        <div>Texte</div>
                    </Button>
                    <Button onClick={() => dispatch(addContent('image'))}>
                        <Icon code='card-image'/>
                        <div>Image</div>
                    </Button>
                    <Button onClick={() => dispatch(addContent('video'))}>
                        <Icon code='person-video2'/>
                        <div>Vidéo</div>
                    </Button>
                </ButtonGroup>
                <ButtonGroup className='add-content-group mt-1'>
                    {!lpHasForm && step === 'landing' && <Button onClick={() => dispatch(addContent('form'))}>
                        <Icon code='ui-checks'/>
                        <div>Formulaire</div>
                    </Button>}
                    <Button onClick={() => dispatch(addContent('cta'))}>
                        <Icon code='link-45deg'/>
                        <div>CTA</div>
                    </Button>
                   <Button onClick={() => dispatch(addContent('code'))}>
                      <Icon code='code-slash'/>
                      <div>Code</div>
                   </Button>
                    <Button onClick={() => dispatch(addContent('socials'))}>
                        <Icon code='facebook'/>
                        <div>RS</div>
                    </Button>
                    {!modalHasQuizz && step === 'modal' && <Button onClick={() => dispatch(addContent('quizz'))}>
                        <Icon code='question'/>
                        <div>Quizz</div>
                    </Button>}
                </ButtonGroup>
            </div>

            <hr style={{color: 'white'}}/>

            {column?.style && <>
                <Accordion className='design-accordion'>
                    <DesignElement eventKey="1" title="Marge interne">
                        <MarginPadding init={column && column?.style?.padding ? column.style?.padding : ''}
                                       option='Marge interne'
                                       callback={(value: string) => {
                                           dispatch(setColumnDesign({...column?.style, padding: value}));
                                       }}/>
                    </DesignElement>
                    <DesignElement eventKey="2" title="Fond">
                        <BackgroundImageController design={column.style}
                                                   onChange={(style: GlobalStyle) => dispatch(setColumnDesign(style))}/>
                    </DesignElement>
                    <DesignElement eventKey="3" title="Display">
                        <DisplayController style={column.style}
                                           onChange={(style: GlobalStyle) => dispatch(setColumnDesign(style))}/>
                    </DesignElement>
                    <DesignElement eventKey="4" title="Autre">
                        <DesignControl title='Ordre mobile'>
                            <FormControl type='number' min={0} defaultValue={column?.mobileOrder}
                                         onChange={(e) => dispatch(setColumnOrder(e.target.value))}/>
                        </DesignControl>
                        <DesignControl title='Arrondi bordure'>
                            <FormControl type='number' defaultValue={column?.style?.borderRadius}
                                         onChange={(e) => dispatch(setBlockDesign({
                                             option: 'borderRadius',
                                             value: e.target.value
                                         }))}/>
                        </DesignControl>
                        <FormCheck type='switch' label='Espace inter-colonnes' className='mt-1'
                                   defaultChecked={column?.style.colPadding ? column.style.colPadding === '1' ? false : true : true}
                                   onChange={(e) => dispatch(setColumnDesign({
                                       ...column?.style,
                                       colPadding: !e.target.checked ? '1' : '0'
                                   }))}/>
                    </DesignElement>
                </Accordion>
            </>}
        </div>
    </>
}

