import { useEffect, useState } from "react";
import { Button, Card, Col, FormCheck, FormControl, FormSelect, FormText, Row } from "react-bootstrap";
import manageApi from "../../../app/services/secured/manage";
import { GatewayAPI, GatewayFormsProps, TALK_GATEWAY } from "../../../types";
import { BlockError, FormInputGroup } from "../../misc/Blocks";
import { BtnIconLoad, ButtonIcon } from "../../misc/Buttons";
import SimpleForm from "../../misc/SimpleForm";
import { PendingLeadsButton } from "../AccountExports";

const TalkForm: React.FC<GatewayFormsProps> = gateway => {
    const [isNew, setIsNew] = useState(true);
    const config = gateway.config as TALK_GATEWAY;

    useEffect(() => {
        if (gateway.id) setIsNew(false);
    }, [gateway])

    const [gatewayAPI, callGatewayAPI] = manageApi.useHandleFormGatewayMutation();
    return <>
        <SimpleForm onSubmit={(json) => {
            gatewayAPI(isNew 
                ? {company: gateway.company, form: gateway.form, gateway: '', data: json as TALK_GATEWAY} as GatewayAPI
                : {company: gateway.company, form: gateway.form, gateway: gateway.id, data: json as TALK_GATEWAY} as GatewayAPI
            )
        }}>
            <FormControl type='hidden' name='gateway_code' value='tawk' />
            <Card className='mt-3'>
                <Card.Header>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex align-items-center'>
                            <FormCheck type='switch' defaultChecked={gateway?.enabled} name='enabled' label='Activer export' className='m-0' />
                            {!isNew && gateway.id && <PendingLeadsButton company_id={gateway.company} form_id={gateway.form} gateway_id={gateway.id} />}
                        </div>
                        <div>
                            <h5 className='text-end m-0'>Talk</h5>
                        </div>
                    </div>
                </Card.Header>
                <Card.Body>
                    <BlockError data={callGatewayAPI} />
                    <Row className="d-flex align-items-center">
                        <FormInputGroup as={Col} label='Nom du service'>
                            <FormControl size='sm' type='text' defaultValue={config?.service_name} placeholder='servicename' 
                                name='config[service_name]' />
                        </FormInputGroup>
                    </Row>
                    <Row>
                        <FormInputGroup label='Login' as={Col}>
                            <FormControl size='sm' type='text' defaultValue={config?.login} placeholder='login'
                                name='config[login]' />
                        </FormInputGroup>
                        <FormInputGroup label='Password' as={Col}>
                            <FormControl size='sm' type='text' defaultValue={config?.password} placeholder='password'
                                name='config[password]' />
                        </FormInputGroup>
                    </Row>
                    <Row >
                        <FormInputGroup label='Code Media' as={Col}>
                            <FormControl size='sm' type='text' defaultValue={config?.media_code} placeholder='media'
                                name='config[media_code]' />
                        </FormInputGroup>
                        <FormInputGroup label='Provenance email' as={Col}>
                            <FormControl size='sm' type='text' defaultValue={config?.provenance_email} placeholder='email'
                                name='config[provenance_email]' />
                            <FormText className="text-muted">(DIRECT si vide)</FormText>
                        </FormInputGroup>
                        <FormInputGroup label='Champ date' as={Col}>
                            <FormSelect size='sm' name='config[date_param]' defaultValue={config?.date_param}>
                                <option value=''>Ne pas envoyer</option>
                                <option value='DATE_INSCRIPTION'>Date inscription</option>
                            </FormSelect>
                        </FormInputGroup>
                    </Row>

                    <div className='mt-3 text-end'>
                        <BtnIconLoad data={callGatewayAPI}>Enregister</BtnIconLoad>
                    </div>
                </Card.Body>
            </Card>
        </SimpleForm>
    </>
}

export default TalkForm;