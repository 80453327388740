import { useEffect } from "react";
import { Col, FormLabel, FormSelect, InputGroup, ListGroup, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getMedia } from "../../../app/slice/media";
import { modalMediasShow } from "../../../app/slice/modal";
import ApiUrls from "../../../assets/js/ApiUrls";
import { LandingMedia } from "../../../types";
import { ButtonIcon } from "../../misc/Buttons";
import { ContainersMenu, DesignTitle } from "../Design";
import { getModal, getModalContent, getModalModel, GlobalStyle, ModalSize, setModalModel, setModalSize, setModalStyle } from "../landing-v2";
import { HideInPreview } from "../LPGenerator";
import ColorPicker from "../Tools/ColorPicker";
import { RowContainer } from "./Row";

export const ModalContainer = () => {
    const dispatch = useAppDispatch();
    const modal = useAppSelector(getModal);
    const modalContent = useAppSelector(getModalContent);
    const modalSize = modal.size ? 'modal-' + modal.size : '';
    const modalModel = useAppSelector(getModalModel);

    //console.log(modal);
    const colStyle: GlobalStyle = {};
    colStyle.backgroundColor = modal.style.backgroundColor ? modal.style.backgroundColor : '#cecece';
    colStyle.backgroundImage = modal.style.backgroundImage ? 'url(' + modal.style.backgroundImage + ')' : '';
    colStyle.backgroundSize = modal.style.backgroundSize ? modal.style.backgroundSize : '';
    colStyle.backgroundPosition = modal.style.backgroundPosition ? modal.style.backgroundPosition : '';
    colStyle.backgroundRepeat = 'no-repeat';
    colStyle.overflow = 'hidden';

    const selectedMedia = useAppSelector<LandingMedia>(getMedia);
    useEffect(() => {
        if (selectedMedia.id)
            dispatch(setModalStyle({ backgroundImage: ApiUrls.front.media(selectedMedia.id.toString(), selectedMedia.extension), flexDirection: 'row' }))
    }, [selectedMedia])

    return <>
        <div className={'modal-dialog ' + modalSize}>
            <div className='modal-content'>
                <div className={'modal-body p-0'}>
                    {(!modalModel || modalModel === 'classic') && <>
                        {modalContent.map((b, i) => <RowContainer key={`modal-container-${i}`} index={i} size={modalContent.length} />)}

                        <HideInPreview className="ps-3 pe-3">
                            <ContainersMenu step='modal' />
                        </HideInPreview>
                    </>}
                    {modalModel === 'extended' && <>
                        <Row style={{ minHeight: '500px' }} >
                            <Col style={colStyle}
                                className='m-0 d-flex align-items-center justify-content-center'>
                                <HideInPreview>
                                    <div>
                                        <ButtonIcon code='eyedropper' size='lg' variant="primary" onClick={() => {
                                            dispatch(modalMediasShow())
                                        }} />
                                    </div>
                                </HideInPreview>
                            </Col>
                            <Col className="">
                                {modalContent.map((b, i) => <RowContainer key={`modal-container-${i}`} index={i} size={modalContent.length} />)}
                                <HideInPreview className="ps-3 pe-3">
                                    <ContainersMenu step='modal' />
                                </HideInPreview>
                            </Col>
                        </Row>
                    </>}

                </div>
            </div>
        </div>
    </>
}

export const ModalDesign = () => {
    const dispatch = useAppDispatch();
    const modal = useAppSelector(getModal);
    return <>
        <h2 id='tools-title'>Modal</h2>

        <div id='tools-wrapper' className='mt-2'>
            <InputGroup size='sm'>
                <InputGroup.Text>Taille</InputGroup.Text>
                <FormSelect defaultValue={modal.size ? modal.size : ''}
                    onChange={(e) => dispatch(setModalSize(e.target.value as ModalSize))}>
                    <option value='sm'>Petit</option>
                    <option value=''>Normal</option>
                    <option value='lg'>Large</option>
                    <option value='xl'>XL</option>
                </FormSelect>
            </InputGroup>

            <InputGroup size='sm' className='mt-1'>
                <InputGroup.Text>Style</InputGroup.Text>
                <FormSelect defaultValue={modal.style.display ? modal.style.display : 'classic'}
                    onChange={(e) => dispatch(setModalModel(e.target.value as any))}>
                    <option value='classic'>Classique</option>
                    <option value='extended'>Large + image</option>
                </FormSelect>
            </InputGroup>

            {modal.style.display === 'extended' && <div className='mt-3'>
                <h6>Image modal</h6>
                <ColorPicker title='Fond' value={modal.style.backgroundColor}
                    onChange={(color) => dispatch(setModalStyle({ ...modal.style, backgroundColor: color }))} />

                <InputGroup size='sm' className='mt-1'>
                    <InputGroup.Text>Taille</InputGroup.Text>
                    <FormSelect value={modal.style.backgroundSize} onChange={(e) => {
                        dispatch(setModalStyle({ ...modal.style, backgroundSize: e.target.value }))
                    }}>
                        <option value='contain'>Contenir</option>
                        <option value='cover'>Couvrir</option>
                    </FormSelect>
                </InputGroup>

                <InputGroup size='sm' className='mt-1'>
                    <InputGroup.Text>Position</InputGroup.Text>
                    <FormSelect value={modal.style.backgroundPosition} onChange={(e) => {
                        dispatch(setModalStyle({ ...modal.style, backgroundPosition: e.target.value }))
                    }}>
                        <option value='center'>Centre</option>
                        <option value='left'>Gauche</option>
                        <option value='top'>Haut</option>
                        <option value='right'>Droite</option>
                        <option value='bottom'>Bas</option>
                    </FormSelect>
                </InputGroup>
            </div>}
        </div>
    </>
}