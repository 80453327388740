
function tableToCSV() {

  var csv_data:any = [];

  var rows = document.getElementsByTagName('tr');
  for (var i = 0; i < rows.length; i++) {

    var cols = rows[i].querySelectorAll('td,th');

    var csvrow = [];
    for (var j = 0; j < cols.length; j++) {
      csvrow.push(decodeHTMLEntities(cols[j].innerHTML.replace(/\&nbsp;/g, '').replace(",", ".")));
      const colSpan = cols[j].attributes[0];
      if (colSpan && colSpan?.nodeName === "colspan") {
        const val = parseInt(colSpan.value) - 1;
        for (var k = 0; k < val; k++) {
          csvrow.push("");
        }
      }
    }

    csv_data.push(csvrow.join(","));
  }
  csv_data = csv_data.join('\n');

  downloadCSVFile(csv_data);
}

function downloadCSVFile(csv_data:any) {

  // Create CSV file object and feed our
  // csv_data into it
  const CSVFile = new Blob([csv_data], { type: "text/csv" });

  // Create to temporary link to initiate
  // download process
  var temp_link = document.createElement('a');

  const date = new Date();

  // Download csv file
  temp_link.download = "reporting-" + date.toLocaleDateString() +".csv";
  var url = window.URL.createObjectURL(CSVFile);
  temp_link.href = url;

  // This link should not be displayed
  temp_link.style.display = "none";
  document.body.appendChild(temp_link);

  // Automatically click the link to trigger download
  temp_link.click();
  document.body.removeChild(temp_link);
}

function decodeHTMLEntities (str:string) {
  if(str && typeof str === 'string') {
    // strip script/html tags
    str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gmi, '');
    str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gmi, '');
  }

  return str;
}

export default tableToCSV;