import ApiUrls from "../../../assets/js/ApiUrls";
import { findQuizz, FrontLanding, LandingAPI_V2, LandingV2 } from "../../../components/LandingsV2/landing-v2";
import { jsonToFormData } from "../../../components/misc/SimpleForm";
import { Category, Field, FormSearchParams, GeneleadForm, Landing, LandingAPI, LandingCustomer, LandingMedia, LandingTemplates, ListData } from "../../../types";
import securedApi from "./securedApi";

const creationApi = securedApi.injectEndpoints({
    endpoints: (build) => ({

        // LANDINGS
        getLandings: build.query<ListData<Landing>, { page: number }>({
            query: (params) => ({
                url: ApiUrls.landing.list,
                params
            }),
            providesTags: ['Landings']
        }),
        createLanding: build.mutation<Landing, Partial<LandingAPI>>({
            query: (data) => ({
                url: ApiUrls.landing.list,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Landings']
        }),
        createLandingV2: build.mutation<Landing, Partial<LandingAPI_V2>>({
            query: (data) => ({
                url: ApiUrls.landing.list,
                method: 'POST',
                body: {...data, data: {...data.data, version: 'v2'}},
            }),
            invalidatesTags: ['Landings']
        }),
        getLanding: build.query<Landing, { id: number }>({
            query: (data) => ApiUrls.landing.landing(data.id),
            providesTags: ['Landing']
        }),
        getTemplates: build.query<LandingTemplates, void>({
            query: () => ApiUrls.templates,
            transformResponse: (r: LandingTemplates) => {
                r.pages.sort((a, b) => (a.id as number) - (b.id as number));
                return r;
            },
            providesTags: ['LandingTemplates'],
        }),
        saveLanding: build.mutation<Landing, { id: number, landing: LandingAPI }>({
            query: (data) => ({
                url: ApiUrls.landing.landing(data.id),
                method: 'POST',
                body: data.landing,
            }),
            invalidatesTags: ['Landing']
        }),
        saveLandingV2: build.mutation<Landing, { id: number, landing: FrontLanding }>({
            query: (data) => ({
                url: ApiUrls.landing.landing(data.id),
                method: 'POST',
                body: LPV2_to_API(data.landing),
            }),
            invalidatesTags: ['Landing']
        }),
        getLandingCustomers: build.query<Array<LandingCustomer>, { id: number }>({
            query: (params) => ApiUrls.landing.customers(params.id),
        }),
        editLandingCustomers: build.mutation<Array<LandingCustomer>, { id: number, customers: any }>({
            query: (params) => ({
                url: ApiUrls.landing.customers(params.id),
                method: 'POST',
                body: jsonToFormData(params.customers),
            }),
        }),

        // CATEGORIES 
        getCategories: build.query<ListData<Category>, void>({
            query: () => ApiUrls.categories.list,
            providesTags: ['Categories'],
        }),
        getCategory: build.query<Category, Pick<Category, 'id'>>({
            query: (args) => ApiUrls.categories.edit(args.id),
        }),
        createCategory: build.mutation<Category, Category>({
            query: (category) => ({
                url: ApiUrls.categories.list,
                method: 'POST',
                body: jsonToFormData(category)
            }),
            invalidatesTags: ['Categories']
        }),
        updateCategory: build.mutation<Category, Pick<Category, 'id'> & { category: Category }>({
            query: (args) => ({
                url: ApiUrls.categories.edit(args.id),
                method: 'POST',
                body: jsonToFormData(args.category)
            })
        }),

        // MEDIAS
        getMedias: build.query<ListData<LandingMedia>, void>({
            query: () => ApiUrls.medias.list,
            providesTags: ['Medias']
        }),
        /** @todo - change FormData */
        uploadMedia: build.mutation<any, FormData>({
            query: (form) => ({
                url: ApiUrls.medias.list,
                method: 'POST',
                body: form,
            }),
            invalidatesTags: ['Medias']
        }),
        replaceMedia: build.mutation<void, { id: number | string, media: any }>({
            query: (params) => ({
                url: ApiUrls.medias.update(params.id),
                method: 'POST',
                body: params.media
            }),
            invalidatesTags: ['Medias']
        }),

        // FORMS
        getFormList: build.query<ListData<GeneleadForm>, FormSearchParams>({
            query: (params) => ({
                url: ApiUrls.forms.list,
                params: params,
            }),
            providesTags: ['Forms']
        }),
        getForm: build.query<GeneleadForm, Pick<GeneleadForm, 'id'>>({
            query: (args) => ApiUrls.forms.edit(args.id.toString()),
        }),
        updateForm: build.mutation<GeneleadForm, Pick<GeneleadForm, 'id'> & { form: GeneleadForm }>({
            query: (args) => ({
                url: ApiUrls.forms.edit(args.id.toString()),
                method: 'POST',
                body: jsonToFormData(args.form),
            })
        }),
        createForm: build.mutation<GeneleadForm, GeneleadForm>({
            query: (new_form) => ({
                url: ApiUrls.forms.list,
                method: 'POST',
                body: jsonToFormData(new_form)
            })
        }),

        // FIELDS
        getFields: build.query<Array<Field>, object>({
            query: (filter) => ({
                url: ApiUrls.fields.list,
                params: filter
            }),
            providesTags: ['Fields'],
        }),
        createField: build.mutation<Field, Field>({
            query: (args) => ({
                url: ApiUrls.fields.list,
                method: 'POST',
                body: jsonToFormData(args)
            }),
            invalidatesTags: ['Fields']
        }),
        updateField: build.mutation<Field, Pick<Field, 'id'> & { field: Field }>({
            query: (args) => ({
                url: ApiUrls.fields.edit(args.id),
                method: 'POST',
                body: jsonToFormData(args.field)
            }),
            invalidatesTags: ['Fields']
        }),
    })
})

function makeFields(data: LandingV2):Array<any> {
    if (data.type === 'form' && data.fields) {
        return data.fields.filter(f => f.type !== 'post-optin' && f.type !== 'optin');
    }
    for (var c of data.childs) {
        const found = makeFields(c);
        if (found.length > 0) {
            return found;
        }
    }
    return [];
}

function makeQuizz(data: LandingV2):Array<any> {
    if (data.type === 'quizz' && data.questions) {
        return data.questions;
    }
    for (var c of data.childs) {
        const found = makeQuizz(c);
        if (found.length > 0) {
            return found;
        }
    }
    return [];
}

export function LPV2_to_API(lp: FrontLanding): LandingAPI_V2 {
    const fields = makeFields(lp.lp);
    const quizz = makeQuizz(lp.modal);
    const lp2: LandingAPI_V2 = {
        id: lp.infos.id,
        category: lp.infos.category,
        code: lp.infos.code,
        company: lp.infos.company,
        created: lp.infos.created,
        description: lp.infos.description,
        dispatchDelay: lp.infos.dispatchDelay,
        minObjective: lp.infos.minObjective,
        name: lp.infos.name,
        objective: lp.infos.objective,
        status: lp.infos.status,
        theme: lp.infos.theme,
        visible: lp.infos.visible,
        options: {
            check_global_duplicate: null,
            checkGlobalDuplicate: lp.infos.options.check_global_duplicate,
            database: lp.infos.options.database,
            emailDatabase: lp.infos.options.emailDatabase,
            emailValidation: lp.infos.options.emailValidation,
            phoneValidation: lp.infos.options.phoneValidation,
            checkOptions: lp.infos.options.checkOptions,
        },
        register: {
            isSensitiveData: lp.infos.register.isSensitiveData,
            objectives: lp.infos.register.objectives,
            recipientId: lp.infos.register.recipientId,
            retentionMonth: lp.infos.register.retentionMonth,
            sensitiveDataDetail: lp.infos.register.sensitiveDataDetail,
            subcontractors: lp.infos.register.subcontractors,
            target: lp.infos.register.target,
            transfertCountries: lp.infos.register.transfertCountries,
            timestamps: {
                created: lp.infos.register.timestamps.created,
                updated: lp.infos.register.timestamps.updated
            }
        },
        site: { domain: lp.infos.site.domain },
        data: {
            lp: lp.lp,
            modal: lp.modal,
            version: 'v2',
            rs_connect: lp.infos.rs_connect,
            rs_share: lp.infos.rs_share,
            share_title: lp.infos.share_title,
            share_desc: lp.infos.share_desc,
            recaptcha: lp.infos.options.recaptcha,
            seo_title: lp.infos.seo_title,
            seo_desc: lp.infos.seo_desc,
            seo_img: lp.infos.seo_img,
        },
        fields: fields,
        quizz: quizz,
    };

    return lp2;
}

export default creationApi;