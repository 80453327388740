import React from 'react';
import { Form } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import authApi from '../../app/services/public/auth';
import AppPublic from '../../AppPublic';
import Urls from '../../assets/js/Urls';
import { RefreshArgs } from '../../types';
import { BlockError, BlockSuccess } from '../misc/Blocks';
import { BtnIconLoad } from '../misc/Buttons';
import SimpleForm from '../misc/SimpleForm';
import AuthLP from './AuthLP';

const Refresh: React.FC = () => {
    const { token } = useParams<{ token: string }>();
    const [refresh, refreshRes] = authApi.useRefreshMutation();
    return <>
        <AppPublic>
            <AuthLP>
                <h2>Nouveau mot de passe</h2>
                <SimpleForm onSubmit={(form) => refresh({ token: token as string, passwords: form as Pick<RefreshArgs, 'passwords'>})}>
                    {/*<FormInputGroup label='Mot de passe'>*/}
                    <label htmlFor="pswd">Mot de passe</label>
                    <Form.Control name='password' type='password' placeholder='Nouveau mot de passe' id="pswd" required />
                    {/*</FormInputGroup>*/}

                    {/*<FormInputGroup label='Confirmez votre mot de passe'>*/}
                    <label htmlFor="confirmpswd">Mot de passe</label>
                    <Form.Control name='password_confirm' type='password' id={"confirmpswd"} placeholder='Confirmez votre mot de passe' required />
                    {/*</FormInputGroup>*/}
                    <BtnIconLoad data={refreshRes} variant='primary' type='submit'>Réinitialiser</BtnIconLoad>
                    <BlockSuccess data={refreshRes}>
                        Votre mot de passe a bien été réinitialisé. Vous allez être redirigé...
                    </BlockSuccess>
                    <BlockError data={refreshRes} />
                    <div className='mt-3 text-center'>
                        <a href={Urls.login}>Connexion</a>
                    </div>
                </SimpleForm>
            </AuthLP>
        </AppPublic>
    </>
}

export default Refresh;