import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LandingMedia } from '../../types';
import { RootState } from '../store';

const init:LandingMedia = {
    id: 0,
    size: 0,
    extension: ''
}

const media = createSlice({
    name: "app",
    initialState: init,
    reducers: {
        selectMedia: (state, action:PayloadAction<LandingMedia>) => {
            state.id = action.payload.id;
            state.size = action.payload.size;
            state.extension = action.payload.extension;
        },
        resetMedia: (state) => {
            state.id = 0; state.size = 0; state.extension = '';
        }
    },
})

export const { selectMedia, resetMedia } = media.actions;
export const getMedia = (state:RootState) => state.media;
export default media.reducer;