import { useEffect, useState } from "react";
import { Badge, FormCheck, FormControl, Modal } from "react-bootstrap";
import { formSuccessRedirect } from "../../app/actions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import manageApi from "../../app/services/secured/manage";
import { getModalData, isModalOpened, ModalTypes } from "../../app/slice/modal";
import { ReportingTemplate, ReportingTemplateProps } from "../../types";
import { FormInputGroup, IfTrue } from "../misc/Blocks";
import { BtnIconLoad } from "../misc/Buttons";
import SimpleForm from "../misc/SimpleForm";
import ModalCloseButton from "../Modals/ModalCloseButton";
import { getReportingParams } from "./reporting";

function getDateDiffFromToday(d1: string) {
    const date1 = new Date();
    const date2 = new Date(d1);
    const diffTime = date2.getTime() - date1.getTime();
    let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    if (diffDays > -1) diffDays = Math.abs(diffDays);
    return diffDays;
}

const ModalReportingTemplate = () => {
    const isOpened = useAppSelector(isModalOpened(ModalTypes.ReportingTemplate))
    const options = useAppSelector<ReportingTemplateProps>(getModalData);
    const params = useAppSelector(getReportingParams);
    
    const [template, setTemplate] = useState<ReportingTemplate>({
        filters: [],
        aggregations: [],
        from: "",
        from_days: 0,
        relative_period: 1,
        reporting: true,
        to: "",
        to_days: 0,
        title: "",
        company_id: []
    });

    const [isNew, setIsNew] = useState(true);
    useEffect(() => {
        if (isOpened && options) {

            if (options.template && options.template.id) {
                setIsNew(false);
                template.title = options.template.name;
            }

            Object.entries(params.search).filter(p => p[1].active).forEach(p => template.aggregations.push(p[0]));
            Object.entries(params.data).filter(p => p[1].active).forEach(p => template.filters.push(p[0]));
            Object.entries(params.stats).filter(p => p[1].active).forEach(p => template.filters.push(p[0]));

            template.company_id = options.company_id as Array<string>;            
            template.from = options.from;
            template.to = options.to;
            template.from_days = getDateDiffFromToday(options.from);
            template.to_days = getDateDiffFromToday(options.to);
        }
    }, [isOpened])

    const [handleTemplate, handleTemplateRes] = manageApi.useHandleTemplateMutation();
    const [deleteTemplate, deleteTemplateRes] = manageApi.useDeleteTemplateMutation();

    const dispatch = useAppDispatch();
    dispatch(formSuccessRedirect({isModal: true, data: handleTemplateRes}));
    dispatch(formSuccessRedirect({isModal: true, data: deleteTemplateRes}));

    return <>
        <IfTrue condition={isOpened && options}>
            <SimpleForm onSubmit={() => {
                if (isNew) {
                    handleTemplate({homeDataTable: [{title: template.title, search: template}]});
                } else {
                    handleTemplate({homeDataTable: [{id: options.template?.id, title: template.title, search: template}]});
                }
            }}>
                <Modal.Header>
                    <h3 className='m-0'>Enregistrement recherche</h3>
                </Modal.Header>
                <Modal.Body>
                    <h6>
                        <span className='me-1'>Période sélectionnée </span>
                        <Badge>{new Date(options?.from).toLocaleDateString('fr-FR')}</Badge>
                        -
                        <Badge>{new Date(options?.to).toLocaleDateString('fr-FR')}</Badge>
                    </h6>
                    <div className='d-flex'>
                        <FormCheck type='radio' label='Période fixe' name='period' onChange={(e) => setTemplate({ ...template, relative_period: 0 })} />
                        <FormCheck type='radio' label='Période glissante' name='period' className='ms-2' defaultChecked={true}
                            onChange={(e) => setTemplate({ ...template, relative_period: 1 })} />
                    </div>
                    <FormInputGroup label="Nom template" className="mt-2">
                        <FormControl placeholder='Template' defaultValue={options?.template?.name}
                            onChange={(e) => setTemplate({ ...template, title: e.target.value })} required />
                    </FormInputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <ModalCloseButton />
                    {
                        !isNew && <>
                            <BtnIconLoad variant="danger" data={deleteTemplateRes} type='button'
                             onClick={() => deleteTemplate({homeDataTable: [{id: options.template?.id, title: template.title, search: template}]})}>
                                Supprimer</BtnIconLoad>
                        </>
                    }
                    <BtnIconLoad data={handleTemplateRes}>{isNew ? 'Créer' : 'Modifier'}</BtnIconLoad>
                </Modal.Footer>
            </SimpleForm>
        </IfTrue>
    </>
}

export default ModalReportingTemplate;