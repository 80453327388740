import { FormControl } from "react-bootstrap";
import { useAppDispatch } from "../../../../app/hooks";
import { setVideo } from "../../landing-v2";

export const VideoContent: React.FC<{ embed: string, block: number, isEdit: boolean }> = ({ embed, block, isEdit }) => {
   const dispatch = useAppDispatch();
   return <>
      {isEdit
         ? <FormControl as="textarea" placeholder="Code d'intégration embed" defaultValue={embed}
            onBlur={(e) => dispatch(setVideo({ block: block, embed: e.target.value }))} />
         : <div className='video-container' dangerouslySetInnerHTML={{ __html: embed }} />
      }
   </>
}