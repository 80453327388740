import { useEffect } from "react";
import { Card } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { formSuccessRedirect } from "../../../app/actions";
import { useAppDispatch } from "../../../app/hooks";
import adminApi from "../../../app/services/secured/admin";
import AppSecured from "../../../AppSecured";
import Urls from "../../../assets/js/Urls";
import { User } from "../../../types";
import { FormButtons } from "../../misc/Blocks";
import SimpleForm from "../../misc/SimpleForm";
import { UserForm } from "./UserForm";

const UserProfile: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [getUser, user] = adminApi.useLazyGetUserQuery();
    const isNew = id && id !== 'new' ? false : true

    useEffect(() => {
        if (id && !isNew) getUser({ id: parseInt(id) });
    }, [id])

    return <>
        <AppSecured>
            <Card className='mt-4'>
                <Card.Header as="h3">
                    {isNew ? 'Création utilisateur' : 'Modification ' + user.data?.first_name + ' ' + user.data?.last_name}
                </Card.Header>
                <Card.Body>
                    {isNew ? <UserNew /> : <UserEdit {...user.data} />}
                </Card.Body>
            </Card>
        </AppSecured>
    </>
}

const UserNew: React.FC = () => {
    const dispatch = useAppDispatch();
    const [create, createRes] = adminApi.useCreateUserMutation();
    dispatch(formSuccessRedirect({data: createRes, redirectUrl: Urls.users.list, message:'Succès création utilisateur'}));
    
    return <>
        <SimpleForm onSubmit={(form) => create(form as User)}>
            <UserForm />
            <FormButtons cancelUrl={Urls.users.list} data={createRes} btnTxt='Créer' />
        </SimpleForm>
    </>
}

const UserEdit: React.FC<Partial<User>> = user => {
    const dispatch = useAppDispatch();
    const [update, updateRes] = adminApi.useUpdateUserMutation();
    dispatch(formSuccessRedirect({data: updateRes, redirectUrl: Urls.users.list, message:'Succès modification utilisateur'}));
    
    return <>
        <SimpleForm onSubmit={(form) => update({ id: user.id as number, form: form as User })}>
            <UserForm {...user} />
            <FormButtons cancelUrl={Urls.users.list} data={updateRes} btnTxt='Modifier' />
        </SimpleForm>
    </>
}

export default UserProfile;