import { useState } from "react";
import { Alert, Badge, Button, Card, CloseButton, Col, Form, InputGroup, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import dataApi from "../../app/services/secured/data";
import AppSecured from "../../AppSecured";
import { CaptainVerifyReturn, PhoneNumber } from "../../types";
import { IfTrue, LoadingBlock } from "../misc/Blocks";
import { BtnIconLoad, Icon } from "../misc/Buttons";
import SimpleForm from "../misc/SimpleForm";
import TableGenelead from "../misc/Table";

const CaptainVerify: React.FC = () => {

    const [phone, setPhone] = useState("");
    const [phoneArray, setPhoneArray] = useState<Array<string>>([]);
    const [verif, verifRes] = dataApi.useCaptainVerifyMutation();

    return <>
        <AppSecured>
            <h1><Icon code='phone-vibrate' /> <FormattedMessage id='verification' /></h1>
            <Card>
                <Card.Body>
                    <Row className='d-flex align-items-center'>
                        <Col lg={5}>
                            <form onSubmit={(e) => e.preventDefault()}>
                                <InputGroup>
                                    <Form.Control name='phone_number[]' placeholder="Numéro" value={phone}
                                        onChange={(e) => setPhone(e.target.value)} />
                                    <Button variant='outline-secondary' type='submit' onClick={() => {
                                        phoneArray.push(phone);
                                        setPhone('');
                                    }}>
                                        + Ajouter
                                    </Button>
                                </InputGroup>
                            </form>
                        </Col>
                        <Col>
                            <Alert variant="primary" className='m-0'>
                                Entrez un ou plusieurs numéros pour vérifier leur validité.
                            </Alert>
                        </Col>

                        <SimpleForm onSubmit={(form) => { verif(form as Array<PhoneNumber>); }}>
                            <div className='mt-2 d-flex'>
                                {
                                    phoneArray.map((p, i) => {
                                        if (!p) return;
                                        return <span key={Math.random()} className='captain-verify-phone-span'>
                                            {p}
                                            <CloseButton onClick={() => {
                                                var array = [...phoneArray];
                                                array.splice(i, 1);
                                                setPhoneArray(array);
                                            }} />
                                            <input type='hidden' name='phone_number[]' value={p} />
                                        </span>
                                    })
                                }
                                <BtnIconLoad data={verifRes} icon='search' disabled={phoneArray.length > 0 ? false : true}>
                                    Vérifier
                                </BtnIconLoad>
                            </div>
                        </SimpleForm>
                    </Row>
                </Card.Body>
            </Card>

            <LoadingBlock data={verifRes}>
                <IfTrue condition={verifRes.isSuccess}>
                    <hr />
                    <TableGenelead  header={[
                        { icon: 'telephone-outbound', name: 'Entrée' },
                        { icon: 'patch-check', name: 'Status' },
                    ]}>
                        {verifRes.data?.map(p => <CaptainVerifyResult key={Math.random()} {...p} />)}
                    </TableGenelead>
                    <div className='d-flex justify-content-end'>
                        Crédits restants : { verifRes.data && verifRes.data[verifRes.data?.length -1]?.captainVerify?.credits }
                    </div>
                </IfTrue>
            </LoadingBlock>
        </AppSecured>
    </>
}

const CaptainVerifyResult: React.FC<CaptainVerifyReturn> = v => {
    return <tr>
        <td>{v.entry}</td>
        <td>{
            v.captainVerify?.result === 'valid'
                ? <Badge bg='success'>Actif</Badge>
                : <><Badge bg='danger'>Erreur</Badge> {v.captainVerify?.message}</>
        }
        </td>
    </tr>
}


export default CaptainVerify;