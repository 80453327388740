import React, {startTransition, useEffect, useState} from "react";
import {
    Badge,
    Button,
    Dropdown,
    DropdownButton,
    FormControl,
    InputGroup,
    Nav,
    OverlayTrigger,
    Tooltip
} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import creationApi from "../../app/services/secured/creation";
import {
    modalExportLandingFilter,
    modalLinkLandingShow,
    modalNewLandingShow,
    modalNewLandingV2Show
} from "../../app/slice/modal";
import AppSecured from "../../AppSecured";
import Urls from "../../assets/js/Urls";
import {Landing} from "../../types";
import {PageHeader} from "../App/PageHeader";
import {LoadingRow} from "../misc/Blocks";
import {ButtonIcon, Icon} from "../misc/Buttons";
import {PaginationGenelead} from "../misc/Pagination";
import SimpleForm from "../misc/SimpleForm";
import TableGenelead, {ListTableHeader} from "../misc/Table";
import {
    getLandingListSearch,
    initLandingListSearch,
    LandingListSearchType,
    setLandingListSearch
} from "../../app/slice/app";

const LandingList = () => {
    const dispatch = useAppDispatch();

    const search = useAppSelector<LandingListSearchType>(getLandingListSearch);
    const landings = creationApi.useGetLandingsQuery(search);

    const setSearch = (s: LandingListSearchType) => {
        dispatch(setLandingListSearch(s));
    }
    const doSearch = (query: string) => {
        dispatch(setLandingListSearch({...search, query}));
    }

    const [header, setHeader] = useState<Array<ListTableHeader>>([
            {name: 'Landing page', icon: 'grid-1x2'},
            {name: 'Client', icon: 'building'},
            {name: 'Base doublon', icon: 'server'},
            {name: 'Options', icon: 'ui-radios', align: 'center'},
            {name: 'Templates', icon: 'display', align: ('center')},
        ]
    );

    useEffect(() => {
        const newH = [...header];
        startTransition(() => {
            if (search.query.length > 0) {
                if (header.findIndex(h => h.name === 'Statut') == -1) {
                    newH.unshift({name: 'Statut', icon: 'broadcast'});
                    setHeader(newH);
                }
                const s = {...search, query: search.query};
                delete s.statuses;
                setSearch(s);
            } else {
                setHeader(newH.filter(h => h.name !== 'Statut'));
                // const s = { ...search, query: '', statuses: 8 };
                // setSearch(s);
            }
        });
    }, [search])

    return <>
        <AppSecured>
            <PageHeader action={<>
                <DropdownButton title={<Icon code={'file-earmark-plus'} />} size='lg'>
                    <Dropdown.Item onClick={() => dispatch(modalNewLandingShow())}>V1</Dropdown.Item>
                    <Dropdown.Item onClick={() => dispatch(modalNewLandingV2Show())}>V2</Dropdown.Item>
                </DropdownButton>
            </>
            }>
                <SimpleForm onSubmit={(query) => doSearch(query.keyword as string)}>
                    <InputGroup size='lg'>
                        <InputGroup.Text><Icon code={'search'}/></InputGroup.Text>
                        <FormControl placeholder='Mot clé, client, ID...' name='keyword' key={'search-' + search.query.length}
                                     defaultValue={search.query}/>
                        {search.query.length > 0 && <>
                            <Button variant={'warning'} onClick={() => dispatch(initLandingListSearch())}>
                                <Icon code={'x-circle'}/></Button>
                        </>}
                        <Button type='submit'><Icon code='check'/></Button>
                    </InputGroup>
                </SimpleForm>
            </PageHeader>
            <div className='d-flex align-items-end justify-content-between fs-16 mt-3'>
                {
                    !search.query && <>
                        <Nav className='nav-tabs' defaultActiveKey={search.statuses}>
                            <Nav.Item><Nav.Link eventKey={'8'} onClick={() => setSearch({
                                ...search,
                                statuses: 8,
                                page: 1
                            })}><Icon code={'globe'}/> Actif</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey={'4'} onClick={() => setSearch({
                                ...search,
                                statuses: 4,
                                page: 1
                            })}><Icon code={'files'}/> Brouillon</Nav.Link></Nav.Item>
                            <Nav.Item><Nav.Link eventKey={'2'} onClick={() => setSearch({
                                ...search,
                                statuses: 2,
                                page: 1
                            })}><Icon code={'archive'}/> Supprimé</Nav.Link></Nav.Item>
                        </Nav>
                    </>
                }
                {landings.isSuccess && <>
                    <PaginationGenelead size='sm'
                                        pages={{current: landings.data.page, total: landings.data.total_pages}}
                                        search={(page: number) => {
                                            setSearch({...search, page})
                                        }}/>
                </>}
            </div>
            <TableGenelead hasActions={true} header={header}>
                <LoadingRow data={landings} colspan={6}>
                    {landings.data && landings.data.list.map(l => <LandingRow key={`lp-${l.id}`} {...l}
                                                                              search={search.query}/>)}

                    {landings.data?.list.length === 0 && <>
                        <tr className={'alert-warning'}>
                            <td colSpan={7} className={'text-center fs-24'}><Icon code={'cloud-slash'} /></td>
                        </tr>
                    </>}
                </LoadingRow>

            </TableGenelead>
            {landings.isSuccess && <>
                <PaginationGenelead pages={{current: landings.data.page, total: landings.data.total_pages}}
                                    search={(page: number) => {
                                        setSearch({...search, page})
                                    }}
                />
            </>}
        </AppSecured>
    </>
}

const LandingRow: React.FC<Landing & { search: string }> = landing => {
    const dispatch = useAppDispatch();
    return <tr>
        {landing.search && <td>
            <div className='d-flex justify-content-center'>
                {landing.status === 8 && <Badge bg='success'>En ligne</Badge>}
                {landing.status === 4 && <Badge bg='primary'>Brouillon</Badge>}
                {landing.status === 2 && <Badge bg='danger'>Supprimé</Badge>}
            </div>
        </td>}
        <td>
            <div className='d-flex justify-content-between'>
                <div>{landing.data.version === 'v2' && <Badge bg='success'>V2</Badge>} {landing.name}</div>
                <div>
                    {landing.form?.id ? <Badge>{landing.form.id}</Badge> :
                        <Badge bg='warning'>Pas de formulaire relié</Badge>}
                </div>
            </div>
        </td>
        <td>
            {landing.company && <a style={{textDecoration:'none'}} href={Urls.companies.view(landing.company.id)}>
                {landing.company.name}
            </a>}
        </td>
        <td><small>{landing.options.database?.name}</small></td>
        <td>
            <div className='d-flex justify-content-center'>
                <OverlayTrigger placement='top' overlay={
                    <Tooltip>Dédoublonage {landing.options.check_global_duplicate ? 'actif' : 'inactif'}</Tooltip>}>
                    <Badge
                        className={landing.options.check_global_duplicate ? 'alt-success fs-16 me-1' : 'alt-danger fs-16 me-1'}><Icon
                        code='people'/></Badge>
                </OverlayTrigger>

                <OverlayTrigger placement='top' overlay={<Tooltip>Validation
                    téléphone {landing.options.phoneValidation ? 'active' : 'inactive'}</Tooltip>}>
                    <Badge
                        className={landing.options.phoneValidation ? 'alt-success fs-16 me-1' : 'alt-danger fs-16 me-1'}><Icon
                        code='phone'/></Badge>
                </OverlayTrigger>

                <OverlayTrigger placement='top' overlay={<Tooltip>Validation
                    email {landing.options.emailValidation ? 'active' : 'inactive'}</Tooltip>}>
                    <Badge className={landing.options.emailValidation ? 'alt-success fs-16' : 'alt-danger fs-16'}><Icon
                        code='envelope'/></Badge>
                </OverlayTrigger>
            </div>
        </td>
        <td>
            {!landing.data.version
                ? <Dropdown>
                    <Dropdown.Toggle size="sm"
                                     className='d-block w-100 alt-primary'>{landing.enabledTemplates.length} templates</Dropdown.Toggle>
                    <Dropdown.Menu>
                        {landing.enabledTemplates.concat(['default']).map(e =>
                            // <Dropdown.Item bg='secondary' className='me-1' key={Math.random()}
                            <Dropdown.Item className='me-1' key={Math.random()}
                                           href={Urls.landing.landingTemplate(landing.site.domain, landing.code, e)}
                                           target='_blank'>
                                {e}</Dropdown.Item>)}
                    </Dropdown.Menu>
                </Dropdown>
                : <Button className='alt-primary d)-block w-100' size='sm'
                          href={'//' + landing.site.domain + '/lp/' + landing.code + '.html'} target='_blank'>
                    Landing Page
                </Button>}
        </td>
        <td>
            <div className='d-flex justify-content-end'>
                {
                    landing.form?.id && (
                        <ButtonIcon
                            code={"file-earmark-arrow-down"}
                            size={"lg"}
                            variant={"primary"}
                            className='me-1'
                            onClick={() => dispatch(modalExportLandingFilter(landing.form?.id ?? ''))}
                        />
                    )
                }

                <ButtonIcon code={"person-workspace"} size={"lg"} variant={"primary"} className='me-1'
                            onClick={() => dispatch(modalLinkLandingShow(landing.id))}/>

                <ButtonIcon code={"files"} size={"lg"} variant={"secondary"} className='me-1'
                            href={landing.data.version === 'v2' ? Urls.landing.duplicatev2(landing.id) : Urls.landing.duplicate(landing.id)}/>

                <ButtonIcon code={"pen"} size={"lg"} variant={"primary"} className='alt-primary'
                            href={landing.data.version === 'v2' ? Urls.landing.editv2(landing.id) : Urls.landing.edit(landing.id)}/>
            </div>
        </td>
    </tr>
}

export default LandingList;