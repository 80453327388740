import {useState} from "react";
import {Alert, Card, Col, Form, ListGroup, Row} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import {useParams} from "react-router-dom";
import {formSuccessRedirect} from "../../app/actions";
import {useAppDispatch, useAppSelector} from "../../app/hooks";
import diffusionApi from "../../app/services/secured/diffusion";
import {addLanding, getLiveLandings, hideLanding, moveLanding, updateDesc} from "../../app/slice/landing-site";
import AppSecured from "../../AppSecured";
import Urls from "../../assets/js/Urls";
import {LiveLanding} from "../../types";
import {FormButtons, FormInputGroup, LoadingBlock} from "../misc/Blocks";
import {ButtonIcon, CancelButton} from "../misc/Buttons";
import SimpleForm from "../misc/SimpleForm";

const SiteLanding = () => {
    const {id} = useParams<{ id: string }>();
    const dispatch = useAppDispatch();

    const liveList = diffusionApi.useGetLivelistQuery(id as string);
    const liveListData = useAppSelector(getLiveLandings);

    const [toBack, resBack] = diffusionApi.useUpdateLivelistMutation();
    dispatch(formSuccessRedirect({
        redirectUrl: Urls.sites.list,
        data: resBack,
        message: 'Succès édition Landing Pages du site ' + liveListData.domain
    }));

    return <>
        <AppSecured>
            <LoadingBlock data={liveList}>
                {
                    id && <>
                        <Card className='genelead-card mt-4'>
                            <Card.Header>
                                Modification du site/domaine : <b>{liveListData.domain}</b>
                            </Card.Header>
                            <SimpleForm onSubmit={() => {
                                toBack({id: parseInt(id), landings: liveListData.visible});
                            }}>
                                <Card.Body>
                                    {/* Add new landing */}
                                    {liveListData.hidden.length > 0 && <>
                                        <LandingAdd/>
                                    </>}

                                    {/* Landing list */}
                                    {liveListData.visible.length > 0 &&
                                        <ListGroup as="ol" numbered>
                                            {liveListData.visible.map((l, i) => <LandingRow key={i} {...l} />)}
                                        </ListGroup>
                                    }

                                    {/* Alerts no data */}
                                    {liveListData.hidden.length === 0 && <>
                                        <Alert variant="warning" className={"mt-2"}>
                                            <Alert.Heading>Aucune LP liée au domaine <b>{liveListData.domain}</b></Alert.Heading>
                                            Veuillez lier une ou plusieurs LPs au domaine pour avoir la possiblité de
                                            les afficher sur la page d'accueil.
                                        </Alert>
                                    </>}

                                    {liveListData.visible.length === 0 && <>
                                        <Alert variant="warning">
                                            Aucune landing page visible sur le site/domaine <b>{liveListData.domain}</b>
                                        </Alert>
                                    </>}
                                    <FormButtons cancelUrl={Urls.sites.list} data={resBack} btnTxt='Modifier'/>
                                </Card.Body>
                            </SimpleForm>
                        </Card>
                    </>
                }
            </LoadingBlock>
        </AppSecured>
    </>
}

const LandingAdd = () => {
    const liveListData = useAppSelector(getLiveLandings);
    const dispatch = useAppDispatch();
    const [resSelect, setResSelect] = useState([]);
    return <div className='mt-2 mb-3'>
        <Typeahead
            id='add-landing'
            placeholder="Ajouter une landing"
            options={liveListData.hidden.map(h => {
                return {label: h.name, code: h.code}
            })}
            selected={resSelect}
            onChange={(s) => {
                if (s[0]) {
                    const val = s[0] as { code: string };
                    dispatch(addLanding({code: val.code}));
                    setResSelect([]);
                }
            }}
        />
    </div>
}

const LandingRow: React.FC<LiveLanding> = (props) => {
    const dispatch = useAppDispatch();
    const liveListData = useAppSelector(getLiveLandings);

    const moveLandingPage = (event: any, landing: LiveLanding, direction: number) => {
        dispatch(moveLanding({landing: landing, direction: direction}));
    }

    return <ListGroup.Item key={props.code}>
        <Row className='d-flex align-items-center'>
            <Col lg={2}>
                <div className='d-flex align-items-center'>
                    <div className='me-2 fs-20'><b>[{props.position}]</b></div>
                    <div>{props.name}</div>
                </div>
            </Col>
            <Col>
                <FormInputGroup label='Description'>
                    <Form.Control as="textarea" placeholder='Description' defaultValue={props.description}
                                  onChange={(e) =>
                                      dispatch(updateDesc({code: props.code, description: e.currentTarget.value}))}/>
                </FormInputGroup>
            </Col>
            <Col lg={1}>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='d-flex flex-column'>
                        {
                            props.position !== null &&
                            props.position > 1 &&
                            <ButtonIcon code={"arrow-up-square"} size={"lg"} variant={""}
                                        onClick={(e) => moveLandingPage(e, props, -1)}/>
                        }
                        {
                            props.position !== null &&
                            props.position < liveListData.visible.length &&
                            <ButtonIcon code={"arrow-down-square"} size={"lg"} variant={""}
                                        onClick={(e) => moveLandingPage(e, props, 1)}/>
                        }
                    </div>
                    <ButtonIcon className='alt-danger' code={"eye-slash-fill"} size={"lg"} variant={"danger"}
                                onClick={() => dispatch(hideLanding({l: props}))}/>
                </div>
            </Col>
        </Row>
    </ListGroup.Item>
}

export default SiteLanding;