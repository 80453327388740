import { Alert, Col, FormCheck, FormControl, FormLabel, FormSelect, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import globalApi from "../../../app/services/secured/global";
import manageApi from "../../../app/services/secured/manage";
import { getLanding, setRegister } from "../../../app/slice/landing";
import { LandingRegister } from "../../../types";

const LandingRGPD = () => {
    const dispatch = useAppDispatch();
    const landing = useAppSelector(getLanding);
    const company = manageApi.useGetCompanyQuery({ id: landing.company });
    const countries = globalApi.useGetCountriesQuery();
    const customers = globalApi.useGetCustomersQuery();
    const isLive = landing.status === 8 ? true : false;
    return <>
        {!isLive && <Alert variant='danger'>
            <Alert.Heading>
                Landing page non-active
            </Alert.Heading>
            Vous pouvez uniquement éditer la partie RGPD lorsque la landing page est active.
            Passez le statut en mode 'Live' pour pouvoir éditer les RGPD.
        </Alert>}
        <div 
            style={!isLive ? { filter: 'blur(8px)', pointerEvents: 'none' } : {}}
        >
            <Alert variant="info">Hébergeur web : Amazon Web services</Alert>
            <Row>
                <Col>
                    <FormLabel className='fs-16'>Objectifs de la collecte de données*</FormLabel>
                    <FormControl type='text' defaultValue={landing.register?.objectives}
                        onBlur={(e) => dispatch(setRegister({ ...landing.register, objectives: e.target.value } as LandingRegister))} />
                </Col>
                <Col>
                    <FormLabel className='fs-16'> Catégories de personnes concernées par la collecte*</FormLabel>
                    <FormControl type='text' defaultValue={landing.register?.target}
                        onBlur={(e) => dispatch(setRegister({ ...landing.register, target: e.target.value } as LandingRegister))} />
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col>
                <FormLabel className='fs-16'>Destinataire principal*</FormLabel>
                {customers.isSuccess && <>
                    <FormSelect key={landing.register?.recipientId?.toString()} defaultValue={landing.register?.recipientId?.toString()}
                        onChange={(e) => dispatch(setRegister({ ...landing.register, recipientId: parseInt(e.target.value) } as LandingRegister))}
                    >
                        <option>Aucun destinaire</option>
                        {customers.data?.filter(c => c.company === landing.company).map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                    </FormSelect>
                </>}

                </Col>
                <Col>
                    <FormLabel className='fs-16'>Pays en dehors de l'UE où la donnée est transférée (si nécessaire)</FormLabel>
                    {countries.isSuccess && <>
                        <Typeahead
                            multiple
                            id='select-transfert-countries'
                            options={countries.data?.map(c => { return { name: c.name, code: c.code } })}
                            labelKey="name"
                            defaultSelected={landing.register?.transfertCountries?.map(t => {
                                const val = countries.data.find(c => c.code === t);
                                return {name: val?.name, code: val?.code}
                            })}
                            onChange={(s) => {
                                const ctrs = s as Array<{ name: string, code: string }>
                                dispatch(setRegister({ ...landing.register, transfertCountries: s.length < 0 ? [] : ctrs.map(c => c.code) } as LandingRegister));
                            }}
                        />
                    </>}
                </Col>
            </Row>
            <Row className='mt-4'>
                <Col>
                    <FormLabel className='fs-16'>Liste des sous-traitants (hors hébergeur)*</FormLabel>
                    <FormControl as="textarea" defaultValue={landing.register?.subcontractors}
                        onBlur={(e) => dispatch(setRegister({ ...landing.register, subcontractors: e.target.value } as LandingRegister))} />
                </Col>
                <Col>
                    <FormLabel className='fs-16'>Durée de conservation des données (mois)</FormLabel>

                    <FormControl className={!landing.register?.retentionMonth ? 'is-invalid' : ''}
                        type='number' defaultValue={landing.register?.retentionMonth}
                        onChange={(e) => dispatch(setRegister({ ...landing.register, retentionMonth: parseInt(e.target.value) } as LandingRegister))} />

                    <FormCheck type='switch' className='mt-3' label='Données sensibles collectées ?'
                        onChange={(e) => { dispatch(setRegister({ ...landing.register, isSensitiveData: e.target.checked } as LandingRegister)) }}
                        defaultChecked={landing.register?.isSensitiveData} />

                    {
                        landing.register?.isSensitiveData && <>
                            <FormLabel className='fs-16'>Nature des données sensibles collectées</FormLabel>
                            <FormControl as="textarea" defaultValue={landing.register?.sensitiveDataDetail}
                                onBlur={(e) => dispatch(setRegister({ ...landing.register, sensitiveDataDetail: e.target.value } as LandingRegister))} />
                        </>
                    }
                </Col>
            </Row>
        </div>
    </>
}

export default LandingRGPD;