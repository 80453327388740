import React from 'react';
import { Form } from 'react-bootstrap';
import authApi from '../../app/services/public/auth';
import AppPublic from '../../AppPublic';
import Urls from '../../assets/js/Urls';
import { LoginArgs } from '../../types';
import { BlockError } from '../misc/Blocks';
import { BtnIconLoad } from '../misc/Buttons';
import SimpleForm from '../misc/SimpleForm';
import AuthLP from './AuthLP';

const Login: React.FC = () => {
    const [login, loginRes] = authApi.useLoginMutation();
    return <>
        <AppPublic>
            <AuthLP>
                <SimpleForm onSubmit={(form) => login(form as LoginArgs)}>
                    <label htmlFor="email">E-mail</label>
                    <Form.Control name='login' type='text' placeholder='Entrez votre adresse e-mail' id="email" required />
                    <label htmlFor="password">Mot de passe</label>
                    <Form.Control name='password' type='password' placeholder='Entrez votre mot de passe' id="password" required />
                    <BtnIconLoad data={loginRes} variant='primary' type='submit'>Se connecter</BtnIconLoad>
                    <BlockError data={loginRes} />
                    <div className="mt-4 text-center">
                        <a href={Urls.forgot}>Mot de passe oublié ?</a>
                    </div>
                </SimpleForm>
            </AuthLP>
        </AppPublic>
    </>
}

export default Login;