import { useEffect, useState } from "react";
import { Button, Col, Container, Form, FormControl, Modal, Row } from "react-bootstrap";
import { formSuccessRedirect } from "../../app/actions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import creationApi from "../../app/services/secured/creation";
import { getModalData, isAnyModalOpened, isModalOpened, modalClose, ModalTypes } from "../../app/slice/modal";
import { addNotification } from "../../app/slice/notifs";
import { Choice, Field, FieldType, ReduxRtkApiResult } from "../../types";
import { FormInputGroup } from "../misc/Blocks";
import { BtnIconLoad, ButtonIcon, LoadingButton } from "../misc/Buttons";
import SimpleForm from "../misc/SimpleForm";
import ModalCloseButton from "../Modals/ModalCloseButton";

const ModalField = () => {
    
    const show = useAppSelector(isAnyModalOpened);
    const isOpened = useAppSelector(isModalOpened(ModalTypes.Field));
    const dispatch = useAppDispatch();
    const fieldData = useAppSelector<Field>(getModalData);

    const [create, createRes] = creationApi.useCreateFieldMutation();
    const [update, updateRes] = creationApi.useUpdateFieldMutation();

    useEffect(() => {
        if (show && isOpened) {
            if (updateRes.isSuccess || createRes.isSuccess) {
                dispatch(addNotification({type: 'success', message:'Succès opération'}));
                dispatch(modalClose());
            }
        }

    }, [updateRes.isSuccess, createRes.isSuccess])
        
    return <>
        {isOpened && <>
            <Modal show={show} size='lg'>
                <SimpleForm onSubmit={(json) => {
                    fieldData && fieldData.id ? update({ id: fieldData.id, field: json as Field }) : create(json as Field)
                }}>
                    <Modal.Header>
                        Champ
                    </Modal.Header>
                    <Modal.Body>
                        <FieldForm {...fieldData} />
                    </Modal.Body>
                    <Modal.Footer>
                        <ModalCloseButton />
                        <Button type='submit' variant='primary'>
                            {fieldData && fieldData.id ? 'Modifier' : 'Créer'}
                        </Button>
                    </Modal.Footer>
                </SimpleForm>
            </Modal>

        </>}
    </>
}

const FieldForm: React.FC<Partial<Field>> = props => {
    const [choices, setChoices] = useState<Array<Partial<Choice> & { isNew: boolean }>>([]);
    const [type, setType] = useState(props.type ? props.type : '');

    useEffect(() => {
        if (props.type === 'C') setChoices(props.choices ? props.choices.map(c => { return { ...c, isNew: false } }) : []);
    }, [props.type])
    return <>
        <Row>
            <Col>
                <FormInputGroup label='Code'>
                    <Form.Control type='text' name='code' placeholder='Code' defaultValue={props.code}
                        readOnly={props.code ? true : false} required
                        pattern="^[-a-z0-9@\.+_]+$" />
                    <small>Format: minuscules, remplacer espaces par -</small>
                </FormInputGroup>
            </Col>
            <Col>
                <FormInputGroup label='Type'>
                    <Form.Select name='type' defaultValue={props.type} disabled={props.type ? true : false} required
                        onChange={(e) => setType(e.target.value)}>
                        {Object.keys(FieldType).map(k => <option key={k} value={k}>{(FieldType as any)[k]}</option>)}
                    </Form.Select>
                </FormInputGroup>
            </Col>
            <Col>
                <FormInputGroup label='Intitulé'>
                    <Form.Control type='text' name='title' placeholder='title' defaultValue={props.title} required />
                </FormInputGroup>
            </Col>
        </Row>

        {
            props.type === 'C' && props.choices &&
            <>
                <Container>
                    <h6 className='mt-3'>Choix</h6>
                    {choices.map((c, i) => <FieldChoices index={i} key={Math.random()} {...c}
                        handleChoice={(choice) => {
                            const newC = [...choices];
                            newC[i] = choice as Partial<Choice> & { isNew: boolean };
                            setChoices(newC);
                        }}

                        onDelete={() => {
                            setChoices([...choices].filter((cc, j) => j !== i))
                        }} />)}

                    <Button className='w-100' onClick={() => {
                        const newC = [...choices];
                        newC.push({ isNew: true });
                        setChoices(newC);
                    }}>Ajouter une réponse</Button>
                </Container>
            </>
        }
    </>
}

const FieldChoices: React.FC<Partial<Choice> & { isNew: boolean, index: number, onDelete: () => void, handleChoice: (choice: Partial<Choice & { isNew: boolean }>) => void }> = choice => {
    return <Row className='mb-1 d-flex align-items-center'>
        <Col sm={4}>
            <FormControl name={`choices[${choice.index}][code]`} defaultValue={choice.code} readOnly={choice.code && !choice.isNew ? true : false}
                placeholder='Code' onBlur={(e) => choice.handleChoice({ ...choice, code: e.target.value })} />
        </Col>
        <Col>
            <FormControl defaultValue={choice.title} placeholder='Nom' name={`choices[${choice.index}][title]`}
                onBlur={(e) => choice.handleChoice({ ...choice, title: e.target.value })} />
        </Col>
        {choice.isNew && <Col sm={2}>
            <div className='d-flex justify-content-end'>
                <ButtonIcon code='x' variant='danger' size='sm' onClick={() => choice.onDelete()} />
            </div>
        </Col>}
    </Row>
}

export default ModalField;