import {useEffect} from "react";
import {Card, Col, Form, Row} from "react-bootstrap";
import {useParams} from "react-router-dom";
import {formSuccessRedirect} from "../../app/actions";
import {useAppDispatch} from "../../app/hooks";
import diffusionApi from "../../app/services/secured/diffusion";
import AppSecured from "../../AppSecured";
import Urls from "../../assets/js/Urls";
import {SiteDomain} from "../../types";
import {FormButtons, FormInputGroup} from "../misc/Blocks";
import SimpleForm from "../misc/SimpleForm";

const Site = () => {
    const {id} = useParams<{ id: string }>();
    const [getSite, site] = diffusionApi.useLazyGetSiteQuery();

    useEffect(() => {
        if (id && id !== 'new') {
            getSite({id: parseInt(id)});
        }
    }, [id])

    return <>
        <AppSecured>
            <Card className='genelead-card mt-4'>
                <Card.Header>
                    {site.data?.domain}
                </Card.Header>
                <Card.Body>
                    {id === 'new' && <SiteNew/>}
                    {id !== 'new' && site.data && <SiteEdit {...site.data} />}
                </Card.Body>
            </Card>
        </AppSecured>
    </>
}

const SiteNew = () => {
    const dispatch = useAppDispatch();
    const [create, createRes] = diffusionApi.useCreateSiteMutation();
    dispatch(formSuccessRedirect({
        data: createRes,
        redirectUrl: Urls.sites.list,
        message: 'Succès création site/domaine'
    }));

    return <>
        <SimpleForm onSubmit={(form) => create(form as SiteDomain)}>
            <SiteForm/>
            <FormButtons cancelUrl={Urls.sites.list} data={createRes} btnTxt={'Créer'}/>
        </SimpleForm>
    </>
}

const SiteEdit: React.FC<SiteDomain> = site => {
    const dispatch = useAppDispatch();
    const [update, updateRes] = diffusionApi.useUpdateSiteMutation();
    dispatch(formSuccessRedirect({
        data: updateRes,
        redirectUrl: Urls.sites.list,
        message: 'Succès modification site/domaine'
    }));

    return <>
        <SimpleForm onSubmit={(form) => update({id: site.id, form: form as SiteDomain})}>
            <SiteForm {...site} />
            <FormButtons cancelUrl={Urls.sites.list} data={updateRes} btnTxt={'Modifier'}/>
        </SimpleForm>
    </>
}

const SiteForm: React.FC<Partial<SiteDomain>> = data => {
    return <>
        <Row>
            <Col>
                <h4>General</h4>
                <FormInputGroup label='Domaine'>
                    <Form.Control type='text' name='domain' placeholder='Domaine' defaultValue={data.domain} required/>
                </FormInputGroup>
                <FormInputGroup label='GTM'>
                    <Form.Control type='text' name='gtm_code' placeholder='GTM' defaultValue={data.gtm_code as string}/>
                </FormInputGroup>
                <FormInputGroup label='Email contact'>
                    <Form.Control type='text' name='contactEmail' placeholder='Email contact'
                                  defaultValue={data.contactEmail} required/>
                </FormInputGroup>
            </Col>
            <Col>
                <h4>SEO</h4>
                <FormInputGroup label='Titre'>
                    <Form.Control type='text' name='seoData[title]' placeholder='Titre'
                                  defaultValue={data.seoData?.title} required/>
                </FormInputGroup>
                <FormInputGroup label='Description'>
                    <Form.Control type='text' name='seoData[desc]' placeholder='Description'
                                  defaultValue={data.seoData?.desc}/>
                </FormInputGroup>
                <FormInputGroup label='Robot'>
                    <Form.Control type='text' name='seoData[robot]' placeholder='Robot'
                                  defaultValue={data.seoData?.robot}/>
                </FormInputGroup>
            </Col>
        </Row>
        <Row>
            <Col>
                <FormInputGroup label='Pixel Facebook ID'>
                    <Form.Control type='number' name='pixelId' placeholder='Pixel Facebook ID'
                                  defaultValue={data.pixelId}/>
                </FormInputGroup>
            </Col>
        </Row>
        <Row>
            <FormInputGroup label='Favicon'>
                <Form.Control name='favicon' as='textarea' placeholder='Favicon' style={{height: '100px'}}
                              defaultValue={data.favicon}/>
                <Form.Text className="text-muted">
                    Nous vous demandons d'insérer le code de votre image en Base 64.
                    Vous pouvez vous rendre ici pour l'obtenir :
                    <a target='_blank' href='https://base64.guru/converter/encode/image/ico'>Base 64</a>
                </Form.Text>
            </FormInputGroup>
        </Row>
    </>
}

export default Site;