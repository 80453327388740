import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {getCustomHeaders} from "../../../assets/js/ApiUrls";

const securedApi = createApi({
    baseQuery: fetchBaseQuery({
        prepareHeaders: (headers, store) => getCustomHeaders(headers, true),
        baseUrl: process.env.REACT_APP_API_DOMAIN
    }),
    tagTypes: ['KeysLink', 'Settings', 'Users', 'Categories', 'Medias', 'Databases', 
    'Companies', 'Forms', 'Fields', 'Coregistration', 'FbForms', 'Campaigns', 'FbPages', 'ReportingTemplates',
    'FormGateways', 'Company', 'FieldConverters', 'Reporting', 'Landings', 'LandingTemplates', 'Landing', 'LandingCustomers'],
    endpoints: () => ({}),
});

export default securedApi;