import { useEffect, useState } from "react";
import { Badge, Tab, Tabs } from "react-bootstrap";
import { FormattedDate } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import manageApi from "../../app/services/secured/manage";
import { getDatepicker } from "../../app/slice/app";
import AppSecured from "../../AppSecured";
import { DatePickerType, Lead, LeadSearch, LeadStatus } from "../../types";
import { PageHeader } from "../App/PageHeader";
import { LoadingBlock } from "../misc/Blocks";
import { Icon } from "../misc/Buttons";
import DatePicker from "../misc/DatePicker";
import { PaginationGenelead } from "../misc/Pagination";
import TableGenelead, { RowNoData } from "../misc/Table";
import { LeadsFilters } from "./LeadFilter";

const LeadList = () => {
    const dispatch = useAppDispatch();
    const { from, to } = useAppSelector<DatePickerType>(getDatepicker);
    const [search, setSearch] = useState<LeadSearch>({
        page: 1,
        statuses: 'dispatched',
        from: from,
        to: to
    });

    const [getLeads, leads] = manageApi.useLazyGetLeadsQuery();

    useEffect(() => {
        getLeads(search);
    }, [search]);

    const setFilters = (search: LeadSearch) => {
        setSearch(search.statuses === 'dispatch_wait' ? {...search, reporting:1} : search);
    }

    return <>
        <AppSecured>
            <PageHeader>
                <DatePicker onChange={(from: string, to: string) => setFilters({ ...search, from, to })} />
                <LeadsFilters currentFilter={search} onFilterChange={(newFilter: LeadSearch) => setFilters(newFilter)} />
            </PageHeader>

            <div className='d-flex align-items-end justify-content-between mt-4'>
                <div className="d-flex align-items-center">
                    <Icon code='person fs-40' />
                    <div className='d-flex flex-column ms-2'>
                        <LoadingBlock data={leads}>
                            <span className='fs-20'>{leads.data?.total}</span>
                        </LoadingBlock>
                        <small>leads</small>
                    </div>

                    {leads.isSuccess && <>
                        <PaginationGenelead
                            className='m-0 ms-3' size='sm'
                            pages={{ current: leads.data.page, total: leads.data.total_pages }}
                            search={(page: number) => { setSearch({ ...search, page }) }}
                        />
                    </>}
                </div>
                <div>
                    <Tabs className='lead-statutes' activeKey={search.statuses}
                        onSelect={(s) => { 
                            setSearch({ ...search, statuses: s as LeadStatus, page: 1 })
                        }}>

                        <Tab eventKey={'new'} title={'Nouveau'} />
                        <Tab eventKey={'dispatched'} title={'Envoyé'} />
                        <Tab eventKey={'test'} title={'Test'} />
                        <Tab eventKey={'duplicate'} title={'Doublon (base client)'} />
                        <Tab eventKey={'duplicate_account'} title={'Doublon (compte)'} />
                        <Tab eventKey={'dispatch_wait'} title={'En attente'} />
                        <Tab eventKey={'spam'} title={'Spam'} />
                    </Tabs>
                </div>
            </div>

            <LoadingBlock data={leads}>
                {leads.isSuccess && leads.data && <>
                    <TableGenelead header={[
                        { name: 'email', icon: 'envelope' },
                        { name: 'statut', icon: 'activity' },
                        { name: 'source', icon: 'server', width: '20%' },
                        { name: 'formulaire', icon: 'file', width: '20%' },
                        { name: 'date', icon: 'calendar', width: '10%' }
                    ]}>
                        <RowNoData list={leads.data.list} colspan={5} />
                        {leads.data.list.map(l => <LeadRow key={l.id} {...l} />)}
                    </TableGenelead>

                    <PaginationGenelead
                        className='m-0 ms-3'
                        pages={{ current: leads.data.page, total: leads.data.total_pages }}
                        search={(page: number) => { setSearch({ ...search, page }) }}
                    />
                </>}
            </LoadingBlock>
        </AppSecured>
    </>
}

const LeadRow: React.FC<Lead> = (lead) => {
    return <tr>
        <td>
            <Badge bg='secondary' className='me-1'>{lead.id}</Badge>
            <span className='me-1'>
                {lead.user_approval ? <Badge bg='success'>Opt-in</Badge> : <Badge bg='danger'>Non opt-in</Badge>}
            </span>
            {lead.email}
        </td>
        <td className='alert-success'>
            {lead.customers.length > 0 && <>
                {lead.customers[0].company} - {lead.customers[0].customer}
            </>}
        </td>
        <td>
            <small>
                {
                    lead.source && <>
                        {lead.source.campaign && <>utm_campaign : <b>{lead.source.campaign}</b> <br /> </>}
                        {lead.source.source && <>utm_source : <b>{lead.source.source}</b> <br /> </>}
                        {lead.source.template && <>template : <b>{lead.source.template}</b> <br /> </>}
                        {lead.source.content && <>content : <b>{lead.source.content}</b> <br /> </>}
                        {lead.source.medium && <>mediums : <b>{lead.source.medium}</b> <br /> </>}
                    </>
                }
                {/* {lead.source.lp} */}
            </small>
        </td>
        <td>
            <Badge bg='secondary' className='me-1'>{lead.form.id}</Badge>
            <Badge bg='primary' className='alt-primary'>{lead.form.name}</Badge>
        </td>
        <td>
            <FormattedDate value={lead.date * 1000} day='2-digit' month='2-digit' year='numeric' hour='2-digit' minute="2-digit" />
        </td>
    </tr>
}

export default LeadList;
