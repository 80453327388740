import React, {useEffect, useRef, useState} from "react";
import {Alert, Button, Col, FormControl, FormLabel, FormSelect, Row} from "react-bootstrap";
import {Typeahead} from "react-bootstrap-typeahead";
import {useParams} from "react-router-dom";
import {useAppDispatch} from "../../app/hooks";
import creationApi from "../../app/services/secured/creation";
import globalApi from "../../app/services/secured/global";
import manageApi from "../../app/services/secured/manage";
import {addNotification} from "../../app/slice/notifs";
import AppSecured from "../../AppSecured";
import Urls from "../../assets/js/Urls";
import {Choice, CustomRecordField, FieldType, FieldTypesString} from "../../types";
import {FormButtons} from "../misc/Blocks";
import {Icon} from "../misc/Buttons";
import SimpleForm from "../misc/SimpleForm";

const CustomRecord = () => {
    const dispatch = useAppDispatch();
    const {company_id} = useParams<{ company_id: string }>();
    const [getCompany, company] = manageApi.useLazyGetCompanyQuery();

    useEffect(() => {
        if (company_id) getCompany({id: parseInt(company_id)})
    }, [company_id])

    const fields = creationApi.useGetFieldsQuery({});
    const [customFields, setCustomFields] = useState<Array<CustomRecordField>>([]);
    const [select, setSelect] = useState<Array<{ label: string, type: string }>>([]);
    const resetData: Array<CustomRecordField> = [
        {code: "email", type: "T"},
        {code: "date", type: "D"},
        {code: "form_code", type: "T"}
    ];

    useEffect(() => {
            setCustomFields(company.data && company.data.exportTemplates.length > 0
                ? company.data.exportTemplates
                : resetData
            );
    }, [company]);

    const onDelete = (code: string) => {
        setCustomFields([...customFields].filter(f => f.code != code));
    }

    const onMove = (field: CustomRecordField, direction: number) => {
        const currentfields = [...customFields];
        const oldPos = currentfields.findIndex(f => field.custom ? f.value === field.value : f.code === field.code);
        const newPos = oldPos + direction;

        if (newPos > -1 && newPos < currentfields.length) {
            const changedVal = currentfields[newPos];
            currentfields[newPos] = currentfields[oldPos];
            currentfields[oldPos] = changedVal;
        }

        setCustomFields(currentfields);
    }

    const customFieldInputRef = useRef<HTMLInputElement>(null);
    const [updateCustom, updateCustomRes] = manageApi.useUpdateCompanyMutation();

    useEffect(() => {
        if (updateCustomRes.isSuccess) dispatch(addNotification({
            message: 'Succès modification dessin enregistrement',
            type: 'success'
        }));
    }, [updateCustomRes.isSuccess])

    return <>
        <AppSecured>
            {company.isSuccess && company.data && <>
                <Alert variant='info' className='mt-3 d-block'>
                    <small>Ce formulaire vous permet de définir un tableau personnalisé pour le client, accessible
                        depuis son interface Genelead.</small>
                </Alert>
                {fields.isSuccess && fields.data && <>
                    <Typeahead
                        id='field-selector'
                        placeholder="Sélectionnez un champ existant pour l'ajouter à la liste"
                        selected={select}
                        options={
                            fields.data.filter(f => customFields.findIndex(ff => ff.code === f.code) === -1)
                                .map(f => {
                                    return {label: f.code, type: f.type, choices: f.choices}
                                })}
                        onChange={(s) => {
                            setSelect(s as any);
                            if (s[0]) {
                                const val = s[0] as { label: string, type: string, choices: Array<Choice> };
                                const newCustom = [...customFields];
                                newCustom.push({
                                    code: val.label,
                                    type: val.type as FieldTypesString,
                                    choices: val.choices
                                });
                                setCustomFields(newCustom);
                                setSelect([]);
                            }
                        }}
                    />
                </>}

                <Row className='mt-4'>
                    <Col sm={3}>
                        <FormLabel>Créer un champ personnalisé</FormLabel>
                        <div className='d-flex'>
                            <FormControl type='text' ref={customFieldInputRef} placeholder='Code custom'/>
                            <Button className='ms-1' onClick={() => {
                                const customField = {
                                    type: 'T' as FieldTypesString, code: '',
                                    value: customFieldInputRef.current?.value, custom: true
                                }
                                const newAdd = [...customFields];
                                newAdd.push(customField);
                                setCustomFields(newAdd);
                            }}>Ajouter</Button>
                        </div>
                    </Col>
                </Row>

                <SimpleForm onSubmit={(json) => updateCustom({company_id: company.data.id, data: json})}>
                    <h6 className='mt-5'>Liste des champs personnalisés</h6>

                    {customFields.length === 0 && <Alert variant='info'>Aucun champ personnalisé.</Alert>}

                    <div className=''>
                        {customFields.map((e, i) => <FieldTemplate key={Math.random()} {...e} index={i}
                                                                   onDelete={onDelete} onMove={onMove}/>)}
                    </div>

                    <div className='mt-3 text-end'>
                        <FormButtons cancelUrl={Urls.companies.view(company.data.id)} btnTxt='Modifier'
                                     data={updateCustomRes}>
                            <Button variant='warning' className='me-1' onClick={() => setCustomFields([...resetData])}>
                                Réinitialiser</Button>
                        </FormButtons>
                    </div>
                </SimpleForm>
            </>}
        </AppSecured>
    </>
}

type FieldTemplateProps = CustomRecordField & {
    index: number,
    custom?: boolean,
    raw?: string,
    onDelete: (code: string) => void,
    onMove: (field: CustomRecordField, direction: number) => void
}

const FieldTemplate: React.FC<FieldTemplateProps> = field => {
    const root = `exportTemplates[${field.index}]`;
    const [getConverters, converters] = globalApi.useLazyGetFieldConvertersQuery();
    const [isConverted, setIsConverted] = useState(false);

    useEffect(() => {
        if (field.type && field.code && ['B', 'T'].indexOf(field.type) > -1 && ["email", "date", "form_code"].indexOf(field.code) === -1) {
            getConverters();
            setIsConverted(true);
        }
    }, [field.type])
    return <>
        {field && <>
            <Row className='d-flex align-items-center'>
                <FormControl type='hidden' name={`${root}[code]`} defaultValue={field.code}/>
                <FormControl type='hidden' name={`${root}[type]`} defaultValue={field.type}/>

                {field.custom && <FormControl type='hidden' name={`${root}[custom]`} defaultValue='1'/>}

                <Col sm={2}>
                    <div className='d-flex align-items-center'>
                        <Button size='lg' variant="danger" className='alt-danger'
                                onClick={() => field.onDelete(field.code as string)}><Icon code='x'/></Button>

                        <div className='ms-3'>
                            <p className='fs-16 m-0'>{field.code} {field.custom &&
                                <i><small>champ custom</small></i>}</p>
                            <small>{FieldType[field.type as FieldTypesString]}</small>
                        </div>

                    </div>
                </Col>

                <Col sm={1}><Icon code={"arrow-left-right"}/></Col>

                <Col sm={8}>
                    <div className='d-flex align-items-center'>
                        <FormControl type='text' name={`${root}[value]`} defaultValue={field.value}
                                     placeholder='Nom du champ'/>
                        {field.custom &&
                            <FormControl type='text' name={`${root}[raw]`} defaultValue={field.raw} placeholder='Valeur'
                                         className='ms-1'/>}

                        { //converter
                            isConverted && !field.custom && <>
                                <FormSelect name={`${root}[converter]`} defaultValue={field.converter} className='ms-1'>
                                    <option value=''>Valeur brute</option>
                                    {converters.data && converters.data?.length > 0 &&
                                        converters.data?.map(c =>
                                            <option disabled={-1 == c.types.indexOf(field.type as FieldTypesString)}
                                                    key={c.id} value={c.id}>{c.title}</option>
                                        )}
                                </FormSelect>
                            </>
                        }
                    </div>

                    { //choices
                        field && field.choices && <ul className="list-group list-group-full">
                            {field.choices && field.choices.map((c, j) => {
                                const choiceRoot = `${root}[choices][${j}]`;
                                return <li key={Math.random()} className='list-group-item border-0'>
                                    <Row className='d-flex align-items-center'>
                                        <Col sm={2} className='text-center'>{c.code}</Col>
                                        <Col sm={1}><Icon code={"arrow-left-right"}/></Col>
                                        <Col>
                                            <FormControl type='hidden' name={`${choiceRoot}[code]`} defaultValue={c.code}/>
                                            <FormControl type='text' name={`${choiceRoot}[value]`} defaultValue={c.value}
                                                         placeholder='Nom du champ'/>
                                        </Col>
                                    </Row>
                                </li>
                            })}
                        </ul>
                    }
                </Col>

                <Col>
                    <div className='d-flex flex-column align-items-center justify-content-center'>
                        <Button size='sm' className='alt-primary mb-1'
                                onClick={() => field.onMove(field, -1)}><Icon
                            code='chevron-up'/></Button>
                        <Button size='sm' className='alt-primary' onClick={() => field.onMove(field, 1)}><Icon
                            code='chevron-down'/></Button>
                    </div>
                </Col>
            </Row>
            <hr/>
        </>}
    </>
}

export default CustomRecord;