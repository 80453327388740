import React, { useEffect } from "react";
import { Col, Form, Modal, Row } from "react-bootstrap";
import { formSuccessRedirect } from "../../../app/actions";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import adminApi from "../../../app/services/secured/admin";
import { getModalData, isModalOpened, ModalTypes } from "../../../app/slice/modal";
import { linkKeysType } from "../../../globals";
import { KeyLink, ModalIsNew, ReduxRtkApiResult } from "../../../types";
import { BlockError, FormInputGroup, IfTrue, IsDefined } from "../../misc/Blocks";
import { BtnIconLoad, Icon } from "../../misc/Buttons";
import SimpleForm from "../../misc/SimpleForm";
import ModalCloseButton from "../../Modals/ModalCloseButton";

const ModalEditLink: React.FC = () => {
    const isOpened = useAppSelector(isModalOpened(ModalTypes.EditKeyLink));
    const stateData = useAppSelector(getModalData);
    return <> {
        isOpened && <>
            <Modal.Header>
                <Modal.Title>
                    <Icon code='key' className='me-2' />
                    {stateData.isNew ? 'Création' : 'Édition'}
                </Modal.Title>
            </Modal.Header>

            {stateData.isNew && <KeyLinkNew />}
            {stateData.identifier && <KeyLinkEdit />}

        </>}
    </>
}

const KeyLinkNew = () => {
    const dispatch = useAppDispatch();
    const [createKeyLink, resCreate] = adminApi.useCreateKeyLinkMutation();
    dispatch(formSuccessRedirect({ data: resCreate, isModal: true, message: 'Succès de la création de la clé de liaison' }));

    return <>
        <SimpleForm onSubmit={form => createKeyLink(form as KeyLink)}>
            <KeyLinkForm />
            <Modal.Footer>
                <ModalCloseButton />
                <BtnIconLoad icon='tag' type='submit' data={resCreate}>Créer</BtnIconLoad>
            </Modal.Footer>
        </SimpleForm>
    </>
}

const KeyLinkEdit = () => {
    const dispatch = useAppDispatch();
    const isOpened = useAppSelector(isModalOpened(ModalTypes.EditKeyLink));
    const stateData = useAppSelector<Pick<KeyLink, 'identifier'>>(getModalData);
    const [getKeyData, keyLinkData] = adminApi.useLazyGetKeyLinkQuery();

    useEffect(() => {
        if (isOpened && stateData.identifier) {
            getKeyData(stateData);
        }
    }, [isOpened, stateData])

    const [updateKeyLink, resUpdate] = adminApi.useUpdateKeyLinkMutation();
    dispatch(formSuccessRedirect({ data: resUpdate, isModal: true, message: 'Succès de la modification de la clé de liaison' }));

    return <>
        <SimpleForm onSubmit={form => updateKeyLink({ identifier: stateData.identifier, form: form as KeyLink })}>
            <BlockError data={resUpdate} />
            {keyLinkData.data && <KeyLinkForm {...keyLinkData.data} />}

            <Modal.Footer>
                <ModalCloseButton />
                <BtnIconLoad icon='save' type='submit' data={resUpdate}>
                    Modifier
                </BtnIconLoad>
            </Modal.Footer>
        </SimpleForm>
    </>
}
//{ identifier: string, res: ReduxRtkApiResult, data?: KeyLink }
const KeyLinkForm: React.FC<Partial<KeyLink>> = props => {
    const stateData = useAppSelector<ModalIsNew>(getModalData);
    return <>
        <Modal.Body>
            <Row>
                <Col>
                    <FormInputGroup label="Key">
                        <Form.Control name='identifier' type='text' defaultValue={props.identifier} required
                            disabled={stateData.isNew ? false : true} />
                    </FormInputGroup>
                    {!stateData.isNew && <Form.Control type='hidden' name='identifier' value={props.identifier} />}
                </Col>

                <Col>
                    <FormInputGroup label="Type">
                        <Form.Select key={Math.random()} defaultValue={props.type} name='type' required>
                            <option value='' disabled>Sélectionnez un type</option>
                            {linkKeysType.map(l => <option key={l} value={l}>{l}</option>)}
                        </Form.Select>
                    </FormInputGroup>
                </Col>
            </Row>
            <FormInputGroup label="Name" className='mt-2'>
                <Form.Control name='name' type='text' defaultValue={props.name} required />
            </FormInputGroup>
            <FormInputGroup label="URL" className='mt-2'>
                <Form.Control defaultValue={props.url} name='url' type='text' required />
            </FormInputGroup>
        </Modal.Body>
    </>
}

export default ModalEditLink;