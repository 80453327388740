import { useEffect, useState } from "react";
import { Card, FormCheck, FormControl, InputGroup } from "react-bootstrap"
import manageApi from "../../../app/services/secured/manage";
import { GatewayAPI, GatewayFormsProps } from "../../../types";
import { BtnIconLoad } from "../../misc/Buttons";
import SimpleForm from "../../misc/SimpleForm"
import { PendingLeadsButton } from "../AccountExports";

const LPMSante:React.FC<GatewayFormsProps> = gateway => {
    const [isNew, setIsNew] = useState(true);

    useEffect(() => {
        if (gateway.id) setIsNew(false);
    }, [gateway])

    const [gatewayAPI, callGatewayAPI] = manageApi.useHandleFormGatewayMutation();
    return <>
        <SimpleForm onSubmit={(json) => {
            gatewayAPI(isNew
                ? { company: gateway.company, form: gateway.form, gateway: '', data: json } as GatewayAPI
                : { company: gateway.company, form: gateway.form, gateway: gateway.id, data: json } as GatewayAPI
            )
        }}>
            <FormControl type='hidden' name='gateway_code' value='lmp-sante' />
            <Card className='mt-3'>
                <Card.Header>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex align-items-center'>
                            <FormCheck id='activate_api' type='switch' label='Activer export' name='enabled' defaultChecked={gateway?.enabled} />
                            {!isNew && gateway.id && <PendingLeadsButton company_id={gateway.company} form_id={gateway.form} gateway_id={gateway.id} />}
                        </div>
                        <h6 className='text-end m-0'>LPM Santé</h6>
                    </div>
                </Card.Header>
                <Card.Body>
                    <div className='text-end'>
                        <BtnIconLoad data={callGatewayAPI}>Enregister</BtnIconLoad>
                    </div>
                </Card.Body>
            </Card>
        </SimpleForm>
    </>
}

export default LPMSante;