import { CSSProperties } from "react";
import { LandingV2 } from "./components/LandingsV2/landing-v2";
import { SimpleFormData } from "./components/misc/SimpleForm";
import { ListTableHeader } from "./components/misc/Table";
import { Reporting } from "./components/Reporting/ReportingClass";

export type CustomHeaders = Record<string, string>;

export type ReduxRtkApiResult = {
  isLoading: boolean,
  isSuccess: boolean,
  isError: boolean,
  isFetching?: boolean,
  isUninitialized?: boolean,
  data?: any,
  error?: any,
};

export type LoginReturn = {
  redirect: string,
  refresh_password: boolean,
  token: string
}

export type UserBasicData = {
  email: string,
  isCustomer: boolean,
  isCustomerAdmin: boolean,
  isUser: boolean,
  isUserAdmin: boolean
}

export type MenuItem = {
  icon: string,
  link: string,
  name: string
}

export type MenuGroup = {
  title: string,
  icon: string,
  items: Array<MenuItem>
}

export type MenuItemProps = {
  link: string,
  icon: string,
  children: string,
  active?: boolean
}

export type MenuAccordionProps = {
  title: string,
  eventKey: string,
  icon: string,
  children: any,
}

type Media = {
  id: number,
  extension: string
}

type LandingMedias = {
  bg?: Media,
  logo?: Media,
  rs?: Media,
  bs?: Media
}

export type Field = {
  id: number,
  title: string,
  code: string,
  type: FieldTypesString,
  choices: Array<Choice>,
  validation_pattern?: string,
  helper?: string,
}

export type LandingField = Pick<Field, 'choices' | 'code' | 'title' | 'type' | 'validation_pattern' | 'helper'> & {
  afterOptin: boolean,
  display: string | null,
  mandatory: boolean,
}

export type LandingModalTypes = "asso" | "clement" | "contest" | "ecommerce" | "quiz" | "redirect" | "simple" | "volet";

export type LandingDesignData = {

  //v2
  lp?: LandingV2,
  modal?: LandingV2,
  version: 'v1' | 'v2'
  seo_title?: string,
  seo_desc?: string,
  seo_img?: string,

  // v1
  fields_color: string,
  form_design: string,
  modal_version: LandingModalTypes,
  optin_mode: number,
  post_optin_title: string,
  why: string,
  why_title: string,
  unsubscribe: string,
  title: string,
  subtitle: string,
  share_title: string,
  share_desc: string,
  rs_share: number,
  rs_connect: string,
  redirect_url: string,
  redirect_text: string,
  policy: string,
  optin_name: string,
  optin: string,
  modal_title: string,
  logo_height: string,
  how_title: string,
  how: string,
  greeting_text: string,
  form_title: string,
  form_description: string,
  excerpt: string,
  cta: string,
  color: string,
  body: string,
  author: string,
  secondary_color: string,
  default_bg_color: string,
  goto_form: string,
  body2: string,
  body3: string,
  body4: string,
  testimonial: string,
  numbers_title: string,
  numbers: string,
  form_subtitle: string,
  contact_number: string,
  offer_name: string,
  offer_price: string,
  offer_desc: string,
  testimonial_title: string,
  offer: string,
  offer_condition: string,
  redirect_link_text: string,
  comeback_text: string,
  comeback_link: string,
  donation_text: string,
  donation_btn_text: string,
  modal_name: string,
  logo: boolean,
  video: string,
  bg: string,
  donation_url: string,
  optin_alert: string,
  optin_text_one: string,
  optin_text_two: string,
  recaptcha: string | boolean,
  current_templates: Array<string>,
  redirect_time: number,
  embed_version?: string,
  embed_title?: string,
  embed_design?: number,
  embed_cta?: string,
  embed_optin?: string,
  embed_greeting?: string,
  use_white_logo?: boolean|null,
}

export type LandingOptions = {
  check_global_duplicate: boolean | null,
  database: Database | null | undefined,
  emailValidation: boolean,
  phoneValidation: boolean,
  emailDatabase?: number,
  checkGlobalDuplicate?: boolean | null,
  checkOptions?: number | Array<number>,
}

export type LandingQuizzAnswers = {
  code: number,
  good: boolean,
  answer: string,
}

export type LandingQuizz = {
  question: string,
  key?: number,
  explanation: string,
  code?: string,
  supplement?: string,
  answers: Array<LandingQuizzAnswers>
}

export type LandingRegister = {
  isSensitiveData: boolean,
  objectives: string,
  recipientId: number,
  retentionMonth: number,
  sensitiveDataDetail: string,
  subcontractors: string,
  target: string,
  transfertCountries: Array<string>,
  timestamps: {
    created: number,
    updated: number
  }
}

type LandingThemeData = {
  button: string,
  color_bg: string,
  color_txt: string,
  seo_desc: string,
  seo_title: string,
  subtitle: string
}

type LandingTheme = {
  id: number,
  name: string,
  slug: string,
  type: number,
  media: {
    id: number,
    size: number,
    extension: string
  } | null,
  data: LandingThemeData
}

type LandingPageCompany = {
  id: number,
  name: string,
  enabled: boolean
}

export type Landing = {
  id: number,
  category: Pick<Category, 'id' | 'name' | 'type'> | null | undefined,
  code: string,
  company: LandingPageCompany | null | undefined,
  created: number,
  data: LandingDesignData,
  description: string | null | undefined,
  dispatchDelay: number,
  enabledTemplates: Array<number | string>,
  fields: Array<LandingField>,
  form: LandingForm | null | undefined,
  medias: Record<string, LandingMedia | undefined>,
  minObjective: number,
  name: string,
  objective: number,
  options: LandingOptions,
  quizz: Array<LandingQuizz>,
  register: LandingRegister | null | undefined,
  site: { domain: string },
  status: number,
  theme: LandingTheme | null | undefined,
  visible: boolean
}

export type LandingAPI =
  Pick<Landing, 'id' | 'options' | 'visible' | 'status' | 'objective' | 'name' | 'minObjective' | 'medias'
    | 'fields' | 'enabledTemplates' | 'dispatchDelay' | 'description' | 'data' | 'code' | 'created' | 'site' | 'register' | 'quizz'>
  &
  {
    form?: string,
    category: number,
    company: number,
    theme: number,
    last?: number,
  }

export type ListData<T> = {
  has_next_page: boolean,
  list: Array<T>,
  page: number,
  total: number,
  total_pages: number
}

export type User = {
  active: boolean,
  apiUser: boolean,
  email: string,
  first_name: string,
  last_name: string,
  id: number,
  roles: Array<string> | undefined
}

export type KeyLink = {
  identifier: string,
  name: string,
  type: string,
  url: string,
}

export type Setting = {
  settings: {
    weight_customer_avg: string,
    weight_customer_bad: string,
    weight_customer_good: string,
  },
  share_packs: {
    exclu: number,
    premium: number,
    standard: number
  }
}

export type CaptainVerify = {
  credits: string,
  formatted_number: string,
  requested_number: string,
  result: string,
  status: boolean,
  message: string,
  details: {
    country: string,
    country_prefix: string,
    details: string,
    network: string,
    original_network: string,
    ported: boolean,
    roaming: boolean
  }
}

export type CaptainVerifyReturn = {
  entry: string,
  captainVerify: CaptainVerify
}

export type Category = {
  id: number,
  slug: string,
  name: string,
  type: number,
  media: LandingMedia,
  data: {
    color_bg: string,
    color_txt: string,
    seo_desc: string,
    seo_title: string,
    subtitle: string
    button?: string
  }
}

export type LandingMedia = {
  id: number,
  size: number,
  extension: string
}

export type SiteDomain = {
  id: number,
  contactEmail: string,
  domain: string,
  favicon: string,
  home_page_enabled: boolean,
  pixelId: number,
  seoData: {
    title: string,
    desc: string,
    robot: string
  }
  /** @deprecated */
  gtm_code: null | string,
}

export type LiveLanding = {
  code: string,
  description: string,
  name: string,
  position: number
}

export type LiveLandingsData = {
  domain: string,
  hidden: Array<LiveLanding>,
  visible: Array<LiveLanding>
}

export type Database = {
  id: number,
  name: string,
  access_token: string,
  emails: number,
  company: Company,
  forms: Array<object>,
  emailDatabaseHistories: Array<{ nbEmails: number, dateCreation: string }>
}

export type CompanyDatabases = {
  id: number,
  name: string,
  databases: Array<Database>
}

export type modalDatabaseProps = {
  companies?: Array<CompanyDatabases>
  company?: CompanyDatabases,
  database?: Database
  isNew?: boolean,
}

export type UpdateKeyLink = {
  identifier: string,
  form: KeyLink
}

export type UpdateCategoryArgs = Pick<Category, 'id'> & {
  form: Category,
}

export type LoginArgs = {
  email: string,
  password: string
}

export type RefreshArgs = {
  token: string,
  passwords: object
}

export type Company = {
  id: number,
  name: string,
  enabled: boolean,
  available_credit: null,
  customers: Array<Customer>,
  group: null,
  exportTemplates: Array<CustomRecordField>,
}

export type LandingCustomer = {
  customer_id: number,
  enabled: boolean,
  customCode: string,
  weight: 1,
  email: string,
}

export type Customer = {
  id: number,
  admin: boolean,
  active: boolean,
  email: string,
  enabled: boolean,
  firstName: string,
  lastName: string,
  phoneNumber: string | null,
  forms: Array<CompanyAccountForm>,
  apiToken: string | null,
}

export type CompanyAccountForm = {
  id: number,
  customCode: string,
  weight: string | number,
  enabled: boolean,
  isPrivate: boolean,
  form: {
    id: string,
    name: string,
    code: string,
  },
  gateways: Array<any>
}

export type Lead = {
  id: number,
  email: string,
  customers: Array<{ company: string, customer: string, status: string }>,
  date: number,
  form: {
    id: number,
    code: string,
    name: string
  },
  status: {
    code: string,
  },
  user_approval: boolean,
  source: {
    campaign: string | null,
    content: string | null,
    lp: string | null,
    medium: string | null,
    source: string | null,
    template: string | null
  }
}

export type DateTimeOptions = {
  weekday: 'long' | 'short' | 'narrow',
  year: 'numeric' | '2-digit',
  month: 'long' | 'numeric',
  day: 'numeric' | '2-digit',
}

export type LeadSearch = {
  page: number,
  statuses: LeadStatus,
  from: string,
  to: string,
  company_id?: string,
  form_id?: string,
  reporting?: number,
}

export type LeadStatus = 'new' | 'dispatched' | 'test' | 'duplicate' | 'duplicate_account' | 'dispatch_wait' | 'spam';

export type LeadExportSearch = {
  page: number,
  type: 'exported' | 'pending',
  from: string,
  to: string,
  company_id?: string | number,
  active?: string,
  gateway_id?: string | number
}

export type LeadExport = {
  id: number,
  success: boolean,
  timestamp: number,
  form: string,
  form_id: number,
  message: string | null,
  lead: Pick<Lead, 'id' | 'email'>,
  customerFormGateway: {
    id: number,
    enabled: boolean,
    gateway: {
      code: string,
      name: string,
      apiKeys: Array<object>
    },
    config: {
      form: string,
      send_lead_email: string,
      subject: string,
      template: string,
      to: string
    }
  }
}

export type LeadExportWaiting = {
  company: string,
  email: string,
  form: string,
  gateway: string,
  lead: number,
  message: string,
  retry: number,
}

export type GeneleadForm = {
  id: number,
  code: string,
  name: string,
  allowDuplicates: boolean,
  dispatchDelay: number,
  hasLanding: boolean,
  mandatory_opt_in: boolean,
  status: { code: string },
  family: { code: string },
  fields: FormFields,
  register: FormRegister
}

export type FormFields = Array<{ field: Field } & { mandatory: boolean }>

export type LandingForm = Pick<GeneleadForm, 'code' | 'id' | 'name'>

type FormRegister = {
  recipientId: number,
  retentionMonth: number,
  isSensitiveData: boolean,
  ojectives: string,
  sensitiveDataDetail: string | null,
  subcontractors: string,
  target: string,
  transfertCountries: Array<object>,
  timestamps: {
    created: number,
    updated: number
  }
}

export type FieldTypesString = 'Q' | 'T' | 'D' | 'C' | 'N' | 'B' | 'K';

export type Choice = {
  code: string,
  is_valid_answer: boolean,
  title: string,
  active?: boolean,
  default?: boolean,
}

export const FieldType: Record<FieldTypesString, string> = {
  'T': 'Text',
  'D': 'Date',
  'C': 'Choice',
  'N': 'Numeric',
  'B': 'Boolean',
  'Q': 'Quizz',
  'K': 'Key',
}

export type FieldConverter = {
  id: string,
  title: string,
  types: Array<FieldTypesString>
}

export type CustomRecordField = Partial<Pick<Field, 'code' | 'type'> & {
  value?: string,
  converter?: string,
  choices?: Array<Partial<Choice & { value: string }>>,
  custom?: boolean
}>;

export type FormSearchParams = {
  query?: string,
  statuses: FormStatuses;
  page: number,
}

export type FormStatuses = 'online' | 'testing' | 'offline';

export const FormStatuses: Array<FormStatuses> = [ 'online', 'testing', 'offline' ];

export type Coregistration = {
  id: number,
  accessToken: string,
  name: string,
  paramConverter: string | null,
  formCodes: Array<string>,
  allowedPacks: Array<object>,
  payout: number
}

export type LinkedFormstoCoreg = Partial<LandingForm> & { match?: string };

export type CoregistrationForm = {
  name: string,
  payout: number,
  formCodes: Array<string>,
  'refresh-api-token': boolean,
}

export type FbForm = {
  formId: number,
  pageId: string,
  facebookFormId: string,
  facebookFormName: string,
  form: { name: string },
  stats: FbStats,
  campaigns: Array<FbCampaign>
}

export type FbStats = {
  duplicates: number,
  errors: number,
  last: number,
  missing: number,
  period_from: number,
  period_to: number,
  total: number
}

export type FbCampaign = {
  id: string,
  name: string
}

export type FbSyncUpdate = {
  from: Date,
  to: Date
}

export type FbCampaignList = {
  campaignId: string,
  campaignName: string,
}

export type FbLinkUpdate = Pick<FbForm, 'form' | 'facebookFormId' | 'campaigns'>;

export type FbPage = FbCampaign;

export type FbLinkForm = Pick<FbForm, 'pageId' | 'facebookFormId' | 'facebookFormName'> & { form: string };

export type Pagination = { page: number }

export type ModalIsNew = { isNew: boolean }

export type PhoneNumber = string;

export type Option = {
  label: string,
  name: string,
  icon: string,
  active?: boolean,
  color?: string,
}

export type ReportingRowsProps = {
  report: Reporting,
  level: number,
  nbGroups: number,
  nbHeaders: number,
  recursive?: boolean
  color?: CSSProperties,
  sort: {key: string, direction: boolean}|undefined,
}

export type ReportingDataProps = {
  search: Array<Option>,
  //options: ReportingParams
}

export type ReportingQueryParameters = {
  from: string,
  to: string,
  company_id?: Array<string>,
  template?: {
    name: string,
    id: string
  },
}

export type OptionsProps = {
  //options: ReportingParams,
  setOption: ( option: Option, type: OptionType ) => void
}

export type ReportingTemplateProps = {
  from: string,
  to: string,
  company_id: Array<string>,
  template: { id: string, name: string }
}

export type OptionType = 'data' | 'search' | 'stats';

export type DatePickerType = {
  from: string,
  to: string,
}

export type ReportingTemplate = {
  filters: Array<string>,
  aggregations: Array<string>,
  from: string,
  from_days: number,
  relative_period: number,
  reporting: boolean,
  to: string,
  to_days: number,
  title: string,
  company_id: Array<string>,
}

export type ReportingTemplateAPI = {
  homeDataTable: Array<Pick<ReportingTemplate, 'title'> & { search: ReportingTemplate } & { id?: string }>,
}

// export type BasicSearch = {
//     page: number,
//     query?: string,
//     status?: string,
// }

export type FormGateway = {
  id: number,
  enabled: boolean,
  gateway: {
    code: string,
    name: string,
    apiKeys: Record<string, string> | null
  },
  config: SENDINBLUE_GATEWAY | TALK_GATEWAY | EDIIS_GATEWAY | MAILER_GATEWAY | PIPEDRIVE_GATEWAY | ADM_GATEWAY
}

export type GatewayFormsProps = Partial<FormGateway> & { company: number, form: number, customer: number }

export type GatewayAPI = {
  company: number | string,
  form: number | string,
  gateway: number | string | '',
  data: Partial<SENDINBLUE_GATEWAY | TALK_GATEWAY | EDIIS_GATEWAY | MAILER_GATEWAY | PIPEDRIVE_GATEWAY | ADM_GATEWAY>
}

export type SENDINBLUE_GATEWAY = {
  api_key: string,
  attributes: {
    firstname: string,
    lastname: string,
    phone: string
  },
  list_id: string
}

export type Sendinblue_API = {
  folders: Array<SendInBlueList>,
  lists: Array<SendInBlueList>
}

export type SendInBlueList = {
  id: number,
  folder_id: number,
  name: string
}

export type TALK_GATEWAY = {
  date_param: string,
  login: string,
  media_code: string,
  password: string,
  provenance_email: string,
  service_name: string,
}

export type EDIIS_GATEWAY = {
  login: string,
  password: string,
  base: string,
  origin: string,
  indic: string,
  action: string,
  sollicitation: string,
  canal?: string,
  webservice?: string,
  field?: Record<string, Record<string, string>>,
}

export type MAILER_GATEWAY = {
  subject: string,
  template: string,
  to: string,
  from: string,
  reply_to: string,
  send_lead_email: boolean | string,
}

export type PIPEDRIVE_GATEWAY = {
  leadads_support: string,
  seo_support: string,
}

export type ADM_GATEWAY = {
  webservice_url: string,
  adm_ident: string,
  adm_camp: string,
  infosig1: string,
  infosig2: string,
  infosig3: string,
  media: string,
  origine?: string,
  asso?: string,
  cid?: string,
}

export type CompanyAccountParams = {
  company: Pick<Company, 'id'>,
  account: Pick<Customer, 'id'>,
  data: Pick<Customer, 'admin' | 'firstName' | 'lastName' | 'email' | 'phoneNumber'> | any,
}

export type RefreshLeadsProps = {
  company_id: string | number,
  form_id: string | number,
  gateway_id: string | number
}

export type GatewayView = {
  company: string,
  company_id: number,
  customer: string,
  customer_id: number,
  form: string,
  form_code: string,
  form_id: number,
  gateway: string,
  gateway_id: number,
}

export type WebserviceView = {
  gateway: string,
  gateway_id: number,
  webservice: Array<GatewayView>,
}

export type LandingTemplates = {
  modals: Array<LandingTemplate>,
  pages: Array<LandingTemplate>,
}

export type LandingTemplate = {
  code: string,
  id: number | string,
  medias: Array<string>,
  variables: Array<string>
}

export type Country = {
  code: string,
  name: string
}

export type CustomerList = {
  company: number,
  id: number,
  name: string
}

export type ReportingMetrics = {
  duplicates: number,
  spent: number,
  duplicatesAccount: number,
  dispatched: number,
  dispatchWaiting: number,
  optins: number,
  optinsPhones: number,
  purged: number,
  total: number,
  exported: Record<string, number> | null,
  date: string,
  week: string,
  month: string,
}

export type ReportingType = ReportingMetrics & {
  uUID: string,
  company: string,
  customer: string,
  lp: string,
  template: string,
  source: string,
  campaign: string,
  customerCode: string,
}