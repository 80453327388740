import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Company, Customer, FbForm, Field, modalDatabaseProps, RefreshLeadsProps, ReportingTemplateProps } from "../../types";
import { AppThunk, RootState } from "../store";

export enum ModalTypes {
    Logout,
    EditKeyLink,
    Medias,
    Database,
    Field,
    FbSyncro,
    FbLink,
    FbSearchPage,
    ReportingTemplate,
    NewCompanyAccount,
    CompanyExportEmails,
    CompanyExportEmailsGlobal,
    CompanyCustomRecord,
    LeadsPending,
    DeleteCompany,
    EnableCompany,
    DeleteAccount,
    NewLanding,
    NewLandingV2,
    LinkLanding,
    ExportLandingFilter,
    ChangeImg,
};

type ModalData = {
    type: ModalTypes,
    data?: any,
    callback?: () => void
}

export type modalSubscriptionProps = {
    customerId: string|number,
    website?: string|number
}

const initialState: Array<ModalData> = [];

const modal = createSlice({
    name:"modal",
    initialState,
    reducers: {
        modalShow: (state, action:PayloadAction<ModalData>) => {
            state.push(action.payload);
        },
        modalClose: (state, action:PayloadAction<void>) => {
            state.pop();
        },
    },
});

export const modalReducer = modal.reducer;
export const { modalShow, modalClose } = modal.actions;

export const modalLogoutShow = ():AppThunk => dispatch => {
    dispatch(modal.actions.modalShow({type: ModalTypes.Logout}));
}

export type EditKeyLinkProps = {
    key_id: string|number,
}

export const modalEditKeyLinkShow = (props:{identifier?:string, isNew?: boolean}):AppThunk => dispatch => {
    dispatch(modal.actions.modalShow({type:ModalTypes.EditKeyLink, data: props}));
}

export const modalMediasShow = ():AppThunk => dispatch => {
    dispatch(modal.actions.modalShow({type:ModalTypes.Medias}));
}

export const modalDatabaseShow = (props:modalDatabaseProps):AppThunk => dispatch => {
    dispatch(modal.actions.modalShow({type:ModalTypes.Database, data: props}));
}

export const modalFieldShow = (props: Partial<Field>):AppThunk => dispatch => {
    dispatch(modal.actions.modalShow({type:ModalTypes.Field, data: props}));
}

export const modalFbSyncShow = (props: Pick<FbForm, 'facebookFormId'>):AppThunk => dispatch => {
    dispatch(modal.actions.modalShow({type:ModalTypes.FbSyncro, data: props}));
}

export const modalFbLinkShow = (props: FbForm):AppThunk => dispatch => {
    dispatch(modal.actions.modalShow({type:ModalTypes.FbLink, data: props}));
}

export const modalFbSearchPageShow = ():AppThunk => dispatch => {
    dispatch(modal.actions.modalShow({type:ModalTypes.FbSearchPage}));
}

export const modalReportingTemplateShow = (props: ReportingTemplateProps):AppThunk => dispatch => {
    dispatch(modal.actions.modalShow({type:ModalTypes.ReportingTemplate, data:props}));
}

export const modalNewCompanyAccountShow = (company_id: string|number):AppThunk => dispatch => {
    dispatch(modal.actions.modalShow({type:ModalTypes.NewCompanyAccount, data:company_id}));
}

export const modalCompanyExportEmailsShow = (company:Company):AppThunk => dispatch => {
    dispatch(modal.actions.modalShow({type:ModalTypes.CompanyExportEmails, data: company}));
}

export const modalCompanyExportEmailsGlobal = (company:Company):AppThunk => dispatch => {
    dispatch(modal.actions.modalShow({type:ModalTypes.CompanyExportEmailsGlobal, data: company}));
}

export const modalCustomRecordShow = (company:Company):AppThunk => dispatch => {
    dispatch(modal.actions.modalShow({type:ModalTypes.CompanyCustomRecord, data: company}));
}

export const modalLeadsPendingShow = (data: RefreshLeadsProps):AppThunk => dispatch => {
    dispatch(modal.actions.modalShow({type:ModalTypes.LeadsPending, data: data}));
}

export const modalDeleteCompanyShow = (data: Pick<Company, 'id'|'name'>):AppThunk => dispatch => {
    dispatch(modal.actions.modalShow({type:ModalTypes.DeleteCompany, data: data}));
}

export const modalEnableCompanyShow = (data: Pick<Company, 'id'|'name'>):AppThunk => dispatch => {
    dispatch(modal.actions.modalShow({type:ModalTypes.EnableCompany, data: data}));
}

export const modalDeleteAccountShow = (data: {company: Pick<Company, 'id'>, account: Pick<Customer, 'id'>}):AppThunk => dispatch => {
    dispatch(modal.actions.modalShow({type:ModalTypes.DeleteAccount, data: data}));
}

export const modalNewLandingShow = ():AppThunk => dispatch => {
    dispatch(modal.actions.modalShow({type:ModalTypes.NewLanding}));
}

export const modalNewLandingV2Show = ():AppThunk => dispatch => {
    dispatch(modal.actions.modalShow({type:ModalTypes.NewLandingV2}));
}

export const modalLinkLandingShow = (data: string|number):AppThunk => dispatch => {
    dispatch(modal.actions.modalShow({type:ModalTypes.LinkLanding, data: data}));
}

export const modalExportLandingFilter = (data: string|number):AppThunk => dispatch => {
    dispatch(modal.actions.modalShow({type:ModalTypes.ExportLandingFilter, data: data}));
}

export const modalChangeImgShow = (data: string|number):AppThunk => dispatch => {
    dispatch(modal.actions.modalShow({type:ModalTypes.ChangeImg, data: data}));
}

export const getModalData = (state:RootState):any|undefined => state.modal.length > 0
    ? state.modal[state.modal.length -1].data
    : null

export const isAnyModalOpened = (state: RootState):boolean => state.modal.length > 0;

export const isModalOpened = (type: ModalTypes) => (state: RootState):boolean => state.modal.length > 0
    ? type === state.modal[state.modal.length -1].type
    : false