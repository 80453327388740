import {useCallback, useEffect, useLayoutEffect, useState} from "react";
import { Accordion, Col, Container, FormSelect, Nav, Row } from "react-bootstrap";
import { useParams, useSearchParams } from "react-router-dom";
import { formSuccessRedirect } from "../../app/actions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import creationApi, { LPV2_to_API } from "../../app/services/secured/creation";
import { redirect } from "../../app/slice/app";
import { addNotification } from "../../app/slice/notifs";
import AppSecured from "../../AppSecured";
import Urls from "../../assets/js/Urls";
import { IfTrue } from "../misc/Blocks";
import { ButtonIcon } from "../misc/Buttons";
import './app.scss';
import { ModalContainer } from "./Components/Modal";
import LandingProperties from "./Components/Properties";
import { RowContainer } from "./Components/Row";
import { ContainersMenu, DesignControl, DesignElement, DesignSidebar, Icon } from "./Design";
import { delLPDraft, getIsPreview, getLandingState, getLP, getPageDesign, getView, GlobalStyle, PageSize, redoLPAction, setIsDuplicate, setIsPreview, setPageDesign, setPageSize, setPageStyle, setStep, undoLPAction, View } from "./landing-v2";
import { BackgroundImageController } from "./Tools/Background";
import { DisplayController } from "./Tools/Display";
import GenerateStyle from "./Tools/GenerateStyle";
import { MarginPadding } from "./Tools/MarginPadding";

const LPGenerator = () => {
   const { id } = useParams<{ id: string }>();
   const [searchP, setSearchP] = useSearchParams();
   const [fetchLanding, landingRes] = creationApi.useLazyGetLandingQuery();
   const isDuplicate = searchP.get('duplicate') === 'true' ? true : false;

   useEffect(() => {
      if (id) { fetchLanding({ id: parseInt(id) }) }
   }, [id])

   const dispatch = useAppDispatch();
   const lpState = useAppSelector(getLandingState);
   const design = useAppSelector(getPageDesign);

   const {
      lp: landing,
      view,
      isPreview,
      step,
      infos: lInfos,
      isDraft,
   } = useAppSelector(getLandingState);

   const containerStyle = GenerateStyle(['backgroundColor', 'backgroundImage', 'backgroundPosition', 'minHeight', 
   'display', 'alignItems', 'flexDirection', 'backgroundSize', 'justifyContent'], landing.style);

   const [saveNewLanding, saveNewLandingRes] = creationApi.useSaveLandingV2Mutation();
   const [duplicateLanding, duplicateLandingRes] = creationApi.useCreateLandingV2Mutation();

   // notif save
   useEffect(() => {
      if (saveNewLandingRes.isSuccess) {
         dispatch(addNotification({ type: 'success', message: 'Succès de la modification.' }))
         localStorage.removeItem('landing');
      }
   }, [saveNewLandingRes.isSuccess]);

   // duplicate
   dispatch(formSuccessRedirect({
      redirectUrl: duplicateLandingRes.data && Urls.landing.editv2(duplicateLandingRes.data.id as number),
      data: duplicateLandingRes,
      message: "Succès duplication de la LP."
   }));

   // keyboard shortcuts
   const handleShortcuts = useCallback((e: KeyboardEvent) => {
      if ((e.metaKey || e.ctrlKey) && e.shiftKey && e.key === 'Z') {
         dispatch(redoLPAction())
      } else if ((e.metaKey || e.ctrlKey) && e.key === 'z') {
         dispatch(undoLPAction());
      }
   }, []);

   useEffect(() => {
      document.addEventListener('keydown', handleShortcuts);

      return () => {
         document.removeEventListener('keydown', handleShortcuts);
      }
   }, [handleShortcuts]);

   useLayoutEffect(() => {
      if (isDuplicate) {
         dispatch(setIsDuplicate(true));
         dispatch(setStep('info'));
      }
   }, [isDuplicate])


   return <AppSecured displayMenu={false}>
      <div id='landing-v2-nav'>
         <Row className='d-flex justify-content-between align-items-center'>
            <Col md={6} className='d-flex'>
               <ButtonIcon code='arrow-left' variant="secondary" size='sm' onClick={() => {
                  dispatch(redirect(Urls.landing.list));
               }}>
                  Retour
               </ButtonIcon>

               <Nav className='nav-pills ms-3' as={"h5"}>
                  <Nav.Item onClick={() => dispatch(setStep('landing'))}>
                     <Nav.Link active={step === 'landing'}>Landing Page</Nav.Link></Nav.Item>
                  <Nav.Item onClick={() => dispatch(setStep('modal'))}>
                     <Nav.Link active={step === 'modal'}>Modal</Nav.Link></Nav.Item>
                  <Nav.Item onClick={() => dispatch(setStep('info'))}>
                     <Nav.Link active={step === 'info'}>Infos</Nav.Link></Nav.Item>
               </Nav>
            </Col>
            <Col md={2} className='d-flex'>
               <ButtonIcon code="arrow-counterclockwise" size='lg' variant="" onClick={() => dispatch(undoLPAction())} />
               <ButtonIcon code="arrow-clockwise" size='lg' variant="" onClick={() => dispatch(redoLPAction())} />
            </Col>
            <Col md={4} className='d-flex justify-content-end'>
               {isDraft && <>
                  <div id='btn-lp-draft' className='btn' onClick={() => {
                     const id = lInfos.id;
                     delLPDraft(id);
                     fetchLanding({ id });
                  }}>
                     <Icon code='easel2' className='me-1' />
                     <span>Brouillon</span>
                  </div>
               </>}

               <ButtonIcon variant='info' size='sm' className="ms-1" onClick={() => { dispatch(setIsPreview(!isPreview)); }}
                  code={!isPreview ? 'eye' : 'pencil'}>{isPreview ? 'Éditer' : 'Prévisualiser'}</ButtonIcon>

               <ButtonIcon variant='success' code='globe' size='sm' className='ms-1'
                  onClick={() => {
                     !isDuplicate
                        ? saveNewLanding({ id: lInfos.id, landing: lpState })
                        : duplicateLanding(LPV2_to_API(lpState))
                  }}>
                  {!isDuplicate ? 'Publier' : 'Dupliquer'}
               </ButtonIcon>

               <IfTrue condition={!isDuplicate}>
                  <ButtonIcon variant='info' size='sm' className="ms-1" target="_blank"
                     href={'//' + lInfos.site.domain + '/lp/' + lInfos.code + '.html'} code='person-workspace'>LP</ButtonIcon>
               </IfTrue>
            </Col>
         </Row>
      </div>

      <Container fluid className='p-0'>
         <Row id='global-row' className='m-0'>

            {!isPreview && (
               <Col sm={2} id='tools' className={view == 'info' ? 'd-none' : ''} style={{ position: 'relative' }}>
                  <DesignSidebar />
               </Col>
            )}

            <Col sm={isPreview || view == 'info' ? 12 : 10} className='p-0'>
               {step === 'landing' && <>
                  <div id='landing-wrapper' style={containerStyle}>
                     <div style={design.padding ? { padding: design.padding } : {}}>
                        {landing.childs.map((b, i) => {
                           return <RowContainer key={`container-${i}`} index={i} size={landing.childs.length} />
                        })}
                     </div>
                  </div>
                  <HideInPreview className='pe-4 ps-4'>
                     <ContainersMenu step='landing' />
                  </HideInPreview>
               </>}
               {step === 'modal' && <>
                  <div id='modal-wrapper'>
                     <Container style={{ background: '#000000AA' }}>
                        <ModalContainer />
                     </Container>
                  </div>
               </>}
               {step === 'info' && <>
                  <LandingProperties />
               </>}
            </Col>
         </Row>
      </Container>
   </AppSecured>
}

export const HideInPreview: React.FC<{ children?: any, className?: string, }> = ({ children, className }) => {
   const preview = useAppSelector(getIsPreview);
   return <div className={className}>{!preview && children}</div>
}

export const HideInViews: React.FC<{ children?: any, views: Array<View> }> = ({ views, children }) => {
   const view = useAppSelector(getView);
   return <>{!views.includes(view) && children}</>
}

export const PageDesign = () => {
   const dispatch = useAppDispatch();
   const design = useAppSelector(getPageDesign);
   const landing = useAppSelector(getLP);

   return <>
      <h2 id='tools-title'>Page</h2>
      <div id='tools-wrapper'>
         <Accordion className='design-accordion'>
            <DesignElement eventKey="1" title='Largeur &amp; hauteur'>
               <DesignControl title='Largeur'>
                  <FormSelect defaultValue={landing.size}
                     onChange={(e) => dispatch(setPageSize(e.target.value as PageSize))}>
                     <option value='container'>Conteneur</option>
                     <option value='fluid'>Pleine page</option>
                  </FormSelect>
               </DesignControl>
               <DesignControl title='Hauteur'>
                  <FormSelect defaultValue={design.minHeight}
                     onChange={(e) => {
                        dispatch(setPageDesign({ option: 'minHeight', value: e.target.value }))
                     }}>
                     <option value=''>Contenu</option>
                     <option value='100vh'>Plein écran</option>
                  </FormSelect>
               </DesignControl>
            </DesignElement>
            <DesignElement eventKey="2" title="Fond">
               {design && <>
                  <BackgroundImageController option="backgroundColor" design={design}
                     onChange={(style: GlobalStyle) => dispatch(setPageStyle(style))} />
               </>}
            </DesignElement>
            <DesignElement eventKey="3" title="Marge interne">
               <MarginPadding init={landing.style?.padding ? landing.style.padding : ''} option='Espace interne (padding)' className='mt-2'
                  callback={(value: string) => {
                     dispatch(setPageDesign({ option: 'padding', value: value }))}
                  } />
            </DesignElement>
            <DesignElement eventKey="4" title='Display'>
               <DisplayController style={design} onChange={(style: GlobalStyle) => dispatch(setPageStyle(style))} />
            </DesignElement>
         </Accordion>
      </div>
   </>
}

export default LPGenerator;