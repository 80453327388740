import { useCallback, useEffect, useState } from "react";
import { Button, FormControl, ListGroup } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { DesignControl, DesignElement, Icon } from "../../Design";
import { getCurrentContent, GlobalStyle, LandingV2, setContent, setContentDesign } from "../../landing-v2";
import ColorPicker from "../../Tools/ColorPicker";

export const CTAContent: React.FC<{ index: number, isEdit: boolean, node: LandingV2 }> = ({ index, isEdit, node }) => {
   const [edit, setEdit] = useState(false);
   const dispatch = useAppDispatch();

   const style: GlobalStyle = {};
   style.backgroundColor = node.style.backgroundColor ? node.style.backgroundColor : '#000000';
   style.color = node.style.color ? node.style.color : '#FFFFFF';
   style.border = node.style.border ? node.style.border : '1px solid #0000000';
   style.padding = node.style.padding ? node.style.padding : '0.5rem 1rem';
   style.fontSize = node.style.fontSize ? node.style.fontSize + 'px' : '16px';

   return <>
      {
         (!edit) ? <>
            <Button style={style} onClick={() => setEdit(true)}>
               {node.attributes?.cta ? node.attributes?.cta : 'CTA'}
            </Button>
         </>
            : <div className='p-2'>
               <FormControl type='text' placeholder='Texte CTA' defaultValue={node.attributes?.cta}
                  onBlur={(e) => dispatch(setContent({ index, node: { ...node, attributes: { ...node.attributes, cta: e.target.value } } }))} />

                  <div className='d-flex'>
                     <FormControl type='text' placeholder='Lien' defaultValue={node.value}
                        onBlur={(e) => dispatch(setContent({ index, node: { ...node, value: e.target.value } }))} />
                     <Button onClick={() => setEdit(false)}>Valider</Button>
                  </div>
            </div>
      }
   </>
}

export const CTADesign: React.FC = () => {

   const dispatch = useAppDispatch();
   const cta = useAppSelector(getCurrentContent);

   const [borderPx, setBorderPx] = useState('3');
   const [borderColor, setBorderColor] = useState('#000000');

   useEffect(() => {
      if (cta?.style.border) {
         setBorderPx(cta?.style.border.split('px')[0].trim());
         setBorderColor(cta?.style.border.split('solid')[1].trim());
      }
   }, []);

   const updateBorderCallback = useCallback(() => {
      dispatch(setContentDesign({ option: 'border', value: borderPx + 'px solid ' + borderColor }));
   }, [borderPx, borderColor]);

   const updateBorderPx = (px: string) => {
      setBorderPx(px);
      updateBorderCallback();
   }

   const updateBorderColor = (color: string) => {
      setBorderColor(color);
      updateBorderCallback();
   }

   return <>
      <DesignElement eventKey='3' title="Aspect">
         <ListGroup horizontal className='w-100 mb-2'>
            <ListGroup.Item action className='text-center' active={!cta?.style.textAlign || cta?.style.textAlign === 'start'}
               onClick={() => dispatch(setContentDesign({ option: 'textAlign', value: 'start' }))}>
               <Icon code='align-start' />
            </ListGroup.Item>
            <ListGroup.Item action className='text-center' active={cta?.style.textAlign === 'center'}
               onClick={() => dispatch(setContentDesign({ option: 'textAlign', value: 'center' }))}>
               <Icon code='align-center' />
            </ListGroup.Item>
            <ListGroup.Item action className='text-center' active={cta?.style.textAlign === 'end'}
               onClick={() => dispatch(setContentDesign({ option: 'textAlign', value: 'end' }))}>
               <Icon code='align-end' />
            </ListGroup.Item>
         </ListGroup>

         <div className='d-flex mt-3 mb-1'>
            <div>
               <DesignControl title='Texte'>
                  <FormControl type='number' min={1} step={1} defaultValue={cta?.style.fontSize ? cta.style.fontSize : '16'} size='sm'
                     onChange={(e) => dispatch(setContentDesign({ option: 'fontSize', value: e.target.value }))} />
               </DesignControl>
            </div>
            <div className='ms-1'>
               <DesignControl title='Bordure'>
                  <FormControl type='number' value={borderPx} min={0} size='sm'
                     onChange={(e) => {
                        updateBorderPx(e.target.value);
                     }} />
               </DesignControl>
            </div>
         </div>

         <ColorPicker value={cta?.style.backgroundColor ? cta.style.backgroundColor : '#000000'} title='Fond'
            onChange={(color: string) => {
               dispatch(setContentDesign({ option: 'backgroundColor', value: color }));
            }} />
         <ColorPicker value={cta?.style.color ? cta.style.color : '#FFFFFF'} title='Texte' onChange={(color: string) => {
            dispatch(setContentDesign({ option: 'color', value: color }));
         }} />
         <ColorPicker value={borderColor} title='Bordure' onChange={updateBorderColor} />
      </DesignElement>
   </>
}