import { Col, Form, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ReportingType } from "../../types";
import { Icon } from "../misc/Buttons";
import { getReportingParams, ParamOptions, setData, setDimensions, setStats } from "./reporting";

const ReportingOptions: React.FC = props => {
    const dispatch = useAppDispatch();
    const params = useAppSelector(getReportingParams);
    return <div key={Object.keys(params).length}>
        <Row className='d-flex align-items-start'>
            <Col md={3}>
                <h6><Icon code='search' /> Dimensions de recherche</h6>
                <Row>
                    {Object.entries(params.search).map(s => 
                        <CheckOption colSize={12} key={Math.random()}
                            param={s[0] as keyof ReportingType} options={s[1]}
                            setOption={(options) => {
                                dispatch(setDimensions({ ...params.search, [`${s[0]}`]: options }))
                            }}
                        />
                    )}
                </Row>
            </Col>
            <Col>
                <h6><Icon code='calculator' /> Données calculées</h6>
                <Row>
                    {Object.entries(params.data).map(s =>
                        <CheckOption colSize={6} key={Math.random()}
                            param={s[0] as keyof ReportingType} options={s[1]}
                            setOption={(options) => {
                                dispatch(setData({ ...params.data, [`${s[0]}`]: options }))
                            }}
                        />
                    )}
                </Row>
                <h6 className='mt-4'><Icon code='bar-chart' /> Statistiques</h6>
                <Row>
                    {Object.entries(params.stats).map(s =>
                        <CheckOption colSize={6} key={Math.random()}
                            param={s[0] as keyof ReportingType} options={s[1]}
                            setOption={(options) => {
                                dispatch(setStats({ ...params.stats, [`${s[0]}`]: options }))
                            }}
                        />
                    )}
                </Row>
            </Col>
        </Row>
    </div>
}


const CheckOption: React.FC<{ setOption: (options: ParamOptions) => void, colSize: number, param: keyof ReportingType, options: ParamOptions }> = props => {
    const id = Math.random().toString();

    return <Col md={props.colSize}>
        <div className='option-wrapper '>
            <Form.Check id={id} type='switch' name={props.param} checked={props.options.active}
                onChange={(e) => props.setOption({ ...props.options, active: e.target.checked })}
            />

            <Form.Label htmlFor={id} className='m-0'>
                <div className='d-flex align-items-center justify-content-between'>
                    <div>{props.options.label}</div>
                    <Icon code={props.options.icon} />
                </div>
            </Form.Label>
        </div>
    </Col>
}

export default ReportingOptions;