import { useState } from "react";
import { Badge, FormControl, Nav } from "react-bootstrap";
import creationApi from "../../app/services/secured/creation";
import AppSecured from "../../AppSecured";
import Urls from "../../assets/js/Urls";
import { FormSearchParams, GeneleadForm } from "../../types";
import { PageHeader } from "../App/PageHeader";
import { LoadingBlock } from "../misc/Blocks";
import { ButtonIcon, Icon } from "../misc/Buttons";
import { PaginationGenelead } from "../misc/Pagination";
import SimpleForm from "../misc/SimpleForm";
import TableGenelead, { RowNoData } from "../misc/Table";

const FormList = () => {
    const [search, setSearch] = useState<FormSearchParams>({ page: 1, statuses: 'online' });
    const forms = creationApi.useGetFormListQuery(search);
    return <>
        <AppSecured>
            <PageHeader action={
                <ButtonIcon href={Urls.forms.edit('new')} variant='primary' code='plus-circle' size='sm'>
                    Nouveau formulaire
                </ButtonIcon>}>
                <Icon code='search' className='fs-20 me-2' />
                <SimpleForm onSubmit={(query) => setSearch({ ...search, query: query.keyword as string })}>
                    <FormControl placeholder='Recherche mot clé' name='keyword' />
                </SimpleForm>
            </PageHeader>

            <div className='d-flex justify-content-between align-items-center'>
                <div>
                    <LoadingBlock data={forms}>
                        {forms.isSuccess && <>
                            <PaginationGenelead 
                                size='sm' className='m-0'
                                search={(page: number) => setSearch({ ...search, page: page })}
                                 pages={{
                                    current: search.page,
                                    total: forms.data.total_pages
                                }} />
                        </>}
                    </LoadingBlock>
                </div>
                <div>
                    <Nav className='nav-tabs' defaultActiveKey={search.statuses}>
                        <Nav.Item><Nav.Link eventKey={'online'} onClick={() => setSearch({ ...search, statuses: 'online', page: 1 })}>En ligne</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey={'testing'} onClick={() => setSearch({ ...search, statuses: 'testing', page: 1 })}>Test</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey={'offline'} onClick={() => setSearch({ ...search, statuses: 'offline', page: 1 })}>Hors ligne</Nav.Link></Nav.Item>
                    </Nav>
                </div>
            </div>

            <LoadingBlock data={forms}>
                {
                    forms.isSuccess && <>
                        <TableGenelead hasActions={true} header={[
                            { name: 'Formulaire', icon: 'ui-radios' },
                            { name: 'code', icon: 'qr-code', align: 'center' },
                            { name: 'LP Link', icon: 'grid-1x2', align:'center' },
                            { name: 'type', icon: 'file', width:'10%', align:'center' },
                            { name: 'Champs', icon: 'input-cursor-text', width: '40%' },
                        ]}>
                            <RowNoData colspan={5} list={forms.data.list} />
                            {forms.data.list.map(f => <FormRow key={f.id} {...f} />)}
                        </TableGenelead>

                        <PaginationGenelead search={(page: number) => setSearch({ ...search, page: page })} pages={{
                            current: search.page,
                            total: forms.data.total_pages
                        }} />
                    </>
                }
            </LoadingBlock>
        </AppSecured>
    </>
}

const FormRow: React.FC<GeneleadForm> = form => {
    return <tr>
        <td><Badge bg='secondary'>{form.id}</Badge> {form.name} </td>
        <td className='text-center'>
            {form.code}
        </td>
        <td className={'text-center fs-24'}>
            {form.hasLanding ? <Icon code='file-check' /> : <Icon code='file-earmark-x' />}</td>
        <td>
            <div className='text-center d-flex flex-column align-items-center'>
                <small>{form.family.code}</small>
                {form.mandatory_opt_in ? <Badge bg='success'>OPT-IN</Badge> : <Badge bg='danger'>OPT-IN</Badge>}
            </div>
        </td>
        <td>
            {form.fields.length > 0 &&
                form.fields.map(f =>
                    <Badge key={Math.random()} className={'me-1 mt-1 ' + (f.mandatory ? 'alt-primary' : 'alt-secondary')}>
                        {f.field.title.substring(0, 100)}
                    </Badge>
                )}
        </td>
        <td className='text-end'>
            <ButtonIcon href={Urls.forms.edit(form.id.toString())} code='pen' variant='primary' size='lg' className='alt-primary' />
        </td>
    </tr>
}

export default FormList;