import { Button, Col, Container, FormCheck, FormControl, FormLabel, ListGroup, Nav, Row, Tab, TabContainer } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { LandingQuizzAnswers } from "../../../types";
import { Icon } from "../../misc/Buttons";
import { RichEditor } from "../../misc/TinymceEditor";
import {
    addAnswer, addQuestion, deleteAnswer, deleteQuestion, getQuestion, getQuizz, getQuizzEnd, getQuizzKey, getQuizzLength,
    moveQuestion, setAnswer, setQuestion, setQuestionIsEdit, setQuizzEnd, setQuizzKey
} from "../landing-v2";
import { HideInPreview } from "../LPGenerator";

export const QuizzContent: React.FC<{ isEdit: boolean }> = ({ isEdit }) => {
    const dispatch = useAppDispatch();
    const quizz = useAppSelector(getQuizz);
    const eKey = useAppSelector(getQuizzKey);
    const thanksIndex = quizz?.length ? quizz.length + 1 : 0;
    const thanksContent = useAppSelector(getQuizzEnd);
    return <>
        <TabContainer key={eKey} defaultActiveKey={eKey ? eKey : 1}>
            <Nav className='nav-pills' fill>{quizz?.map((q, i) =>
                <Nav.Item key={Math.random()} style={{ cursor: 'pointer' }}>
                    <Nav.Link eventKey={i + 1} onClick={() => dispatch(setQuizzKey((i + 1).toString()))}>{i + 1}</Nav.Link>
                </Nav.Item>)}
                <HideInPreview>
                <Nav.Item>
                    <Button className='ms-2' variant='success' onClick={() => dispatch(addQuestion())}>+ Question</Button>
                </Nav.Item>
                </HideInPreview>
                <Nav.Item>
                    <Nav.Link eventKey={thanksIndex} onClick={() => dispatch(setQuizzKey(thanksIndex.toString()))}>Remerciement</Nav.Link>
                </Nav.Item>
            </Nav>
            <Tab.Content>
                {quizz?.map((q, i) => <Tab.Pane key={`quizz-pane-${i}`} eventKey={(i + 1).toString()}>
                    <QuizzNode index={i} />
                </Tab.Pane>
                )}
                <Tab.Pane eventKey={thanksIndex} style={{minHeight:'300px'}} className='p-2'>
                    <RichEditor initVal={
                        thanksContent ? thanksContent : '<h1>Merci!</h1><h2>Score: {score}</h2>'
                    }
                        onBlur={(html) => dispatch(setQuizzEnd(html)) }
                    />
                </Tab.Pane>
            </Tab.Content>
        </TabContainer>
    </>
}

export const QuizzNode: React.FC<{ index: number }> = ({ index }) => {
    const dispatch = useAppDispatch();
    const question = useAppSelector(getQuestion(index));
    return <div className='mt-2 mb-3' style={{ padding: '20px' }}>
        {question?.isEdit && <QuizzActions questionIndex={index} />}

        {
            question?.isEdit ? <>
                <FormLabel>Question</FormLabel>
                <FormControl as="textarea" placeholder='Question' defaultValue={question?.question} onBlur={(e) => dispatch(setQuestion({ qIndex: index, option: 'question', value: e.target.value }))} />

                <FormLabel>Explication</FormLabel>
                <FormControl as="textarea" placeholder='Explication' defaultValue={question?.explanation} onBlur={(e) => dispatch(setQuestion({ qIndex: index, option: 'explanation', value: e.target.value }))} />
            </>
                : <>
                    <div className='d-flex align-items-center'>
                        <h5 className='m-0'>{question?.question}</h5>
                        <HideInPreview>
                            <Button variant='' onClick={() => dispatch(setQuestionIsEdit({ questionIndex: index, isEdit: true }))}>
                                <Icon code='pencil' /></Button>
                        </HideInPreview>
                    </div>

                    <HideInPreview>
                        <small>{question?.explanation}</small>
                    </HideInPreview>
                </>
        }

        {/* <h6>Réponses</h6> */}
        <Container className='mt-2'>
            <Row>
                <QuizzWrapper isEdit={question?.isEdit ? true : false}>
                    {question?.answers.map((a, i) => <QuizzAnswer isEdit={question.isEdit ? true : false} key={`quizz-answer-${i}`} answer={a}
                        onChange={(answer) => {
                            dispatch(setAnswer({
                                nodeIndex: index,
                                answerIndex: i,
                                answer
                            }))
                        }}

                        onDelete={() => {
                            dispatch(deleteAnswer({ nodeIndex: index, answerIndex: i, }))
                        }}
                    />)}
                </QuizzWrapper>
                {question?.isEdit && <Button onClick={() => dispatch(addAnswer({ nodeIndex: index }))}>+ Réponses</Button>}
            </Row>
        </Container>
    </div>
}

const QuizzWrapper: React.FC<{children?: any, isEdit: boolean }> = ({ isEdit, children }) => {
    return <>
        {isEdit ? children : <ListGroup>{children}</ListGroup>}
    </>
}

type QuizzAnswerProps = {
    answer: LandingQuizzAnswers,
    onChange: (answer: LandingQuizzAnswers) => void,
    onDelete: () => void,
    isEdit: boolean
}

export const QuizzAnswer: React.FC<QuizzAnswerProps> = ({ answer, onChange, isEdit, onDelete }) => {
    const dispatch = useAppDispatch();
    return <>
        {isEdit
            ? <>
                <Col sm={12} className='mb-2'>
                    <Row className='d-flex '>
                        <Col sm={10}>
                            <FormControl type='text' defaultValue={answer.answer}
                                onBlur={(e) => onChange({ ...answer, answer: e.target.value })} />

                            <small className='text-muted mt-1'>
                                <FormCheck label='Valide' defaultChecked={answer.good}
                                    onChange={(e) => onChange({ ...answer, good: e.target.checked })} />
                            </small>
                        </Col>
                        <Col sm={2}>
                            <div className='d-flex justify-content-end'>
                                <Button size='sm' variant='danger' onClick={() => onDelete()}><Icon code='x' /></Button>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </>
            : <>
                <ListGroup.Item action>
                    {answer.answer}
                </ListGroup.Item>
            </>
        }
    </>
}

export const QuizzActions: React.FC<{ questionIndex: number }> = ({ questionIndex }) => {
    const dispatch = useAppDispatch();
    const quizzLength = useAppSelector(getQuizzLength);
    const moveQ = (direction: number) => {
        dispatch(moveQuestion({ questionIndex, direction }));
        dispatch(setQuizzKey((questionIndex + 1 + direction).toString()));
    }
    return <div className='d-flex align-items-center justify-content-end mb-1'>
        <div className='d-flex justify-content-end align-items-center'>
            <div className='d-flex align-items-center field-actions'>
                <Button size='sm' variant='' onClick={() => dispatch(deleteQuestion({ questionIndex }))}>
                    <Icon code='trash' /></Button>
                {quizzLength && quizzLength > 0 && <>
                    <Button size='sm' variant='' onClick={() => moveQ(-1)}>
                        <Icon code='chevron-left' /></Button>
                </>}
                {quizzLength && questionIndex + 1 < quizzLength && <>
                    <Button size='sm' variant='' onClick={() => moveQ(1)}>
                        <Icon code='chevron-right' /></Button>
                </>}
            </div>
            <div className='ms-2'>
                <Button size='sm' variant='primary' onClick={() => dispatch(setQuestionIsEdit({ questionIndex, isEdit: false }))}>
                    <Icon code='check' /></Button>
            </div>
        </div>
    </div>
}