import { useCallback, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import FormRange from "react-bootstrap/esm/FormRange";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { Icon } from "../Design";
import useClickOutside from "./useClickOutside";

type ColorPickerProps = {
   value: string | undefined,
   title: string,
   onChange: (color: string) => void,
   hasOpacity?: boolean,
   className?: string,
}

export const ColorPicker: React.FC<ColorPickerProps> = ({ value, title, onChange, className, hasOpacity }) => {

   const popover = useRef(null);
   const [isOpen, toggle] = useState(false);
   const [color, setColor] = useState('');
   const [opacity, setOpacity] = useState('');

   // init conversion
   useEffect(() => {
      if (value) {
         if (value.substring(0, 4) === 'rgba') {
            const rgbaObj = RGBAtoHex(value);
            setColor(rgbaObj.hex);
            setOpacity(rgbaObj.opacity);
         } else {
            setColor(value);
         }
      }
   }, []);

   // events
   const close = useCallback(() => toggle(false), []);
   useClickOutside(popover, close);

   const updateColor = (newColor: string) => {
      setColor(newColor);
   }

   const updateOpacity = (opacity:string) => {
      setOpacity(opacity);
   }

   useEffect(() => {
      if (color) {
         onChange(color);
      }
   }, [color]);

   useEffect(() => {
      if (opacity) {
         onChange(hexToRgbA(color, opacity) as string);
      }
   }, [opacity])

   return <div className='mb-3'>
      <div><b>{title}</b></div>
      <div className='d-flex align-items-start'>
         <div className='picker'>
            <span className='text-muted fs-12'>Couleur</span>
            <div
               className="swatch"
               style={{ backgroundColor: value }}
               onClick={() => toggle(true)}
            />

            {isOpen && (
               <div className='popover' ref={popover}>
                  <HexColorPicker
                     color={color}
                     onChange={updateColor}
                  />
                  <HexColorInput placeholder="#000000" color={color} onChange={setColor} />
               </div>
            )}
         </div>
         {hasOpacity && (
            <div>
               <span className='text-muted fs-12'>Opacité</span>
               <FormRange min={0} max={1} step={0.1} className='ms-2' value={opacity}
                  onChange={(e) => {
                     updateOpacity(e.target.value);
                  }} />
            </div>
         )}
         <Button variant='' onClick={() => onChange('rgba(255, 255, 255, 0)')}><Icon code='x-square' /></Button>
      </div>
   </div>
}

const RGBAtoHex = (color: string): { hex: string, opacity: string } => {
   if (color.startsWith('rgba')) {
      let color2 = color.substring(5).split(")")[0].split(',');

      let r = (+color2[0]).toString(16),
         g = (+color2[1]).toString(16),
         b = (+color2[2]).toString(16),
         a = color2[3];

      if (r.length == 1) { r = "0" + r; }
      if (g.length == 1) { g = "0" + g; }
      if (b.length == 1) { b = "0" + b; }

      return {
         hex: "#" + r + g + b,
         opacity: a
      }
   }

   return {
      hex: color,
      opacity: ''
   };
}

const hexToRgbA = (color: string, opacity: string) => {
   if (color.length === 7) {
      var c: any;
      if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(color)) {
         c = color.substring(1).split('');
         if (c.length == 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
         }
         c = '0x' + c.join('');
         return 'rgba(' + [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',') + ',' + opacity + ')';
      }
      throw new Error('Bad Hex');
   }
}

export default ColorPicker;