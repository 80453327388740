import { useEffect, useState } from "react";
import { Button, NavLink } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { Navigate } from "react-router-dom";
import { ReduxRtkApiResult } from "../../types";

type BootstrapVariants = "primary" | "secondary" | "warning" | "danger" | "info" | "success";

type DataBlockButtonProps = {
    data: ReduxRtkApiResult,
    children?: any,
    type?: "submit" | "button",
    variant?: BootstrapVariants,
    className?: string,
    onClick?: any,
    icon?: string | undefined
    disabled?: boolean,
    size?: "sm" | "lg",
    redirect?: string
};

// LoadingButton : Button qui affiche un loader tant que le data load
export const LoadingButton: React.FC<DataBlockButtonProps> = (props) => {
    return <Button type={props.type} variant={props.variant} className={props.className} onClick={props.onClick} disabled={props.disabled}>
        {
            props.data.isLoading || props.data.isFetching
                ? <>
                    <span className="spinner-border spinner-border-sm"></span>
                    <span className="ms-1"><FormattedMessage id="pleaseWait" /></span>
                </>
                : props.children
        }
    </Button>
}

type IconProps = {
    code: string,
    className?: string
}

export const Icon: React.FC<IconProps> = (props) => {
    return <span className={`bi bi-${props.code} ${props.className}`} />
}


export const BtnIconLoad: React.FC<DataBlockButtonProps> = (props) => {
    const onClick = props.onClick;
    const [disabled, setDisabled] = useState(false);

    useEffect(() => {
        if (props.data.isLoading || props.data.isFetching || props.disabled) {
            setDisabled(true);
        } else {
            setDisabled(false);
        }
    }, [props.data.isLoading, props.data.isFetching, props.disabled])

    return <>
        <Button size={props.size} type={props.type} variant={props.variant} className={props.className} onClick={onClick} disabled={disabled}>
            {
                props.data.isLoading || props.data.isFetching
                    ? <>
                        <span className="spinner-border spinner-border-sm"></span>
                        <span className="ms-1"><FormattedMessage id="pleaseWait" /></span>
                    </>
                    : <div className='d-flex align-items-center justify-content-center'><Icon code={props.icon as string} className='me-2'/>{props.children}</div>
            }
            {
                props.data.isSuccess && props.redirect && <Navigate to={props.redirect} />
            }
        </Button>
    </>
}

type ButtonIconProps = {
    code: string,
    href?: string,
    size: "sm" | "lg",
    className?: string,
    variant: "primary" | "danger" | "secondary" | "warning" | 'info' | '' | 'outline-primary' | 'success' | 'outline-secondary',
    onClick?: (e?:any) => void,
    children?: any,
    target?: string,
    disabled?: boolean,
}

export const ButtonIcon: React.FC<ButtonIconProps> = (props) => {
    const icon = <Icon code={props.code} />

    return props.href
        ? <NavLink target={props.target} href={props.href} className={`btn-icon btn-navlink btn btn-${props.size} btn-${props.variant} ${props.className}`} disabled={props.disabled}>
            {icon}
            { props.children && <span className='btn-label'>{props.children}</span> }
        </NavLink>
        : <Button className={props.className + ' btn-icon'} variant={props.variant} size={props.size} onClick={props.onClick} disabled={props.disabled}>
            {icon}
            {props.children && <span className='btn-label'>{props.children}</span> }
        </Button>
}

export const CancelButton: React.FC<{children?: any, link: string, className?: string }> = ({ link, children, className }) => {
    return <><NavLink
        href={link}
        className={`me-1 btn-cancel btn btn-sm btn-secondary ${className}`}>{children || "Retour"}</NavLink>
    </>
}

export const ActionButton:React.FC<ButtonIconProps > = () => {
    return <>
    </>
}