import { useEffect, useState } from "react"
import {Button, Col, Form, Modal, Row} from "react-bootstrap"
import { Typeahead } from "react-bootstrap-typeahead"
import { useAppSelector } from "../../app/hooks"
import { getModalData, isAnyModalOpened, isModalOpened, ModalTypes } from "../../app/slice/modal"
import {IfTrue, LoadingBlock} from "../misc/Blocks"
import SimpleForm from "../misc/SimpleForm"
import ModalCloseButton from "../Modals/ModalCloseButton"
import globalApi from "../../app/services/secured/global";
import DatePicker from "../misc/DatePicker";
import {SimpleDateInit} from "../misc/DatePeriod";
import ApiUrls from "../../assets/js/ApiUrls";
import {StorageManager} from "../../app/services/storage";
import {ButtonIcon} from "../misc/Buttons";

type ListEntry = {
    id: number;
    name: string;
}

const ModalExportLandingFilter = () => {
    const show= useAppSelector(isAnyModalOpened);
    const isOpened= useAppSelector(isModalOpened(ModalTypes.ExportLandingFilter));
    const formId = useAppSelector(getModalData);

    const [getCompanies, companiesReq] = globalApi.useLazyGetCompaniesListQuery();
    const [ companies, setCompanies ] = useState<Array<ListEntry>>([]);
    const [getDatabases, databasesReq] = globalApi.useLazyGetDatabasesListQuery();
    const [ databases, setDatabases ] = useState<Array<ListEntry>>([]);

    const [range, setRange] = useState<Array<string>>([SimpleDateInit.from, SimpleDateInit.to]);
    const [downloadLink, setDownloadLink] = useState<URL>();

    const [selectedCompany, setSelectedCompany] = useState<ListEntry | null>(null);
    const [selectedDatabase, setSelectedDatabase] = useState<ListEntry | null>(null);

    const generateLink = () => {
        const link = new URL(ApiUrls.leads.downloads("xlsx", StorageManager.get()), "https://app.genelead.fr");
        link.searchParams.set("form", formId);
        link.searchParams.set("from", range[0]);
        link.searchParams.set("to", range[1]);

        if (selectedCompany){
            link.searchParams.set("excluded_company", selectedCompany.id.toString());
        }
        if (selectedDatabase){
            link.searchParams.set("excluded_database", selectedDatabase.id.toString());
        }

        setDownloadLink(link);
    }

    useEffect(() => {
        if (formId && isOpened) {
            getCompanies();
            getDatabases();
        }
    }, [ formId, isOpened ])

    useEffect(() => {
        if (companiesReq.isSuccess) {
            setCompanies(companiesReq.data);
        }
        if (databasesReq.isSuccess) {
            setDatabases(databasesReq.data);
        }
    }, [ companiesReq, databasesReq ])

    useEffect(() => {
        if(downloadLink){
            setDownloadLink(undefined);
        }
    }, [selectedCompany, selectedDatabase, range]);

    return <IfTrue condition={isOpened && show}>
        <Modal size='lg' show={show}>
            <SimpleForm onSubmit={( data ) => {}}>
                <Modal.Header>
                    Exports des leads - Vue filtre
                </Modal.Header>
                <Modal.Body>
                    <Form.Group as={Row}>
                        <Form.Label column sm={"9"} className={"d-flex align-items-center"}>Format du fichier d'export</Form.Label>
                        <Col sm={"3"} className={"d-flex align-items-center justify-content-end"}>
                            <p className={"mb-0 text-end"}>Xlsx</p>
                        </Col>
                    </Form.Group>
                    <Form.Group as={Row} className={"mt-2"}>
                        <Form.Label column sm={"5"} className={"lh-sm"}>Période
                            <br/><span className={"small"}><i className="bi bi-exclamation-diamond"></i> Export <b>limité à 10 000 leads</b></span>
                        </Form.Label>
                        <Col sm={"7"} className={"d-flex align-items-center"}>
                            <DatePicker onChange={(from, to) => setRange([from, to]) } className={"w-100"} />
                        </Col>
                    </Form.Group>
                    <LoadingBlock data={companiesReq}>
                        {
                            companies && <Form.Group as={Row} className={"mt-2"}>
                                <Form.Label column sm={"5"}>Exclure les leads d'un client</Form.Label>
                                <Col sm={"7"}>
                                    <Typeahead
                                        id="select-company"
                                        placeholder="Client"
                                        options={companies || []}
                                        labelKey={"name"}
                                        onChange={(selectedOptions) => {
                                            setSelectedCompany(selectedOptions[0] as ListEntry || null);
                                        }}
                                    />
                                </Col>
                            </Form.Group>
                        }
                    </LoadingBlock>
                    <LoadingBlock data={databasesReq}>
                        {
                            databases && <Form.Group as={Row} className={"mt-2"}>
                                <Form.Label column sm={"5"}>Exclure les leads d'une base email</Form.Label>
                                <Col sm={"7"}>
                                    <Typeahead
                                        id="select-company"
                                        placeholder="Base"
                                        options={databases || []}
                                        labelKey={"name"}
                                        onChange={(selectedOptions) => {
                                            setSelectedDatabase(selectedOptions[0] as ListEntry || null);
                                        }}
                                    />
                                </Col>
                            </Form.Group>
                        }
                    </LoadingBlock>
                </Modal.Body>
                <Modal.Footer>
                    <ModalCloseButton/>
                    <Button type='submit' onClick={() => generateLink()}>Générer le lien</Button>
                    {downloadLink && <ButtonIcon href={downloadLink.toString()} code={"save"} size={"sm"} variant={"success"} className={"text-center text-white"} target={"_blank"}> Télécharger</ButtonIcon>}
                </Modal.Footer>
            </SimpleForm>
        </Modal>
    </IfTrue>
}

export default ModalExportLandingFilter;