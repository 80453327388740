const fr:Record<string, string> = {
    "login": "Login",
    "error": "Erreur",
    "pleaseWait": "Veuillez patienter...",
    "customer": "client",
    "form": "formulaire",
    "input": "champ",
    "links": "liens",
    "name": "nom",
    "key": "Clé",
    "cancel": "annuler",
    "success": "succès",
    "save": "Sauvegarder",
    "verification": "Vérification",
    'close': "Fermer",
    'datepicker-today': 'Aujourd\'hui',
    'datepicker-yesterday': 'Hier',
    'datepicker-this-week': 'Cette semaine',
    'datepicker-this-month': 'Ce mois',
    'datepicker-previous-month': 'Mois dernier',
    'datepicker-this-year': 'Cette année',
    'datepicker-last-year': 'Année dernière',
    "cta": "Call-to-action",
    "form_title":"Titre formulaire",
    "title":"Titre",
    "body":"Corps",
    "subtitle":"Sous-titre",
    "excerpt":"Accroche",
    "optin_alert":"Optin Alert",
    "author":"Auteur",
    "offer": "Offre",
    "product":"Produit",
    "offer_condition":"Condition(s) de l'offre",
    "ec_terms":"Termes e-commerce",
    "promo_code":"Code promotionnel",
    "modal_title":"Titre de la modale",
    "redirect_text": "Texte de redirection",
    "redirect_link_text":"Texte de bouton de redirection",
    "form_description":"Description du formulaire",
    "form_subtitle":"Soustitre du formulaire",
    "goto_form":"Texte du bouton pour afficher le formulaire (mobile)",
    "why_title":"Pourquoi (Titre)",
    "how_title":"Comment (Titre)",
    "why":"Pourquoi",
    "how":"Comment",
    "terms":"Termes du jeu concours",
    "greeting_text":"Texte de remerciement",
    "donation_text":"Texte de donation",
    "donation_btn_text":"Texte du bouton de donation",
    "modal_name":"Nom client - a mettre en footer de la modal",
    "policy":"Politique de confidentialité",
    "testimonial_title":"Titre du témoignage",
    "testimonial":"Témoignage",
    "body2":"Corps de texte (2)",
    "body3":"Corps de texte (3)",
    "body4":"Corps de texte (4)",
    "numbers_title":"Titre de la section avec les chiffres clés",
    "numbers":"Chiffres clés",
    "offer_name":"Nom de l'offre",
    "offer_price":"Prix de l'offre",
    "offer_desc":"Description de l'offre",
    "contact_number":"Texte avant formulaire",
    "cookie":"Message pour le bandeau cookies",
    "unsubscribe":"Message de desinscription",
    "share_text":"Texte de partage",
    "post_optin_title":"Choix du titre de la section post-optin (optionnel)",
    "comeback_text":"Texte du lien de retour home",
    "redirect_url": "URL de redirection",
    "comeback_link": "URL de retour",
    "bg":"Image de fond",
    "video": "Lien vidéo",
    "donation_url":"URL de donation",
    "logo":"Logo",
    "rs":"Réseaux sociaux",
    "Text":"Texte",
    "Choice":"Choix",
    "Boolean": "Oui/Non",
    "Numeric": "Numérique",
    "Date":"Date",
    "sendinblue": "Sendinblue",
    "tawk":"Talk",
    "mailer":"Mailer",
    "lmp-sante":"LMP Santé",
    '404':'Erreur',
    'Key':'Clé unique',
    'help_text':'Texte d\'aide (optionnel)',
    "adm": "ADM",
    "socials":"Réseaux sociaux",
}

export default fr;