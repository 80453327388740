import { useState, useEffect } from "react";
import { FormControl, FormSelect, Image, InputGroup } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { getMedia } from "../../../../app/slice/media";
import { modalMediasShow } from "../../../../app/slice/modal";
import ApiUrls from "../../../../assets/js/ApiUrls";
import { LandingMedia } from "../../../../types";
import { ButtonIcon } from "../../../misc/Buttons";
import { DesignElement, DesignControl } from "../../Design";
import { Position, LandingV2, getView, getContent, setImage, setContent, getCurrentContent, setContentDesign } from "../../landing-v2";

export const ImageContent: React.FC<{ url: string, pos: Position, isEdit: boolean, code: string, node: LandingV2 }> = ({ pos, url, isEdit, code, node }) => {
   const { row, col, block } = pos;
   const dispatch = useAppDispatch();
   const selectedMedia = useAppSelector<LandingMedia>(getMedia);
   const view = useAppSelector(getView);
   const [current, setCurrent] = useState('');
   const blockContent = useAppSelector(getContent(pos));

   useEffect(() => {
      if (selectedMedia.id && current === code && block !== null)
         dispatch(setImage({ block, url: ApiUrls.front.media(selectedMedia.id.toString(), selectedMedia.extension) }))
   }, [selectedMedia])

   const isFluid = !blockContent?.style.display || blockContent?.style.display === 'fit-content' ? true : false;
   let styleIfNot = {};
   if (blockContent?.style.display === 'manual') {
      styleIfNot = { width: 'auto', height: '100%' };
   } else if (blockContent?.style.display === 'full-width') {
      styleIfNot = { width: '100%' }
   }

   return <div style={blockContent?.style.textAlign ? { textAlign: blockContent.style.textAlign, height: '100%' } : { height: '100%' }}>
      {block !== null && isEdit && view === 'block-design'
         ? <>
            {!url
               ? <div className='image-selector' style={{ backgroundImage: 'url(' + url + ')', backgroundSize: '100% auto' }}>
                  <ButtonIcon code='eyedropper' size='lg' variant="primary" onClick={() => {
                     setCurrent(code);
                     dispatch(modalMediasShow())
                  }} />
               </div>
               : <div style={{ height: '100%' }}>
                  <Image src={url} fluid={isFluid} style={styleIfNot} />
                  <div className='picker-existing-image d-flex flex-column align-items-center'>
                     <ButtonIcon code='image' size='lg' variant="primary" onClick={() => {
                        setCurrent(code);
                        dispatch(modalMediasShow())
                     }} />
                     <FormControl placeholder='Attribut Alt' className='mt-2' defaultValue={node.attributes?.alt}
                        onBlur={(e) => dispatch(setContent({ index: block, node: { ...node, attributes: { alt: e.target.value } } }))} />
                  </div>
               </div>
            }
         </>
         : <Image src={url} fluid={isFluid} style={styleIfNot} />
      }
   </div>
}

export const ImageDesign = () => {
   const dispatch = useAppDispatch();
   const content = useAppSelector(getCurrentContent);
   return <>
      <DesignElement eventKey='3' title="Design image">
         <DesignControl title='Taille'>
            <FormSelect defaultValue={!content?.style.display ? 'fit-content' : content.style.display}
               onChange={(e) => dispatch(setContentDesign({ option: 'display', value: e.target.value }))}>
               <option value='fit-content'>Contenu</option>
               <option value='full-width'>Conteneur</option>
               <option value='manual'>Manuel</option>
            </FormSelect>
         </DesignControl>
         <DesignControl title='Alignement'>
            <FormSelect defaultValue={content?.style.textAlign}
               onChange={(e) => dispatch(setContentDesign({ option: 'textAlign', value: e.target.value }))}>
               <option value='start'>Début</option>
               <option value='center'>Centre</option>
               <option value='end'>Fin</option>
            </FormSelect>
         </DesignControl>
         {content?.style.display === 'manual' && <>
            <DesignControl title='Hauteur'>
               <InputGroup className='mt-1'>
                  <FormControl type='number' min={0} defaultValue={content?.style.height}
                     onChange={(e) => dispatch(setContentDesign({ option: 'height', value: e.target.value }))} />
                  <InputGroup.Text>px</InputGroup.Text>
               </InputGroup>
            </DesignControl>
         </>}
      </DesignElement>
   </>
}