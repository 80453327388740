import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LiveLanding, LiveLandingsData } from '../../types';
import diffusionApi from '../services/secured/diffusion';
import { RootState } from '../store';

const init:LiveLandingsData = {
    domain: '',
    hidden: [],
    visible: []
}

const landingSite = createSlice({
    name: "landing",
    initialState: init,
    reducers: {
        moveLanding: (state, action:PayloadAction<{landing: LiveLanding, direction:number}>) => {
            
            const l_index = state.visible.findIndex(l => l.code === action.payload.landing.code);
            
            if (l_index >= 0 && l_index < state.visible.length) {
                const landing = state.visible[l_index];
                const newPos = landing.position + action.payload.direction;
                const tmpLanding = state.visible.find(l => l.position === newPos);
                if (tmpLanding) {
                    tmpLanding.position = landing.position;
                    landing.position = newPos;
                }
                state.visible.sort((a, b) => a.position - b.position);
            }
        },
        hideLanding: (state, action:PayloadAction<{l: LiveLanding}>) => {
            const ld = state.visible.find(l => l.code === action.payload.l.code);

            if (ld) {
                ld.position = 0;
                state.hidden.push(ld);
                const index = state.visible.indexOf(ld);
                state.visible.splice(index, 1);
                state.visible.map(l => l.position = state.visible.indexOf(l));
            }
        },
        addLanding: (state, action:PayloadAction<Pick<LiveLanding, 'code'>>) => {
            const hidden_ld = state.hidden.find(l => l.code === action.payload.code);
            
            if (hidden_ld) {
                hidden_ld.description = ' ';
                hidden_ld.position = state.visible.length + 1;
                state.visible.push(hidden_ld);
                const hidden_index = state.hidden.indexOf(hidden_ld);
                state.hidden.splice(hidden_index, 1);
            }
        },
        updateDesc: (state, action:PayloadAction<Pick<LiveLanding, 'code'|'description'>>) => {
            const ld = state.visible.find(l => l.code === action.payload.code);
            if (ld) ld.description = action.payload.description;
        }
    },
    extraReducers: (build) => {
        build.addMatcher(diffusionApi.endpoints.getLivelist.matchFulfilled,
            (state, action) => {
                state.domain = action.payload.domain;
                state.hidden = action.payload.hidden;
                state.visible = action.payload.visible;
            })
    }
})

export const { moveLanding, hideLanding, addLanding, updateDesc } = landingSite.actions;
export const getLiveLandings = (state:RootState) => state.landingSite;
export default landingSite.reducer;