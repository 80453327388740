import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { Navigate } from "react-router-dom";
import userApi from "./app/services/secured/user";
import Urls from "./assets/js/Urls";
import Sidebar from "./components/App/Sidebar";
import AppToast from "./components/App/Toast";
import { IfTrue, LoadingBlock } from "./components/misc/Blocks";
import ModalContainer from "./components/Modals/ModalContainer";


const AppSecured: React.FC<{children?: any, displayMenu?: boolean}> = ({ children, displayMenu }) => {
    const user = userApi.useProfileQuery();
    return <>
        <LoadingBlock data={user}>
            {
                (user.data?.isUserAdmin === false && user.data?.isUser === false) && <Navigate to={Urls.login} />
            }
            <Container id='app-container' fluid>
                <Row className='h-100'>
                    <IfTrue condition={displayMenu !== false}>
                        <Col lg={2}>
                            <Sidebar />
                        </Col>
                    </IfTrue>
                    <Col lg={displayMenu === false ? 12:10}>
                        <Container id='content-container' fluid style={displayMenu === false ? {padding: '0'} : {}}>
                            {children}
                        </Container>
                    </Col>
                </Row>
            </Container>
            <AppToast />
            <ModalContainer />
        </LoadingBlock>
    </>
}

export default AppSecured;