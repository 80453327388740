import { useEffect, useState } from "react";
import { Card, Col, Form, Image, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { formSuccessRedirect } from "../../app/actions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import creationApi from "../../app/services/secured/creation";
import { getMedia } from "../../app/slice/media";
import { modalMediasShow } from "../../app/slice/modal";
import AppSecured from "../../AppSecured";
import ApiUrls from "../../assets/js/ApiUrls";
import Urls from "../../assets/js/Urls";
import { Category, ReduxRtkApiResult } from "../../types";
import { FormButtons, FormInputGroup } from "../misc/Blocks";
import { ButtonIcon } from "../misc/Buttons";
import SimpleForm from "../misc/SimpleForm";

const CategoryEdit: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const [req, res] = creationApi.useLazyGetCategoryQuery();

    useEffect(() => {
        if (id !== 'new') {
            req({id: parseInt(id as string)});
        }
    }, [id])

    return <>
        <AppSecured>
            <Card className='genelead-card mt-4'>
                <Card.Header>
                    New
                </Card.Header>
                <Card.Body>
                    <CategoryForm
                        id={id as string}
                        data={res}
                        mutation={
                            id === 'new'
                                ? creationApi.useCreateCategoryMutation()
                                : creationApi.useUpdateCategoryMutation()}
                    />
                </Card.Body>
            </Card>
        </AppSecured>
    </>
}

type CategoryFormProps = {
    mutation: any,
    data: ReduxRtkApiResult,
    id: string
}

const CategoryForm: React.FC<CategoryFormProps> = (props) => {
    const dispatch = useAppDispatch();
    const [showButtonField, setShowButtonField] = useState(false);
    const [mutation, mutationRes] = props.mutation;
    let data: Partial<Category> = {};

    if (props.data.isSuccess && props.data) {
        data = props.data.data;
    }

    const [media, setMedia] = useState(data.media);

    useEffect(() => {
        if (data.type === 2) setShowButtonField(true);
        if (data.media) {
            setMedia(data.media);
        }
    }, [data])

    const newMedia = useAppSelector(getMedia);
    useEffect(() => {
        if (newMedia.id > 0) {
            setMedia(newMedia);
        }
    }, [newMedia])

    dispatch(formSuccessRedirect({data:mutationRes, redirectUrl:Urls.categories.list}))

    return <>
        <SimpleForm onSubmit={(form) => {
            props.id === 'new'
                ? mutation(form as Category) //create
                : mutation({ id: props.id, category: form as Category}) //update
        }}>
        
        <Row className='d-flex align-items-start'>
            <Col lg={3}>
                <div className='d-flex align-items-center justify-content-between'>
                    <Form.Label><h4 className='m-0 me-2'>Type</h4></Form.Label>
                    <div className='d-flex'>
                        <Form.Check name='type' type='radio'
                            className='me-2'
                            label={'Catégorie'} value={1}
                            defaultChecked={data.type === 1 || data.type === 0}
                            onChange={(e) => setShowButtonField(false)} />
                        <Form.Check name='type' type='radio'
                            label={'Thème'} value={2}
                            defaultChecked={data.type === 2}
                            onChange={(e) => setShowButtonField(true)} />
                    </div>
                </div>

                <div className='d-flex align-items-center justify-content-between text-center'>
                    <Form.Label><h4 className='m-0 me-2'>Couleur</h4></Form.Label>
                    <div className='d-flex'>
                        <div className='me-2'>
                            <Form.Control key={Math.random()} type='color' name='data[color_bg]'
                                defaultValue={data.data?.color_bg} required />
                            <Form.Label className='m-0'>Fond</Form.Label>
                        </div>
                        <div>
                            <Form.Control key={Math.random()} type='color' name='data[color_txt]' defaultValue={data.data?.color_txt} required />
                            <Form.Label className='m-0'>Texte</Form.Label>
                        </div>
                    </div>
                </div>

                <div className='mt-3'>
                    <ButtonIcon className='w-100 mb-1' code={"image"} size={"sm"} variant={"primary"}
                        onClick={() => { dispatch(modalMediasShow()) }}>
                        {media?.id ? 'Changer' : 'Ajouter'}
                    </ButtonIcon>
                    <div>
                        {
                            media &&
                            media.id !== 0 &&
                            <Image rounded fluid src={ApiUrls.front.media(media.id.toString(), media.extension)} />
                        }
                    </div>
                    <Form.Control type="hidden" name="media" value={media?.id || ""} />
                </div>
            </Col>
            <Col>
                <FormInputGroup label='Nom'>
                    <Form.Control name='name' placeholder="Nom" defaultValue={data.name} required />
                </FormInputGroup>
                <FormInputGroup label='Slug'>
                    <Form.Control name='slug' placeholder="Slug" defaultValue={data.slug} required />
                </FormInputGroup>
                <FormInputGroup label='Sous-titre'>
                    <Form.Control name='data[subtitle]' placeholder="Subtitle" defaultValue={data.data?.subtitle} />
                </FormInputGroup>
                <Row>
                    <FormInputGroup as={Col} label='SEO Titre'>
                        <Form.Control name='data[seo_title]' placeholder="SEO Titre" defaultValue={data.data?.seo_title} />
                    </FormInputGroup>
                    <FormInputGroup as={Col} label='SEO Description'>
                        <Form.Control name='data[seo_desc]' placeholder="SEO Description" defaultValue={data.data?.seo_desc} />
                    </FormInputGroup>
                </Row>
                {
                    showButtonField && <>
                        <FormInputGroup label='Button'>
                            <Form.Control name='data[button]' placeholder='Button' defaultValue={data.data?.button} />
                        </FormInputGroup>
                    </>
                }
            </Col>
        </Row>
        <FormButtons cancelUrl={Urls.categories.list} data={mutationRes} btnTxt={props.id === 'new' ? 'Créer' : 'Modifier'} />
        </SimpleForm>
    </>
}
export default CategoryEdit;