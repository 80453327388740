import ApiUrls from "../../../assets/js/ApiUrls";
import { jsonToFormData } from "../../../components/misc/SimpleForm";
import { LoginArgs, LoginReturn, RefreshArgs } from "../../../types";
import publicApi from "./publicApi";

const authApi = publicApi.injectEndpoints({
    endpoints: (build) => ({
        login: build.mutation<LoginReturn, LoginArgs>({
            query: (form) => ({
                url: ApiUrls.login,
                method: "POST",
                body: jsonToFormData(form),
            }),
        }),
        forgot: build.mutation<any, Pick<LoginArgs, 'email'>>({
            query: (form) => ({
                url: ApiUrls.forgot,
                method: 'POST',
                body: jsonToFormData(form)
            })
        }),
        refresh: build.mutation<any, RefreshArgs>({
            query: (data) => ({
                url: ApiUrls.refresh(data.token),
                method: 'POST',
                body: jsonToFormData(data.passwords)
            })
        })
    })
})

export default authApi;