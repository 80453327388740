import { useEffect, useMemo, useRef, useState } from "react";
import { Button, Container, Dropdown, FormCheck, FormControl, FormLabel, InputGroup, ListGroup } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import creationApi from "../../../app/services/secured/creation";
import { Choice } from "../../../types";
import { addField, FieldType, getFormAttributes, getFormFields, getFormStyle, getView, GlobalStyle, setFormData, setFormDesign } from "../landing-v2";
import ColorPicker from "../Tools/ColorPicker";
import { BooleanField, ControlField, DateField, KeyField, OneChoiceField, OptinField, PostOptinField } from "./Fields";

export const FormContent: React.FC<{ isEdit: boolean }> = ({ isEdit }) => {

   const dispatch = useAppDispatch();
   const form = useAppSelector(getFormFields);
   const formStyle = useAppSelector(getFormStyle);
   const view = useAppSelector(getView);
   const attrs = useAppSelector(getFormAttributes);

   const style: GlobalStyle = {};
   if (formStyle) {
      style.main_color = formStyle.main_color ? formStyle.main_color : '#000000';
      style.secondary_color = formStyle.secondary_color ? formStyle.secondary_color : '#000000';
      style.backgroundColor = formStyle.backgroundColor ? formStyle.backgroundColor : '#FFFFFF00';
      style.border = formStyle.border ? formStyle.border : '0px solid #000000';
      style.borderRadius = formStyle.borderRadius ? formStyle.borderRadius + 'px' : '2px';
      style.color = formStyle.color ? formStyle.color : '';
   }

   const [addExisitingField, setAddExistingField] = useState(false);
   const fields = creationApi.useGetFieldsQuery({});

   const fix = useRef(false);
   useEffect(() => {
      if (form && form.findIndex(f => f.code === 'email') < 0 && fix.current === false) {
         fix.current = true;
         dispatch(addField({ type: 'T', code: 'email', title: 'Email', isEdit: false, mandatory: true }));
      }
   }, [form]);

   const fieldsOptions = useMemo(() => {
      if (fields.data && form) 
      {
         return fields.data
         .filter(f => form && form ? form.findIndex(ff => ff.code === f.code) === -1 : true)
         .map(f => { return { label: '[' + f.code + '] ' + f.title, code: f.code, type: f.type, title: f.title, choices: f.choices } });
      }
   }, [form, fields.data]);

   return <>
      {form && <>
         <div id='form-wrapper' style={attrs?.hasTitle === false ? {} : { marginTop: '2rem' }}>
            <Container id='form-container' style={style}>
               {(attrs?.hasTitle === true || attrs?.hasTitle === undefined) && <FormTitle />}
               {form.map((f, i) => {
                  return <div key={`form-node-${i}`} className='mt-3 mb-1'>
                     {f.type === 'T' && <ControlField type='text' fieldIndex={i} />}
                     {f.type === 'N' && <ControlField type='number' fieldIndex={i} />}
                     {f.type === 'C' && <OneChoiceField fieldIndex={i} />}
                     {f.type === 'B' && <BooleanField fieldIndex={i} />}
                     {f.type === 'optin' && <OptinField fieldIndex={i} />}
                     {f.type === 'D' && <DateField fieldIndex={i} />}
                     {f.type === 'K' && <KeyField fieldIndex={i} />}
                     {/* {f.type === 'checkbox' && <MultipleChoicesField fieldIndex={i} />} */}
                  </div>
               })}
               <FormButton />
               {form.findIndex(f => f.type === 'post-optin') > -1 && <PostOptinField />}
            </Container>
            {
               isEdit && view === 'block-design' && <>
                  <div id='form-actions-wrapper' className='d-flex justify-content-center mb-1 mt-1'>
                     <div className='w-100 p-2 d-flex align-items-center'>
                        {!addExisitingField ? <>
                           <Button className='w-100' onClick={() => setAddExistingField(true)}>
                              Ajouter champ
                           </Button>
                        </>
                           : (form && fields.data) && <>
                              <Typeahead className='w-100'
                                 id='existing-field'
                                 placeholder='Rechercher champ'
                                 selected={[]}
                                 options={fieldsOptions ? fieldsOptions : []}
                                 onChange={(s) => {
                                    if (s[0]) {
                                       const val = s[0] as { code: string, type: FieldType, title: string, choices: Array<Choice> };
                                       dispatch(addField({
                                          type: val.type,
                                          code: val.code,
                                          title: val.title,
                                          choices: val.type === 'C' && val.choices.length > 0 ? val.choices : [],
                                       }));
                                    }
                                 }}
                              />
                           </>
                        }

                        <Dropdown key={Math.random() + form.length} className='ms-1 w-100'>
                           <Dropdown.Toggle className='w-100' variant='success'>Optin</Dropdown.Toggle>
                           <Dropdown.Menu>
                              <Dropdown.Item onClick={() => dispatch(addField({ type: 'optin' }))}>Optin</Dropdown.Item>
                              {form.findIndex(f => f.type === 'post-optin') === -1 &&
                                 <Dropdown.Item onClick={() => dispatch(addField({ type: 'post-optin' }))}>Post-optin</Dropdown.Item>}
                           </Dropdown.Menu>
                        </Dropdown>
                     </div>

                  </div>
               </>
            }
         </div>
      </>}
   </>
}

export const FormButton = () => {
   const dispatch = useAppDispatch();
   const data = useAppSelector(getFormAttributes);
   const formStyle = useAppSelector(getFormStyle);
   const [displayButton, setDisplayButton] = useState(false);

   return <div className='d-flex justify-content-center mt-3'>
      <Button variant='' className='px-5'
         style={{ backgroundColor: formStyle?.main_color ? formStyle.main_color : '#000000', color: formStyle?.secondary_color ? formStyle.secondary_color : '#FFFFFF' }}
         onClick={() => setDisplayButton(true)}>
         {
            !displayButton
               ? <div>{data?.cta ? data.cta : 'CTA'}</div>
               : <>
                  <FormControl type='text' defaultValue={data?.cta}
                     onBlur={(e) => {
                        dispatch(setFormData({ option: 'cta', value: e.target.value }))
                        setDisplayButton(false)
                     }} />
               </>
         }
      </Button>
   </div>
}

export const FormTitle = () => {
   const dispatch = useAppDispatch();
   const data = useAppSelector(getFormAttributes);
   const formStyle = useAppSelector(getFormStyle);
   const [displayTitle, setDisplayTitle] = useState(false);
   return <>
      <div id='form-title' className='text-start d-flex align-items-end'>
         {
            !displayTitle
               ? <div className='p-2' style={{
                  backgroundColor: formStyle?.main_color ? formStyle.main_color : '#000000',
                  color: formStyle?.secondary_color ? formStyle.secondary_color : '#FFFFFF',
                  cursor: 'pointer'
               }}
                  onClick={() => setDisplayTitle(true)}>
                  <h6 className='m-0'>{data?.title ? data?.title : 'Titre formulaire'}</h6>
               </div>
               : <>
                  <FormControl type='text' defaultValue={data?.title}
                     onBlur={(e) => {
                        dispatch(setFormData({ option: 'title', value: e.target.value }))
                        setDisplayTitle(false)
                     }} />
               </>
         }
      </div>
   </>
}

export const FormDesign = () => {
   const style = useAppSelector(getFormStyle);
   const attrs = useAppSelector(getFormAttributes);
   const dispatch = useAppDispatch();

   const handleFormDesign = (option: string) => () => {
      dispatch(setFormDesign({ option: 'labels_display', value: option }));
   }

   const [border, setBorder] = useState({ px: '1', color: '#000000' });
   useEffect(() => {
      if (style?.border) {
         const currBorder = style?.border;
         setBorder({
            px: currBorder.substring(0, 1),
            color: currBorder.substring(currBorder.length, currBorder.length - 7)
         });
      }
   }, []);

   const updateBorder = () => {
      dispatch(setFormDesign({ option: 'border', value: border.px + 'px solid ' + border.color }));
   }

   return <>
      <div className='design-controller'>
         <span className='title'>Formulaire</span>

         <FormCheck type='switch' label='Titre' className='mt-1 mb-0' defaultChecked={attrs?.hasTitle !== undefined ? attrs.hasTitle : true}
            onChange={(e) => dispatch(setFormData({ option: 'hasTitle', value: e.target.checked }))} />

         <ColorPicker value={style?.main_color} title='Principale'
            onChange={(color: string) => {
               dispatch(setFormDesign({ option: 'main_color', value: color }));
               setBorder({ ...border, color });
               updateBorder();
            }} />

         <ColorPicker value={style?.secondary_color} title='Secondaire' className='mt-1'
            onChange={(color: string) => dispatch(setFormDesign({ option: 'secondary_color', value: color }))} />

         <ColorPicker value={style?.backgroundColor} title='Fond' className='mt-1'
            onChange={(color: string) => dispatch(setFormDesign({ option: 'backgroundColor', value: color }))} />

         <ColorPicker value={style?.backgroundColor} title='Texte' className='mt-1'
            onChange={(color: string) => dispatch(setFormDesign({ option: 'color', value: color }))} />

         <InputGroup size='sm' className='mt-1'>
            <InputGroup.Text>Bordure</InputGroup.Text>
            <FormControl type='number' size='sm' min={0} value={border.px} step={0.5}
               onChange={(e) => {
                  setBorder({ ...border, px: e.target.value });
                  updateBorder();
               }} />
         </InputGroup>

         <InputGroup size='sm' className='mt-1'>
            <InputGroup.Text>Arrondi</InputGroup.Text>
            <FormControl type='number' size='sm' min={0} defaultValue={style?.borderRadius} step={0.5}
               onChange={(e) => dispatch(setFormDesign({ option: 'borderRadius', value: e.target.value }))} />
         </InputGroup>

         <FormLabel className='mb-0 mt-3'>Labels</FormLabel>
         <ListGroup className='label-design mt-2' defaultActiveKey={style?.labels_display} horizontal>
            <ListGroup.Item action eventKey="label" onClick={handleFormDesign('label')}><span className="icon-label-border"></span></ListGroup.Item>
            <ListGroup.Item action eventKey="placeholder" onClick={handleFormDesign('placeholder')}><span className="icon-placeholder-border"></span></ListGroup.Item>
         </ListGroup>
         <ListGroup className='label-design mt-2' defaultActiveKey={style?.labels_display} horizontal>
            <ListGroup.Item action eventKey="no-border-label" onClick={handleFormDesign('no-border-label')}><span className="icon-placeholder-border-bottom"></span></ListGroup.Item>
            <ListGroup.Item action eventKey="no-border-placeholder" onClick={handleFormDesign('no-border-placeholder')}><span className='icon-label-border-bottom'></span></ListGroup.Item>
         </ListGroup>
      </div>
   </>
}