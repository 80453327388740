import { InputGroup } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import globalApi from "../../app/services/secured/global";
import manageApi from "../../app/services/secured/manage";
import { Company, LeadSearch } from "../../types";
import { LoadingBlock } from "../misc/Blocks";
import { Icon } from "../misc/Buttons";
import { addToSearch, FilterSelect, FiltersInterface } from "../misc/Filters";

export const LeadsFilters: React.FC<FiltersInterface<LeadSearch>> = props => {
    const companiesReq = globalApi.useGetCompaniesListQuery();
    const formsReq = globalApi.useGetFormsListQuery();

    const onChange = (newFilter: object) => { props.onFilterChange(addToSearch(props.currentFilter, newFilter)); }
    
    return <>
        <div className="ms-5">
            <InputGroup className="filters d-flex align-items-center">
                <Icon code='funnel' className="me-3 fs-30" />
                <LoadingBlock data={companiesReq}>
                    {companiesReq.data && <>
                        <Typeahead
                            id='select-customer'
                            placeholder="Client"
                            options={companiesReq.data.map((c: Company) => { return {label:c.name, id:c.id} })}
                            onChange={(selected) => {
                                if (selected[0]) {
                                    const company = selected[0] as {label: string, id: number};
                                    onChange({company_id: company.id});
                                } else {
                                    onChange({company_id: null})
                                }
                            }}
                        />
                    </>}
                </LoadingBlock>
                <LoadingBlock data={formsReq}>
                    {formsReq.data && <>
                        <Typeahead
                            id='select-form'
                            placeholder="Formulaire"
                            options={formsReq.data.map((f) => { return {label: '[' + f.id + '] ' +f.name, id:f.id} })}
                            onChange={(selected) => {
                                if (selected[0]) {
                                    const form = selected[0] as {label: string, id: number};
                                    onChange({form_id: form.id});
                                } else {
                                    onChange({form_id: null});
                                }
                            }}
                        />
                    </>}
                </LoadingBlock>
            </InputGroup>
        </div>
    </>
}