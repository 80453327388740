import { Card, Col, Container, Row } from "react-bootstrap";
import geneleadLogo from "../../assets/img/logo.svg";
import React from "react";

const AuthLP: React.FC<{children?: any}> = ({ children }) => {
    return <>
        <Container id='auth-lp' fluid>
            <Row className="justify-content-center">
                {/*<Col lg={9} id='auth-image-col' />*/}
                <Col lg={5} id='form-col' className='d-flex flex-column justify-content-center'>
                    <Container>
                        <Card className='auth-card'>
                            <Card.Body className="p-0">
                                <img className="logo" src={geneleadLogo} alt=""/>
                                {children}
                            </Card.Body>
                        </Card>
                    </Container>
                </Col>
            </Row>
        </Container>
    </>
}

export default AuthLP;