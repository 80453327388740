import { useEffect, useState } from "react"
import { Badge, Col, FormControl, FormSelect, InputGroup, ListGroup, ListGroupItem, Modal, Row } from "react-bootstrap"
import { formSuccessRedirect } from "../../app/actions"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import diffusionApi from "../../app/services/secured/diffusion"
import globalApi from "../../app/services/secured/global"
import { getModalData, isAnyModalOpened, isModalOpened, ModalTypes } from "../../app/slice/modal"
import { FbCampaign, FbForm } from "../../types"
import { BlockError, FormInputGroup, IfTrue, OnSuccess } from "../misc/Blocks"
import { ButtonIcon, Icon, LoadingButton } from "../misc/Buttons"
import SimpleForm from "../misc/SimpleForm"
import ModalCloseButton from "../Modals/ModalCloseButton"

const ModalFbLink = () => {
    const dispatch = useAppDispatch();
    const show = useAppSelector(isAnyModalOpened);
    const isOpened = useAppSelector(isModalOpened(ModalTypes.FbLink));

    const data = useAppSelector<Pick<FbForm, 'formId' | 'campaigns' | 'facebookFormId' | 'facebookFormName'>>(getModalData);
    const [getForms, forms] = globalApi.useLazyGetFormsListQuery();
    const [getCampaigns, campaigns] = globalApi.useLazyGetCampaignsQuery();

    const [campaignSearch, setCampaignSearch] = useState('');
    const [currentCampaigns, setCurrentCampaigns] = useState<Array<FbCampaign>>([]);

    useEffect(() => {
        if (isOpened) {
            getForms();
            getCampaigns();
            setCurrentCampaigns(data.campaigns);
        }
    }, [isOpened])

    const onCampaignRemove = (e: FbCampaign) => {
        setCurrentCampaigns([...currentCampaigns].filter(c => c.id !== e.id))
    }

    const onCampaignSelect = (e: any) => {
        const newCurrent = [...currentCampaigns];
        const newCampaign = campaigns.data?.find(c => c.campaignId === e.currentTarget.value);
        newCurrent.push({ id: newCampaign?.campaignId as string, name: newCampaign?.campaignName as string });
        setCurrentCampaigns(newCurrent)
    }

    const [update, updateRes] = diffusionApi.useUpdateLinkMutation();
    dispatch(formSuccessRedirect({isModal: true, data: updateRes}));
    
    return <>
        <IfTrue condition={data && isOpened}>
            <Modal size="xl" show={show}>
                <SimpleForm onSubmit={(json) => {
                    update({
                        facebookFormId: data.facebookFormId,
                        form: json.form,
                        campaigns: currentCampaigns
                    })
                }}>
                    <Modal.Header>
                        <h6>Liaison formulaire {data?.facebookFormName}</h6>
                    </Modal.Header>
                    <Modal.Body>
                        <BlockError data={updateRes} />
                        <Row className='d-flex align-items-center'>
                            <Col>
                                <FormInputGroup label='Formulaire Genlead'>
                                    <FormSelect name='form' defaultValue={data?.formId}>
                                    {/*<FormSelect name='form' defaultValue={data?.formId} placeholder='Formulaire Genelead'>*/}
                                        <option>Sélectionnez un formulaire</option>
                                        <OnSuccess data={forms}>
                                            {forms.data?.map(f => <option key={f.id} value={f.code}>{f.name}</option>)}
                                        </OnSuccess>
                                    </FormSelect>
                                </FormInputGroup>

                                <InputGroup className='mt-4 mb-1'>
                                    <InputGroup.Text><Icon code='facebook' /></InputGroup.Text>
                                    <FormControl type='text' placeholder='Chercher une campagne' value={campaignSearch}
                                        onChange={(e) => setCampaignSearch(e.target.value)} />
                                </InputGroup>
                                <FormInputGroup label='Campagnes FB'>
                                    <FormSelect onChange={onCampaignSelect}>
                                    {/*<FormSelect placeholder='Campagnes FB' onChange={onCampaignSelect}>*/}
                                        <option></option>
                                        {campaigns.data &&
                                            campaigns?.data
                                                .filter(c => currentCampaigns.map(dc => dc?.id).indexOf(c?.campaignId) == - 1)
                                                .filter(c =>
                                                    campaignSearch.length > 2
                                                        ? c?.campaignName?.toLowerCase().startsWith(campaignSearch.toLowerCase())
                                                        : true
                                                )
                                                .map(c => <option key={Math.random()} value={c?.campaignId}>{c?.campaignName}</option>
                                                )}
                                    </FormSelect>
                                </FormInputGroup>
                            </Col>
                            <Col>
                                <h6>Campagnes en cours</h6>
                                <ListGroup variant='flush'>
                                    {currentCampaigns.map(c => <CurrentCampaign key={Math.random()} {...c} onDelete={onCampaignRemove} />)}
                                </ListGroup>
                            </Col>
                        </Row>
                    </Modal.Body>
                    <Modal.Footer>
                        <ModalCloseButton />
                        <LoadingButton data={updateRes} type='submit'>Modifier</LoadingButton>
                    </Modal.Footer>
                </SimpleForm>
            </Modal>

        </IfTrue>

    </>
}

const CurrentCampaign: React.FC<FbCampaign & { onDelete: (e: FbCampaign) => void }> = campaign => {
    return <ListGroupItem variant='primary' className='d-flex justify-content-between'>
        <div>
            <b>{campaign.name}</b> <br />
            <Badge className='alt-primary'>{campaign.id}</Badge>
        </div>
        <ButtonIcon code={"x"} size={"sm"} variant={"danger"} className='alt-danger'
            onClick={() => campaign.onDelete(campaign)} />
        { campaign.name &&  <FormControl type='hidden' name={campaign.name} value={1} /> }
    </ListGroupItem>
}

export default ModalFbLink;