import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {formatDateForRequest} from '../../components/misc/DatePicker';
import {DatePickerType} from '../../types';
import {RootState} from '../store';

export type LandingListSearchType = {
    page: number,
    query: string,
    statuses?: number,
}

interface AppState {
    redirect: string | null,
    datepicker: DatePickerType,
    landingListSearch: LandingListSearchType,
}

const lSearch:LandingListSearchType = localStorage.getItem('landingListSearch')
    ? JSON.parse(localStorage.getItem('landingListSearch') as string)
    : {
        page: 1,
        query: '',
        statuses: 8
    }


const init: AppState = {
    redirect: null,
    datepicker: {
        from: formatDateForRequest(new Date(Date.now() - 14 * 1000 * 12000)),
        to: formatDateForRequest(new Date())
    },
    landingListSearch: lSearch
}

const app = createSlice({
    name: "app",
    initialState: init,
    reducers: {
        clearRedirect: (state: AppState) => {
            state.redirect = null;
        },
        redirect: (state: AppState, action: PayloadAction<string>) => {
            state.redirect = action.payload;
        },
        setDatepicker: (state: AppState, action: PayloadAction<DatePickerType>) => {
            state.datepicker = action.payload;
        },
        setLandingListSearch: (state, action: PayloadAction<LandingListSearchType>) => {
            state.landingListSearch.page = action.payload.page;
            state.landingListSearch.query = action.payload.query;
            state.landingListSearch.statuses = action.payload.statuses;

            localStorage.setItem('landingListSearch', JSON.stringify(action.payload));
        },
        initLandingListSearch: (state) => {
            state.landingListSearch = {
                page: 1,
                query: '',
                statuses: 8,
            }
            localStorage.removeItem('landingListSearch');
        }
    },
    extraReducers: (builder) => {
    }
})

export const {clearRedirect, redirect, setDatepicker, setLandingListSearch, initLandingListSearch} = app.actions;

export const getRedirect = (state: RootState) => state.app.redirect;
export const getDatepicker = (state: RootState) => state.app.datepicker;
export const getLandingListSearch = (state: RootState): LandingListSearchType => state.app.landingListSearch;

export default app.reducer;