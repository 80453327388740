import { Alert, Card, Col, Form, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import adminApi from "../../app/services/secured/admin";
import AppSecured from "../../AppSecured";
import { Setting } from "../../types";
import { BlockError, FormInputGroup, LoadingBlock } from "../misc/Blocks";
import { BtnIconLoad, Icon } from "../misc/Buttons";
import SimpleForm from "../misc/SimpleForm";

const Configuration: React.FC = () => {
    const settings = adminApi.useGetSettingsQuery();
    const setting = settings.data;
    const [update, updateRes] = adminApi.useUpdateSettingsMutation();
    return <>
        <AppSecured>
            <LoadingBlock data={settings}>
                <Card className='mt-4'>
                    <Card.Body>
                        <SimpleForm onSubmit={(form) => {
                            update(form as Setting)
                        }}>
                            <BlockError data={updateRes} />
                            <Row>
                                <Col>
                                    <h2><Icon code='signpost-split' /> Priorité clients</h2>
                                    <Alert variant="info">
                                        Un chiffre bas signifie une priorité haute (1 chance sur 20, 1 chance sur 100...)
                                    </Alert>
                                    <Row>
                                        <FormInputGroup as={Col} label='Standard'>
                                            <Form.Control name='settings[weight_customer_bad]' type='text' defaultValue={setting?.settings.weight_customer_bad} required />
                                        </FormInputGroup>
                                        <FormInputGroup as={Col} label='Premium'>
                                            <Form.Control name='settings[weight_customer_avg]' type='text' defaultValue={setting?.settings.weight_customer_avg} required />
                                        </FormInputGroup>
                                        <FormInputGroup as={Col} label='Exclusif'>
                                            <Form.Control name='settings[weight_customer_good]' type='text' defaultValue={setting?.settings.weight_customer_good} required />
                                        </FormInputGroup>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className='mt-4'>
                                <Col>
                                    <h2><Icon code='share' /> Partage de leads</h2>
                                    <Alert variant="info">
                                        Définir le nombre de courtier maximum entre lesquels dispatcher les leads
                                    </Alert>
                                    <Row>
                                        <FormInputGroup as={Col} label='Standard'>
                                            <Form.Control name='share_packs[standard]' type='text' defaultValue={setting?.share_packs.standard} required />
                                        </FormInputGroup>
                                        <FormInputGroup as={Col} label='Premium'>
                                            <Form.Control name='share_packs[premium]' type='text' defaultValue={setting?.share_packs.premium} required />
                                        </FormInputGroup>
                                        <FormInputGroup as={Col} label='Exclusif'>
                                            <Form.Control name='share_packs[exclu]' type='text' defaultValue={setting?.share_packs.exclu} required />
                                        </FormInputGroup>
                                    </Row>
                                </Col>
                            </Row>
                            <Row className='text-end mt-3'>
                                <Col>
                                    <BtnIconLoad data={updateRes} icon=''>
                                        Modifier
                                    </BtnIconLoad>
                                </Col>
                            </Row>
                        </SimpleForm>
                    </Card.Body>
                </Card>
            </LoadingBlock>
        </AppSecured>
    </>
}

export default Configuration;