import { useEffect, useState } from "react";
import { Form, FormLabel, FormSelect, Modal } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { formSuccessRedirect } from "../../app/actions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import diffusionApi from "../../app/services/secured/diffusion";
import globalApi from "../../app/services/secured/global";
import { isModalOpened, ModalTypes } from "../../app/slice/modal";
import { FbLinkForm } from "../../types";
import { BlockError, FormInputGroup, IfTrue, LoadingBlock, NoDataBlock } from "../misc/Blocks";
import { Icon, LoadingButton } from "../misc/Buttons";
import SimpleForm from "../misc/SimpleForm";
import ModalCloseButton from "../Modals/ModalCloseButton";

const ModalFbSearchPage = () => {
  const dispatch = useAppDispatch();
  const isOpened = useAppSelector(isModalOpened(ModalTypes.FbSearchPage))

  const [getFbPages, fbPages] = diffusionApi.useLazyGetFbPagesQuery()
  const [getFbPageForms, fbPageForms] = diffusionApi.useLazyGetFbPageFormsQuery();
  const geneleadForms = globalApi.useGetFormsListQuery();

  useEffect(() => {
    if (isOpened) getFbPages()
  }, [isOpened])

  const [config, setConfig] = useState<Partial<FbLinkForm>>();
  const [linkForm, linkFormRes] = diffusionApi.useLinkFbFormMutation();

  dispatch(formSuccessRedirect({ isModal: true, data: linkFormRes }));
  return <>
    <IfTrue condition={isOpened}>
      <SimpleForm onSubmit={() => {
        linkForm(config as FbLinkForm)
      }}>
        <Modal.Header>
          Lier formulaire facebook
        </Modal.Header>
        <Modal.Body>
          <BlockError data={linkFormRes} />

          <LoadingBlock data={fbPages}>
            <FormLabel className='mt-2 mb-0'>Formulaire facebook</FormLabel>
            {fbPages.data && <>
              <Typeahead
                id=''
                options={fbPages.data.map(f => {
                  return { label: f.name, id: f.id };
                })}

                onChange={(s) => {
                  if (s[0]) {
                    const val = s[0] as { label: string, id: number };
                    setConfig({ ...config, pageId: val.id.toString() });
                    getFbPageForms({ id: val.id.toString() });
                  }
                }}
              />
            </>}
          </LoadingBlock>

          <IfTrue condition={config?.pageId}>
            <LoadingBlock data={fbPageForms}>
              <NoDataBlock data={fbPageForms.data} title={"formulaire facebook"}>
                {fbPageForms.data && <>
                  <FormLabel className='mt-2 mb-0'>LeadGen Form</FormLabel>
                  <Typeahead
                    id='select-fb-page'
                    options={fbPageForms.data.map(f => {
                      return { label: f.name, id: f.id };
                    })}

                    onChange={
                      (s) => {
                        if (s[0]) {
                          const val = s[0] as { label: string, id: number };
                          return setConfig({
                            ...config,
                            facebookFormId: val.id.toString(),
                            facebookFormName: val.label,
                          })
                        }
                      }
                    }
                  />
                </>}
              </NoDataBlock>
            </LoadingBlock>
          </IfTrue>

          <IfTrue condition={config?.facebookFormId && config?.pageId}>
            <LoadingBlock data={geneleadForms}>
              {geneleadForms.data && <>
                <FormLabel className='mt-2 mb-0'>Landing Page</FormLabel>
                <Typeahead
                  id='select-genelead-form'
                  placeholder='Landing page'
                  options={geneleadForms?.data?.slice().sort((a, b) => a.name.localeCompare(b.name)).map(g => {
                    return { label: '[' + g.id + '] ' + g.name, id: g.id }
                  })}
                  onChange={(s) => {
                    if (s[0]) {
                      const val = s[0] as { id: number };
                      setConfig({ ...config, form: val.id.toString() });
                    }
                  }}
                />
              </>}
            </LoadingBlock>
          </IfTrue>

          <IfTrue condition={config?.form}>
            <div className="d-flex justify-content-center mt-3">
              <LoadingButton data={linkFormRes} className='w-100'><Icon code='link' /> Relier</LoadingButton>
            </div>
          </IfTrue>

        </Modal.Body>
        <Modal.Footer>
          <ModalCloseButton />
        </Modal.Footer>
      </SimpleForm>
    </IfTrue>
  </>
}

export default ModalFbSearchPage;