import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import Urls from '../../assets/js/Urls';
import authApi from '../services/public/auth';
import { StorageManager } from '../services/storage';
import { LoginReturn } from '../../types';
import userApi from '../services/secured/user';

const auth = createSlice({
    name: "app",
    initialState: {},
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addMatcher(authApi.endpoints.login.matchFulfilled, 
                (state, action:PayloadAction<LoginReturn>) => {
                    StorageManager.set(action.payload.token);
                    window.location.href = Urls.dash;
            })
            .addMatcher(userApi.endpoints.logout.matchFulfilled,
                () => {
                    StorageManager.clear();
                    window.location.href = "/";
                })
            .addMatcher(authApi.endpoints.refresh.matchFulfilled, 
                () => {
                    setTimeout(() => {
                        window.location.href=Urls.login;
                    }, 3000)
                })
    }
})

export default auth.reducer;