import { useEffect, useState } from "react";
import { Badge, FormCheck, Tab, Tabs } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { FormattedDate } from "react-intl";
import { useAppSelector } from "../../app/hooks";
import globalApi from "../../app/services/secured/global";
import manageApi from "../../app/services/secured/manage";
import { getDatepicker } from "../../app/slice/app";
import AppSecured from "../../AppSecured";
import Urls from "../../assets/js/Urls";
import { DatePickerType, LeadExport, LeadExportSearch, LeadExportWaiting } from "../../types";
import { PageHeader } from "../App/PageHeader";
import { LoadingBlock } from "../misc/Blocks";
import { ButtonIcon, Icon } from "../misc/Buttons";
import DatePicker from "../misc/DatePicker";
import { PaginationGenelead } from "../misc/Pagination";
import TableGenelead, { RowNoData } from "../misc/Table";
import LeadsExportFilters from "./LeadsExportFilters";

const LeadsExportList = () => {
    const { from, to } = useAppSelector<DatePickerType>(getDatepicker);
    const [setExports, exports] = manageApi.useLazyGetExportLeadsQuery();
    const [setWaiting, exps_waiting] = manageApi.useLazyGetLeadsWaitingQuery();
    const companies = globalApi.useGetCompaniesListQuery();
    const gateways = globalApi.useGetGatewaysQuery();

    const [search, setSearch] = useState<LeadExportSearch>({
        page: 1,
        type: 'pending',
        from: from,
        to: to,
    });

    useEffect(() => {
        search.type === 'exported' ? setExports(search) : setWaiting();
    }, [search])
    return <>
        <AppSecured>
            <PageHeader action={
                <ButtonIcon href={Urls.webservices} code={"hdd-network"} size={"sm"} variant={"primary"}>Webservices</ButtonIcon>
            }>
                <DatePicker
                    onChange={(from: string, to: string) => {
                        setSearch({ ...search, from: from, to: to })
                    }}
                />
                {search.type === 'exported' && <>
                    {companies.isSuccess && <>
                        <Typeahead
                            id='search-company'
                            options={companies.data.map(c => { return { label: c.name, id: c.id } })}
                            placeholder='Clients'
                            className='ms-5'
                            onChange={(s) => {
                                if (s[0]) {
                                    const val = s[0] as { id: number };
                                    setSearch({ ...search, company_id: val.id });
                                } else {
                                    const { company_id, ...newSearch } = search;
                                    setSearch(newSearch);
                                }
                            }}
                        />
                    </>}
                    {gateways.isSuccess && <>
                        <Typeahead
                            id='search-forms'
                            options={gateways.data.map(g => { return { label: g.name, id: g.id } })}
                            placeholder='Webservice'
                            className='ms-2'
                            onChange={(s) => {
                                if (s[0]) {
                                    const val = s[0] as { id: number };
                                    setSearch({ ...search, gateway_id: val.id });
                                } else {
                                    const { gateway_id, ...newSearch } = search;
                                    setSearch(newSearch);
                                }
                            }}
                        />
                    </>}
                    <FormCheck onChange={(e) => {
                        if (e.currentTarget.checked) {
                            setSearch({ ...search, active: '0' });
                        } else {
                            const { active, ...newSearch } = search;
                            setSearch(newSearch);
                        }
                    }}
                        value='0' label='Erreurs' type='switch' className='ms-2' />
                </>}
            </PageHeader>

            <Tabs className='lead-statutes' activeKey={search.type}
                onSelect={(s) => setSearch({ ...search, type: s as 'pending' | 'exported' })}>

                <Tab eventKey={'pending'} title={'File d\'attente'}>
                    <LoadingBlock data={exps_waiting}>
                        {
                            exps_waiting.isSuccess && <>
                                <TableGenelead header={[
                                    { name: 'client', icon: 'building' },
                                    { name: 'email', icon: 'envelope' },
                                    { name: 'form', icon: 'ui-radios' },
                                    { name: 'service', icon: 'globe' },
                                    { name: 'message', icon: 'exclamation' },
                                    { name: 'Essais', icon: 'info' },
                                ]}>
                                    <RowNoData list={exps_waiting.data} colspan={6} />
                                    {exps_waiting.data?.length > 0 && exps_waiting.data?.map(e => <LeadExportWaitingRow key={Math.random()} {...e} />)}
                                </TableGenelead>
                            </>
                        }
                    </LoadingBlock>
                </Tab>

                <Tab eventKey={'exported'} title={'Exportés'}>
                    <LoadingBlock data={exports}>
                        {exports.isSuccess && <>
                            <div className='d-flex align-items-center'>
                                <div className='d-flex align-items-center data-leads'>
                                <Icon code='person fs-40' />
                                <div className='d-flex flex-column ms-2'>
                                    <LoadingBlock data={exports}>
                                        <span className='fs-20'>{exports.data?.total}</span>
                                    </LoadingBlock>
                                    <small>leads</small>
                                </div>
                                </div>
                                <PaginationGenelead
                                    className='m-0 ms-3'
                                    size='sm'
                                    pages={{ current: exports.data.page, total: exports.data.total_pages }}
                                    search={(page: number) => { setSearch({ ...search, page }) }}
                                />
                            </div>

                            <TableGenelead header={[
                                { name: 'export', icon: 'list' },
                                { name: 'Landing page', icon: 'grid-1x2' },
                                { name: 'lead', icon: 'person' },
                                { name: 'date', icon: 'calendar' },
                                { name: '', icon: 'check', align: 'end' },
                            ]}>
                                <RowNoData list={exports.data.list} colspan={5} />
                                {exports.data?.list?.length > 0 && exports.data.list.map(e => <LeadExportRow key={e.id} {...e} />)}
                            </TableGenelead>

                            <PaginationGenelead
                                className='mt-2'
                                size='sm'
                                pages={{ current: exports.data.page, total: exports.data.total_pages }}
                                search={(page: number) => { setSearch({ ...search, page }) }}
                            />
                        </>}

                    </LoadingBlock>
                </Tab>

            </Tabs>
        </AppSecured>
    </>
}

const LeadExportWaitingRow: React.FC<LeadExportWaiting> = lead_w => {
    return <tr>
        <td>{lead_w.company}</td>
        <td><Badge>{lead_w.lead}</Badge> {lead_w.email}</td>
        <td>{lead_w.form}</td>
        <td>{lead_w.gateway}</td>
        <td>{lead_w.message}</td>
        <td>{lead_w.retry}</td>
    </tr>
}

const LeadExportRow: React.FC<LeadExport> = lead_e => {
    return <tr className={lead_e.success ? '' : 'alert-danger'}>
        <td> {lead_e.customerFormGateway.gateway.name} </td>
        <td> <Badge bg='secondary' className='alt-primary'>{lead_e.form_id}</Badge> {lead_e.form} </td>
        <td> <Badge bg='secondary' className='alt-secondary'>{lead_e.lead.id}</Badge> {lead_e.lead.email} </td>
        <td> <FormattedDate value={lead_e.timestamp * 1000} year='numeric' month='2-digit' day="2-digit" hour='2-digit' minute="2-digit" /> </td>
        <td className='text-end'>
            {lead_e.success ? <Badge bg='success'> </Badge> : <span className='alert-danger'>{lead_e.message}</span>}
        </td>
    </tr>
}

export default LeadsExportList;