import React from "react";
import { Modal } from "react-bootstrap";
import { useAppSelector } from "../../app/hooks";
import { isAnyModalOpened, isModalOpened, ModalTypes } from "../../app/slice/modal";
import { Icon } from "../misc/Buttons";
import ModalCloseButton from "../Modals/ModalCloseButton";
import { MediaBlock } from "./MediaList";

const ModalMedias: React.FC = () => {
    const show = useAppSelector(isAnyModalOpened);
    return <>
        {useAppSelector(isModalOpened(ModalTypes.Medias)) && <>
            <Modal size="xl" show={show}>
                <Modal.Header>
                    <Modal.Title>
                        <Icon code='image' /> Sélectionnez une image
                    </Modal.Title>
                    <ModalCloseButton />
                </Modal.Header>
                <Modal.Body>
                    <MediaBlock display={'modal'} />
                </Modal.Body>
                <Modal.Footer>
                    <ModalCloseButton />
                </Modal.Footer>
            </Modal>
        </>
        }
    </>
}

export default ModalMedias;