import { useEffect } from "react";
import { Alert, Card, Col, FormCheck, FormControl, FormLabel, FormSelect, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import globalApi from "../../../app/services/secured/global";
import manageApi from "../../../app/services/secured/manage";
import { LANDING_STATUSES } from "../../../app/slice/landing";
import { getLandingInfos, getLandingRegister, setRegister } from "../landing-v2";

const RGPD = () => {

    const dispatch = useAppDispatch();
    const lInfos = useAppSelector(getLandingInfos);
    const [getCompany, company] = manageApi.useLazyGetCompanyQuery();
    const countries = globalApi.useGetCountriesQuery();
    const customers = globalApi.useGetCustomersQuery();
    const isLive = lInfos.status === LANDING_STATUSES.LIVE;
    const register = useAppSelector(getLandingRegister);

    useEffect(() => {
        if (lInfos.company) getCompany({ id: lInfos.company });
    }, [lInfos.company])

    return <Card>
        <Card.Body>
            {!isLive && <>
                <Alert variant='danger'>
                    <Alert.Heading>
                        Landing page non-active
                    </Alert.Heading>
                    Vous pouvez uniquement éditer la partie RGPD lorsque la landing page est active.
                    Passez le statut en mode 'Live' pour pouvoir éditer les RGPD.
                </Alert>
            </>}
            <div style={!isLive ? { filter: 'blur(8px)', pointerEvents: 'none' } : {}}>
                <Alert variant="info">Hébergeur web : Amazon Web services</Alert>
                <Row>
                    <Col>
                        <FormLabel className='fs-16'>Objectifs de la collecte de données*</FormLabel>
                        <FormControl type='text' defaultValue={register.objectives}
                            onChange={(e) => dispatch(setRegister({ ...register, objectives: e.target.value }))} />
                    </Col>
                    <Col>
                        <FormLabel className='fs-16'> Catégories de personnes concernées par la collecte*</FormLabel>
                        <FormControl type='text' defaultValue={register.target}
                            onChange={(e) => dispatch(setRegister({ ...register, target: e.target.value }))} />
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col>
                        <FormLabel className='fs-16'>Destinataire principal*</FormLabel>
                        {customers.isSuccess && <>
                            <FormSelect defaultValue={register.recipientId}
                                    onChange={(e) => dispatch(setRegister({ ...register, recipientId: parseInt(e.target.value) }))}>
                                <option>Aucun destinaire</option>
                                {customers.data?.filter(c => c.company === lInfos.company).map(c => <option key={c.id} value={c.id}>{c.name}</option>)}
                            </FormSelect>
                        </>}

                    </Col>
                    <Col>
                        <FormLabel className='fs-16'>Pays en dehors de l'UE où la donnée est transférée (si nécessaire)</FormLabel>
                        {countries.isSuccess && <>
                            <Typeahead
                                multiple
                                id='select-transfert-countries'
                                options={countries.data?.map(c => { return { name: c.name, code: c.code } })}
                                defaultSelected={
                                    register.transfertCountries.length 
                                        ? countries.data.filter(c => register.transfertCountries.indexOf(c.code) > -1).map(c => { return { name: c.name, code: c.code } })
                                        : []
                                }
                                labelKey="name"
                                onChange={(s) => {
                                    const ctrs = s as Array<{ name: string, code: string }>
                                    dispatch(setRegister({ ...register, transfertCountries: s.length < 0 ? [] : ctrs.map(c => c.code) }));
                                }}
                            />
                        </>}
                    </Col>
                </Row>
                <Row className='mt-4'>
                    <Col>
                        <FormLabel className='fs-16'>Liste des sous-traitants (hors hébergeur)*</FormLabel>
                        <FormControl as="textarea" defaultValue={register.subcontractors}
                            onChange={(e) => dispatch(setRegister({ ...register, subcontractors: e.target.value }))} />
                    </Col>
                    <Col>
                        <FormLabel className='fs-16'>Durée de conservation des données (mois)</FormLabel>
                        <FormControl className={!register.retentionMonth ? 'is-invalid' : ''}
                            type='number' defaultValue={register.retentionMonth}
                            onChange={(e) => dispatch(setRegister({ ...register, retentionMonth: parseInt(e.target.value) }))} />

                        <FormCheck type='switch' className='mt-3' label='Données sensibles collectées ?'
                            onChange={(e) => { dispatch(setRegister({ ...register, isSensitiveData: e.target.checked })) }}
                            defaultChecked={register?.isSensitiveData} />

                        {register.isSensitiveData && <>
                            <FormLabel className='fs-16'>Nature des données sensibles collectées</FormLabel>
                            <FormControl as="textarea" defaultValue={register.sensitiveDataDetail}
                                onChange={(e) => dispatch(setRegister({ ...register, sensitiveDataDetail: e.target.value }))} />
                        </>}
                    </Col>
                </Row>
            </div>
        </Card.Body>
    </Card>
}

export default RGPD;