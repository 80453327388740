import { useState } from "react";
import { Card, Col, FormCheck, FormControl, FormLabel, FormSelect, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import creationApi from "../../../app/services/secured/creation";
import dataApi from "../../../app/services/secured/data";
import { convertCheckPhoneOptions, getLanding, optinMode, PHONE_ACCOUNT_VERIF, PHONE_BASE_VERIF, PHONE_LP_VERIF, PHONE_NO_VERIF, setLanding, setLandingData, setOptions } from "../../../app/slice/landing";
import { FormInputGroup } from "../../misc/Blocks";
import { Icon } from "../../misc/Buttons";
import { RichEditor } from "../../misc/TinymceEditor";



const LandingOptions = () => {
    const dispatch = useAppDispatch();
    const landing = useAppSelector(getLanding);
    const [optin, setOptin] = useState(landing.data.optin_mode);
    const databases = dataApi.useGetDatabaseListQuery({ company_id: landing.company });
    
    let phoneOptToApi = [...landing.options.checkOptions as Array<number>];

    const handlePhoneOptions = (value: string, checked?: boolean) => {
        const v = parseInt(value);
        if ([0, 1, 2].includes(v)) {
            phoneOptToApi[0] = v;
        } else {
            phoneOptToApi[1] = checked ? v : 0;
        }

        dispatch(setLanding({ ...landing, options: { ...landing.options, checkOptions: phoneOptToApi } }));
    }

    return <div>
        <Row>
            <Col>
                <Card>
                    <Card.Header as="h4">
                        <Icon code='person' /> Interface client
                    </Card.Header>
                    <Card.Body>
                        <FormLabel className='fs-16'>Templates accessibles interface client</FormLabel>
                        {
                            landing.enabledTemplates && <>
                                <Typeahead
                                    id='select-customer-templates'
                                    defaultSelected={landing.data.current_templates?.filter(c => c).map(c => { return { label: c.toString(), code: c } })}
                                    options={
                                        landing.enabledTemplates && landing.enabledTemplates?.length > 0
                                            ? landing.enabledTemplates
                                                .filter(t => landing.data.current_templates ? landing.data.current_templates?.indexOf(t.toString()) === -1 : true)
                                                .map(t => { return { label: t.toString(), code: t } }) as any
                                            : []
                                    }
                                    multiple
                                    onChange={(s) => {
                                        if (s && s.length > 0) {
                                            const customerEnabled = s as Array<{ id: number, code: string }>;
                                            dispatch(setLandingData({ ...landing.data, current_templates: customerEnabled.map(c => c.code) }));
                                        }
                                    }}
                                />
                            </>
                        }
                        <Row className='d-flex align-items-center mt-2'>
                            <Col sm={7}>
                                <FormLabel className='fs-16 m-0'>Différer dispatch lead (min)</FormLabel>
                            </Col>
                            <Col>
                                <FormControl type='number' defaultValue={landing.dispatchDelay}
                                    onChange={(e) => dispatch(setLanding({ ...landing, dispatchDelay: parseInt(e.target.value) }))} />
                            </Col>
                        </Row>

                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card>
                    <Card.Header as="h4">
                        <Icon code='people' /> Doublons
                    </Card.Header>
                    <Card.Body>
                        <FormLabel>Base doublons emails &amp; téléphones</FormLabel>
                        {
                            databases.data && <>
                                <FormSelect key={landing.options.database?.id} defaultValue={landing.options.database?.id.toString()}
                                    onChange={(e) => dispatch(setOptions({ ...landing.options, emailDatabase: parseInt(e.target.value) }))}
                                >
                                    <option>Aucune</option>
                                    {databases.data?.list[0]?.databases?.map(d => <option key={d.id} value={d.id}>{d.name}</option>)}
                                </FormSelect>
                            </>
                        }
                        <FormCheck type='switch' label='Dédoublonner emails compte client' className='mt-2'
                            defaultChecked={landing.options.check_global_duplicate ? true : false}
                            onChange={(e) => dispatch(setOptions({ ...landing.options, checkGlobalDuplicate: e.target.checked }))} />

                        <hr className='m-1' />

                        <p className='m-0 mt-2'><Icon code='phone' /> Dédoublonnage Téléphone</p>
                        <FormLabel className='radio cursor-pointer mb-0'>
                            <input className='d-none' type='radio' name='phone-duplicate' value='0' defaultChecked={phoneOptToApi[0] === PHONE_NO_VERIF}
                                onChange={(e) => e.target.checked && handlePhoneOptions(e.target.value)} />
                            <div className='d-flex flex-wrap justify-content-center align-items-center py-1 px-2 border-0 rounded'>
                                Non
                            </div>
                        </FormLabel>
                        <FormLabel className='radio cursor-pointer mb-0'>
                            <input className='d-none' type='radio' name='phone-duplicate' value='1' defaultChecked={phoneOptToApi[0] === PHONE_LP_VERIF}
                                onChange={(e) => e.target.checked && handlePhoneOptions(e.target.value)} />
                            <div className='d-flex flex-wrap justify-content-center align-items-center py-1 px-2 border-0 rounded'>
                                Landing Page
                            </div>
                        </FormLabel>
                        <FormLabel className='radio cursor-pointer mb-0'>
                            <input type='radio' className='d-none' name='phone-duplicate' value='2' defaultChecked={phoneOptToApi[0] === PHONE_ACCOUNT_VERIF}
                                onChange={(e) => e.target.checked && handlePhoneOptions(e.target.value)} />
                            <div className='d-flex flex-wrap justify-content-center align-items-center py-1 px-2 border-0 rounded'>
                                Compte client
                            </div>
                        </FormLabel>

                        <FormCheck type='switch' name='phone-duplicate-base' label='Base repoussoir' className='mt-1' value='4' defaultChecked={phoneOptToApi[1] === PHONE_BASE_VERIF}
                            onChange={(e) => handlePhoneOptions(e.target.value, e.target.checked)} />
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card className='me-2 mb-2'>
                    <Card.Header as="h4">
                        <Icon code='lock' /> Sécurité
                    </Card.Header>
                    <Card.Body>
                        <FormCheck type='switch' label='Vérification numéro de téléphone' defaultChecked={landing.options.phoneValidation}
                            onChange={(e) => dispatch(setOptions({ ...landing.options, phoneValidation: e.target.checked }))} />
                        <FormCheck type='switch' label='Vérification email' defaultChecked={landing.options.emailValidation}
                            onChange={(e) => dispatch(setOptions({ ...landing.options, emailValidation: e.target.checked }))} />
                        <FormCheck type='switch' label='Google recaptcha' defaultChecked={landing.data.recaptcha ? true : false}
                            onChange={(e) => dispatch(setLandingData({ ...landing.data, recaptcha: e.target.checked }))} />
                    </Card.Body>
                </Card>
            </Col>
        </Row>

        <Row className="mt-4">

            <Col>
                <Card className='me-2 mb-2'>
                    <Card.Header>
                        <div className=''>
                            <h4><Icon code='facebook' /> Réseaux sociaux</h4>
                            <div className='d-flex'>
                                <FormCheck type='switch' label='Afficher connexions'
                                    defaultChecked={landing.data.rs_connect === '1' ? true : false}
                                    onChange={(e) => dispatch(setLandingData({ ...landing.data, rs_connect: e.target.checked ? '1' : '0' }))} />

                                <FormCheck type='switch' label='Afficher partages' className='ms-2'
                                    defaultChecked={landing.data.rs_share === 1 ? true : false}
                                    onChange={(e) => dispatch(setLandingData({ ...landing.data, rs_share: e.target.checked ? 1 : 0 }))} />
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <FormLabel className='fs-16'>Titre publication</FormLabel>
                        <FormControl as="textarea" defaultValue={landing.data.share_title}
                            onChange={(e) => dispatch(setLandingData({ ...landing.data, share_title: e.target.value }))} />

                        <FormLabel className='mt-3 fs-16'>Description publication</FormLabel>
                        <FormControl as="textarea" defaultValue={landing.data.share_desc}
                            onChange={(e) => dispatch(setLandingData({ ...landing.data, share_desc: e.target.value }))} />
                    </Card.Body>
                </Card>
            </Col>

            <Col>
                <Card className='me-2 mb-2'>
                    <Card.Header as="h4">
                        <Icon code='wrench' /> Configuration optin
                    </Card.Header>
                    <Card.Body>
                        <FormInputGroup label="Comportement de l'optin">
                            <FormSelect defaultValue={landing.data.optin_mode} onChange={(e) => {
                                setOptin(parseInt(e.target.value));
                                dispatch(setLandingData({ ...landing.data, optin_mode: parseInt(e.target.value) }));
                            }}>
                                {optinMode.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
                            </FormSelect>
                        </FormInputGroup>

                        <FormLabel className='mt-3'>Nom client dans l'optin par défaut</FormLabel>
                        <FormControl defaultValue={landing.data.optin_name}
                            onBlur={(e) => dispatch(setLandingData({ ...landing.data, optin_name: e.target.value }))} />

                        {optin !== 2
                            ? <>
                                <FormLabel className='mt-3'>Optin personnalisée</FormLabel>

                                <RichEditor initVal={landing.data.optin ? landing.data.optin : ' '}
                                    onBlur={(content) => dispatch(setLandingData({ ...landing.data, optin: content }))} />
                            </>
                            : <Row>
                                <Col>
                                    <FormLabel className='mt-3'>1ère optin</FormLabel>
                                    <FormControl defaultValue={landing.data.optin_text_one}
                                        onBlur={(e) => dispatch(setLandingData({ ...landing.data, optin_text_one: e.target.value }))} />
                                </Col>
                                <Col>
                                    <FormLabel className='mt-3'>2ème optin</FormLabel>
                                    <FormControl defaultValue={landing.data.optin_text_two}
                                        onBlur={(e) => dispatch(setLandingData({ ...landing.data, optin_text_two: e.target.value }))} />
                                </Col>
                            </Row>}

                        <FormLabel className='mt-3'>Texte alerte si choix "non optin" sélectionné</FormLabel>
                        <FormControl as="textarea" defaultValue={landing.data.optin_alert}
                            onBlur={(e) => dispatch(setLandingData({ ...landing.data, optin_alert: e.target.value }))} />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </div>
}

export default LandingOptions;