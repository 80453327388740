import {useState} from "react";
import {Alert, Button, FormCheck, FormLabel, InputGroup, Modal} from "react-bootstrap";
import {useAppSelector} from "../../app/hooks";
import {StorageManager} from "../../app/services/storage";
import {getModalData, isModalOpened, ModalTypes} from "../../app/slice/modal";
import ApiUrls from "../../assets/js/ApiUrls";
import {Company} from "../../types";
import {ButtonIcon} from "../misc/Buttons";
import {SimpleDateInit} from "../misc/DatePeriod";
import DatePicker from "../misc/DatePicker";
import SimpleForm from "../misc/SimpleForm";
import ModalCloseButton from "../Modals/ModalCloseButton";

const ModalExportEmail = () => {
    const isOpened = useAppSelector(isModalOpened(ModalTypes.CompanyExportEmails));
    const data = useAppSelector<Company>(getModalData);

    const [range, setRange] = useState<Array<string>>([SimpleDateInit.from, SimpleDateInit.to]);
    const [downloadLink, setDownloadLink] = useState<URL>();

    const [crypt, setCrypt] = useState('md5');

    const generateLink = () => {
        const link = new URL(ApiUrls.companies.downloadEmails(data.id, StorageManager.get()), process.env.REACT_APP_API_DOMAIN);
        link.searchParams.set("from", range[0]);
        link.searchParams.set("to", range[1]);
        link.searchParams.set("format", crypt);
        setDownloadLink(link);
    }

    return <>
        {isOpened && <>
            <SimpleForm onSubmit={(json) => {}}>
                <Modal.Header>
                    {data.name} | Export des emails
                </Modal.Header>
                <Modal.Body>
                    <div className='d-flex flex-column align-items-center'>
                        <Alert variant='info'>Sélectionnez un période et un format de cryptage pour en extraire les leads.</Alert>
                        <DatePicker onChange={(from, to) => setRange([from, to]) } />
                        <div>
                            <InputGroup>
                                <FormCheck type='radio' name='crypt' id='md5' value='md5' defaultChecked={true}
                                    onChange={(e) => { if (e.target.checked) setCrypt('md5') }} />
                                <FormLabel htmlFor="md5" className='ms-2' >md5</FormLabel>
                            </InputGroup>

                            <InputGroup>
                                <FormCheck type='radio' name='crypt' id='sha256'
                                    onChange={(e) => { if (e.target.checked) setCrypt('sha256') }} />
                                <FormLabel htmlFor="sha256" className='ms-2'>sha256</FormLabel>
                            </InputGroup>   
                        </div>
                    </div>
                    {downloadLink && <ButtonIcon href={downloadLink.toString()} code={"save"} size={"sm"} variant={"primary"}>Télécharger</ButtonIcon>}
                </Modal.Body>
                <Modal.Footer>
                    <ModalCloseButton />
                    <Button type='submit' onClick={() => generateLink()}>Extraire</Button>
                </Modal.Footer>
            </SimpleForm>
        </>}
    </>
}

export default ModalExportEmail;