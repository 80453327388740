import React, { useEffect, useState } from "react";
import { Badge, Nav, Tab, Tabs } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import globalApi from "../../app/services/secured/global";
import manageApi from "../../app/services/secured/manage";
import AppSecured from "../../AppSecured";
import Urls from "../../assets/js/Urls";
import { PageHeader } from "../App/PageHeader";
import { LoadingBlock } from "../misc/Blocks";
import { ButtonIcon, Icon } from "../misc/Buttons";
import TableGenelead from "../misc/Table";

export const WebservicesObj: Record<string, string> = {
    "SendinBlue": 'sendinblue',
    "LMP Santé": 'lmp-sante',
    "ADM VALUE": "adm",
    "Pipedrive": 'pipedrive',
    "Mailer Genelead": "mailer",
    "EdiisAlizée": "ediis",
    "Talk": "tawk",
    "Plezi": "plezi"
}

const Webservices = () => {
    const [search, setSearch] = useState<{ company_id?: number, form_id?: number }>({});
    const webservices_2 = manageApi.useGetWebservicesQuery(search);

    const companies = globalApi.useGetCompaniesListQuery();
    const forms = globalApi.useGetFormsListQuery();

    return <>
        <AppSecured>
            <PageHeader>
                <h1><Icon code='hdd-network' /> Webservices</h1>
                <div className='ms-4 d-flex'>
                    {companies.isSuccess && companies.data && <>
                        <Typeahead
                            id='company-search'
                            placeholder="Client"
                            options={companies.data.map(c => { return { label: c.name, value: c.id } })}
                            onChange={(s) => {
                                if (s[0]) {
                                    const val = s[0] as { label: string, value: number }
                                    setSearch({ ...search, company_id: val.value });
                                } else {
                                    const { company_id, ...rst } = search;
                                    setSearch(rst);
                                };
                            }}
                        />
                    </>}
                    {forms.isSuccess && forms.data && <>
                        <Typeahead
                            id='forms-search'
                            placeholder="Formulaires"
                            className='ms-2'
                            options={forms.data.map(f => { return { label: f.name, value: f.id } })}
                            onChange={(s) => {
                                if (s[0]) {
                                    const val = s[0] as { label: string, value: number }
                                    setSearch({ ...search, form_id: val.value });
                                } else {
                                    const { form_id, ...rst } = search;
                                    setSearch(rst);
                                };
                            }}
                        />
                    </>}
                </div>
            </PageHeader>
            <LoadingBlock data={webservices_2}>
                <Tab.Container defaultActiveKey={1}>
                    <Nav className='nav-tabs'>
                        {webservices_2.data?.map(w => <Nav.Item key={w.gateway_id}><Nav.Link eventKey={w.gateway_id}>{w.gateway}</Nav.Link></Nav.Item>)}
                    </Nav>
                    <Tab.Content>
                        {webservices_2.data?.map((w, i) => <Tab.Pane key={w.gateway_id} eventKey={w.gateway_id}>
                            <TableGenelead className="mt-3" hasActions={true} header={[
                                { name: 'Client', icon: 'building' },
                                { name: 'Compte', icon: 'person' },
                                { name: 'Formulaire', icon: 'file' },
                            ]}>
                                {w.webservice.map((x, j) => <React.Fragment key={j}>
                                    <tr>
                                        <td>{x.company}</td>
                                        <td>{x.customer}</td>
                                        <td>{x.form_code}</td>
                                        <td className='text-end'><ButtonIcon href={
                                            Urls.companies.view(x.company_id) + `?form=${x.form_code}&account=${x.customer_id}&gateway=${WebservicesObj[w.gateway]}`}
                                            code={"eye"} size={"lg"} variant={"primary"}></ButtonIcon></td>
                                    </tr>
                                </React.Fragment>)}
                            </TableGenelead>
                        </Tab.Pane>)}
                    </Tab.Content>
                </Tab.Container>
            </LoadingBlock>
        </AppSecured>
    </>
}

export default Webservices;