import { useRef, useState } from "react";
import { Accordion, Button, FormControl, FormLabel } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { ButtonIcon } from "../misc/Buttons";
import { ColumnDesign } from "./Components/Column";
import { ContentDesign } from "./Components/Content/Wrapper";
import { ModalDesign } from "./Components/Modal";
import { RowDesign } from "./Components/Row";
import { addRow, addRowTemplate, FormStep, getView, LandingV2 } from "./landing-v2";
import { PageDesign } from "./LPGenerator";

export const DesignTitle: React.FC<{ title: string, code: string, className?: string }> = ({ title, code, className }) => {
  return <div className={'design-title ' + className}>
    <h6 className='m-0 d-flex align-items-center justify-content-start p-2' >
      <Icon code={code} /> <span className='ms-2'>{title}</span>
    </h6>
  </div>
}

export const ContainersMenu: React.FC<{ step: FormStep }> = ({ step }) => {
  const dispatch = useAppDispatch();
  const [row, setAddRow] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const models = containerModels();
  return <div id='containers-menu'>
    {!row ? <>
      <Button size='lg' className='w-100 p-2' onClick={() => setAddRow(true)}>
        <Icon code='plus-circle' /> Conteneur
      </Button>
    </>
      : <div id='add-wrapper'>
        <div className='d-flex align-items-center'>
          <span>Modèles</span>
          {models.map((m, i) => <Button key={`btn-container-${i}`} className='ms-1'
            onClick={() => {
              dispatch(addRowTemplate(m));
              setAddRow(false);
            }}>{m.length} cols</Button>)}
        </div>
        <div className='d-flex align-items-center mt-2'>
          <span className='me-1'>Manuel</span>
          <FormControl placeholder='Nombre de colonnes' type='number' ref={inputRef} />
          <ButtonIcon code='check' size='lg' variant='primary' className='ms-1'
            onClick={() => {
              inputRef.current && dispatch(addRow(parseInt(inputRef.current.value)));
              setAddRow(false);
            }} />
        </div>
      </div>
    }
  </div>
}

export function containerModels() {
  const columnContainer: LandingV2 = { childs: [], type: 'col', style: {} };
  const models = [];
  models[0] = [{ ...columnContainer, size: 12 }];
  models[1] = [{ ...columnContainer, size: 6 }, { ...columnContainer, size: 6 }];
  models[2] = [{ ...columnContainer, size: 8 }, { ...columnContainer, size: 4 }];
  models[3] = [{ ...columnContainer, size: 4 }, { ...columnContainer, size: 4 }, { ...columnContainer, size: 4 }];
  models[4] = [{ ...columnContainer, size: 2 }, { ...columnContainer, size: 8 }, { ...columnContainer, size: 2 }];
  models[5] = [{ ...columnContainer, size: 3 }, { ...columnContainer, size: 3 }, { ...columnContainer, size: 3 }, { ...columnContainer, size: 3 }];
  models[6] = [{ ...columnContainer, size: 3 }, { ...columnContainer, size: 3 }, { ...columnContainer, size: 3 }, { ...columnContainer, size: 3 }];

  return models;
}

export const Icon: React.FC<{ code: string, className?: string, style?: object }> = (props) => {
  return <span style={props.style ? props.style : {}}><span className={`bi bi-${props.code} ${props.className}`} /></span>
}

export const DesignElement: React.FC<{ children?: any, eventKey: string, title: string, }> = ({ children, eventKey, title }) => {
  return <>
    <Accordion.Item eventKey={eventKey}>
      <Accordion.Header>{title}</Accordion.Header>
      <Accordion.Body>{children}</Accordion.Body>
    </Accordion.Item>
  </>
}

export const DesignControl: React.FC<{ children?: any, title: string }> = ({ title, children }) => {
  return <div className='design-control'>
    <FormLabel>{title}</FormLabel>
    {children}
  </div>
}

export const DesignSidebar = () => {
  const view = useAppSelector(getView);
  return <>
    <div style={{ position: 'sticky', top: '20px' }}>
      {view === 'page-design' && <><PageDesign /></>}
      {view === 'row-design' && <><RowDesign /></>}
      {view === 'block-design' && <><ColumnDesign /></>}
      {view === 'content-design' && <><ContentDesign /></>}
      {view === 'modal-design' && <><ModalDesign /></>}
      {/* {view === 'info' && <><ToolsTitle title='Info' /><LandingInfos /></>} */}
    </div>
  </>
}