import { FormCheck } from "react-bootstrap";
import { useAppDispatch } from "../../../../app/hooks";
import { DesignElement, Icon } from "../../Design";
import { LandingV2, handleSocials, getCurrentContent, setContentDesign } from "../../landing-v2";
import ColorPicker from "../../Tools/ColorPicker";
import { useAppSelector } from "../../../../app/hooks";
import GenerateStyle from "../../Tools/GenerateStyle";

export const SocialsContent: React.FC<{ isEdit: boolean, block: number, node: LandingV2 }> = ({ isEdit, block, node }) => {
   const dispatch = useAppDispatch();
   const hasFb = node.childs.findIndex(c => c.type === 'fb') > -1;
   const hasTwitter = node.childs.findIndex(c => c.type === 'twitter') > -1;
   const hasLinkedin = node.childs.findIndex(c => c.type === 'linkedin') > -1;
   // const hasInstagram = node.childs.findIndex(c => c.type === 'instagram') > -1;
      
   return <div style={node.style}>
      {isEdit
         ? <div className='pt-1 fs-20'>
            <div className='d-flex justify-content-center'>
               <div className='d-flex'>
                  <Icon code='facebook' />
                  <FormCheck type='switch' defaultChecked={hasFb}
                     onChange={(e) => dispatch(handleSocials({ block, active: e.target.checked, rs: 'fb' }))} />
               </div>
               <div className='d-flex'>
                  <Icon code='twitter' />
                  <FormCheck type='switch' defaultChecked={hasTwitter}
                     onChange={(e) => dispatch(handleSocials({ block, active: e.target.checked, rs: 'twitter' }))} />
               </div>
               <div className='d-flex'>
                  <Icon code='linkedin' />
                  <FormCheck type='switch' defaultChecked={hasLinkedin}
                     onChange={(e) => dispatch(handleSocials({ block, active: e.target.checked, rs: 'linkedin' }))} />
               </div>
               {/* <div className='d-flex'>
                  <Icon code='instagram' />
                  <FormCheck type='switch' defaultChecked={hasInstagram}
                     onChange={(e) => dispatch(handleSocials({ block, active: e.target.checked, rs: 'instagram' }))} />
               </div> */}
            </div>
         </div>
         : <>
            <div className='d-flex justify-content-center'>
               { hasFb && <span style={{ fontSize: '30px' }}><Icon code='facebook' /></span> }
               { hasTwitter && <span style={{ fontSize: '30px' }}><Icon code='twitter' className='ms-2' /></span> }
               { hasLinkedin && <span style={{ fontSize: '30px' }}><Icon code='linkedin' className='ms-2' /></span> }
               {/* { hasInstagram && <span style={{ fontSize: '30px' }}><Icon code='instagram' className='ms-2' /></span> } */}
            </div>
         </>
      }
   </div>
}

export const SocialsDesign = () => {
   const dispatch = useAppDispatch();
   const socials = useAppSelector(getCurrentContent);
   return (
      <DesignElement eventKey="3" title="Aspect">
         <ColorPicker title='' 
            value={socials?.style.color}
            onChange={(color:string) => {
               dispatch(setContentDesign({option:'color', value:color}));
            }}
          />
      </DesignElement>
   )
}

