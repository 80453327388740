import { Modal } from "react-bootstrap";
import { useAppSelector } from "../../app/hooks";
import { getModalData, isModalOpened, ModalTypes } from "../../app/slice/modal";
import SimpleForm from "../misc/SimpleForm";
import ModalCloseButton from "../Modals/ModalCloseButton";
import InformationsPane from "./AccountInfo";

const ModalNewAccount = () => {
    const isOpened = useAppSelector(isModalOpened(ModalTypes.NewCompanyAccount));
    const company_id = useAppSelector(getModalData);
    return <> {isOpened && company_id && <>
        <Modal.Header>
            Nouveau compte
        </Modal.Header>
        <Modal.Body>
            <InformationsPane company={company_id} />
        </Modal.Body>
        <Modal.Footer>
            <ModalCloseButton />
        </Modal.Footer>
    </>}</>
}

export default ModalNewAccount;