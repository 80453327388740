import { useEffect, useLayoutEffect, useState } from "react";
import { Accordion, Alert, Badge, Button, Card, Col, Dropdown, DropdownButton, Row } from "react-bootstrap";
import { useAppDispatch } from "../../app/hooks";
import manageApi from "../../app/services/secured/manage";
import { modalLeadsPendingShow } from "../../app/slice/modal";
import { CompanyAccountForm, Customer, FormGateway, RefreshLeadsProps } from "../../types";
import { LoadingBlock } from "../misc/Blocks";
import { ButtonIcon, Icon } from "../misc/Buttons";
import ADMValue from "./ApiForms/ADMValue";
import EdiisAlizeeForm from "./ApiForms/EdiisAlizee";
import LPMSante from "./ApiForms/LPMSante";
import MailerGenelead from "./ApiForms/MailerGenelead";
import Pipedrive from "./ApiForms/Pipedrive";
import SendInBlueForm from "./ApiForms/SendInBlue";
import TalkForm from "./ApiForms/Talk";

const Webservices: Record<string, string> = {
    sendinblue: "SendinBlue",
    tawk: "Talk",
    ediis: "EdiisAlizée",
    //plezi: "Plezi",
    mailer: "Mailer Genelead",
    pipedrive: "Pipedrive",
    adm: "ADM VALUE",
    "lmp-sante": "LMP Santé",
    //interdit: "Interdit au public"
}

const ExportPane: React.FC<Customer & { company: number, formSelected: string | null, gatewaySelected: string | null }> = account => {
    const [activeK, setActiveK] = useState('')
    useEffect(() => { if (account.formSelected) setActiveK(account.formSelected) }, [account.formSelected]);
    return <>
        <Row>
            {account.forms.map((f, i) => <FormGatewaysItem gatewaySelected={f.form.code === account.formSelected ? account.gatewaySelected : null} key={i}
                customer={account.id} company={account.company} {...f} />)}
        </Row>
    </>
}

const FormGatewaysItem: React.FC<CompanyAccountForm & { company: number, customer: number, gatewaySelected: string | null }> = form => {
    const [getFormGateways, formGateways] = manageApi.useLazyGetFormGatewaysQuery();

    useLayoutEffect(() => {
        if (form.id) getFormGateways({ company: form.company, form: form.id });
    }, [form.id])
    
    const [showExportForm, setShowExportForm] = useState('');
    const [newWebservice, setNewWebservice] = useState('');

    const showExportConfig = (code: string) => () => {
        setShowExportForm(code);
        setNewWebservice('');
    }

    const showNewWebservice = (code: string) => () => {
        setShowExportForm('');
        setNewWebservice(code);
    }
    useEffect(() => {
        if (formGateways.isSuccess) setNewWebservice('');
    }, [formGateways])

    useLayoutEffect(() => {
        if (formGateways.isSuccess && form.gatewaySelected) {
            setShowExportForm(form.gatewaySelected);
        }
    }, [form.gatewaySelected, formGateways.isSuccess])

    return <>
        <Col sm={6}>
            <Card className='card-form mb-2'>
                <Card.Header>
                    <Badge className='alt-primary fs-14'>{form.form.id}</Badge>
                    <span className='ms-2'>{form.form.name}</span>
                </Card.Header>
                <Card.Body>
                    <LoadingBlock data={formGateways}>
                        <div className='d-flex flex-wrap'>

                            {formGateways.data &&
                                formGateways.data.map(f =>
                                    <Button key={Math.random()} variant={f.enabled ? 'success' : 'danger'} size="sm"
                                        className={showExportForm === f.gateway.code ? 'ms-1 mb-1' : f.enabled ? 'alt-success ms-1 mb-1' : 'alt-danger ms-1 mb-1'}
                                        onClick={showExportConfig(showExportForm && showExportForm === f.gateway.code ? '' : `${f.gateway.code}.${f.id}`)}>
                                        {f.gateway.name}
                                    </Button>
                                )}

                            {!newWebservice && <>
                                <DropdownButton variant="outline-primary" title="Ajouter" id="input-group-dropdown-1"
                                    className='ms-1' size="sm" >
                                    {Object.keys(Webservices)
                                        // .filter(w => formGateways.data?.findIndex(f => f.gateway.code === w) === -1)
                                        .map((w) => <Dropdown.Item key={Math.random()} onClick={showNewWebservice(w)}>{(Webservices)[w]}</Dropdown.Item>)}
                                </DropdownButton>
                            </>}

                            {newWebservice &&
                                <ButtonIcon className='ms-1 alt-secondary' variant='secondary' code='x' size='sm'
                                    onClick={showNewWebservice('')}>Annuler</ButtonIcon>}
                        </div>

                        <div>
                            {formGateways.data && <>
                                {showExportForm.indexOf('sendinblue.') > -1 &&
                                    <SendInBlueForm
                                        key={showExportForm}
                                        company={form.company}
                                        form={form.id}
                                        customer={form.customer}
                                         {...formGateways.data.find(f => f.id === parseInt(showExportForm.split(".")[1])) as FormGateway} />}
                                {showExportForm.indexOf('tawk') > -1 &&
                                    <TalkForm key={showExportForm} company={form.company} form={form.id} customer={form.customer}
                                              {...formGateways.data.find(f => f.id === parseInt(showExportForm.split(".")[1])) as FormGateway} />}
                                {showExportForm.indexOf('ediis') > -1 &&
                                    <EdiisAlizeeForm key={showExportForm} company={form.company} form={form.id} customer={form.customer}
                                                     {...formGateways.data.find(f => f.id === parseInt(showExportForm.split(".")[1])) as FormGateway} />}
                                {showExportForm.indexOf('mailer') > -1 &&
                                    <MailerGenelead key={showExportForm} company={form.company} form={form.id} customer={form.customer}
                                                    {...formGateways.data.find(f => f.id === parseInt(showExportForm.split(".")[1])) as FormGateway} />}
                                {showExportForm.indexOf('pipedrive') > -1 &&
                                    <Pipedrive key={showExportForm} company={form.company} form={form.id} customer={form.customer}
                                               {...formGateways.data.find(f => f.id === parseInt(showExportForm.split(".")[1])) as FormGateway} />}
                                {showExportForm.indexOf('adm') > -1 &&
                                    <ADMValue key={showExportForm} company={form.company} form={form.id} customer={form.customer}
                                              {...formGateways.data.find(f => f.id === parseInt(showExportForm.split(".")[1])) as FormGateway} />}
                                {showExportForm.indexOf('lmp-sante') > -1 &&
                                    <LPMSante key={showExportForm} company={form.company} form={form.id} customer={form.customer}
                                              {...formGateways.data.find(f => f.id === parseInt(showExportForm.split(".")[1])) as FormGateway} />}
                            </>}

                            {newWebservice === 'ediis' && <EdiisAlizeeForm company={form.company} form={form.id} customer={form.customer} />}
                            {newWebservice === 'mailer' && <MailerGenelead company={form.company} form={form.id} customer={form.customer} />}
                            {newWebservice === 'pipedrive' && <Pipedrive company={form.company} form={form.id} customer={form.customer} />}
                            {newWebservice === 'adm' && <ADMValue company={form.company} form={form.id} customer={form.customer} />}
                            {newWebservice === 'lmp-sante' && <LPMSante company={form.company} form={form.id} customer={form.customer} />}
                            {newWebservice === 'sendinblue' && <SendInBlueForm company={form.company} form={form.id} customer={form.customer} />}
                            {newWebservice === 'tawk' && <TalkForm company={form.company} form={form.id} customer={form.customer} />}
                            {/* {newWebservice === 'interdit' &&  <Interditaupublic />} */}
                            {/* {newWebservice === 'plezi' &&  <Plezi />} */}
                        </div>
                    </LoadingBlock>
                </Card.Body>
            </Card>
        </Col>
    </>
}

export const PendingLeadsButton: React.FC<RefreshLeadsProps> = props => {
    const dispatch = useAppDispatch();
    return <>
        <ButtonIcon size='sm' variant='outline-primary' className='ms-2' code='clock-history'
            onClick={() => dispatch(modalLeadsPendingShow(props))} >
            Repasser leads en file d'attente
        </ButtonIcon>
    </>
}

export default ExportPane;