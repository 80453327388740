import { useState } from "react";
import { Alert, Button, Col, Form, FormControl, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { formSuccessRedirect } from "../../app/actions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import dataApi from "../../app/services/secured/data";
import globalApi from "../../app/services/secured/global";
import { getModalData } from "../../app/slice/modal";
import { Company, Database, modalDatabaseProps } from "../../types";
import { BlockError, FormInputGroup } from "../misc/Blocks";
import { BtnIconLoad } from "../misc/Buttons";
import SimpleForm from "../misc/SimpleForm";
import ModalCloseButton from "../Modals/ModalCloseButton";

export const DatabaseNew = () => {
    const dispatch = useAppDispatch();
    const companies = globalApi.useGetCompaniesListQuery();

    const [create, res] = dataApi.useCreateDatabaseMutation();
    dispatch(formSuccessRedirect({ data: res, isModal: true, message: 'Succès création de la base' }))
    return <>
        <SimpleForm onSubmit={(form) => create(form as Database)}>
            <BlockError data={res} />

            <DataForm type='new' companies={companies.data} />
            
            <Row className="mt-2">
                <Col className='d-flex justify-content-end'>
                    <div className='me-1'><ModalCloseButton /></div>
                    <BtnIconLoad type='submit' data={res} icon='save'>
                        Créer
                    </BtnIconLoad>
                </Col>
            </Row>
        </SimpleForm>
    </>
}

export const DatabaseNewFromCompany = () => {
    const dispatch = useAppDispatch();
    const data = useAppSelector<modalDatabaseProps>(getModalData);
    const [create, res] = dataApi.useCreateDatabaseMutation();

    dispatch(formSuccessRedirect({ data: res, isModal: true, message: 'Succès création de la base' }))
    return <>
        <BlockError data={res} />
        <SimpleForm onSubmit={(form) => create(form as Database)}>
                
            <DataForm type='new-from-company' company={data.company?.name} companyId={data.company?.id.toString()} />
            
            <Row className="mt-2">
                <Col className='d-flex justify-content-end'>
                    <div className='me-1'><ModalCloseButton /></div>
                    <BtnIconLoad type='submit' data={res} icon='save'>
                        Créer
                    </BtnIconLoad>
                </Col>
            </Row>
        </SimpleForm>
    </>
}

export const DatabaseEdit = () => {
    const dispatch = useAppDispatch();
    const data = useAppSelector<modalDatabaseProps>(getModalData);
    const [update, res] = dataApi.useUpdateDatabaseMutation();
    const [showDelete, setShowDelete] = useState(false);

    dispatch(formSuccessRedirect({ data: res, isModal: true, message: 'Succès modification de la base' }))
    return <>
        {data.database?.id && !showDelete && <>
            <BlockError data={res} />
            <SimpleForm onSubmit={(form) => {
                const formData = form as Pick<Database, 'name'>;
                update({id: data.database?.id as number, name: formData.name});
            }}>
                
                <DataForm type='edit' company={data.database.company?.name} name={data.database?.name} />

                <Row className="mt-2">
                    <Col className='d-flex justify-content-end'>
                        <div className='me-1'><ModalCloseButton /></div>

                        <Button variant="danger" onClick={() => setShowDelete(true)} className='me-1'>
                            Supprimer
                        </Button>
                        
                        <BtnIconLoad type='submit' data={res} icon=''>
                            Modifier
                        </BtnIconLoad>
                    </Col>
                </Row>
            </SimpleForm>
        </>}

        {data.database?.id && showDelete && <DatabaseDelete />}
    </>
}

export const DatabaseDelete: React.FC<any> = props => {
    const dispatch = useAppDispatch();
    const data = useAppSelector<modalDatabaseProps>(getModalData);
    
    const database:Database|undefined = data.database;
    
    const [del, resDelete] = dataApi.useDeleteDatabaseMutation();
    dispatch(formSuccessRedirect({data:resDelete, isModal:true, message:'Succès supression de la base ' + database?.name}))
    return <>
        {database && <>
            <Alert variant="danger">Êtes-vous certain de vouloir supprimer cette base d'emails?</Alert>
            
            {database.forms.length > 0 && <>
                <Alert variant='danger'>
                    <Alert.Heading>Attention!</Alert.Heading>
                    Les opérations suivantes devront être rattachées à une autre base de donneés pour la
                    gestion des doublons :
                    <ul>
                        {database.forms.map(f => <li key={Math.random()}>{<>{f}</>}</li>)}
                    </ul>
                </Alert>
            </>}

            <BlockError data={resDelete} />

            <SimpleForm onSubmit={() => del({id:database.id})}>
                <Row className="mt-2">
                    <Col className='d-flex justify-content-end'>
                        <div className='me-1'><ModalCloseButton /></div>
                        <BtnIconLoad type='submit' data={resDelete} icon='trash' variant="danger">
                            Supprimer
                        </BtnIconLoad>
                    </Col>
                </Row>
            </SimpleForm>
        </>}
    </>
}

type DataFormProps = {
    company?: string,
    companyId?: string,
    companies?: Array<Company>
    name?: string,
    type: string
}

const DataForm: React.FC<DataFormProps> = (props) => {
    const [newCompany, setNewCompany] = useState<number>(0);
    return <>
        <FormInputGroup label='Client'>
            {(props.type === 'edit' || props.type === 'new-from-company') &&
                <Form.Control type='text' name='company' defaultValue={props?.company} disabled />}

            {props.type === 'new-from-company' && <Form.Control type='hidden' name='company' value={props.companyId} />}
        </FormInputGroup>

        {props.type === 'new' && props.companies && <>
                <Typeahead
                    id='select-new-company-database'
                    options={props.companies.map(c => {return {label:c.name, id: c.id} })}
                    className='mb-2'
                    placeholder='Rechercher un client'
                    onChange={(option) => {
                        const chosen = option[0] as {label: string, id: number};
                        setNewCompany(chosen.id);
                    }}
                />
                <FormControl type='hidden' name='company' value={newCompany} onChange={() => {}} />
            </>}
        <FormInputGroup label='Nom base'>
            <Form.Control type='text' name='name' placeholder='Nom base' defaultValue={props?.name} />
        </FormInputGroup>
    </>
}


