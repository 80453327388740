import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Choice, Landing, LandingAPI, LandingDesignData, LandingField, LandingMedia, LandingOptions, LandingQuizz, LandingQuizzAnswers, LandingRegister } from '../../types';
import creationApi from '../services/secured/creation';
import { RootState } from '../store';

export const PHONE_NO_VERIF = 0;
export const PHONE_LP_VERIF = 1;
export const PHONE_ACCOUNT_VERIF = 2;
export const PHONE_BASE_VERIF = 4;

const init: LandingAPI = {
    id: 0,
    category: 0,
    code: '',
    company: 0,
    created: 0,
    description: '',
    dispatchDelay: 0,
    //form: '',
    last: 0,
    minObjective: 0,
    name: '',
    objective: 0,
    status: 0,
    theme: 0,
    visible: false,
    enabledTemplates: [],
    fields: [],
    medias: {},
    options: {
        check_global_duplicate: false,
        database: null,
        emailDatabase: 0,
        emailValidation: false,
        phoneValidation: false,
        checkOptions: [0, 0]
    },
    quizz: [],
    register: {
        isSensitiveData: false,
        objectives: '',
        recipientId: 0,
        retentionMonth: 12,
        sensitiveDataDetail: '',
        subcontractors: '',
        target: '',
        transfertCountries: [],
        timestamps: {
            created: 0,
            updated: 0
        }
    },
    site: { domain: '' },
    data: {
        version: 'v1',
        fields_color: '',
        form_design: '',
        modal_version: 'simple',
        optin_mode: 0,
        post_optin_title: '',
        why: '',
        why_title: '',
        unsubscribe: '',
        title: '',
        subtitle: '',
        share_title: '',
        share_desc: '',
        rs_share: 0,
        rs_connect: '',
        redirect_url: '',
        redirect_text: '',
        policy: '',
        optin_name: '',
        optin: '',
        modal_title: '',
        logo_height: '',
        how_title: '',
        how: '',
        greeting_text: '',
        form_title: '',
        form_description: '',
        excerpt: '',
        cta: '',
        color: '#000000',
        body: '',
        author: '',
        secondary_color: '',
        default_bg_color: '#f8f8f8',
        goto_form: '',
        body2: '',
        body3: '',
        body4: '',
        testimonial: '',
        numbers_title: '',
        numbers: '',
        form_subtitle: '',
        contact_number: '',
        offer_name: '',
        offer_price: '',
        offer_desc: '',
        testimonial_title: '',
        offer: '',
        offer_condition: '',
        redirect_link_text: '',
        comeback_text: '',
        comeback_link: '',
        donation_text: '',
        donation_btn_text: '',
        modal_name: '',
        logo: false,
        video: '',
        bg: '',
        donation_url: '',
        optin_alert: '',
        optin_text_one: '',
        optin_text_two: '',
        recaptcha: '',
        current_templates: [],
        redirect_time: 3000,
    }
};

export const ContentWordings: { default: Array<keyof LandingDesignData>, specific: Array<keyof LandingDesignData> } = {
    default: ['title', 'subtitle', 'excerpt', 'body', 'cta', 'form_title', 'color', 'secondary_color', 'logo_height'],
    specific: ['author', 'post_optin_title', 'unsubscribe', 'policy', 'form_description', 'goto_form', 'body2', 'body3',
        'body4', 'testimonial', 'numbers_title', 'numbers', 'greeting_text', 'form_subtitle', 'contact_number',
        'offer_name', 'offer_price', 'offer_desc', 'testimonial_title', 'why', 'why_title', 'how', 'how_title',
        'offer', 'offer_condition', 'logo', 'redirect_url', 'comeback_link', 'bg', 'video', 'donation_url', 'redirect_time']
}

export const SpecificWordings: Partial<Record<keyof LandingDesignData, { type: string }>> = {
    logo: { type: 'boolean' },
    redirect_url: { type: 'link' },
    comeback_link: { type: 'link' },
    bg: { type: 'image' },
    video: { type: 'link' },
    donation_url: { type: 'link' }
}

export const optinMode = [
    { label: "Double", value: 2 },
    { label: "Invisible", value: 3 },
    { label: "Non obligatoire", value: 0 },
    { label: "Obligatoire", value: 1 },
]

export const LANDING_STATUSES = {
    LIVE: 8,
    DRAFT: 4,
    BIN: 2
}

const landing = createSlice({
    name: "landing",
    initialState: { landing: init, isNew: true, hasEdits: false, isQuizz: false, isDuplicate: false, isLive: false },
    reducers: {
        setLanding: (state, action: PayloadAction<LandingAPI>) => {
            state.landing = action.payload;
            state.hasEdits = true;
        },
        setLandingStatus: (state, action:PayloadAction<number>) => {
            state.landing.status = action.payload;
            if (action.payload === LANDING_STATUSES.LIVE) {
                state.landing.register = {
                    isSensitiveData: false,
                    objectives: '',
                    recipientId: 0,
                    retentionMonth: 12,
                    sensitiveDataDetail: '',
                    subcontractors: '',
                    target: '',
                    transfertCountries: [],
                    timestamps: {
                        created: 0,
                        updated: 0
                    }
                };
            }
        },
        setLandingData: (state, action: PayloadAction<LandingDesignData>) => {
            state.landing.data = action.payload;
        },
        setFields: (state, action: PayloadAction<Array<LandingField>>) => {
            state.landing.fields = action.payload;
        },
        setField: (state, action: PayloadAction<{ index: number, field: LandingField }>) => {
            state.landing.fields[action.payload.index] = action.payload.field;
        },
        setFieldChoices: (state, action: PayloadAction<{ fieldIndex: number, choices: Array<Choice> }>) => {
            state.landing.fields[action.payload.fieldIndex].choices = action.payload.choices;
        },
        setFieldChoice: (state, action: PayloadAction<{ fieldIndex: number, choiceIndex: number, choice: Choice }>) => {
            state.landing.fields[action.payload.fieldIndex].choices[action.payload.choiceIndex] = action.payload.choice;
        },
        setFieldChoiceDefault: (state, action: PayloadAction<{ fieldIndex: number, choiceIndex: number, default: boolean }>) => {
            state.landing.fields[action.payload.fieldIndex].choices[action.payload.choiceIndex].default = action.payload.default;

            if (action.payload.default) {
                state.landing.fields[action.payload.fieldIndex].choices.filter((c, i) => i !== action.payload.choiceIndex).map(c => c.default = false);
            }
        },
        setMedia: (state, action: PayloadAction<{ code: string, media: LandingMedia }>) => {
            state.landing.medias = { ...state.landing.medias, [`${action.payload.code}`]: action.payload.media };
        },
        setOptions: (state, action: PayloadAction<LandingOptions>) => {
            state.landing.options = action.payload;
        },
        setRegister: (state, action: PayloadAction<LandingRegister>) => {
            state.landing.register = action.payload;
        },
        setIsQuizz: (state, action: PayloadAction<boolean>) => {
            state.isQuizz = action.payload;
        },
        setQuizzArray: (state, action: PayloadAction<Array<LandingQuizz>>) => {
            state.landing.quizz = action.payload;
        },
        setQuizz: (state, action: PayloadAction<{ index: number, quizz: LandingQuizz }>) => {
            state.landing.quizz[action.payload.index] = action.payload.quizz;
        },
        setQuizzAnswers: (state, action: PayloadAction<{ index: number, answers: Array<LandingQuizzAnswers> }>) => {
            state.landing.quizz[action.payload.index].answers = action.payload.answers;
        },
        setIsDuplicate: (state, action: PayloadAction<boolean>) => {
            state.isDuplicate = action.payload;

            if (action.payload) {
                state.landing.register = {
                    isSensitiveData: false,
                    objectives: '',
                    recipientId: 0,
                    retentionMonth: 12,
                    sensitiveDataDetail: '',
                    subcontractors: '',
                    target: '',
                    transfertCountries: [],
                    timestamps: {
                        created: 0,
                        updated: 0
                    }
                };

                state.landing.status = LANDING_STATUSES.DRAFT;
            }
        },
    },
    extraReducers: (builder) => {
        builder
            .addMatcher(creationApi.endpoints.getLanding.matchFulfilled,
                (state, action: PayloadAction<Landing>) => {
                    if (!action.payload.data.version || action.payload.data.version === 'v1') {
                        state.landing.id = action.payload.id;
                        state.landing.name = action.payload.name;
                        state.landing.options = action.payload.options;
                        state.landing.visible = action.payload.visible;
                        state.landing.status = action.payload.status;
                        state.landing.objective = action.payload.objective;
                        state.landing.minObjective = action.payload.minObjective;
                        state.landing.medias = action.payload.medias;
                        state.landing.fields = action.payload.fields;
                        state.landing.enabledTemplates = action.payload.enabledTemplates;
                        state.landing.dispatchDelay = action.payload.dispatchDelay;
                        state.landing.description = action.payload.description;
                        state.landing.data = action.payload.data;
                        state.landing.code = action.payload.code;
                        state.landing.created = action.payload.created;
                        state.landing.site = action.payload.site;
                        state.landing.register = action.payload.register;
                        state.landing.quizz = action.payload.quizz;
                        //state.landing.form = action.payload.form?.code ? action.payload.form?.code : '';
                        state.landing.category = action.payload.category?.id ? action.payload.category.id : 0;
                        state.landing.company = action.payload.company?.id ? action.payload.company.id : 0;
                        state.landing.theme = action.payload.theme?.id ? action.payload.theme.id : 0;

                        state.landing.options.checkOptions = convertCheckPhoneOptions(action.payload.options.checkOptions ? action.payload.options.checkOptions as number : 0);

                        if (action.payload?.quizz?.length > 0) {
                            state.isQuizz = true;
                        }

                        state.isNew = false;
                        state.hasEdits = false;

                        if (state.isDuplicate) {
                            delete state.landing.form;
                            state.landing.name = action.payload.name + ' copy';
                            state.landing.code = action.payload.code + '-copy';

                            state.landing.status = LANDING_STATUSES.DRAFT;

                            state.landing.quizz.map(q => {
                                delete q.code;
                                delete q.key;
                            });
                        }
                        else if (action.payload.status === LANDING_STATUSES.LIVE) {
                            state.isLive = true;
                        }

                        if (state.landing.fields.length === 0 || state.landing.fields.findIndex(f => f.code === 'email') === -1) {
                            state.landing.fields.push({
                                code: 'email', title: "Email", type: 'T', choices: [],
                                mandatory: true, afterOptin: false, display: null
                            });
                        }

                        if (!state.landing.data.color) {
                            state.landing.data.color = '#000000';
                        }
                    }
                })
            .addMatcher(creationApi.endpoints.createLanding.matchFulfilled,
                (state) => {
                    state.isDuplicate = false;
                })
    }
})

export const getLanding = (state: RootState): LandingAPI => state.landing.landing;
export const getLandingData = (state: RootState): LandingDesignData => state.landing.landing.data;
export const getLandingMedias = (state: RootState): Record<string, LandingMedia | undefined> => state.landing.landing.medias;
export const isNewLanding = (state: RootState): boolean => state.landing.isNew;
export const hasEdits = (state: RootState): boolean => state.landing.hasEdits;
export const isQuizz = (state: RootState): boolean => state.landing.isQuizz;
export const isDuplicate = (state: RootState): boolean => state.landing.isDuplicate;
export const isLive = (state: RootState): boolean => state.landing.isLive;

export const convertCheckPhoneOptions = (value: number) => {
    const arrOpt = [0, 0];
    [PHONE_NO_VERIF, PHONE_LP_VERIF, PHONE_ACCOUNT_VERIF].map(n => {
        if ((value & n) === n) {
            arrOpt[0] = n;
        }
    });

    if ((value & PHONE_BASE_VERIF) === PHONE_BASE_VERIF) {
        arrOpt[1] = PHONE_BASE_VERIF;
    }

    return arrOpt;
}

export const { setLanding, setLandingData, setFields, setField, setFieldChoices, setFieldChoice,
    setMedia, setOptions, setRegister, setIsQuizz, setQuizzArray, setQuizzAnswers, setQuizz, setIsDuplicate,
    setFieldChoiceDefault, setLandingStatus } = landing.actions;
export default landing.reducer;