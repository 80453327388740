import React, {useEffect} from 'react';
import {Navigate} from "react-router-dom";
import { useAppDispatch, useAppSelector } from '../../app/hooks';
import { clearRedirect, getRedirect } from '../../app/slice/app';

const AppRedirect:React.FC = () => {
	const redirectUrl = useAppSelector(getRedirect);
	const dispatch = useAppDispatch();

	useEffect(() => {
		if (redirectUrl) {
			dispatch(clearRedirect());
		}
	}, [redirectUrl]);

	return redirectUrl ? <Navigate to={redirectUrl} /> : null;
};

export default AppRedirect;