import { useEffect } from "react";
import { Alert, Modal } from "react-bootstrap";
import { formSuccessRedirect } from "../../app/actions";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import manageApi from "../../app/services/secured/manage";
import { getModalData, isModalOpened, ModalTypes } from "../../app/slice/modal";
import { IfTrue } from "../misc/Blocks";
import { Icon, LoadingButton } from "../misc/Buttons";
import SimpleForm from "../misc/SimpleForm";
import ModalCloseButton from "../Modals/ModalCloseButton";

const ModalEnableCompany = () => {
    const dispatch = useAppDispatch();
    const isOpened = useAppSelector(isModalOpened(ModalTypes.EnableCompany));
    const data = useAppSelector<{id: number, name: string}>(getModalData);

    const [enableCompany, enableCompanyRes] = manageApi.useLazyEnableCompanyQuery();
    useEffect(() => {
        if (enableCompanyRes.isSuccess) dispatch(manageApi.util.invalidateTags(['Companies']));
    }, [enableCompanyRes.isSuccess]);
    
    dispatch(formSuccessRedirect({isModal: true, data: enableCompanyRes, message:'Succès réactivation du client.'}))
    return <IfTrue condition={isOpened && data}>
        <SimpleForm onSubmit={() => enableCompany({id:data.id}) }>
            <Modal.Body>
                <Alert variant='success'>
                    <Alert.Heading><Icon code='check' /> Réactivation {data?.name}</Alert.Heading>
                    La réactivation d'un client s'appliquera également à l'ensemble des comptes clients associés. <br />
                    Êtes-vous sûr de vouloir activer le client ?
                </Alert>
            </Modal.Body>
            <Modal.Footer>
                <ModalCloseButton />
                <LoadingButton variant='success' data={enableCompanyRes}>Réactiver</LoadingButton>
            </Modal.Footer>
        </SimpleForm>
    </IfTrue>
}

export default ModalEnableCompany;