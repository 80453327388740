import { useEffect } from "react";
import { Alert } from "react-bootstrap";
import { formSuccessRedirect } from "../../app/actions";
import { useAppDispatch } from "../../app/hooks";
import diffusionApi from "../../app/services/secured/diffusion";
import AppSecured from "../../AppSecured";
import Urls from "../../assets/js/Urls";
import { BlockError, IsDefined } from "../misc/Blocks";
import { ButtonIcon, CancelButton, Icon } from "../misc/Buttons";

const FbInstall = () => {
    const dispatch = useAppDispatch();
    const fbInstallLink = diffusionApi.useGetInstallQuery();
    const searchParams = new URL(window.location.href).searchParams;
    const [installFbApp, installRes] = diffusionApi.useInstallFbAppMutation();

    useEffect(() => {
        if (searchParams.has('code') && installRes.isUninitialized) {
            installFbApp({ code: searchParams.get('code') });
        }
    }, [searchParams, installRes])
    
    dispatch(formSuccessRedirect({data:installRes, redirectUrl: Urls.fb.forms, message:'Succès installation application'}))
    return <>
        <AppSecured>
            <Alert variant='info' className='mt-4'>
                <Alert.Heading>
                    <Icon code='facebook' /> Installation de l'application
                </Alert.Heading>
                <div className='d-flex align-items-stretch justify-content-between'>
                    <div>
                        Cliquez sur "Continuer" pour installer l'application Genelead sur le compte Facebook. <br />
                        Assurez-vous de bien utiliser le compte <b>publishing@orixa-media.com</b>
                    </div>

                    <IsDefined data={fbInstallLink.data}>
                        <ButtonIcon href={fbInstallLink.data?.link} code={"Facebook"} size={"lg"} variant={"primary"}>Continuer sur Facebook</ButtonIcon>
                    </IsDefined>
                </div>
            </Alert>

            <IsDefined data={searchParams.get('error')}>
                <Alert>
                    <Alert.Heading>Erreur - Échec installation</Alert.Heading>
                    {
                        ["error", "error_code", "error_description", "error_reason"]
                            .filter(err => searchParams.has(err))
                            .map(err => <li className={err}><span className="mr-2">{err} =</span><strong>{searchParams.get(err)}</strong></li>)
                    }
                </Alert>
            </IsDefined>

            <BlockError data={installRes} />
            <div className='d-flex justify-content-end'>
                <CancelButton link={Urls.fb.forms} />
            </div>
        </AppSecured>
    </>
}



export default FbInstall;