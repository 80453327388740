import React, { useEffect, useState } from "react";
import { useAppSelector } from "../../../app/hooks";
import { Dimension, Dimensions, getReportingData, getReportingParams, ParamOptions } from "../reporting";
import { BuildReporting, getKeyValue, Reporting } from "../ReportingClass";
import { RowNoData } from "../../misc/Table";
import { Button, Table } from "react-bootstrap";
import tableToCSV from "../TableToCsv";
import { Icon } from "../../misc/Buttons";
import ReportingRows from "./Rows";

export const headerTemplate = [
  { name: 'customer', sortable: false },
  { name: 'source', sortable: false },
  { name: 'lp', sortable: false },
  { name: 'template', sortable: false },
  { name: 'campaign', sortable: false },
  { name: 'date', sortable: false },
  { name: 'week', sortable: false },
  { name: 'month', sortable: false },
  { name: 'optinsPhones', sortable: true },
  { name: 'cpl', sortable: true },
  { name: 'spent', sortable: true },
  { name: 'total', sortable: true },
  { name: 'duplicates', sortable: true },
  { name: 'duplicatesAccount', sortable: true },
  { name: 'optins', sortable: true },
  { name: 'dispatched', sortable: true },
  { name: 'dispatchWaiting', sortable: true },
  { name: 'optinRate', sortable: true },
  { name: 'phoneRate', sortable: true },
  { name: 'duplicatesGlobal', sortable: true },
  { name: 'duplicatesRate', sortable: true },
  { name: 'duplicatesAccountRate', sortable: true },
  { name: 'purged', sortable: true },
  { name: 'exported' },
  { name: 'exportedRate' }
];

export type HeaderProps = { label: string, name: string, icon: string, sortable: boolean };

const ReportingTable = () => {

  const [ header, companies, report, totalTr, dims ] = useReporting();
  const [sort, setSort] = useState<{ key: string, direction: boolean }>();

  return <>
    {header && companies && (
      <ReportingWrapper
        header={header}
        onSort={(k) => {
          if (k === sort?.key) {
            setSort({...sort, direction: !sort.direction});
          } else {
            setSort({key: k, direction: true});
          }
        }}
      >
        <tbody>
        {companies
          .sort(( a, b ) => {
            const val1 = getKeyValue(report.getChild(a), sort?.key);
            const val2 = getKeyValue(report.getChild(b), sort?.key);
            return sort?.direction ? val2 - val1 : val1 - val2;
          })
          .map(c => {
            const company_report = report.getChild(c);
            return <ReportingRows
              sorted={header}
              key={Math.random()}
              level={0}
              nbGroups={dims.length}
              report={company_report}
              nbHeaders={header.length + 1}
              sort={sort}
            />
          })}
        {companies?.length > 0 ? totalTr : <RowNoData colspan={header.length + 1} list={companies}/>}
        </tbody>
      </ReportingWrapper>
    )}
  </>
}

const useReporting = () => {
  const reporting = useAppSelector(getReportingData);
  const params = useAppSelector(getReportingParams);
  const [ header, setHeader ] = useState<HeaderProps[]>();

  useEffect(() => {
    let optionsTable: Array<ParamOptions & { name: string }> = [];
    let tableHeaders: HeaderProps[] = [];

    Object.entries(params.search).filter(p => p[ 1 ].active).forEach(p => optionsTable.push({
      ...p[ 1 ],
      name: p[ 0 ]
    }));
    Object.entries(params.stats).filter(p => p[ 1 ].active).forEach(p => optionsTable.push({
      ...p[ 1 ],
      name: p[ 0 ]
    }));
    Object.entries(params.data).filter(p => p[ 1 ].active).forEach(p => optionsTable.push({ ...p[ 1 ], name: p[ 0 ] }));

    headerTemplate.forEach(h => {
      const node = optionsTable.find(f => f.name === h.name);
      if ( node ) {
        tableHeaders.push({ icon: node.icon, name: node.name, label: node.label, sortable: h.sortable ?? false });
      }
    })

    setHeader(tableHeaders);
  }, [ params ]);

  // build reporting
  let report: Reporting, companies: Array<string> = [];
  let totalTr;

  const dims: Array<Dimension> = Object.keys(params.search).filter(d => params.search[ d as keyof Dimensions ].active) as Array<Dimension>;

  if ( reporting.length && header ) {
    report = BuildReporting(reporting, dims);
    companies = Object.keys(report.child);
    totalTr = <ReportingRows
      sorted={header}
      recursive={false}
      level={0}
      nbGroups={dims.length}
      report={report}
      nbHeaders={header?.length as number}
      color={{ background: '#393c47', color: 'white' }}
      sort={undefined}
    />
  }

  return [
    header,
    companies,
    //@ts-ignore
    report,
    totalTr,
    dims
  ] as const;
}

type ReportingTableProps = {
  children?: any,
  header: HeaderProps[],
  onSort: (k: string) => void,
}

const ReportingWrapper: React.FC<ReportingTableProps> = ( { header, children, onSort } ) => {
  return <>
    <div className={"d-flex justify-content-end mt-3"}>
      <Button
        variant={"success"}
        onClick={tableToCSV}
      >
        <Icon className={"fs-20"} code={"filetype-csv"}/>
      </Button>
    </div>

    <div className='mt-4'>
      <Table
        className='reporting-table'
        bordered
      >
        <thead>
        <tr className='pictos'>
          <th key={Math.random()}>
            Client
          </th>
          {header.map(h =>
            <th key={Math.random()}>
              <div className='text-center'>
                {h.sortable
                  ? <a className={"sort-by"} onClick={() => onSort(h.name)}>{h.label}</a>
                  : h.label}
              </div>
            </th>
          )}
        </tr>
        </thead>
        {children}
      </Table>
    </div>
  </>
}

export default ReportingTable;