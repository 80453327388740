import { useEffect, useState } from "react";
import { Toast, ToastContainer } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Notification, removeNotification } from "../../app/slice/notifs";
import { Icon } from "../misc/Buttons";

const CustomToast = (toast: Notification) => {
    const [show, setShow] = useState(true);
    const dispatch = useAppDispatch();
    
    useEffect(() => {
		setTimeout(() => {
			dispatch(removeNotification(toast.id));
		}, 5000);
	}, []);

    return <>
        <Toast bg={toast.type} show={show} onClose={() => setShow(false)} delay={4000} autohide>
            <Toast.Header>
                <strong className='me-auto'><Icon code='app-indicator' /> Genelead</strong>
            </Toast.Header>
            <Toast.Body>
                <div style={{color:'white'}}>{toast.message}</div>
            </Toast.Body>
        </Toast>
    </>
}

const AppToast = () => {
    const toasts: Array<Notification> = useAppSelector(state => state.notifs);
    return <>
        <ToastContainer position="bottom-end" className='p-3' style={{zIndex: '7000'}}>
            {toasts.map(t => <CustomToast key={t.id} {...t} />)}
        </ToastContainer>
    </>
};

export default AppToast;