import {useAppDispatch} from "../../../../app/hooks";
import {setContentValue} from "../../landing-v2";
import {FormControl} from "react-bootstrap";

export const CustomCode: React.FC<{ content: string, block: number, isEdit: boolean }> = ({content, block, isEdit}) => {
    const dispatch = useAppDispatch();
    return <>
        {isEdit
            ? <div>
                <FormControl placeholder={'Code personnalisé Javascript'} defaultValue={content} type={"textarea"}
                             onChange={(e) => dispatch(
                                 setContentValue({
                                     index: block,
                                     value: e.target.value
                                 }))}
                />
            </div>
            : <div className={'d-flex align-items-center justify-content-center text-light'} style={{
                height: '50px',
                background: 'repeating-linear-gradient(\n' +
                    '  -55deg,\n' +
                    '  #222,\n' +
                    '  #222 10px,\n' +
                    '  #333 10px,\n' +
                    '  #333 20px\n' +
                    ')'
            }}>Code personnalisé</div>
        }
    </>
}