import React, { useState } from "react";
import { Badge, Button, FormControl, InputGroup, Nav, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useAppDispatch } from "../../app/hooks";
import globalApi from "../../app/services/secured/global";
import manageApi from "../../app/services/secured/manage";
import { modalDeleteCompanyShow, modalEnableCompanyShow } from "../../app/slice/modal";
import AppSecured from "../../AppSecured";
import Urls from "../../assets/js/Urls";
import { Company } from "../../types";
import { PageHeader } from "../App/PageHeader";
import { LoadingBlock } from "../misc/Blocks";
import { ButtonIcon, Icon } from "../misc/Buttons";
import { PaginationGenelead } from "../misc/Pagination";
import SimpleForm from "../misc/SimpleForm";
import TableGenelead from "../misc/Table";

const CompanyList = () => {
    const [search, setSearch] = useState<any>({ page: 1, active_company: true });
    const req = manageApi.useGetCompaniesQuery(search);
    const allCompanies = globalApi.useGetCompaniesListQuery();

    const handleSelect = (key: number) => () => {
        const newS = { ...search, page: 1 };
        if (search.active_company) delete newS.active_company;
        if (search.deleted_company) delete newS.deleted_company;
        if (search.deleted_customer) delete newS.deleted_customer;

        switch (key) {
            case 1: newS.active_company = true; break;
            case 2: newS.deleted_company = true; break;
            case 3: newS.deleted_customer = true; break;
        }
        setSearch(newS);
    }

    return <>
        <AppSecured>
            <PageHeader>
                <SimpleForm onSubmit={(query) => setSearch({...search, query: query.keyword as string})}>
                    <InputGroup size='sm'>
                        <FormControl placeholder='Recherche mot clé' name='keyword' />
                        <Button type='submit'><Icon code='search' /></Button>
                    </InputGroup>
                </SimpleForm>
            </PageHeader>

            <div className='d-flex justify-content-between'>
                <div>
                    <PaginationGenelead className='mb-2' size='sm' pages={{ current: req.data?.page as number, total: req.data?.total_pages as number }}
                        search={(page: number) => { setSearch({ ...search, page }) }} />
                </div>
                <div>
                    <Nav className="nav-tabs" defaultActiveKey={'1'}>
                        <Nav.Item><Nav.Link eventKey={'1'} onClick={handleSelect(1)}>Actifs</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey={'2'} onClick={handleSelect(2)}>Clients supprimés</Nav.Link></Nav.Item>
                        <Nav.Item><Nav.Link eventKey={'3'} onClick={handleSelect(3)}>Comptes supprimés</Nav.Link></Nav.Item>
                    </Nav>
                </div>
            </div>
            <LoadingBlock data={req}>
                {
                    req.isSuccess && req.data && <>
                        <TableGenelead hasActions={true} header={[
                            { name: 'Client', icon: 'building', width: "30%" },
                            { name: 'Compte(s)', icon: 'people' },
                        ]}>
                            {req.data.list.map(c => <CompanyRow key={c.id} {...c} deleted={search.deleted_company ? true : false} />)}
                        </TableGenelead>

                        <PaginationGenelead className='mt-2' pages={{ current: req.data.page, total: req.data.total_pages }}
                            search={(page: number) => { setSearch({ ...search, page }) }} />
                    </>
                }
            </LoadingBlock>
        </AppSecured>
    </>
}

const CompanyRow: React.FC<Company & { deleted?: boolean }> = company => {
    const dispatch = useAppDispatch();
    return <tr>
        <td className='fs-16'>{company.name}</td>
        <td>
            <div className='d-flex flex-wrap'>
                {company.customers.map(c => {
                    let nbGateways = 0;
                    let nbForms = 0;
                    c.forms.map(f => {
                        nbGateways += f.gateways.length;
                        nbForms++;
                    })

                    return <React.Fragment key={c.id}>
                        <OverlayTrigger placement="top" overlay={
                            <Tooltip> {nbForms} formulaire(s), {nbGateways} export(s)</Tooltip>
                        }>
                            <div>
                                <Badge bg={c.enabled ? 'primary' : 'danger'} className='fs-12 me-1 mb-1'>{c.email}</Badge>
                            </div>
                        </OverlayTrigger>
                    </React.Fragment>
                }
                )}
            </div>
        </td>
        <td>
            <div className='d-flex justify-content-end'>
                {company.enabled && <>
                    <ButtonIcon href={Urls.companies.view(company.id)} code='eye' variant='primary' size='lg' className='alt-primary' />
                    <ButtonIcon code='x' variant='danger' size='lg' className='alt-danger ms-1'
                        onClick={() => dispatch(modalDeleteCompanyShow({ id: company.id, name: company.name }))} />
                </>}
                {company.deleted && <>
                    <ButtonIcon code='' size='sm' variant='success'
                        onClick={() => dispatch(modalEnableCompanyShow({ id: company.id, name: company.name }))}>
                        Réactiver</ButtonIcon>
                </>}
            </div>
        </td>
    </tr>
}

export default CompanyList;