import { Table } from "react-bootstrap"
import { Icon } from "./Buttons"

export type ListTableHeader = {
    icon: string, name: string, align?: string, width?: string
}

export type TableGeneleadProps = {
    header: Array<ListTableHeader>,
    children: any,
    hasActions?: boolean,
    className?: string
}

const TableGenelead: React.FC<TableGeneleadProps> = (props) => {
    return <>
        <Table className={props.className+' genelead-table'} size="sm" bordered hover>
            <thead>
                <tr>
                    {props.header.map(h => <TableTH key={Math.random()} icon={h.icon} name={h.name} align={h.align} width={h.width} />)}
                    {props.hasActions && <th className='text-end'><Icon code='pencil-square' /></th>}
                </tr>
            </thead>
            <tbody>
                {props.children}
            </tbody>
        </Table>
    </>
}

export const TableTH: React.FC<ListTableHeader> = ({ icon, name, align = 'start', width = '' }) => {
    //const textAlign = 'text-' + align;
    return <>
        <th className={''} style={{ width: width }}><Icon code={icon} className='me-1' />{name}</th>
    </>
}

export const RowNoData: React.FC<{ colspan: number, list: Array<any> }> = ({ colspan, list }) => {
    return <>
        {(list?.length === 0 || !list) && <>
            <tr>
                <td colSpan={colspan} className='text-center'><Icon code='cloud-slash fs-20' /></td>
            </tr>
        </>}
    </>
}

export default TableGenelead;