import { useEffect, useState } from "react";
import { Alert, Col, Dropdown, FormLabel, Nav, Tab } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useParams, useSearchParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import creationApi from "../../app/services/secured/creation";
import { redirect } from "../../app/slice/app";
import {
    getLanding,
    getLandingData,
    isLive,
    isQuizz,
    LANDING_STATUSES,
    setIsDuplicate,
    setLanding,
    setLandingData
} from "../../app/slice/landing";
import { addNotification } from "../../app/slice/notifs";
import AppSecured from "../../AppSecured";
import Urls from "../../assets/js/Urls";
import { LandingDesignData } from "../../types";
import { PageHeader } from "../App/PageHeader";
import { IfTrue, LoadingBlock } from "../misc/Blocks";
import { BtnIconLoad, ButtonIcon, Icon } from "../misc/Buttons";
import { RichEditor } from "../misc/TinymceEditor";
import LandingAction from "./Forms/LandingAction";
import LandingFields from "./Forms/LandingFields";
import LandingInfo from "./Forms/LandingInfo";
import LandingMedias from "./Forms/LandingMedias";
import LandingOptions from "./Forms/LandingOptions";
import LandingQuizz from "./Forms/LandingQuizz";
import LandingRGPD from "./Forms/LandingRGPD";
import LandingTemplates from "./Forms/LandingTemplates";
import LandingEmbed from "./Forms/LandingEmbed";

const LandingEdit = () => {
    const dispatch = useAppDispatch();
    const { id }   = useParams<{ id: string }>();

    const [ searchP, setSearchP ]     = useSearchParams();
    const [ activeTab, setActiveTab ] = useState('infos');

    const landing         = useAppSelector(getLanding);
    const landingHasQuizz = useAppSelector(isQuizz);
    const isLandingLive   = useAppSelector(isLive);

    const [ fetchLanding, landingRes ]              = creationApi.useLazyGetLandingQuery();
    const [ saveLanding, saveLandingRes ]           = creationApi.useSaveLandingMutation();
    const [ duplicateLanding, duplicateLandingRes ] = creationApi.useCreateLandingMutation();

    const isDuplicate = searchP.get('duplicate') === 'true' ? true : false;

    useEffect(() => {
        if (id) fetchLanding({ id: parseInt(id) });
    }, [ id ])

    useEffect(() => {
        if (isDuplicate) dispatch(setIsDuplicate(true));
    }, [ isDuplicate ])

    useEffect(() => {
        if (saveLandingRes.isSuccess) {
            dispatch(addNotification({ message: 'Succès enregistrement des modifications', type: 'success' }));
        }
    }, [ saveLandingRes.isSuccess ])

    useEffect(() => {
        if (duplicateLandingRes.isSuccess) {
            dispatch(addNotification({ message: 'Succès duplication de la LP', type: 'success' }));
            dispatch(redirect(Urls.landing.edit(duplicateLandingRes.data.id)));
        }
    }, [ duplicateLandingRes.isSuccess ])

    return <>
        <AppSecured>
            <PageHeader action={<>
                <ButtonIcon code={"arrow-left"} size={"sm"} variant={"secondary"} className='me-1 alt-secondary'
                            href={Urls.landing.list}>
                    Retour</ButtonIcon>
                {
                    landing.enabledTemplates.length > 0 && <>
                        <Dropdown>
                            <Dropdown.Toggle size="sm"><Icon code='eye'/> Templates</Dropdown.Toggle>
                            <Dropdown.Menu>
                                {landing.enabledTemplates.concat([ 'default' ]).map(e =>
                                    // <Dropdown.Item bg='secondary' className='me-1' key={Math.random()}
                                    <Dropdown.Item className='me-1' key={Math.random()}
                                                   href={Urls.landing.landingTemplate(landing.site.domain, landing.code, e)}
                                                   target='_blank'>
                                        {e}</Dropdown.Item>)}
                            </Dropdown.Menu>
                        </Dropdown>
                    </>
                }
                <BtnIconLoad data={saveLandingRes} icon={isDuplicate ? 'files' : 'save'} size={"sm"} variant={"success"}
                             className='ms-1'
                             onClick={() => {
                                 isDuplicate
                                     ? duplicateLanding(landing)
                                     : saveLanding({ id: landing.id, landing: landing })
                             }}>
                    {isDuplicate ? 'Dupliquer' : 'Enregistrer'}
                </BtnIconLoad>
            </>}>
                <div>
                    <h2 className="m-0 me-3">{landing?.name}</h2>
                    <p className='text-muted'>[{landing?.code}]</p>
                </div>
                <div>
                    {!isLandingLive && landing.status === LANDING_STATUSES.LIVE && <>
                        <Alert className="w-100">
                            Remplissez les données RGPD pour pouvoir plublier la Landing page.
                        </Alert>
                    </>}
                </div>
            </PageHeader>
            <LoadingBlock data={landingRes}>
                {landing && <>
                    <div className='tabs-landing'>
                        {landing && <>
                            <Tab.Container defaultActiveKey={activeTab}>
                                <Tab.Content>
                                    <Nav fill className='nav-tabs fs-16' defaultActiveKey={'infos'}
                                         style={{ cursor: 'pointer' }}>
                                        <LandingNavItem eventKey="infos" name="Informations"
                                                        onChange={() => setActiveTab('infos')}/>
                                        <LandingNavItem eventKey="form" name="Formulaire"
                                                        onChange={() => setActiveTab('form')}/>
                                        <LandingNavItem eventKey="templates" name="Contenu"
                                                        onChange={() => setActiveTab('templates')}/>
                                        <LandingNavItem eventKey="action" name="Page de remerciement"
                                                        onChange={() => setActiveTab('action')}/>
                                        {landingHasQuizz &&
                                            <LandingNavItem eventKey="quizz" name="Quizz"
                                                            onChange={() => setActiveTab('quizz')}/>}
                                        <LandingNavItem eventKey="medias" name="Medias"
                                                        onChange={() => setActiveTab('medias')}/>
                                        <LandingNavItem eventKey="options" name="Options"
                                                        onChange={() => setActiveTab('options')}/>
                                        <LandingNavItem eventKey="rgpd" name="RGPD"
                                                        onChange={() => setActiveTab('rgpd')}/>
                                        <LandingNavItem eventKey="embed" name="Intégration"
                                                        onChange={() => setActiveTab('embed')}/>
                                    </Nav>
                                    <Tab.Pane eventKey="infos">
                                        {activeTab === 'infos' && <LandingInfo/>}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="form">
                                        {activeTab === 'form' && <LandingFields/>}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="templates">
                                        {activeTab === 'templates' && <LandingTemplates/>}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="action">
                                        {activeTab === 'action' && <LandingAction/>}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="medias">
                                        {activeTab === 'medias' && <LandingMedias/>}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="options">
                                        {activeTab === 'options' && <LandingOptions/>}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="rgpd">
                                        {activeTab === 'rgpd' && <LandingRGPD/>}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="quizz">
                                        {activeTab === 'quizz' && <LandingQuizz/>}
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="embed">
                                        {activeTab === 'embed' && <LandingEmbed/>}
                                    </Tab.Pane>
                                </Tab.Content>
                            </Tab.Container>
                        </>}
                    </div>
                </>}
            </LoadingBlock>
        </AppSecured>
    </>
}

const LandingNavItem: React.FC<{ eventKey: string, name: string, onChange: ( eventKey: string ) => void }> = ( {
                                                                                                                   eventKey,
                                                                                                                   name,
                                                                                                                   onChange
                                                                                                               } ) => {
    return <>
        <Nav.Item><Nav.Link eventKey={eventKey} onClick={() => onChange(eventKey)}>{name}</Nav.Link></Nav.Item>
    </>
}

export const hasSpecificField = ( enabledTemplates: Array<number | string>, field: string ): boolean => {
    let hasSpecificField = false;
    const templates      = creationApi.useGetTemplatesQuery();
    if (templates.isSuccess && enabledTemplates) {
        templates.data.pages.filter(p => enabledTemplates.findIndex(l => l === p.id) > -1)
            .map(s => {
                if (s.variables.findIndex(v => v === field) > -1)
                    hasSpecificField = true;
            })
    }
    return hasSpecificField;
}

type DataFieldProps = {
    field: keyof LandingDesignData,
    size?: number,
    specific?: boolean,
    className?: string,
}

export const DataField: React.FC<DataFieldProps> = ( { field, size = 12, specific = false, className = 'mb-4' } ) => {
    const dispatch    = useAppDispatch();
    const landingData = useAppSelector(getLandingData);
    return <>
        {field && landingData && <>
            <Col sm={size} className={className}>
                <FormLabel className='fs-18'>
                    {<FormattedMessage id={field}/>}
                </FormLabel>
                <RichEditor initVal={landingData[field] ? landingData[field] as string : " "}
                            color_map={[ landingData.color, 'Primaire', landingData.secondary_color, 'Secondaire', '#000000', 'Noir', '#FFFFFF', 'Blanc' ]}
                            onBlur={( html ) => dispatch(setLandingData({ ...landingData, [`${field}`]: html }))}/>
            </Col>
        </>}
    </>
}

export default LandingEdit;