import { formatDateForRequest } from "./DatePicker";

export type ReportingPeriod = [Date, Date];
export type ReportingPeriodString = [string, string];
export type ReportingPeriodObject = {from: string, to:string}

const addDays = (date:Date, days: number):Date => {
    const dateOffset = new Date();
    dateOffset.setTime(date.getTime() + days * 86400 * 1000);

    return dateOffset;
}

export const today = ():ReportingPeriod => ([new Date(), new Date()]);

export const yesterday = ():ReportingPeriod => ([addDays(new Date(), -1), addDays(new Date(), -1)]);

const thisMonth = ():ReportingPeriod => {
    const from = new Date(), to = new Date();
    from.setDate(1);
    return [from, to];
};

const previousMonth = ():ReportingPeriod => {
    const period:ReportingPeriod = thisMonth();
    
    const lastDay = addDays(period[0], -1);
    const firstDay = new Date(new Date(lastDay).setDate(1));

    period[0] = firstDay;
    period[1] = lastDay;

    return period;
}

const thisYear = ():ReportingPeriod => {
    const period = today();
    period[0] = new Date(`${new Date().getFullYear()}-01-01`);

    return period;
}

const previousYear = ():ReportingPeriod => {
    const period = today();
    period[0] = new Date(`${new Date().getFullYear() - 1}-01-01`);
    return period;
}

const thisWeek = () => {
    const period = today();

    const MONDAY = 1;
    const SUNDAY = 0;

    const startOfWeek:number = MONDAY;

    period[0] = addDays(period[0], -1 * (period[0].getDay() - startOfWeek))

    return period;
}

type Periods = "Today"|"Yesterday"|"ThisYear"|"ThisMonth"|"PreviousMonth"|"ThisWeek"|'PreviousYear'

export const DatePeriod:Record<Periods, ReportingPeriod> = {
    Today: today(),
    Yesterday: yesterday(),
    ThisYear: thisYear(),
    ThisMonth: thisMonth(),
    PreviousMonth: previousMonth(),
    ThisWeek: thisWeek(),
    PreviousYear: previousYear(),
};

export const ReportingPeriodToString = (period:ReportingPeriod):ReportingPeriodString => ([
    period[0].toISOString().substring(0, 10),
    period[1].toISOString().substring(0, 10),
])

export const ReportinPeriodFromString = (period: ReportingPeriodString):ReportingPeriod => ([
    new Date(period[0]),
    new Date(period[1]),
])

export const getRelativeDate = (numOfDays: number): string => {
    const date = new Date();
    const daysAgo = new Date(date.getTime());

    daysAgo.setDate(date.getDate() + numOfDays);

    return formatDateForRequest(daysAgo);
}

export const DateInit = {
    from: new Date(new Date().getTime() - 1 * 86400 * 1000),
    to: new Date(),
}

export const SimpleDateInit = {
    from: DateInit.from.toISOString().substr(0, 10),
    to: DateInit.to.toISOString().substr(0, 10)
}