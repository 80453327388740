import { useState, useEffect } from "react";
import { InputGroup, Button, FormSelect, Row, Col, FormLabel, FormControl } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getMedia } from "../../../app/slice/media";
import { modalMediasShow } from "../../../app/slice/modal";
import ApiUrls from "../../../assets/js/ApiUrls";
import { LandingMedia } from "../../../types";
import { ButtonIcon } from "../../misc/Buttons";
import { Icon, DesignControl } from "../Design";
import { GlobalStyle } from "../landing-v2";
import ColorPicker from "./ColorPicker";

type BackgroundImageProps = {
   title: string, url: string | undefined, onChange: (url: string) => void,
   className?: string, code: string,
}

export const BackgroundImage: React.FC<BackgroundImageProps> = ({ title, url, onChange, className, code }) => {

   const dispatch = useAppDispatch();
   const selectedMedia = useAppSelector<LandingMedia>(getMedia);
   const [current, setCurrent] = useState('');

   useEffect(() => {
      if (selectedMedia.id && current === code) {
         onChange(ApiUrls.front.media(selectedMedia.id.toString(), selectedMedia.extension))
      }
   }, [selectedMedia])

   return <div className={'d-flex align-items-center ' + className}>
      <InputGroup size='sm'>
         <InputGroup.Text>Image</InputGroup.Text>
         <ButtonIcon size='sm' code='eyedropper' variant='' onClick={() => { dispatch(modalMediasShow()); setCurrent(code) }} />
         <Button variant='' onClick={() => onChange('')}><Icon code='trash' /></Button>
      </InputGroup>
   </div>
}

type BgControllerProps = {
   design: GlobalStyle,
   onChange: (design: GlobalStyle) => void,
   option?: 'backgroundColor' | 'pageColor',
}

export const BackgroundImageController: React.FC<BgControllerProps> = ({ design, onChange, option = 'backgroundColor' }) => {
   const [manualSize, setManualSize] = useState(false);
   
   return <>
      <ColorPicker value={design.backgroundColor} title='Fond' hasOpacity={true}
         onChange={(color: string) => onChange({ ...design, [`${option}`]: color })} />

      <hr />

      <BackgroundImage title='Image' url={design.backgroundImage} className='mb-1' code='column-background'
         onChange={(url: string) => onChange({ ...design, backgroundImage: url })} />

      {
         design.backgroundImage && <>
            <DesignControl title='Taille'>
               <FormSelect defaultValue={design.backgroundSize?.includes('px') ? 'manual' : design.backgroundSize}
                  onChange={(e) => {
                     onChange({ ...design, backgroundSize: e.target.value });
                     setManualSize(e.target.value === 'manual' ? true : false);
                  }}>
                  <option value='contain'>Contenir</option>
                  <option value='cover'>Couvrir</option>
                  <option value='manual'>Manuel</option>
               </FormSelect>
            </DesignControl>

            {manualSize && <div className='mt-1'>
               <Row>
                  <Col>
                     <FormLabel className='m-0'>Largeur</FormLabel>
                     <InputGroup size='sm'>
                        <FormControl type='text' defaultValue={design.backgroundWidth}
                           onChange={(e) => onChange({ ...design, backgroundWidth: e.target.value })}
                        />
                        <InputGroup.Text>%, px</InputGroup.Text>
                     </InputGroup>
                  </Col>
                  <Col>
                     <FormLabel className='m-0'>Hauteur</FormLabel>
                     <InputGroup size='sm' >
                        <FormControl type='text' defaultValue={design.backgroundHeight}
                           onChange={(e) => onChange({ ...design, backgroundHeight: e.target.value })}
                        />
                        <InputGroup.Text>%, px</InputGroup.Text>
                     </InputGroup>
                  </Col>
               </Row>
            </div>}


            <DesignControl title='Position'>
               <FormSelect defaultValue={design.backgroundPosition}
                  onChange={(e) => onChange({ ...design, backgroundPosition: e.target.value })}>
                  <option value='center'>Centre</option>
                  <option value='left'>Gauche</option>
                  <option value='top'>Haut</option>
                  <option value='right'>Droite</option>
                  <option value='bottom'>Bas</option>
               </FormSelect>
            </DesignControl>

            <DesignControl title='Décalage'>
               <FormControl type='number' defaultValue={design.backgroundOffset ? design.backgroundOffset : 0}
                  onChange={(e) => onChange({ ...design, backgroundOffset: e.target.value })}
               />
            </DesignControl>
         </>
      }
   </>
}
