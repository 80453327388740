import { useEffect, useState } from "react";
import { Alert, Card, Col, FormCheck, FormControl, FormLabel, FormSelect, Image, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import dataApi from "../../../app/services/secured/data";
import { PHONE_ACCOUNT_VERIF, PHONE_BASE_VERIF, PHONE_LP_VERIF, PHONE_NO_VERIF } from "../../../app/slice/landing";
import { getMedia } from "../../../app/slice/media";
import { modalMediasShow } from "../../../app/slice/modal";
import ApiUrls from "../../../assets/js/ApiUrls";
import { IfTrue } from "../../misc/Blocks";
import { ButtonIcon, Icon } from "../../misc/Buttons";
import { getLandingInfos, setLandingInfo } from "../landing-v2";

const Options = () => {
    const lInfos = useAppSelector(getLandingInfos);
    const dispatch = useAppDispatch();
    const [getDatabases, databases] = dataApi.useLazyGetDatabaseListQuery();

    const selectedMedia = useAppSelector(getMedia);

    useEffect(() => {
        if (lInfos.company) getDatabases({ company_id: lInfos.company });
    }, [lInfos.company])

    useEffect(() => {
        if (selectedMedia.id) {
            const url = ApiUrls.front.media(selectedMedia.id.toString(), selectedMedia.extension);
            dispatch(setLandingInfo({ ...lInfos, seo_img: url }));
        }
    }, [selectedMedia]);

    let phoneOptToApi = [...lInfos.options.checkOptions as Array<number>];
    //console.log(lInfos.options.checkOptions);

    const handlePhoneOptions = (value: string, checked?: boolean) => {
        const v = parseInt(value);
        if ([0, 1, 2].includes(v)) {
            phoneOptToApi[0] = v;
        } else {
            phoneOptToApi[1] = checked ? v : 0;
        }

        dispatch(setLandingInfo({ ...lInfos, options: { ...lInfos.options, checkOptions: phoneOptToApi } }));
    }

    const [database, setDatabase] = useState('');

    useEffect(() => {
        if (lInfos.options.database?.id) {
            setDatabase(lInfos.options.database?.id.toString());
        }
    }, [lInfos.options.database])

    return <div>
        <Row className='mt-2'>
            <Col>
                <Card>
                    <Card.Header as="h4">
                        <Icon code='people' /> Doublons
                    </Card.Header>
                    <Card.Body>
                        <IfTrue condition={lInfos.company === 0}>
                            <Alert variant="danger">Aucun client sélectionné.</Alert>
                        </IfTrue>
                        {databases.isSuccess && <>
                            <FormLabel>Base doublons</FormLabel>
                            {databases.data.list[0]?.databases.length > 0 ? <>
                                <FormSelect value={database}
                                    onChange={(e) => {
                                        setDatabase(e.target.value);
                                        dispatch(setLandingInfo({ ...lInfos, options: { ...lInfos.options, emailDatabase: parseInt(e.target.value) } }));
                                    }}>
                                    <option value='0'>Aucune</option>
                                    {databases.data.list[0]?.databases.map((d, i) => <option key={`database-${i}`} value={d.id}>{d.name}</option>)}
                                </FormSelect>
                                <FormCheck type='switch' label='Dédoublonner emails compte client' className='mt-2' defaultChecked={lInfos.options.check_global_duplicate}
                                    onChange={(e) => dispatch(setLandingInfo({ ...lInfos, options: { ...lInfos.options, check_global_duplicate: e.target.checked } }))} />
                            </>
                                : <>
                                    <Alert variant='warning'>Aucune base doublon trouvée pour ce client.</Alert>
                                </>}
                        </>}

                        <hr className='m-1' />

                        <p className='m-0 mt-2'><Icon code='phone' /> Dédoublonnage Téléphone</p>
                        <FormLabel className='radio cursor-pointer mb-0'>
                            <input className='d-none' type='radio' name='phone-duplicate' value='0' defaultChecked={phoneOptToApi[0] === PHONE_NO_VERIF}
                                onChange={(e) => e.target.checked && handlePhoneOptions(e.target.value)} />
                            <div className='d-flex flex-wrap justify-content-center align-items-center py-1 px-2 border-0 rounded'>
                                Non
                            </div>
                        </FormLabel>
                        <FormLabel className='radio cursor-pointer mb-0'>
                            <input className='d-none' type='radio' name='phone-duplicate' value='1' defaultChecked={phoneOptToApi[0] === PHONE_LP_VERIF}
                                onChange={(e) => e.target.checked && handlePhoneOptions(e.target.value)} />
                            <div className='d-flex flex-wrap justify-content-center align-items-center py-1 px-2 border-0 rounded'>
                                Landing Page
                            </div>
                        </FormLabel>
                        <FormLabel className='radio cursor-pointer mb-0'>
                            <input type='radio' className='d-none' name='phone-duplicate' value='2' defaultChecked={phoneOptToApi[0] === PHONE_ACCOUNT_VERIF}
                                onChange={(e) => e.target.checked && handlePhoneOptions(e.target.value)} />
                            <div className='d-flex flex-wrap justify-content-center align-items-center py-1 px-2 border-0 rounded'>
                                Compte client
                            </div>
                        </FormLabel>

                        <FormCheck type='switch' name='phone-duplicate-base' label='Base repoussoir' className='mt-1' value='4' defaultChecked={phoneOptToApi[1] === PHONE_BASE_VERIF}
                            onChange={(e) => handlePhoneOptions(e.target.value, e.target.checked)} />
                    </Card.Body>
                </Card>
            </Col>
            <Col>
                <Card className='me-2 mb-2'>
                    <Card.Header as="h4">
                        <Icon code='lock' /> Sécurité
                    </Card.Header>
                    <Card.Body>
                        <FormCheck type='switch' label='Vérification numéro de téléphone' checked={lInfos.options.phoneValidation}
                            onChange={(e) => dispatch(setLandingInfo({ ...lInfos, options: { ...lInfos.options, phoneValidation: e.target.checked } }))} />
                        <FormCheck type='switch' label='Vérification email' checked={lInfos.options.emailValidation}
                            onChange={(e) => dispatch(setLandingInfo({ ...lInfos, options: { ...lInfos.options, emailValidation: e.target.checked } }))} />
                        <FormCheck type='switch' label='Google recaptcha' checked={lInfos.options.recaptcha}
                            onChange={(e) => dispatch(setLandingInfo({ ...lInfos, options: { ...lInfos.options, recaptcha: e.target.checked } }))} />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </div>
}

export const SocialsOptions = () => {
    const lInfos = useAppSelector(getLandingInfos);
    const dispatch = useAppDispatch();
    return <>
        <Row>
            <Col>
                <Card>
                    <Card.Header as="h4"><Icon code='globe' /> SEO</Card.Header>
                    <Card.Body>
                        <FormLabel>Titre SEO</FormLabel>
                        <FormControl as="textarea" defaultValue={lInfos.seo_title}
                            onBlur={(e) => dispatch(setLandingInfo({ ...lInfos, seo_title: e.target.value }))} />

                        <FormLabel className='mt-2'>Desc. SEO</FormLabel>
                        <FormControl as="textarea" defaultValue={lInfos.seo_desc}
                            onBlur={(e) => dispatch(setLandingInfo({ ...lInfos, seo_desc: e.target.value }))} />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
        <Row className="mt-3">
            <Col>
                <Card className=''>
                    <Card.Header>
                        <div className='d-flex align-items-center'>
                            <h4 className='m-0'><Icon code='facebook' /> Réseaux sociaux</h4>
                            <div className='d-flex ms-3'>
                                <FormCheck type='switch' label='Afficher connexions' checked={lInfos.rs_connect}
                                    onChange={(e) => dispatch(setLandingInfo({ ...lInfos, rs_connect: e.target.checked }))} />
                                <FormCheck type='switch' label='Afficher partages' className='ms-3' checked={lInfos.rs_share}
                                    onChange={(e) => dispatch(setLandingInfo({ ...lInfos, rs_share: e.target.checked }))} />
                            </div>
                        </div>
                    </Card.Header>
                    <Card.Body>
                        <FormLabel className='fs-16'>Titre publication</FormLabel>
                        <FormControl as="textarea" defaultValue={lInfos.share_title}
                            onChange={(e) => dispatch(setLandingInfo({ ...lInfos, share_title: e.target.value }))} />

                        <FormLabel className='mt-3 fs-16'>Description publication</FormLabel>
                        <FormControl as="textarea" defaultValue={lInfos.share_desc}
                            onChange={(e) => dispatch(setLandingInfo({ ...lInfos, share_desc: e.target.value }))} />

                        <FormLabel className='mt-3 fs-16'>Image partage</FormLabel>
                        <ButtonIcon code='eyedropper' size='lg' variant="primary" onClick={() => dispatch(modalMediasShow())} />
                        <Image src={lInfos.seo_img} fluid />
                    </Card.Body>
                </Card>
            </Col>
        </Row>
    </>
}

export default Options;