import {Alert, Card, Col, FormControl, FormLabel, FormSelect, Row} from "react-bootstrap";
import {useAppDispatch, useAppSelector} from "../../../app/hooks";
import {getLanding, setLandingData} from "../../../app/slice/landing";
import {Icon} from "../../misc/Buttons";
import {FormInputGroup} from "../../misc/Blocks";

const design = [
    { label: "Bootstrap 5", value: 1 },
    { label: "Bootstrap 5 - Avec Label", value: 2 },
    { label: "Par défaut", value: 3 },
    { label: "Par défaut - Avec Label", value: 4 },
]

const version = "1.2.3";

const LandingEmbed = () => {
    const dispatch = useAppDispatch();
    const landing = useAppSelector(getLanding);
    const isLive = landing.status === 8 ? true : false;

    return <>
        {!isLive && <Alert variant='danger'>
            <Alert.Heading>
                Landing page non-active
            </Alert.Heading>
            Vous ne pouvez intégrer le formulaire de cette landing page que lorsque celle-ci est active.
            Passez le statut en mode 'Live' pour pouvoir éditer et récupérer le code d'intégration.
        </Alert>}
        <div
            style={!isLive ? { filter: 'blur(8px)', pointerEvents: 'none' } : {}}
        >
            <Row>
                <Col>
                    <Card className='me-2 mb-2'>
                        <Card.Header as="h4">
                            <div className=''>
                                <Icon code='wrench' /> Configuration
                            </div>
                        </Card.Header>
                        <Card.Body>
                            <Row>
                                <Col>
                                    <FormInputGroup label="Version">
                                        <FormSelect defaultValue={version} onChange={(e) => {
                                            dispatch(setLandingData({ ...landing.data, embed_version: e.target.value }))
                                        }} disabled={true}>
                                            <option key={version} value={version}>{version}</option>
                                        </FormSelect>
                                    </FormInputGroup>
                                </Col>
                                <Col>
                                    <FormInputGroup label="Design">
                                        <FormSelect defaultValue={landing.data?.embed_design} onChange={(e) => {
                                            dispatch(setLandingData({ ...landing.data, embed_design: parseInt(e.target.value) }))
                                        }}>
                                            {design.map(o => <option key={o.value} value={o.value}>{o.label}</option>)}
                                        </FormSelect>
                                    </FormInputGroup>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <FormLabel className='fs-16 mt-3'>Titre</FormLabel>
                                    <FormControl as="textarea" defaultValue={landing.data.embed_title}
                                                 onChange={(e) => dispatch(setLandingData({ ...landing.data, embed_title: e.target.value }))} />
                                </Col>
                                <Col>
                                    <FormLabel className='fs-16 mt-3'>Call-to-Action</FormLabel>
                                    <FormControl as="textarea" defaultValue={landing.data.embed_cta}
                                                 onChange={(e) => dispatch(setLandingData({ ...landing.data, embed_cta: e.target.value }))} />
                                </Col>
                                <Col>
                                    <FormLabel className='fs-16 mt-3'>Optin</FormLabel>
                                    <FormControl as="textarea" defaultValue={landing.data.optin}
                                                 onChange={(e) => dispatch(setLandingData({ ...landing.data, embed_optin: e.target.value }))} />
                                </Col>
                                <Col>
                                    <FormLabel className='fs-16 mt-3'>Message de remerciement</FormLabel>
                                    <FormControl as="textarea" defaultValue={landing.data.greeting_text}
                                                 onChange={(e) => dispatch(setLandingData({ ...landing.data, embed_greeting: e.target.value }))} />
                                </Col>
                            </Row>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            {
                landing.data.embed_design && <>
                    <hr/>
                    <Row>
                        <Col>
                            <Card>
                                <Card.Header as="h4">
                                    <div className=''>
                                        <Icon code='code-slash' /> Code d'intégration
                                    </div>
                                </Card.Header>
                                <Card.Body>
                                    <Row>
                                        {landing.data.embed_design && <Col>
                                            <Alert variant="info" className="mb-1">
                                                Vous avez choisi le design suivant : <b>{design[landing.data.embed_design - 1].label}</b>
                                                <br/>
                                                {landing.data.embed_design == 1 || landing.data.embed_design == 2
                                                    ? `Vous devez insérer une feuille de style bootstrap 5 sur la page de destination dans le <head></head>`
                                                    : `Vous devez ajouter le style genelead par défaut dans le <head></head> de votre page de destination : `
                                                }
                                            </Alert>
                                            {landing.data.embed_design == 3 || landing.data.embed_design == 4
                                                ? <pre>
                                                    {`<link rel="stylesheet" href="https://js.genelead.fr/${version}/form.css">`}
                                                </pre>
                                                : <></>
                                            }
                                        </Col>
                                        }
                                    </Row>
                                    <Row>
                                        <Col>
                                    <Alert variant='info' className="mb-1">
                                        "<b>form_id</b>" doit être <b>unique</b> sur la page
                                    </Alert>
                                    <pre className="mb-1">
                                        {`<div id="form_id"></div>
<script type="text/javascript">
    window.addEventListener("DOMContentLoaded", (event) => {
        GeneleadForm.init("form_id", "${landing.code}", {
            template: ${landing.data.embed_design},
            ${(landing.data.embed_title || landing.data.embed_cta || landing.data.embed_optin || landing.data.embed_greeting) ? `dico: {${landing.data.embed_title ? `title: "${landing.data.embed_title}",` : ""}${landing.data.embed_cta ? `cta: "${landing.data.embed_cta}",` : ""}${landing.data.embed_optin ? `optin: "${landing.data.embed_optin}",` : ""}${landing.data.embed_greeting ? `success: "${landing.data.embed_greeting}",`: ""}}` : ""}
        });
    });
</script>`}
                                    </pre>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                    <pre className="mt-3">
                                        {`<script src="https://js.genelead.fr/${version}/form.js"></script>`}
                                    </pre>
                                        </Col>
                                    </Row>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </>
            }
        </div>
    </>
}

export default LandingEmbed;