import React from "react";
import { ReportingRowsProps } from "../../../types";
import { getKeyValue, Reporting } from "../ReportingClass";
import { FormattedMessage, FormattedNumber } from "react-intl";
import { HeaderProps } from "./Table"

const rowsColors = [ '#afbde5', '#cad3ee', '#d7ddf2', '#e5e9f6', '#f2f4fb' ];

export const ReportingRows: React.FC<ReportingRowsProps & { sorted: HeaderProps[] }> = ( props ) => {
  const {
          report,
          level,
          nbGroups,
          nbHeaders,
          recursive = true,
          color,
          sorted,
          sort
        } = props;

  const childKeys = Object.keys(report.child);
  const hasChildren = childKeys.length > 0 as boolean;

  const initTds = [];
  for ( var i = 0; i < nbGroups + 1; i++ ) {
    if ( i === level ) {
      initTds.push(<td colSpan={nbGroups - level + 1}>{report.label}</td>);
    } else if ( i < level ) {
      initTds.push(<td></td>)
    }
  }

  let rowTds = [];
  rowTds.push(DataRow(report, sorted));

  // console.log(childKeys);

  return <>
    <tr style={hasChildren && recursive ? { background: rowsColors[ level ] } : color}>

      {level === 0 && <td colSpan={nbGroups + 1}>{report.label}</td>}

      {level > 0 && initTds.map(t => <React.Fragment key={Math.random()}>{t}</React.Fragment>)}

      {rowTds.map(r => <React.Fragment key={Math.random()}>{r}</React.Fragment>)}
    </tr>

    {recursive && hasChildren && childKeys
      .sort(( a, b ) => {
        const val1 = getKeyValue(report.getChild(a), sort?.key);
        const val2 = getKeyValue(report.getChild(b), sort?.key);
        return sort?.direction ? val2 - val1 : val1 - val2;
      })
      .map(c =>
        <ReportingRows
          sorted={sorted}
          key={Math.random()}
          report={report.getChild(c)}
          level={level + 1}
          nbGroups={nbGroups}
          nbHeaders={nbHeaders}
          sort={sort}
        />)}
  </>
}

const DataRow = ( report: Reporting, options: HeaderProps[] ) => {
  const rowTds: Array<JSX.Element> = [];
  options.map(o => {
    let data;
    switch ( o.name ) {
      case 'cpl':
        data = <FormattedNumber style='currency' currency="eur" value={report.getCPLRate()}/>;
        break;
      case 'optinRate':
        data = <FormattedNumber style='percent' value={report.getOptinRate()}/>;
        break;
      case 'phoneRate':
        data = <FormattedNumber style='percent' value={report.getOptinPhoneRate()}/>;
        break;
      case 'duplicatesGlobal':
        data = <FormattedNumber style='percent' value={report.getAllDuplicates()}/>;
        break;
      case 'duplicatesRate':
        data = <FormattedNumber style='percent' value={report.getDuplicateRate()}/>;
        break;
      case 'duplicatesAccountRate':
        data = <FormattedNumber style='percent' value={report.getDuplicateAccountRate()}/>;
        break;
      case 'total':
        data = <FormattedNumber style='decimal' value={report.getTotal()}/>;
        break;
      case 'spent':
        data = <FormattedNumber style='currency' currency='eur' value={report.getSpent()}/>;
        break;
      case 'optins':
        data = <FormattedNumber style='decimal' value={report.getOptins()}/>;
        break;
      case 'optinsPhones':
        data = <FormattedNumber style='decimal' value={report.getOptinsPhones()}/>;
        break;
      case 'duplicates':
        data = <FormattedNumber style='decimal' value={report.getDuplicates()}/>;
        break;
      case 'duplicatesAccount':
        data = <FormattedNumber style='decimal' value={report.getDuplicatesAccount()}/>;
        break;
      case 'dispatched':
        data = <FormattedNumber style='decimal' value={report.getDispatched()}/>;
        break;
      case 'dispatchWaiting':
        data = <FormattedNumber style='decimal' value={report.getDispatchedWaiting()}/>;
        break;
      case 'purged':
        data = <FormattedNumber style='decimal' value={report.getPurged()}/>;
        break;
      case 'exported':
        const exported = report.getExported();
        if ( exported ) {
          const webservices = Object.keys(exported);
          data = webservices.map(w => <React.Fragment key={Math.random()}><small>
            <div><FormattedMessage id={w}/> | <b>{exported[ w ]}</b></div>
          </small></React.Fragment>);
        }
        break;
      case 'exportedRate':
        const exportedRate = report.getExportedRate();
        if ( exportedRate ) {
          const webservices = Object.keys(exportedRate);
          data = webservices.map(w => <React.Fragment key={Math.random()}><small>
            <div><FormattedMessage id={w}/> | <FormattedNumber style='percent' value={exportedRate[ w ]}/></div>
          </small></React.Fragment>);
        }
        break;
    }

    if ( data ) {
      rowTds.push(
        <td key={Math.random()} aria-label={o.name} className='text-end'>
          {data}
        </td>
      );
    }
  })

  return rowTds;
}

export default ReportingRows;