import { Pagination } from "react-bootstrap";

type PaginationProps = {
    search: (page:number) => void,
    pages: {
        current: number,
        total: number
    }
    size?: 'lg' | 'sm',
    className?: string,
}

export const PaginationGenelead: React.FC<PaginationProps> = ({ search, pages, className, size }) => {
    let items = [];

    let min = (pages.current - 3);
    let max = (pages.current + 3);

    if (min < 1) min = 1;
    if (max > pages.total) max = pages.total;

    if (min > 2) {
        items.push(<Pagination.Item key={Math.random()} onClick={() => search(1)}>1</Pagination.Item>)
        items.push(<Pagination.Item key={Math.random()}>...</Pagination.Item>)
    }

    for (let number = min; number <= max; number++) {
        items.push(
            <Pagination.Item key={number} active={number === pages.current} onClick={(e) => search(parseInt(e.currentTarget.textContent as string))}>
                {number}
            </Pagination.Item>,
        );
    }

    if (pages.total > max) {
        items.push(<Pagination.Item key={Math.random()}>...</Pagination.Item>)
        items.push(<Pagination.Item key={pages.total} active={pages.total === pages.current} 
            onClick={() => search(parseInt(pages.total.toString()))}>
            {pages.total}
        </Pagination.Item>)
    }

    return <>
        {pages.total > 0 && <Pagination size={size} className={className}>{items}</Pagination>}
    </>
}

