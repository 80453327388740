import { useParams } from "react-router-dom";
import { formSuccessRedirect } from "../../app/actions";
import { useAppDispatch } from "../../app/hooks";
import dataApi from "../../app/services/secured/data";
import AppSecured from "../../AppSecured";
import Urls from "../../assets/js/Urls";
import { Coregistration, CoregistrationForm } from "../../types";
import { BlockError, FormButtons, OnSuccess } from "../misc/Blocks";
import SimpleForm from "../misc/SimpleForm";
import { CoregForm } from "./CoregForm";

const CoregEdit = () => {
    const { id } = useParams<{ id: string }>();
    return <>
        <AppSecured>
            <div className='mt-4'>
            {id === 'new' ? <FormCoregNew /> : <FormCoregEdit id={parseInt(id as string)} />}
            </div>
        </AppSecured>
    </>
}

const FormCoregNew = () => {
    const dispatch = useAppDispatch();
    const [create, createRes] = dataApi.useCreateCoregMutation();
    
    dispatch(formSuccessRedirect({ data: createRes, redirectUrl: Urls.coregistration.list, message: 'Succès création Co-registration' }));
    
    return <>
        <SimpleForm onSubmit={(json) => { create(json as CoregistrationForm); }}>
            <BlockError data={createRes} />
            <CoregForm />
            <FormButtons data={createRes} cancelUrl={Urls.coregistration.list} btnTxt='Créer' />
        </SimpleForm>
    </>
}

const FormCoregEdit: React.FC<Pick<Coregistration, 'id'>> = coreg => {
    const dispatch = useAppDispatch();
    
    const data = dataApi.useGetCoregQuery({ id: coreg.id });
    const [update, updateRes] = dataApi.useUpdateCoregMutation();
  
    dispatch(formSuccessRedirect({data:updateRes, redirectUrl:Urls.coregistration.list, message:'Succès édition de la co-registration'}));
    
    return <>
        <OnSuccess data={data}>
            <SimpleForm onSubmit={(json) => { update({id: coreg.id, form: json as CoregistrationForm}) }}>
                <CoregForm {...data.data} />
                <FormButtons data={updateRes} cancelUrl={Urls.coregistration.list} btnTxt='Modifier' />
            </SimpleForm>
        </OnSuccess>
    </>
}

export default CoregEdit;