import 'tinymce/tinymce';
import { Editor } from '@tinymce/tinymce-react';
import React, { useRef, useState } from "react";
import { Button } from 'react-bootstrap';
import 'tinymce/skins/content/default/content.min.css';
import 'tinymce/skins/ui/oxide/content.min.css';
import 'tinymce/skins/ui/oxide/skin.min.css';
import 'tinymce/themes/silver';
import 'tinymce/models/dom/model';
import 'tinymce/icons/default/icons';
import 'tinymce/plugins/advlist';
import 'tinymce/plugins/anchor';
import 'tinymce/plugins/autolink';
import 'tinymce/plugins/autoresize';
import 'tinymce/plugins/charmap';
import 'tinymce/plugins/code';
import 'tinymce/plugins/fullscreen';
import 'tinymce/plugins/help';
import 'tinymce/plugins/image';
import 'tinymce/plugins/insertdatetime';
import 'tinymce/plugins/link';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/media';
import 'tinymce/plugins/lists';
import 'tinymce/plugins/nonbreaking';
import 'tinymce/plugins/searchreplace';
import 'tinymce/plugins/table';
import 'tinymce/plugins/template';
import 'tinymce/plugins/wordcount';
import '../../assets/scss/fonts.css';

type RichEditorProps = {
    initVal: string,
    onBlur?: (data: string) => void,
    color_map?: Array<string>
}

export const EditorV2: React.FC<RichEditorProps> = ({ initVal, onBlur, color_map = [] }) => {
    const basicTools = 'blocks fontfamily fontsize | forecolor backcolor | align | bold italic | numlist bullist | link | media | image| removeformat ';
    const ref = useRef<any>(null);
    const [showEditor, setShowEditor] = useState(false);
    return <>
        {
            !showEditor
                ? <div style={initVal.trim().length === 0 ? {height:'50px'} : {}}>
                    <div className='d-flex justify-content-end'><Button className='btn-edit-editor' onClick={() => setShowEditor(true)}>Éditer</Button></div>
                    <div className='preview-editor' dangerouslySetInnerHTML={{ __html: initVal }} />
                </div>
                : <>
                    {onBlur && <>
                        <Editor
                            onInit={(evt, editor) => ref.current = editor}
                            tinymceScriptSrc="tinymce/tinymce.min.js"
                            initialValue={initVal ? initVal : ' '}
                            onBlur={() => onBlur(ref.current.getContent())}
                            init={{
                                color_map: color_map,
                                skin: false,
                                content_css: '/bootstrap.min.css, /public.css',
                                height: 100,
                                menubar: false,
                                font_css: '/fonts.css',
                                plugins: ['link', 'media', 'autoresize', 'image', 'lists'],
                                toolbar: basicTools,
                                formats: { div: { block: 'div' } },
                                style_formats: [{ title: 'Div', format: 'div' }],
                                block_formats: "Paragraphe=p;Bloc=div;Titre 1=h1;Titre 2=h2;Titre 3=h3;Quote=blockquote",
                                font_family_formats: "Arial;Poppins;Oswald;Raleway;Lora;Montserrat;Nunito;Roboto;Merriweather;Spectral",
                                content_style: "body { font-family:'Poppins'; font-size:14px; font-weight: normal; background: transparent; }",
                            }}
                        />
                    </>}
                </>
        }
    </>
}

export const RichEditor: React.FC<RichEditorProps> = ({ initVal, onBlur, color_map = [] }) => {
    const basicTools = 'blocks fontfamily fontsize | forecolor backcolor | align | bold italic | numlist bullist | link | media | image| removeformat ';
    const ref = useRef<any>(null);
    return <>
        {onBlur && <>
            <Editor
                onInit={(evt, editor) => ref.current = editor}
                tinymceScriptSrc="tinymce/tinymce.min.js"
                initialValue={initVal ? initVal : ' '}
                onBlur={() => onBlur(ref.current.getContent())}
                init={{
                    color_map: color_map,
                    skin: false,
                    content_css: '/bootstrap.min.css, /public.css',
                    height: 100,
                    menubar: false,
                    font_css: '/fonts.css',
                    plugins: ['link', 'media', 'autoresize', 'image', 'lists'],
                    toolbar: basicTools,
                    formats: { div: { block: 'div' } },
                    style_formats: [{ title: 'Div', format: 'div' },],
                    advlist_bullet_styles: 'aides',
                    block_formats: "Paragraphe=p;Bloc=div;Titre 1=h1;Titre 2=h2;Titre 3=h3;Quote=blockquote",
                    font_family_formats: "Arial;Poppins;Oswald;Raleway;Lora;Montserrat;Nunito;Roboto;Merriweather;Spectral",
                    content_style: "body { font-family:'Poppins'; font-size:14px; font-weight: normal; }",
                }}
            />
        </>}
    </>
}