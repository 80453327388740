import { useEffect, useState } from "react";
import { Row, Col, FormControl, FormCheck, Button } from "react-bootstrap";
import { formSuccessRedirect } from "../../app/actions";
import { useAppDispatch } from "../../app/hooks";
import manageApi from "../../app/services/secured/manage";
import { modalDeleteAccountShow } from "../../app/slice/modal";
import { addNotification } from "../../app/slice/notifs";
import Urls from "../../assets/js/Urls";
import { Customer } from "../../types";
import { BlockError, FormInputGroup } from "../misc/Blocks";
import { BtnIconLoad } from "../misc/Buttons";
import SimpleForm from "../misc/SimpleForm";

const InformationsPane: React.FC<Partial<Customer> & {company: number}> = account => {
    const dispatch = useAppDispatch();
    const [updateAccount, updateAccountRes] = manageApi.useUpdateCompanyAccountMutation();
    const [createAccount, createAccountRes] = manageApi.useCreateAccountMutation();
    const [isNew, setisNew] = useState(account.id ? false:true);

    useEffect(() => {
        if (updateAccountRes.isSuccess)
            dispatch(addNotification({message: 'Succès modification des informations du compte', type:'success'}));
    }, [updateAccountRes.isSuccess, createAccountRes])

    const [enableCustomer, enableCustomerRes] = manageApi.useLazyEnableAccountQuery();
    useEffect(() => {
        if (enableCustomerRes.isSuccess) {
            dispatch(manageApi.util.invalidateTags(['Company']));
            dispatch(addNotification({ type: 'success', message: 'Succès activation du compte.' }));
        }
    }, [enableCustomerRes.isSuccess])

    
    dispatch(formSuccessRedirect({isModal: true, message:'Succès création du compte', data:createAccountRes}));
    return <>
        <SimpleForm onSubmit={(json) => {
            !isNew 
                ? updateAccount({ company: {id: account.company as number}, account: {id: account.id as number},
                    data: json as Pick<Customer, 'firstName'|'lastName'|'phoneNumber'|'admin'|'email'>})
                : createAccount({ company: {id: account.company}, 
                    data: json as Pick<Customer, 'firstName'|'lastName'|'phoneNumber'|'admin'|'email'>})
        }}>
            <div className='d-flex align-items-center justify-content-center mb-4'>
                {account.apiToken && <Button size='sm' className='' href={Urls.companies.apiDoc(account.apiToken)} target="_blank">
                        Documentation API</Button>
                }
                {account.enabled
                    ? <Button variant='danger' size='sm' className='ms-1' onClick={() => dispatch(modalDeleteAccountShow(
                        { company: { id: account.company }, account: { id: account.id as number } }))}>
                        Désactiver compte</Button>
                    : <Button variant='success' size='sm' className='ms-1 d-block'
                        onClick={() => enableCustomer({ company: { id: account.company }, account: { id: account.id as number } })}>
                        Activer compte</Button>
                }
            </div>
            <BlockError data={updateAccountRes} />
            <Row>
                <FormInputGroup as={Col} label='Prénom'>
                    <FormControl name='firstName' placeholder='Prénom' defaultValue={account?.firstName} required />
                </FormInputGroup>
                <FormInputGroup as={Col} label='Nom'>
                    <FormControl name='lastName' placeholder='Nom' defaultValue={account?.lastName} required />
                </FormInputGroup>
            </Row>
            <Row>
                <FormInputGroup as={Col} label='Email'>
                    <FormControl name='email' placeholder='Email' defaultValue={account?.email} required />
                </FormInputGroup>
                <FormInputGroup as={Col} label='Téléphone'>
                    <FormControl name='phoneNumber' placeholder='Téléphone' defaultValue={account?.phoneNumber as string} />
                </FormInputGroup>
            </Row>
            <Row className='mt-2'>
                <Col className='d-flex'>
                    <FormCheck type='switch' name='admin' label='Utilisateur admin' defaultChecked={account?.admin} />
                    
                    <FormCheck type='switch' name='refresh-api-token' label='Utilisateur API' className='ms-3' defaultChecked={account?.apiToken ? true:false} />
                </Col>
            </Row>
            <div className='mt-3 d-flex text-end justify-content-end'>
                {!isNew ? <BtnIconLoad data={updateAccountRes}>Enregistrer</BtnIconLoad> : <BtnIconLoad data={createAccountRes}>Créer</BtnIconLoad>}
            </div>
        </SimpleForm>
    </>
}

export default InformationsPane;