import { configureStore, ThunkAction, Action, getDefaultMiddleware } from '@reduxjs/toolkit';
import publicApi from './services/public/publicApi';
import securedApi from './services/secured/securedApi';
import appReducer from './slice/app';
import authReducer from './slice/auth';
import mediaReducer from './slice/media';
import landingSiteReducer from './slice/landing-site';
import { notificationReducer } from './slice/notifs';
import { modalReducer } from './slice/modal';
import { httpErrorMiddleware } from './errors';
import landingReducer from './slice/landing';
import landingV2 from '../components/LandingsV2/landing-v2';
import reportingReducer from '../components/Reporting/reporting';
import undoable from 'redux-undo';

export const store = configureStore({
    reducer: {
        app: appReducer,
        auth: authReducer,
        notifs: notificationReducer,
        modal: modalReducer,
        media: mediaReducer,
        landingSite: landingSiteReducer,
        landing: landingReducer,
        //@ts-ignore
        landingv2: undoable(landingV2),
        reporting: reportingReducer,

        [publicApi.reducerPath]: publicApi.reducer,
        [securedApi.reducerPath]: securedApi.reducer,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware().concat([
        httpErrorMiddleware,
        publicApi.middleware,
        securedApi.middleware
    ])
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
