import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { useAppSelector } from "../../app/hooks";
import { getModalData, isModalOpened, ModalTypes } from "../../app/slice/modal";
import { modalDatabaseProps } from "../../types";
import { Icon } from "../misc/Buttons";
import { DatabaseEdit, DatabaseNew, DatabaseNewFromCompany } from "./DatabaseManage";

const ModalDatabase = () => {
    const data: modalDatabaseProps = useAppSelector(getModalData);
    const isOpened = useAppSelector(isModalOpened(ModalTypes.Database));
    const [type, setType] = useState('');

    useEffect(() => {
        if (isOpened) {
            if (data.isNew && data.company) setType('new-from-company');
            else if (data.isNew) setType('new');
            else if (data.database) setType('edit');
        }
    }, [data])

    return <>
        {
            isOpened && <>
                <Modal.Header className='fs-20'>
                    <Icon code='server' className='me-1' />
                    {type === 'edit' && 'Édition base ' + data.database?.name}
                    {type === 'new-from-company' && 'Nouvelle base - ' + data.company?.name}
                    {type === 'new' && 'Nouvelle base email'}
                </Modal.Header>
                <Modal.Body>
                    {type === 'edit' && <DatabaseEdit />}
                    {type === 'new-from-company' && <DatabaseNewFromCompany />}
                    {type === 'new' && <DatabaseNew />}
                </Modal.Body>
            </>
        }
    </>
}


export default ModalDatabase;