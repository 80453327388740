import { isRejectedWithValue, Middleware, MiddlewareAPI } from "@reduxjs/toolkit";
import Urls from "../assets/js/Urls";
import { addNotification } from "./slice/notifs";

export const httpErrorMiddleware: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
	if (isRejectedWithValue(action)) {
		const status: string = (action.payload.status).toString();

		switch (status) {
			case "401":
				window.location.href= Urls.login
			break;
			case "500":
				api.dispatch(addNotification({
					type: "danger",
					message: action.payload?.data?.error || "Erreur serveur",
				}));
				break;
		}
	}

	return next(action);
}