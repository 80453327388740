import React from "react";
import {Button} from "react-bootstrap";
import {FormattedMessage} from "react-intl";
import { useAppDispatch } from "../../app/hooks";
import { modalClose } from "../../app/slice/modal";

const ModalCloseButton: React.FC = ({}) => {
	const dispatch = useAppDispatch();
	return <Button onClick={() => dispatch(modalClose())} variant="secondary"><FormattedMessage id='close' /></Button>
}

export default ModalCloseButton;