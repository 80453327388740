import { useEffect, useState } from "react";
import { Alert, Col, Container, FormCheck, FormControl, FormLabel, FormText, ListGroup, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { FormattedMessage } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import creationApi from "../../../app/services/secured/creation";
import { getLanding, setField, setFieldChoice, setFieldChoiceDefault, setFieldChoices, setFields, setLanding, setLandingData } from "../../../app/slice/landing";
import { Choice, FieldType, FieldTypesString, LandingField } from "../../../types";
import { IfTrue, LoadingBlock } from "../../misc/Blocks";
import { ButtonIcon } from "../../misc/Buttons";

const LandingFieldsForm: React.FC = () => {
    const dispatch = useAppDispatch();
    const landing = useAppSelector(getLanding);

    const fields = creationApi.useGetFieldsQuery({});
    const [fieldArray, setFieldArray] = useState<Array<Partial<LandingField>>>([]);
    const [selected, setSelected] = useState([]);

    useEffect(() => {
        if (landing.fields) setFieldArray(landing.fields)
    }, [landing.fields])

    const moveField = (code: string, direction: number) => {
        const oldPos = fieldArray.findIndex(f => f.code === code);
        const newPos = oldPos + direction;
        const newArr = [...fieldArray];
        newArr[oldPos] = fieldArray[newPos];
        const val = fieldArray[oldPos];
        newArr[newPos] = val;

        setFieldArray(newArr);
        dispatch(setFields(newArr as Array<LandingField>));
    }

    const deleteField = (code: string) => {
        const newArr = [...fieldArray].filter(f => f.code !== code);
        setFieldArray(newArr);
        dispatch(setFields(newArr as Array<LandingField>));
    }

    const handleFormDesign = (design: string) => () => dispatch(setLandingData({ ...landing.data, form_design: design }));

    return <>
        <LoadingBlock data={fields}>
            <Row>
                <Col sm={9}>
                    <div className='d-flex align-items-center mb-3'>
                        <h5 className='m-0'>Formulaire</h5>
                        {fields.isSuccess && <>
                            <Typeahead
                                id='add-field'
                                className='ms-3 w-100'
                                options={fields.data
                                    ?.filter(f => landing.fields.findIndex(ff => ff.code === f.code) === -1)
                                    ?.map(f => { return { label: '[' + f.code + '] ' + f.title, id: f.id } })}
                                placeholder='Rechercher un champ à ajouter'
                                selected={selected}
                                onChange={(s) => {
                                    setSelected(s as any);
                                    if (s[0]) {
                                        const value = s[0] as { id: number };
                                        const newField = fields.data.find(f => f.id === value.id);
                                        if (newField) {
                                            const newArr = [...fieldArray];
                                            newArr.push(newField);
                                            setFieldArray(newArr);
                                            setSelected([]);
                                            dispatch(setFields(newArr as Array<LandingField>));
                                        }
                                    }
                                }}
                            />
                        </>}
                    </div>
                    <Container>
                        {fieldArray.length === 0 && <Alert variant='warning'>Le formulaire est vide. Ajoutez un champ !</Alert>}
                        {fieldArray.map((f, i) =>
                            <FieldRow key={Math.random()} index={i} field={f}
                                length={fieldArray.length} {...f} moveField={moveField} deleteField={deleteField} />)}
                    </Container>
                </Col>
                <Col sm={3} className='ps-4'>
                    <h5>Objectifs</h5>
                    <Row>
                        <Col>
                            <FormLabel>Minimum</FormLabel>
                            <FormControl type='number' defaultValue={landing.minObjective} onBlur={(e) => dispatch(setLanding({ ...landing, minObjective: parseInt(e.target.value) }))} />
                        </Col>
                        <Col>
                            <FormLabel>Maximum</FormLabel>
                            <FormControl type='number' defaultValue={landing.objective} onBlur={(e) => dispatch(setLanding({ ...landing, objective: parseInt(e.target.value) }))} />
                        </Col>
                    </Row>

                    <h5 className='m-0 mt-4'>Design</h5>
                    <FormControl name='data.form_design' type='hidden' />
                    <ListGroup className='mt-2' defaultActiveKey={landing.data?.form_design} horizontal>
                        <ListGroup.Item action eventKey="1" onClick={handleFormDesign('1')}><span className="icon-label-border"></span></ListGroup.Item>
                        <ListGroup.Item action eventKey="2" onClick={handleFormDesign('2')}><span className="icon-placeholder-border"></span></ListGroup.Item>
                        <ListGroup.Item action eventKey="3" onClick={handleFormDesign('3')}><span className="icon-placeholder-border-bottom"></span></ListGroup.Item>
                        <ListGroup.Item action eventKey="4" onClick={handleFormDesign('4')}><span className='icon-label-border-bottom'></span></ListGroup.Item>
                    </ListGroup>

                    <div className='d-flex align-items-center mt-5'>
                        <FormControl type='color' defaultValue={landing.data.fields_color}
                            onChange={(e) => dispatch(setLandingData({ ...landing.data, fields_color: e.target.value }))} />
                        <div><FormLabel className='m-0 ms-2'>Couleur de fond champs post-in</FormLabel></div>
                    </div>
                </Col>
            </Row>
        </LoadingBlock>
    </>
}

type FieldRowProps = {
    field: Partial<LandingField>,
    index: number,
    length: number,
    moveField: (code: string, direction: number) => void,
    deleteField: (code: string) => void
}

const FieldRow: React.FC<FieldRowProps> = ({ field, index, length, moveField, deleteField }) => {
    const dispatch = useAppDispatch();
    const specialTypes: Array<string> = ['B', 'C'];
    const handleDisplay = (display: string) => () => dispatch(setField({ index: index, field: { ...field, display: display } as LandingField }));

    return <>
        <Row className='mb-4 d-flex align-items-center'>
            <Col sm={1} className='text-end fs-16'>{index + 1} </Col>
            <Col sm={specialTypes.includes(field.type as string) ? 5 : 8}>
                <FormControl defaultValue={field?.title}
                    onBlur={(e) => dispatch(setField({ index: index, field: { ...field, title: e.target.value } as LandingField }))} />

                <IfTrue condition={field.type === 'K'}>
                    <FormControl type='text' name='validation_pattern' placeholder='Clé de validation'
                        required pattern="^[-a-zA-Z0-9@\.+_]+$" defaultValue={field.validation_pattern}
                        onBlur={(e) => dispatch(setField({ index: index, field: { ...field, validation_pattern: e.target.value } as LandingField }))} />
                    <small>Key : veuillez écrire un pattern à valider. {`{x} lettre, {0} nombre`}. </small> <br />
                    <small>Exemple : {`A{x}{0}{0}{x}5BD`} | Code valide : AY22Z5BD</small>
                </IfTrue>

                <FormControl defaultValue={field?.helper} size="sm" className="mt-2" placeholder="Texte d'aide (optionnel)"
                    onBlur={(e) => dispatch(setField({ index: index, field: { ...field, helper: e.target.value } as LandingField }))} />

                <div className='d-flex justify-content-between'>
                    <FormText className='text-muted'
                    ><b>{field?.code}</b> | <FormattedMessage id={FieldType[field.type as FieldTypesString]} />
                    </FormText>
                </div>
            </Col>

            <IfTrue condition={specialTypes.includes(field.type as string)}>
                <Col sm={3}>
                    <IfTrue condition={field.type === 'C'}>
                        <div className='field-choice-legend'>
                            <div className='d-flex align-items-center justify-content-between'>
                                <small><i>affichage</i></small>
                                <small><i>exemple</i></small>
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <FormCheck type='radio' name={`display-${index}`} label='Sélecteur' value='select'
                                    defaultChecked={field.display === 'select'}
                                    onChange={handleDisplay('select')} />
                                <select className='ms-3'><option>option</option></select>
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <FormCheck type='radio' name={`display-${index}`} label='Liste' value='list' className=''
                                    defaultChecked={field.display === 'list'}
                                    onChange={handleDisplay('list')} />
                                <FormCheck type='checkbox' className='ms-3' />
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <FormCheck type='radio' name={`display-${index}`} label='Autocomplétion' value='autocomplete' className=''
                                    defaultChecked={field.display === 'autocomplete'}
                                    onChange={handleDisplay('autocomplete')} />
                                <FormCheck type='radio' className='ms-3' />
                            </div>
                            <div className='d-flex align-items-center justify-content-between'>
                                <div>
                                    <FormCheck type='radio' name={`display-${index}`} label='Cases à cocher' value='checkbox' className=''
                                        defaultChecked={field.display === 'checkbox'}
                                        onChange={handleDisplay('checkbox')} />
                                </div>
                                <FormCheck type='radio' className='ms-3' />
                            </div>
                        </div>
                    </IfTrue>

                    <IfTrue condition={field.type === 'B'}>
                        <div className='d-flex'>
                            <FormCheck type='radio' name={`display-${index}`} label='Case à cocher' value='checkbox'
                                defaultChecked={field.display === 'checkbox'}
                                onChange={handleDisplay('checkbox')} />
                            <FormCheck type='radio' name={`display-${index}`} label='Bouton Oui/Non' value='radio' className='ms-2'
                                defaultChecked={field.display === 'radio'}
                                onChange={handleDisplay('radio')} />
                        </div>
                    </IfTrue>
                </Col>
            </IfTrue>

            <Col sm={2}>
                <FormCheck type='switch' label="Post optin" defaultChecked={field?.afterOptin}
                    onChange={(e) => {
                        e.preventDefault();
                        dispatch(setField({ index: index, field: { ...field, afterOptin: e.target.checked } as LandingField }))
                    }} />

                <FormCheck type='switch' label="Obligatoire" defaultChecked={field?.mandatory}
                    onChange={(e) => {
                        e.preventDefault();
                        dispatch(setField({ index: index, field: { ...field, mandatory: e.target.checked } as LandingField }))
                    }} />
            </Col>
            <Col sm={1}>
                <div className='d-flex align-items-center'>
                    <div className='d-flex flex-column me-2'>
                        <IfTrue condition={index > 0}>
                            <ButtonIcon code='arrow-up-square' variant='' size='lg' className='mb-1 '
                                onClick={(e) => {
                                    e.preventDefault();
                                    moveField(field.code as string, -1)
                                }} />
                        </IfTrue>

                        <IfTrue condition={index + 1 < length}>
                            <ButtonIcon code='arrow-down-square' variant='' size='lg' className=''
                                onClick={(e) => {
                                    e.preventDefault();
                                    moveField(field.code as string, 1)
                                }} />
                        </IfTrue>
                    </div>
                    <div>
                        <ButtonIcon code='x' variant='danger' size='lg' className='alt-danger'
                            onClick={(e) => {
                                e.preventDefault();
                                deleteField(field.code as string)
                            }} />
                    </div>
                </div>
            </Col>
            {/* <Col sm={{
                span:11,
                offset:1
            }}>
                
            </Col> */}
        </Row>

        <FieldChoices field={field as LandingField} fieldIndex={index} />
    </>
}

const FieldChoices: React.FC<{ field: LandingField, fieldIndex: number }> = ({ field, fieldIndex }) => {
    const landing = useAppSelector(getLanding);
    const fieldTmp = landing.fields[fieldIndex];
    const dispatch = useAppDispatch();
    //const [choicesArray, setChoicesArray] = useState<Array<Choice>>(field.choices);

    const moveChoice = (code: string, direction: number) => {
        const oldPos = field.choices.findIndex(c => c.code === code);
        const newPos = oldPos + direction;
        const newArr = [...field.choices];;
        newArr[oldPos] = field.choices[newPos];
        const val = field.choices[oldPos];
        newArr[newPos] = val;
        dispatch(setFieldChoices({ fieldIndex: fieldIndex, choices: newArr }));
    }

    return <div className='mb-3 mt-1'>
        {field.choices?.map((c, i) =>
            <FieldChoice key={Math.random()} choice={c} index={i} field={field}
                length={field.choices.length} moveChoice={moveChoice}
                handleChoice={(choice: Choice) => {
                    dispatch(setFieldChoice({ fieldIndex: fieldIndex, choiceIndex: i, choice: choice }));

                    if (choice.default) {
                        dispatch(setFieldChoiceDefault({ fieldIndex: fieldIndex, choiceIndex: i, default: true }));
                    }
                }} />)}
    </div>
}

type FieldChoiceProps = {
    choice: Choice,
    field: LandingField
    index: number,
    length: number,
    moveChoice: (code: string, direction: number) => void,
    handleChoice: (choice: Choice) => void,
}

const FieldChoice: React.FC<FieldChoiceProps> = ({ choice, index, length, field, moveChoice, handleChoice }) => {
    return <>
        <Row className='d-flex align-items-center pb-3 pt-3' style={{ background: '#ececec' }}>
            <Col sm={1}>{ }</Col>
            <Col sm={1} className='text-center'>[ {index + 1} ]</Col>
            <Col sm={4}>
                <FormControl type='text' defaultValue={choice.title} onBlur={(e) => handleChoice({ ...choice, title: e.target.value })} />
                <FormText className='text-muted'><b>{choice.code}</b></FormText>
            </Col>
            <Col sm={5}>
                <div className='d-flex'>
                    <FormCheck type='switch' label='Actif' defaultChecked={choice.active}
                        onChange={(e) => {
                            e.preventDefault();
                            handleChoice({ ...choice, active: e.target.checked })
                        }} />
                    <FormCheck type='radio' label='Valeur par défaut' className='ms-3'
                        name={'field-choice-' + field.code}
                        defaultChecked={choice.default}
                        onChange={(e) => {
                            handleChoice({ ...choice, default: e.target.checked })
                        }} />
                </div>
            </Col>
            <Col sm={1}>
                <div className='d-flex align-items-center'>
                    <div className='d-flex flex-column me-2'>
                        <IfTrue condition={index > 0}>
                            <ButtonIcon code='arrow-up-square' variant='' size='sm' className='mb-1'
                                onClick={(e) => {
                                    e.preventDefault();
                                    moveChoice(choice.code, -1)
                                }} />
                        </IfTrue>

                        <IfTrue condition={index + 1 < length}>
                            <ButtonIcon code='arrow-down-square' variant='' size='sm'
                                onClick={(e) => {
                                    e.preventDefault();
                                    moveChoice(choice.code, 1)
                                }} />
                        </IfTrue>
                    </div>
                </div>
            </Col>
        </Row>
    </>
}

export default LandingFieldsForm;