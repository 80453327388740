import { useCallback, useEffect, useLayoutEffect, useRef, useState } from "react";
import { FormControl, InputGroup } from "react-bootstrap";
import { Icon } from "../Design";

type MarginPaddingProps = {
   init: string,
   option: string,
   callback: (value: string) => void,
   className?: string,
};

export const MarginPadding: React.FC<MarginPaddingProps> = ({ init, callback, option, className }) => {
   const initSpace = init.replace(/\s/g, '').split('%').filter(p => p);
   const [blockSpace, setBlockSpace] = useState({
      top: initSpace[0] ? parseFloat(initSpace[0]) : 0.0,
      right: initSpace[1] ? parseFloat(initSpace[1]) : 0.0,
      bottom: initSpace[2] ? parseFloat(initSpace[2]) : 0.0,
      left: initSpace[3] ? parseFloat(initSpace[3]) : 0.0
   });

   const updateState = useCallback(() => {
      const spaceStyle = blockSpace.top + '% ' + blockSpace.right + '% ' + blockSpace.bottom + '% ' + blockSpace.left + '%';
      callback(spaceStyle);
   }, [blockSpace]);

   const changeSpace = ({ type }: { type: 'top' | 'right' | 'left' | 'bottom' }) => (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value) {
         const obj = { ...blockSpace, [`${type}`]: parseFloat(e.target.value) };
         setBlockSpace(obj);
         return obj;
      }
   };

   const fUpdate = useRef(true);
   useLayoutEffect(() => {
      if (fUpdate.current) {
         fUpdate.current = false;
      } else {
         updateState();
      }
   }, [blockSpace]);

   return <>
      <div className='d-flex'>
         <InputGroup size='sm' className=''>
            <InputGroup.Text id="basic-addon1"><Icon code='arrow-bar-right' /></InputGroup.Text>
            <FormControl type='number' min={0} max={100} className='small-number-input' defaultValue={initSpace[3]} step={0.1}
               onChange={changeSpace({ type: 'left' })} />
         </InputGroup>
         <InputGroup size='sm' className='ms-1'>
            <InputGroup.Text id="basic-addon1"><Icon code='arrow-bar-down' /></InputGroup.Text>
            <FormControl type='number' min={0} max={100} className='small-number-input' defaultValue={initSpace[0]} step={0.1}
               onChange={changeSpace({ type: 'top' })} />
         </InputGroup>
      </div>
      <div className='d-flex mt-1'>
         <InputGroup size='sm' className=''>
            <InputGroup.Text id="basic-addon1"><Icon code='arrow-bar-left' /></InputGroup.Text>
            <FormControl type='number' min={0} max={100} className='small-number-input' defaultValue={initSpace[1]} step={0.1}
               onChange={changeSpace({ type: 'right' })} />
         </InputGroup>
         <InputGroup size='sm' className='ms-1'>
            <InputGroup.Text id="basic-addon1"><Icon code='arrow-bar-up' /></InputGroup.Text>
            <FormControl type='number' min={0} max={100} className='small-number-input' defaultValue={initSpace[2]} step={0.1}
               onChange={changeSpace({ type: 'bottom' })} />
         </InputGroup>
      </div>
   </>
}