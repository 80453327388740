import { useEffect, useState } from "react";
import {Card, Col, FormCheck, FormControl, FormLabel, FormSelect, Row} from "react-bootstrap";
import manageApi from "../../../app/services/secured/manage";
import { EDIIS_GATEWAY, GatewayAPI, GatewayFormsProps } from "../../../types";
import { BlockError, FormInputGroup } from "../../misc/Blocks";
import { BtnIconLoad } from "../../misc/Buttons";
import SimpleForm from "../../misc/SimpleForm";
import { PendingLeadsButton } from "../AccountExports";

const EdiisAlizeeForm: React.FC<GatewayFormsProps> = gateway => {
    const [isNew, setIsNew] = useState(true);
    const config = gateway.config as EDIIS_GATEWAY;

    useEffect(() => {
        if (gateway.id) setIsNew(false);
    }, [gateway])

    const [gatewayAPI, callGatewayAPI] = manageApi.useHandleFormGatewayMutation();

    return <>
        <SimpleForm onSubmit={(json) => {
            gatewayAPI(isNew 
                ? {company: gateway.company, form: gateway.form, gateway: '', data: json as EDIIS_GATEWAY} as GatewayAPI
                : {company: gateway.company, form: gateway.form, gateway: gateway.id, data: json as EDIIS_GATEWAY} as GatewayAPI
            )
        }}>
            <FormControl type='hidden' name='gateway_code' value='ediis' />
            <Card className='mt-3'>
                <Card.Header>
                    <div className='d-flex justify-content-between align-items-center'>
                        <div className='d-flex align-items-center'>
                            <FormCheck id='activate_api' type='switch' label='Activer export' name='enabled' defaultChecked={gateway.enabled} />
                            {!isNew && gateway.id && <PendingLeadsButton company_id={gateway.company} form_id={gateway.form} gateway_id={gateway.id} />}
                        </div>
                        <h6 className='text-end m-0'>EdiisAlizée</h6>
                    </div>
                </Card.Header>
                <Card.Body>
                    <BlockError data={callGatewayAPI} />
                    <h6 className='mt-2'>Paramètres généraux</h6>
                    <Row>
                        <FormInputGroup label='Login' as={Col}>
                            <FormControl size='sm' type='text' placeholder='Login' name="config[login]" defaultValue={config?.login} />
                        </FormInputGroup>
                        <FormInputGroup label='Mot de passe' as={Col}>
                            <FormControl size='sm' type='text' placeholder='pass' name="config[password]" defaultValue={config?.password} />
                        </FormInputGroup>
                        <FormInputGroup label='Base' as={Col}>
                            <FormControl size='sm' type='text' placeholder='base' name="config[base]" defaultValue={config?.base} />
                        </FormInputGroup>
                        <FormInputGroup label='Origine' as={Col}>
                            <FormControl size='sm' type='text' placeholder='origine' name="config[origin]" defaultValue={config?.origin} />
                        </FormInputGroup>
                    </Row>

                    <h6 className='mt-2'>Paramètres webservice création/réaction</h6>
                    <Row>
                        <FormInputGroup label='Indic' as={Col}>
                            <FormControl size='sm' type='text' placeholder='indic' name="config[indic]" defaultValue={config?.indic} />
                        </FormInputGroup>
                        <FormInputGroup label='Action' as={Col}>
                            <FormControl size='sm' type='text' placeholder='action' name="config[action]" defaultValue={config?.action} />
                        </FormInputGroup>
                        <FormInputGroup label='Canal' as={Col}>
                            <FormControl size='sm' type='text' placeholder='canal' name="config[canal]" defaultValue={config?.canal} />
                        </FormInputGroup>
                    </Row>
                    <Row>
                        <FormInputGroup label='Webservice' as={Col}>
                            <FormSelect name="config[webservice]" defaultValue={config?.webservice || "1"}>
                                <option value="1">creation_reaction</option>
                                <option value="2">creation_relation_consentement</option>
                            </FormSelect>
                        </FormInputGroup>
                    </Row>

                    <h6 className='mt-2'>Paramètres webservice inscription newsletter</h6>
                    <FormInputGroup label='Indicateur de sollication'>
                        <FormControl size='sm' type='text' placeholder='sollication' name="config[sollicitation]" defaultValue={config?.sollicitation} />
                    </FormInputGroup>

                    <h6 className='mt-2'>Référentiel Client</h6>
                    <Row>
                        <FormInputGroup label='Civilite (m)' as={Col}>
                            <FormControl size='sm' type='text' placeholder='Civilite (m)' name="config[field][civility][m]" defaultValue={config?.field?.civility?.m} />
                        </FormInputGroup>
                        <FormInputGroup label='Civilite (mme)' as={Col}>
                            <FormControl size='sm' type='text' placeholder='Civilité (mme)' name="config[field][civility][md]" defaultValue={config?.field?.civility?.md} />
                        </FormInputGroup>
                    </Row>

                    <div className='mt-3 text-end'>
                        <BtnIconLoad data={callGatewayAPI}>Enregister</BtnIconLoad>
                    </div>
                </Card.Body>
            </Card>
        </SimpleForm>
    </>
}

export default EdiisAlizeeForm;