import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import manageApi from '../../app/services/secured/manage';
import { RootState } from '../../app/store';
import { ReportingType } from '../../types';

export type ReportingGlobalParameters = {
    search: Dimensions,
    stats: Stats,
    data: Datas,
}

export type ParamOptions = { label: string, icon: string, active: boolean };

export type Dimension = keyof Pick<ReportingType, 'customer' | 'source' | 'lp' | 'template' | 'campaign' | 'date' | 'week' | 'month'>;
export type Dimensions = Record<Dimension, ParamOptions>

export type RStat = 'total' | 'spent' | 'optins' | 'optinsPhones' | 'duplicates' | 'duplicatesAccount' | 'dispatched' | 'dispatchWaiting' | 'purged' | 'exported'
export type Stats = Record<keyof Pick<ReportingType, RStat>, ParamOptions>

export type RData = 'cpl' | 'optinRate' | 'phoneRate' | 'duplicatesGlobal' | 'duplicatesRate' | 'duplicatesAccountRate' | 'exportedRate';
export type Datas = Record<RData, ParamOptions>

export type ReportingQuery = {
    from: string,
    to: string,
    company_id?: string,
    template?:undefined,
}

type ReportingSlice = {
    data: Array<ReportingType>,
    params: ReportingGlobalParameters,
    loading: boolean,
}

const init: ReportingSlice = {
    loading: false,
    data: [],
    params: {
        search: {
            customer: { label: 'Compte', icon: 'building', active: false },
            source: { label: 'Source', icon: 'glob', active: false },
            lp: { label: 'LP', icon: 'grid', active: false },
            template: { label: 'Format de LP', icon: 'info-circle-fill', active: false },
            campaign: { label: 'Campagnes', icon: 'facebook', active: false },
            month: { label: 'Mois', icon: 'calendar', active: false },
            week: { label: 'Semaine', icon: 'calendar', active: false },
            date: { label: 'Date', icon: 'calendar', active: false },
        },
        stats: {
            total: { label: 'Total collecté', icon: 'activity', active: false },
            spent: { label: 'Dépenses', icon: 'cash-stack', active: true },
            optins: { label: 'Envoyés Optin', icon: 'send-check', active: false },
            optinsPhones: { label: 'Envoyés Optin + Tel', icon: 'envelope-check', active: true },
            duplicates: { label: 'Doublons (base client)', icon: 'people', active: false },
            duplicatesAccount: { label: 'Doublons (compte)', icon: 'people', active: false },
            dispatched: { label: 'Attribués', icon: 'person-check', active: false },
            exported: { label: 'Exportés Webservice', icon: 'person-check', active: false },
            dispatchWaiting: { label: 'Non attribués', icon: 'person-x', active: false },
            purged: { label: 'Purgés', icon: 'person-dash', active: false },
        },
        data: {
            cpl: { label: 'CPL', icon: 'cash-coin', active: true },
            optinRate: { label: 'Taux optin', icon: 'bag-check', active: true },
            phoneRate: { label: 'Taux tel', icon: 'telephone-plus', active: true },
            duplicatesGlobal: { label: 'Taux doublons (global)', icon: 'people', active: true },
            duplicatesRate: { label: 'Taux doublons (client)', icon: 'people', active: false },
            duplicatesAccountRate: { label: 'Taux doublons (compte)', icon: 'building', active: false },
            exportedRate: { label: 'Taux exports webservice', icon: 'hdd-network', active: true },
        }
    },
}

const reporting = createSlice({
    name: "app",
    initialState: init,
    reducers: {
        setParams: (state, action: PayloadAction<ReportingGlobalParameters>) => {
            state.params = action.payload;
        },
        setReportingData: (state, action:PayloadAction<Array<ReportingType>>) => {
            state.data = action.payload;
            state.loading = false;
        },
        setDimensions: (state, action: PayloadAction<Dimensions>) => {
            state.params.search = action.payload;
        },
        setStats: (state, action:PayloadAction<Stats>) => {
            state.params.stats = action.payload;
        },
        setData: (state, action:PayloadAction<Datas>) => {
            state.params.data = action.payload;
        },
        resetParams: (state) => {
            state.params.search = init.params.search;
            state.params.data = init.params.data;
            state.params.stats = init.params.stats;
        }
    },
})

export const getReportingData = (state: RootState) => state.reporting.data;
export const getReportingParams = (state: RootState) => state.reporting.params;
export const getDimensions = (state:RootState) => Object.keys(Object.entries(state.reporting.params.search).filter(s => s[1].active));

export const { setParams, setDimensions, setStats, setData, setReportingData, resetParams } = reporting.actions;

export default reporting.reducer;