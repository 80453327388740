import { Card, Form, Image, InputGroup } from "react-bootstrap";
import diffusionApi from "../../app/services/secured/diffusion";
import AppSecured from "../../AppSecured";
import Urls from "../../assets/js/Urls";
import { SiteDomain } from "../../types";
import { PageHeader } from "../App/PageHeader";
import { LoadingBlock } from "../misc/Blocks";
import { ButtonIcon } from "../misc/Buttons";
import TableGenelead from "../misc/Table";

const SiteList: React.FC = () => {
    const sitesReq = diffusionApi.useGetSiteListQuery();
    return <>
        <AppSecured>
            <PageHeader action={
                    <ButtonIcon code={"plus-circle"} size={"sm"} variant={"primary"} href={Urls.sites.edit('new')}>
                        Nouveau site / domaine
                    </ButtonIcon>}>
            </PageHeader>

            <LoadingBlock data={sitesReq}>
                <TableGenelead hasActions={true} header={[
                    { name: 'site', icon: 'globe' },
                    //{ name: 'info', icon: 'info' }
                ]}>
                    {sitesReq.data?.map(s => <SiteRow key={s.id} {...s} />)}
                </TableGenelead>
            </LoadingBlock>
        </AppSecured>
    </>
}

const SiteRow: React.FC<SiteDomain> = site => {
    return <tr>
        <td>{site.favicon && <Image src={site.favicon} />} <a target='_blank' href={site.domain}>{site.domain}</a></td>
        {/* <td>{site.seoData.title} <small>{site.seoData.desc}</small></td> */}
        <td className='text-end'>
            <ButtonIcon href={Urls.sites.edit(site.id.toString())} className='me-1 alt-primary' code={"pencil-square"}
                size={"lg"} variant={"primary"} />
            <ButtonIcon code={"diagram-3"} size={"lg"} variant={"secondary"} className='alt-secondary'
                href={Urls.sites.landings(site.id.toString())}
            />
        </td>
    </tr>
}

const SiteCard: React.FC<{ site: SiteDomain }> = ({ site }) => {
    return <>
        <Card className='me-2 text-center site-domain-card'>
            <Card.Header>
                {site.favicon && <Image src={site.favicon} />} {site.domain}
            </Card.Header>
            <Card.Body>
                {site.seoData.title} <br />
                <small>{site.seoData.desc}</small>
            </Card.Body>
            <Card.Footer className='text-end'>

            </Card.Footer>
        </Card>
    </>
}

export default SiteList;