import { Badge } from "react-bootstrap";
import adminApi from "../../../app/services/secured/admin";
import AppSecured from "../../../AppSecured";
import Urls from "../../../assets/js/Urls";
import { User } from "../../../types";
import { PageHeader } from "../../App/PageHeader";
import { LoadingBlock } from "../../misc/Blocks";
import { ButtonIcon } from "../../misc/Buttons";
import TableGenelead from "../../misc/Table";

const UserList: React.FC = () => {
    const reqUsers = adminApi.useGetUsersListQuery();
    return <>
        <AppSecured>
            <PageHeader action={
                <ButtonIcon code={"person-plus"} size={"sm"} variant={"primary"} href={Urls.users.edit('new')}>
                    Nouvel utilisateur    
                </ButtonIcon>} />
            <LoadingBlock data={reqUsers}>
                <TableGenelead header={[
                    { icon: 'activity', name: '', align: 'center' },
                    { icon: 'person', name: 'name' },
                    { icon: 'envelope', name: 'Email' },
                    { icon: 'server', name: 'API', align: 'center' },
                    { icon: 'door-open', name: 'Roles' },
                ]} hasActions={true}>
                    {reqUsers.data?.map(u => <UserRow key={u.id} user={u} />)}
                </TableGenelead>
            </LoadingBlock>
        </AppSecured>
    </>
}

const UserRow: React.FC<{ user: User }> = ({ user }) => {
    return <tr>
        <td className='text-center'>{user.active ? <><Badge bg='success'> </Badge></> : <><Badge bg='danger'> </Badge></>}</td>
        <td>{user.first_name + ' ' + user.last_name}</td>
        <td>{user.email}</td>
        <td className='text-center'>
            {!user.apiUser ? <Badge className="alt-primary" >User</Badge> : <></>}
        </td>
        <td>{user.roles?.map(r => <div key={Math.random()}>{r}</div>)}</td>
        <td className='text-end'>
            <ButtonIcon code='pen' size='lg' variant='primary' className='alt-primary' href={Urls.users.edit(user.id.toString())} />
        </td>
    </tr>
}

export default UserList;