import { useEffect, useMemo, useState } from "react";
import { Col, FormCheck, FormControl, FormLabel, InputGroup, Row } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import creationApi from "../../../app/services/secured/creation";
import { ContentWordings, getLanding, getLandingData, setLanding, setLandingData, SpecificWordings } from "../../../app/slice/landing";
import { LandingDesignData, LandingTemplate } from "../../../types";
import { LoadingBlock } from "../../misc/Blocks";
import { DataField, hasSpecificField } from "../LandingEdit";

export const BasicWordings: Array<string> = ['title', 'subtitle', 'excerpt', 'body'];

const LandingTemplates: React.FC = () => {
    const dispatch = useAppDispatch();
    const landing = useAppSelector(getLanding);
    const templates = creationApi.useGetTemplatesQuery();
    const [enabledTemplates, setEnabledTemplates] = useState<Array<number | string>>([...landing.enabledTemplates]);

    const bWordings = useMemo(() => {
        return BasicWordings.map(w => {
            return <DataField key={Math.random()} className='mb-4' field={w as keyof LandingDesignData} />
        });
    }, []);

    return <>
        <Row>
            <Col>{bWordings}</Col>
            <Col sm={3} className='ps-5'>
                <h6>Templates</h6>
                <LoadingBlock data={templates}>
                    {templates.isSuccess && <>
                        {templates.data.pages.map((p, i) =>

                            <TemplateItem onCheck={(templateCode: string, active: boolean) => {
                                const template = templates.data.pages.find(t => t.code === templateCode);
                                if (template && active) {
                                    const newArr = [...enabledTemplates].filter(t => t !== template.id)
                                    setEnabledTemplates(newArr);
                                    dispatch(setLanding({ ...landing, enabledTemplates: newArr }));
                                } else if (template && !active) {
                                    const enabled = [...enabledTemplates];
                                    enabled.push(template.id as number);
                                    setEnabledTemplates(enabled);
                                    dispatch(setLanding({ ...landing, enabledTemplates: enabled }));
                                }
                            }} key={Math.random()} enabled={enabledTemplates} index={i} {...p} />)}
                    </>}
                    <Row className='mt-3'>
                        <Col className='d-flex align-items-center text-start'>
                            <FormControl type='color' defaultValue={landing.data.color ? landing.data.color : '#000000'}
                                onChange={(e) => dispatch(setLandingData({ ...landing.data, color: e.target.value }))} />
                            <FormLabel className='ms-3'>Couleur principale</FormLabel>
                        </Col>

                        {hasSpecificField(enabledTemplates, 'secondary_color') && <>
                            <Col className='d-flex align-items-center text-start mt-2'>
                                <FormControl type='color' defaultValue={landing.data.secondary_color}
                                    onChange={(e) => dispatch(setLandingData({ ...landing.data, secondary_color: e.target.value }))} />
                                <FormLabel className='ms-3'>Couleur secondaire</FormLabel>
                            </Col>
                        </>}

                        <Col className='d-flex align-items-center text-start mt-2'>
                            <FormControl type='color' defaultValue={landing.data.default_bg_color ? landing.data.default_bg_color : '#f8f8f8'}
                                         onChange={(e) => dispatch(setLandingData({ ...landing.data, default_bg_color: e.target.value }))} />
                            <FormLabel className='ms-3'>Couleur de fond (defaut)</FormLabel>
                        </Col>
                    </Row>

                    <hr />
                    <DataField field="cta" />
                    <DataField field="form_title" />

                    <FormLabel>Taille logo sur desktop</FormLabel>
                    <FormControl type='number' defaultValue={landing.data.logo_height ? landing.data.logo_height : '90'}
                        onChange={(e) => dispatch(setLandingData({ ...landing.data, logo_height: e.target.value }))} />
                </LoadingBlock>
            </Col>

            {enabledTemplates.length > 0 && <>
                <hr className='mt-2 mb-2' />
                <Row>
                    <h5 className='mt-4'>Champs spécifiques</h5>
                    <SpecificFields enabledTemplates={enabledTemplates} />
                </Row>
            </>}
        </Row>
    </>
}

type TemplateItemProps = LandingTemplate & {
    index: number,
    enabled: Array<number | string>,
    onCheck: (fieldCode: string, active: boolean) => void
}

const TemplateItem: React.FC<TemplateItemProps> = template => {
    const [checked, setChecked] = useState(template.enabled.findIndex(t => t === template.id) > -1 ? true : false);
    return <InputGroup>
        <FormCheck checked={checked} type='checkbox' id={'template-' + template.index}
            onChange={() => template.onCheck(template.code, checked)} />
        <FormLabel className='template-label ms-2' htmlFor={'template-' + template.index}>[{template.id}] {template.code}</FormLabel>
    </InputGroup>
}

const SpecificFields: React.FC<{ enabledTemplates: Array<number | string> }> = ({ enabledTemplates }) => {
    const templates = creationApi.useGetTemplatesQuery();
    const [specificFields, setSpecificFields] = useState<Array<{ field: keyof LandingDesignData, type: string }>>([]);

    useEffect(() => {
        if (templates.isSuccess && enabledTemplates) {
            const newArr: Array<{ field: keyof LandingDesignData, type: string }> = [];
            templates.data.pages
                .filter(p => enabledTemplates.findIndex(l => l === p.id) > -1)
                .map(s => {
                    s.variables
                        .filter(m => ContentWordings.specific.includes(m as keyof LandingDesignData))
                        //.filter(m => SpecificWordings[m as keyof LandingDesignData] ? true:false)
                        .forEach((m) => {
                            const val = { field: m as keyof LandingDesignData, type: SpecificWordings[m as keyof LandingDesignData] ? SpecificWordings[m as keyof LandingDesignData]?.type as string : 'editor' };
                            if (newArr.findIndex(v => v.field === m) === -1)
                                newArr.push(val);
                        });
                })

            setSpecificFields(newArr);
        }
    }, [templates.isSuccess, enabledTemplates]);

    const sFields = useMemo(() => {
        return specificFields.map(s =>
            s.type === 'editor'
                ? <DataField key={Math.random()} field={s.field} size={6} specific={true} className='mb-4' />
                : <SpecificInput key={Math.random()} field={s.field} />
        )
    }, [specificFields]);

    return <>{sFields}</>;
}

const SpecificInput: React.FC<{ field: string }> = ({ field }) => {
    const dispatch = useAppDispatch();
    const landingData = useAppSelector(getLandingData);
    return <Col sm={6}>
        <FormLabel className='fs-18'><FormattedMessage id={field} /></FormLabel>
        <FormControl type='text' defaultValue={landingData[`${field}` as keyof LandingDesignData] as string}
            onBlur={(e) => dispatch(setLandingData({ ...landingData, [`${field}`]: e.target.value }))} />
    </Col>
}

export default LandingTemplates;