import { useEffect, useState } from "react";
import { Button, Card, Col, Container, FormCheck, FormControl, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { getLanding, setQuizzArray, setQuizzAnswers, setQuizz } from "../../../app/slice/landing";
import { LandingQuizz, LandingQuizzAnswers } from "../../../types";
import { FormInputGroup } from "../../misc/Blocks";
import { ButtonIcon } from "../../misc/Buttons";

const Quizz = () => {
    const dispatch = useAppDispatch();
    const landing  = useAppSelector(getLanding);
    return <>
        {landing.quizz.map((q, i) =>
            <QuizzField key={Math.random()} quizz={q} index={i}
                        onDelete={(qIndex) => {
                            dispatch(setQuizzArray([ ...landing.quizz ].filter((qq, j) => j !== qIndex) as Array<LandingQuizz>));
                        }}/>)}

        <ButtonIcon code='plus' variant='primary' size='lg' className='mt-4 w-100'
                    onClick={() => {
                        const newQuizz = [ ...landing.quizz ] as Array<Partial<LandingQuizz>>;
                        newQuizz.push({});
                        dispatch(setQuizzArray(newQuizz as Array<LandingQuizz>));
                    }}
        >Ajouter une question</ButtonIcon>
    </>
}

type QuizzFieldProps = {
    quizz: Partial<LandingQuizz>,
    index: number,
    onDelete: (qIndex: number) => void,
}

const QuizzField: React.FC<QuizzFieldProps> = ({ quizz, index, onDelete }) => {
    const dispatch                = useAppDispatch();
    const [ answers, setAnswers ] = useState(quizz.answers ? quizz.answers : []);

    const addAnswer = () => {
        const newAnswers = [ ...answers ];
        newAnswers.push({ code: answers.length + 1, good: false, answer: '' });
        setAnswers(newAnswers);
        dispatch(setQuizzAnswers({ index: index, answers: newAnswers }));
    }

    useEffect(() => {
        if (answers.length === 0) {
            addAnswer();
        }
    }, [answers]);

    return <div className='mt-3'>
        <Card>
            <Card.Header>
                <Row className='d-flex align-items-center'>
                    <Col>
                        <FormInputGroup label='Question'>
                            <FormControl type='text' defaultValue={quizz?.question} placeholder='question'
                                         onBlur={(e) => {
                                             dispatch(setQuizz({
                                                 index: index,
                                                 quizz: { ...quizz, question: e.target.value } as LandingQuizz
                                             }))
                                         }}/>
                        </FormInputGroup>
                    </Col>
                    <Col sm={1}>
                        <ButtonIcon code='x' size='lg' variant='danger' onClick={() => onDelete(index)}
                                    className='d-block w-100'/>
                    </Col>
                </Row>
                <FormInputGroup label="Complément">
                    <FormControl as="textarea" defaultValue={quizz?.supplement} placeholder="Complément"
                                 onBlur={(e) => {
                                     dispatch(setQuizz({
                                         index: index,
                                         quizz: { ...quizz, supplement: e.target.value } as LandingQuizz
                                     }))
                                 }}/>
                </FormInputGroup>
                <FormInputGroup label='Explication'>
                    <FormControl as="textarea" defaultValue={quizz?.explanation} placeholder='explication'
                                 onBlur={(e) => {
                                     dispatch(setQuizz({
                                         index: index,
                                         quizz: { ...quizz, explanation: e.target.value } as LandingQuizz
                                     }))
                                 }}/>
                </FormInputGroup>
            </Card.Header>
            <Card.Body>
                <Row>
                    <h6>Réponses</h6>
                    {answers.map((a, i) =>
                        <QuizzAnswer key={Math.random()} answer={a}
                                     handleAnswer={(answer: Partial<LandingQuizzAnswers>) => {
                                         const newAnswers = [ ...answers ];
                                         newAnswers[i]    = answer as LandingQuizzAnswers;
                                         setAnswers(newAnswers);
                                         dispatch(setQuizzAnswers({
                                             index: index,
                                             answers: newAnswers
                                         }));
                                     }}
                                     deleteAnswer={() => dispatch(setQuizzAnswers({
                                         index: index,
                                         answers: [ ...answers ].filter(a => a.code !== i + 1)
                                     }))}
                        />)}
                    <Col className='mt-1'>
                        <Button onClick={() => addAnswer()}>Ajouter une réponse</Button>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </div>
}

type QuizzAnswerProps = {
    answer: Partial<LandingQuizzAnswers>,
    handleAnswer: (answer: Partial<LandingQuizzAnswers>) => void,
    deleteAnswer: () => void
}

const QuizzAnswer: React.FC<QuizzAnswerProps> = ({ answer, handleAnswer, deleteAnswer }) => {
    return <>
        <Col sm={6} className='mt-1'>
            <Row className='d-flex align-items-center'>
                <Col sm={9}>
                    <FormControl defaultValue={answer?.answer}
                                 onBlur={(e) => handleAnswer({ ...answer, answer: e.target.value })}/>
                </Col>
                <Col sm={2}><FormCheck defaultChecked={answer?.good} label='Valide' className='m-0'
                                       onChange={(e) => handleAnswer({ ...answer, good: e.target.checked })}/></Col>
                <Col sm={1}><ButtonIcon size='lg' variant='danger' code='x' onClick={() => deleteAnswer()}/></Col>
            </Row>
        </Col>
    </>
}

export default Quizz;