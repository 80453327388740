import ApiUrls from "../../../assets/js/ApiUrls";
import { jsonToFormData } from "../../../components/misc/SimpleForm";
import { FbCampaign, FbForm, FbLinkForm, FbPage, FbSyncUpdate, ListData, LiveLanding, LiveLandingsData, SiteDomain } from "../../../types";
import securedApi from "./securedApi";

const diffusionApi = securedApi.injectEndpoints({
    endpoints: (build) => ({

        // SITES & DOMAINS
        getSiteList: build.query<Array<SiteDomain>, void>({
            query: () => ApiUrls.sites.list
        }),
        getSite: build.query<SiteDomain, Pick<SiteDomain, 'id'>>({
            query: (args) => ApiUrls.sites.edit(args.id)
        }),
        updateSite: build.mutation<void, Pick<SiteDomain, 'id'> & {form: SiteDomain}>({
            query: (args) => ({
                url: ApiUrls.sites.edit(args.id),
                method: 'POST',
                body: jsonToFormData(args.form) 
            })
        }),
        createSite: build.mutation<void, SiteDomain>({
            query: (form) => ({
                url: ApiUrls.sites.list,
                method: 'POST',
                body: jsonToFormData(form)
            })
        }),
        getLivelist: build.query<LiveLandingsData, string>({
            query: (id) => ApiUrls.landing.liveList(id)
        }),
        updateLivelist: build.mutation<void, Pick<SiteDomain, 'id'> & {landings: Array<LiveLanding>}>({
            query: (data) => ({
                url: ApiUrls.landing.liveList(data.id.toString()),
                method: 'POST',
                body: {landings: data.landings}
            })
        }),

        // FACEBOOK
        getFbForms: build.query<ListData<FbForm>, {query: string, page:number}>({
            query: (args) => ({
                url: ApiUrls.fbforms.list,
                params: args
            }),
            providesTags: ['FbForms'],
        }),
        updateSyncro: build.mutation<any, Pick<FbForm, 'facebookFormId'> & {form: FbSyncUpdate}>({
            query: (args) => ({
                url: ApiUrls.fbforms.synchro(args.facebookFormId),
                method: 'POST',
                body: jsonToFormData(args.form)
            })
        }),
        updateLink: build.mutation<any, Pick<FbForm, 'form'|'facebookFormId'|'campaigns'>>({
            query: (form) => ({
                url: ApiUrls.fbforms.list,
                method: 'POST',
                body: jsonToFormData(form)
            })
        }),
        getFbPages: build.query<Array<FbPage>, void>({
            query: () => ApiUrls.fbforms.searchPages,
            providesTags: ['FbPages']
        }),
        getFbPageForms: build.query<Array<FbCampaign>, Pick<FbPage, 'id'>>({
            query: (query) => ApiUrls.fbforms.searchPageForms(query.id),
        }),
        linkFbForm: build.mutation<any, FbLinkForm>({
            query: (args) => ({
                url: ApiUrls.fbforms.list,
                method: 'POST',
                body: args
            })
        }),
        getInstall: build.query<{link: string}, void>({
            query: () => ApiUrls.fbforms.install,
        }),
        installFbApp: build.mutation<any, any>({
            query: (args) => ({
                url: ApiUrls.fbforms.install,
                method: 'POST',
                body: jsonToFormData(args)
            })
        })
    })
})

export default diffusionApi;