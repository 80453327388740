import { useEffect, useLayoutEffect, useState } from "react"
import { Card, Col, FormCheck, FormControl, FormLabel, Modal, Row } from "react-bootstrap"
import { Typeahead } from "react-bootstrap-typeahead"
import { formSuccessRedirect } from "../../app/actions"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import creationApi from "../../app/services/secured/creation"
import manageApi from "../../app/services/secured/manage"
import { getModalData, isAnyModalOpened, isModalOpened, modalClose, ModalTypes } from "../../app/slice/modal"
import { addNotification } from "../../app/slice/notifs"
import { Customer, LandingCustomer } from "../../types"
import { FormInputGroup, IfTrue, LoadingBlock } from "../misc/Blocks"
import { BtnIconLoad } from "../misc/Buttons"
import SimpleForm from "../misc/SimpleForm"
import ModalCloseButton from "../Modals/ModalCloseButton"

const ModalLinkAccount = () => {
    const dispatch = useAppDispatch();
    const show     = useAppSelector(isAnyModalOpened);
    const isOpened = useAppSelector(isModalOpened(ModalTypes.LinkLanding));
    const id       = useAppSelector(getModalData);

    const [ getCustomers, customersReq ]    = creationApi.useLazyGetLandingCustomersQuery();
    const [ getLanding, landing ]           = creationApi.useLazyGetLandingQuery();
    const [ getCompany, company ]           = manageApi.useLazyGetCompanyQuery();
    const [ editCustomer, editCustomerRes ] = creationApi.useEditLandingCustomersMutation();

    const [ resetSelect, setResetSelect ] = useState([]);
    const [ customers, setCustomers ]     = useState<Array<Partial<LandingCustomer>>>([]);

    useEffect(() => {
        setCustomers([]);
        if (id && isOpened) {
            getLanding({ id: id });
            getCustomers({ id: id });
        }
    }, [ id, isOpened ])

    useEffect(() => {
        if (customersReq.isSuccess) {
            setCustomers(customersReq.data);
        }
    }, [ customersReq ])

    useEffect(() => {
        if (isOpened && landing.data && landing.data.company) getCompany({ id: landing.data.company.id });
    }, [ landing.data?.code ])

    useEffect(() => {
        if (editCustomerRes.isSuccess) {
            setCustomers([]);
            dispatch(addNotification({ type: 'success', message: 'Succès édition.' }));
            dispatch(modalClose());
        }
    }, [ editCustomerRes.isSuccess ])

    return <IfTrue condition={isOpened && show}>
        <Modal size='lg' show={show}>
            <SimpleForm onSubmit={( data ) => {
                editCustomer({ id: id, customers: data });
            }}>
                <Modal.Header>
                    Link Landing Page - Compte client
                </Modal.Header>
                <Modal.Body>
                    <LoadingBlock data={customersReq}>
                        {
                            company.data?.customers && landing.data?.company && <>
                                <Typeahead
                                    id='select-customer'
                                    placeholder='Ajouter un compte'
                                    options={company.data.customers
                                        .filter(c => customers?.findIndex(cc => cc.customer_id === c.id) === -1)
                                        .map(c => {
                                            return { label: c.email, id: c.id }
                                        })}
                                    selected={resetSelect}
                                    onChange={( s ) => {
                                        if (s[0] && customers) {
                                            const val  = s[0] as { label: string, id: number }
                                            const newC = [ ...customers ];
                                            newC.push({ email: val.label, customer_id: val.id });
                                            setCustomers(newC);
                                            setResetSelect([]);
                                        }
                                    }}
                                />
                            </>
                        }
                        <Row className='mt-2' key={customers?.length}>
                            {customersReq.isSuccess && company.isSuccess && customers?.length > 0 && customers.map(( c, i ) => {
                                    return <CustomerCard key={customers.length + Math.random()} customer={c} index={i}
                                                         company_customers={company.data?.customers as Array<Customer>}/>
                                }
                            )}
                        </Row>
                    </LoadingBlock>
                </Modal.Body>
                <Modal.Footer>
                    <ModalCloseButton/>
                    <BtnIconLoad data={editCustomerRes}>Modifier</BtnIconLoad>
                </Modal.Footer>
            </SimpleForm>
        </Modal>
    </IfTrue>
}

const CustomerCard: React.FC<{ customer: Partial<LandingCustomer>, index: number, company_customers: Array<Customer> }> = ( {
                                                                                                                                customer,
                                                                                                                                index,
                                                                                                                                company_customers
                                                                                                                            } ) => {

    const isFromCompany = company_customers?.findIndex(c => c.id === customer.customer_id) === -1 ? false : true;

    return <Col sm={6}>
        <Card className='mt-2' bg={isFromCompany ? '' : 'danger'}>
            <Card.Header as="h6">
                <div className='d-flex align-items-center justify-content-between'>
                    <div>{customer.email}</div>
                </div>
            </Card.Header>
            <Card.Body>
                <Row className='d-flex align-items-center'>
                    <FormControl type='hidden' name={`customers[${index}][customer_id]`} value={customer.customer_id}/>
                    <Col sm={2}>
                        <FormCheck type='switch' className='fs-16' name={`customers[${index}][enabled]`}
                                   defaultChecked={customer.enabled}
                        />
                        <FormLabel>Actif</FormLabel>
                    </Col>
                    <Col>
                        <FormInputGroup label='Code custom' className='ms-2'>
                            <FormControl placeholder='code' type='text' name={`customers[${index}][customCode]`}
                                         defaultValue={customer.customCode}/>
                        </FormInputGroup>
                    </Col>
                    <Col>
                        <FormInputGroup label='Poids'>
                            <FormControl placeholder='weight' type='number' name={`customers[${index}][weight]`}
                                         defaultValue={customer.weight}/>
                        </FormInputGroup>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </Col>
}

export default ModalLinkAccount;