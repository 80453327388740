import React from "react";
import { Alert, Col, FloatingLabel, InputGroup, Row, Spinner } from 'react-bootstrap';
import { ReduxRtkApiResult } from "../../types";
import { BtnIconLoad, CancelButton } from "./Buttons";

type DataBlockProps = {
    data: ReduxRtkApiResult,
    children: any,
}

export const LoadingBlock: React.FC<DataBlockProps> = ({ data, children }) => {
    return <>
        {
            data.isLoading || data.isFetching
                ? <div className="text-center mt-4" >
                    <div><Spinner animation="grow" size='sm' variant='primary' /></div>
                </div>
                : data.isSuccess
                    ? children
                    : null
        }
    </>
};

export const LoadingRow: React.FC<DataBlockProps & { colspan: number }> = ({ data, children, colspan }) => {
    return <>
        {
            data.isLoading || data.isFetching
                ? <tr className='alert alert-primary'>
                    <td colSpan={colspan} className='text-center'><span className="spinner-grow" /></td>
                </tr>
                : data.isSuccess
                    ? children
                    : null
        }
    </>
}

export const BlockError: React.FC<Pick<DataBlockProps, "data">> = ({ data }) => {
    const status = data?.error?.originalStatus || data?.error?.status;

    return <>
        {
            data.isError && <Alert variant={"dark"} className="bg-danger my-2 rounded-0 border-0">
                {
                    "401" === status.toString()
                        ? "Utilisateur inconnu"
                        : "403" === status.toString()
                            ? "Accès à une ressource non autorisé"
                            : "404" === status.toString()
                                ? "Ressource introuvable"
                                : "500" === status.toString()
                                    ? data?.error?.data?.error || "Erreur serveur"
                                    : `Erreur inconnue (${status})`
                }
            </Alert>
        }
    </>
}

export const BlockSuccess: React.FC<DataBlockProps> = ({ data, children }) => {
    return <>
        {
            data.isSuccess && <Alert variant="success" className="my-2">{children}</Alert>
        }
    </>
}

type FormInputGroupProps = {
    children: any,
    label?: string,
    icon?: string,
    className?: string,
    as?: any
}
export const FormInputGroup: React.FC<FormInputGroupProps> = ({ children, label, icon, className, as }) => {
    return <>
        <InputGroup as={as} className={className + ' mb-1'}>
            <FloatingLabel controlId={label} label={label} className='w-100'>
                {children}
            </FloatingLabel>
        </InputGroup>

        {/* <InputGroup as={as} className={className}>
            <InputGroup.Text>
                {icon && <Icon className='me-1' code={icon} />}
                {label}
            </InputGroup.Text>
            {children}
        </InputGroup> */}
    </>
}

type FormButtonsProps = {
    children?: any,
    cancelUrl: string,
    data: ReduxRtkApiResult,
    icon?: string,
    btnTxt?: string
}

export const FormButtons: React.FC<FormButtonsProps> = ({ cancelUrl, data, icon = 'save', btnTxt = 'save', children }) => {
    return <>
        <Row className='mt-3'>
            <Col className='d-flex justify-content-end'>
                {children}
                <CancelButton link={cancelUrl} />
                <BtnIconLoad type='submit' data={data}>{btnTxt}</BtnIconLoad>
            </Col>
        </Row>
    </>
}

export const OnSuccess: React.FC<{ children: any, data: ReduxRtkApiResult }> = ({ children, data }) => {
    return <>
        {data.isSuccess && data.data && children}
    </>
}

export const IsDefined: React.FC<{children?: any, data: object | undefined | null | string }> = ({ data, children }) => { return <>{data && children}</> }

export const IfTrue: React.FC<{children?: any, condition: any }> = ({ condition, children }) => {
    return <>
        {condition && children}
    </>
}

export const NoDataBlock: React.FC<{children?: any, data: object | undefined, title: string }> = ({ data, children, title }) => {
    return <>
        {data && Object.entries(data).length > 0 ? children : <>
            <Alert variant="warning" className='text-center'>
                Aucune donnée(s) : <b>{title}</b>
            </Alert>
        </>}
    </>
}