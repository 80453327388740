import { ReportingMetrics, ReportingType } from "../../types";
import { Dimension } from "./reporting";

export function getChartData( api_result: Array<ReportingType>, mesured: string, groupby: string ) {

  const chartData: Record<string, number> = {};
  const cplData: Record<string, { dispatched: number, spent: number }> = {};

  [ ...api_result ]
    .sort(( a, b ) => new Date(a.date).getTime() - new Date(b.date).getTime())
    .map(r => {
      if ( groupby === 'date' ) {
        const hasDate = chartData[ `${r.date}` ];
        if ( !hasDate ) {
          chartData[ `${r.date}` ] = r.optinsPhones;
          cplData[ `${r.date}` ] = { dispatched: r.dispatched, spent: r.spent };
        } else {
          chartData[ `${r.date}` ] += r.optinsPhones;
          cplData[ `${r.date}` ] = {
            dispatched: cplData[ `${r.date}` ].dispatched + r.dispatched,
            spent: cplData[ `${r.date}` ].spent + r.spent
          };
        }
      } else if ( groupby === 'source' ) {
        const hasSrc = chartData[ `${r.source}` ];
        if ( !hasSrc ) {
          chartData[ `${r.source}` ] = r.optinsPhones;
          cplData[ `${r.source}` ] = { dispatched: r.dispatched, spent: r.spent };
        } else {
          chartData[ `${r.source}` ] += r.optinsPhones;
          cplData[ `${r.source}` ] = {
            dispatched: cplData[ `${r.source}` ].dispatched + r.dispatched,
            spent: cplData[ `${r.source}` ].spent + r.spent
          };
        }
      }
    })

  if ( mesured === 'cpl' ) {
    Object.keys(chartData).map(k => {
      chartData[ k ] = cplData[ k ].spent / cplData[ k ].dispatched;
    })
  }

  return chartData;
}

export function BuildReporting( api_result: Array<ReportingType>, dimensions: Array<Dimension> ): Reporting {
  const root = new Reporting('Total');

  [ ...api_result ]
    .sort(( a, b ) =>
      a.company === b.company
        ? a.date < b.date
          ? 1
          : -1
        : a.company > b.company
          ? 1
          : -1)
    .forEach(( r: ReportingType ) => {
      dimensions.reduce(( previous: Reporting, current: Dimension ) => {
        if ( current === 'date' ) {
          return previous.getChild(new Date(r[ current ]).toLocaleDateString('fr-FR', {
            year: 'numeric',
            month: 'short',
            day: '2-digit'
          }))
        } else if ( current === 'week' ) {
          const d = new Date(r[ 'date' ]);
          const day  = d.getDay(),
                diff = d.getDate() - day + (day == 0 ? -6 : 1);
          const monday = new Date(d.setDate(diff));
          const sunday = new Date(d.setDate(d.getDate() + 6));

          return previous.getChild(monday.toLocaleDateString('fr-FR', { month: 'short', day: '2-digit' })
            + " - " + sunday.toLocaleDateString('fr-FR', { month: 'short', day: '2-digit', year: 'numeric' })
          )
        } else if ( current === 'month' ) {
          const d = new Date(r[ 'date' ]);
          const firstday = new Date(d.getFullYear(), d.getMonth(), 1);
          return previous.getChild(firstday.toLocaleDateString('fr-FR', { month: 'short', year: '2-digit' }))
        }

        return previous.getChild(r[ current ]);

      }, root.getChild(r.company)).addMetrics(r as ReportingMetrics);
    });
  return root;
}

export type ReportingClass = {
  label: string,
  child: Record<string, Reporting>,
  metrics: Metrics,
}

//ex : CCFD on init
export class Reporting {
  label: string = '';
  child: Record<string, Reporting> = {};
  metrics = new Metrics();

  constructor( label: string ) {
    this.label = label;
  }

  getChild( label: string ): Reporting {
    if ( !(label in this.child) ) {
      this.child[ label ] = new Reporting(label);
    }

    return this.child[ label ];
  }

  addMetrics( metrics: ReportingMetrics ) {
    this.metrics.addMetrics(metrics);
  }

  // getters
  getTotal(): number {
    return this.metrics.total + Object.keys(this.child).map(k => this.child[ k ].getTotal()).reduce(( p: number, i: number ) => p + i, 0)
  }

  getSpent(): number {
    return this.metrics.spent + Object.keys(this.child).map(k => this.child[ k ].getSpent()).reduce(( p: number, i: number ) => p + i, 0)
  }

  getDuplicates(): number {
    return this.metrics.duplicates + Object.keys(this.child).map(k => this.child[ k ].getDuplicates()).reduce(( p: number, i: number ) => p + i, 0)
  }

  getDuplicatesAccount(): number {
    return this.metrics.duplicatesAccount + Object.keys(this.child).map(k => this.child[ k ].getDuplicatesAccount()).reduce(( p: number, i: number ) => p + i, 0)
  }

  getDispatched(): number {
    return this.metrics.dispatched + Object.keys(this.child).map(k => this.child[ k ].getDispatched()).reduce(( p: number, i: number ) => p + i, 0)
  }

  getDispatchedWaiting(): number {
    return this.metrics.dispatchWaiting + Object.keys(this.child).map(k => this.child[ k ].getDispatchedWaiting()).reduce(( p: number, i: number ) => p + i, 0)
  }

  getOptins(): number {
    return this.metrics.optins + Object.keys(this.child).map(k => this.child[ k ].getOptins()).reduce(( p: number, i: number ) => p + i, 0)
  }

  getOptinsPhones(): number {
    return this.metrics.optinsPhones + Object.keys(this.child).map(k => this.child[ k ].getOptinsPhones()).reduce(( p: number, i: number ) => p + i, 0)
  }

  getPurged(): number {
    return this.metrics.purged + Object.keys(this.child).map(k => this.child[ k ].getPurged()).reduce(( p: number, i: number ) => p + i, 0)
  }

  getCPLRate(): number {
    const dispatched = this.getDispatched();
    return dispatched > 0 ? this.getSpent() / dispatched : 0;
  }

  getOptinRate(): number {
    const dispatched = this.getDispatched();
    return dispatched > 0 ? this.getOptins() / dispatched : 0;
  }

  getOptinPhoneRate(): number {
    const optin = this.getOptins();
    return optin > 0 ? this.getOptinsPhones() / optin : 0;
  }

  getDuplicateRate(): number {
    const total = this.getTotal();
    return total > 0 ? this.getDuplicates() / total : 0;
  }

  getDuplicateAccountRate(): number {
    const total = this.getTotal();
    return total > 0 ? this.getDuplicatesAccount() / total : 0;
  }

  getAllDuplicates(): number {
    return (this.getDuplicateRate() + this.getDuplicateAccountRate());
  }

  getExported(): Record<string, number> | null {
    return this.metrics.exported;
  }

  getExportedRate(): Record<string, number> | null {
    const exported = this.getExported();
    if ( exported ) {
      const keys = Object.keys(exported);
      if ( keys.length > 0 ) {
        const exportedRate: Record<string, number> = {};
        const optinsPhones = this.getOptinsPhones();
        const optins = this.getOptins();
        if ( optinsPhones > 0 ) {
          keys.map(k => {
            exportedRate[ k ] = optinsPhones > 0 ? exported[ k ] / optinsPhones : 0;
          })
        } else if ( optins > 0 ) {
          keys.map(k => {
            exportedRate[ k ] = optins > 0 ? exported[ k ] / optins : 0;
          })
        }
        return exportedRate;
      }
    }
    return {};
  }
}

export class Metrics {
  spent: number;
  duplicates: number;
  duplicatesAccount: number;
  dispatched: number;
  dispatchWaiting: number;
  optins: number;
  optinsPhones: number;
  purged: number;
  total: number;
  exported: Record<string, number> | null;
  date: string;

  constructor() {
    this.spent = 0;
    this.duplicates = 0;
    this.duplicatesAccount = 0;
    this.dispatched = 0;
    this.dispatchWaiting = 0;
    this.optins = 0;
    this.optinsPhones = 0;
    this.purged = 0;
    this.total = 0;
    this.exported = {};
    this.date = '';
  }

  addMetrics( metrics: ReportingMetrics ) {
    this.spent += metrics.spent;
    this.duplicates += metrics.duplicates;
    this.duplicatesAccount += metrics.duplicatesAccount;
    this.dispatched += metrics.dispatched;
    this.dispatchWaiting += metrics.dispatchWaiting;
    this.optins += metrics.optins;
    this.optinsPhones += metrics.optinsPhones;
    this.purged += metrics.purged;
    this.total += metrics.total;

    if ( metrics.exported ) {
      const keys = Object.keys(metrics.exported);
      keys.forEach(k => {
        if ( k && this.exported && metrics.exported ) {
          if ( this.exported[ k ] ) {
            this.exported[ k ] += metrics.exported[ k ];
          } else {
            this.exported[ k ] = metrics.exported[ k ];
          }
        }
      });
    }
  }
}

export const getKeyValue = ( r: Reporting, k: string|undefined ) => {
  switch ( k ) {
    case "cpl":
      return r.getCPLRate();
    case "optinRate":
      return r.getOptinRate();
    case "phoneRate":
      return r.getOptinPhoneRate();
    case "duplicates":
      return r.getDuplicates();
    case "duplicatesAccount":
      return r.getDuplicatesAccount();
    case "total":
      return r.getTotal();
    case "spent":
      return r.getSpent();
    case "optins":
      return r.getOptins();
    case "optinsPhones":
      return r.getOptinsPhones();
    case "dispatchWaiting":
      return r.getDispatchedWaiting();
    case "purged":
      return r.getPurged();
    case "duplicatesGlobal":
      return r.getAllDuplicates();
    case "dispatched":
      return r.getDispatched();
    case "duplicatesRate":
      return r.getDuplicateRate();
    case "duplicatesAccountRate":
      return r.getDuplicateAccountRate();

  }
  return 0;
}