import { useEffect, useLayoutEffect, useState } from "react";
import { Button, Col, FormCheck, FormControl, FormLabel, FormSelect, ListGroup, Row } from "react-bootstrap";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import { HideInPreview } from "../LPGenerator";
import { addChoice, deleteChoice, deleteField, Fields, getChoices, getField, getFormAttributes, getFormColumnIsEdit, getFormLength, getFormStyle, moveField, setChoice, setField, setFieldIsEdit, setFormData } from "../landing-v2";
import { Choice } from "../../../types";
import { RichEditor } from "../../misc/TinymceEditor";
import { Icon } from "../../misc/Buttons";

const InitFieldEdit = (fieldIndex:number) => {
    const dispatch = useAppDispatch();
    const field = useAppSelector(getField(fieldIndex));
    const isFormColumnInFocus = useAppSelector(getFormColumnIsEdit);

    useEffect(() => {
        if (field && !isFormColumnInFocus && field?.isEdit) {
            dispatch(setFieldIsEdit({ fieldIndex, isFieldEdit: false }));
        }
    }, [isFormColumnInFocus]);
}

const EditFieldButton: React.FC<{fieldIndex: number}> = ({fieldIndex}) => {
    const dispatch = useAppDispatch();
    const fStyle = useAppSelector(getFormStyle);

    return <>
        <HideInPreview className='field-edit'>
            <Button size='sm' variant='' style={{color: fStyle?.color ? fStyle.color : ''}}
                onClick={() => dispatch(setFieldIsEdit({ fieldIndex, isFieldEdit: true }))} className='m-0 p-0 ms-2'>
                <Icon code='pencil' /></Button>
        </HideInPreview>
    </>
}

const EditFieldLabel: React.FC<{ title: string | undefined, fieldIndex: number, className?: string }> = ({ title, fieldIndex, className }) => {
    return <div className='d-flex align-items-center'>
        <FormLabel className={className}>{title}</FormLabel>
        <EditFieldButton fieldIndex={fieldIndex} />
    </div>
}

export const PostOptinField: React.FC = () => {
    const data = useAppSelector(getFormAttributes);
    const [isEdit, setIsEdit] = useState(false);
    const dispatch = useAppDispatch();
    return <div className='optin post-optin text-center d-flex justify-content-center'>
        {
            !isEdit ? <>
                <small style={{ cursor: 'pointer' }} onClick={() => setIsEdit(true)}>
                    {data?.postOptin ? data.postOptin : 'Post Optin'}
                </small>
            </>
                : <>
                    <FormControl as="textarea" defaultValue={data?.postOptin}
                        onBlur={(e) => { dispatch(setFormData({ option: 'postOptin', value: e.target.value })); setIsEdit(false) }} />
                </>
        }
    </div>
}

export const DateField: React.FC<{ fieldIndex: number }> = ({ fieldIndex }) => {
    const dispatch = useAppDispatch();
    const field = useAppSelector(getField(fieldIndex));
    InitFieldEdit(fieldIndex);
    return <div className='field'>
        {field?.isEdit
            ? <>
                <FieldActions index={fieldIndex} type={'Date'} />
                <FieldName fieldIndex={fieldIndex} />
                <FormControl size='sm' placeholder="Texte d'aide" className='mt-1' defaultValue={field.helper}
                    onBlur={(e: any) => dispatch(setField({ fieldIndex, field: { ...field, helper: e.target.value } }))} />
            </>
            : <>
                <FormLabel>
                    {field?.title}
                    {field?.mandatory && "*"}
                </FormLabel>
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='w-100'>
                        <FormControl type={'date'} name={field?.code} />
                        <div>
                            <small className='text-muted'>{field?.helper}</small>
                        </div>
                    </div>
                    <EditFieldButton fieldIndex={fieldIndex} />
                </div>
            </>}
    </div>
}

export const ControlField: React.FC<{ fieldIndex: number, type: 'number' | 'text' }> = ({ fieldIndex, type }) => {
    const dispatch = useAppDispatch();
    const field = useAppSelector(getField(fieldIndex));
    const formStyle = useAppSelector(getFormStyle);
    InitFieldEdit(fieldIndex);

    let fTitle = field?.title;
    if (field?.mandatory) fTitle += '*';
    
    return <div>
        {!field?.isEdit
            ? <div className='field'>
                {(!formStyle?.labels_display || formStyle.labels_display === 'label' || formStyle.labels_display === 'no-border-label')
                    && <>
                        <FormLabel>
                            {fTitle}
                        </FormLabel>
                    </>}
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='w-100'>
                        <FormControl type={type} name={field?.code} className={formStyle?.labels_display === 'no-border-label' || formStyle?.labels_display === 'no-border-placeholder' ? 'no-border' : ''}
                            placeholder={formStyle?.labels_display === 'placeholder' || formStyle?.labels_display === 'no-border-placeholder'
                                ? fTitle : ''} />
                        <div>
                            <small className='text-muted'>{field?.helper}</small>
                        </div>
                    </div>
                    <EditFieldButton fieldIndex={fieldIndex} />
                </div>
            </div>
            : <>
                <FieldActions index={fieldIndex} type={type === 'text' ? 'Texte' : 'Numérique'} />
                <FieldName fieldIndex={fieldIndex} />
                <FormControl size='sm' placeholder="Texte d'aide" className='mt-1' defaultValue={field.helper}
                    onBlur={(e: any) => dispatch(setField({ fieldIndex, field: { ...field, helper: e.target.value } }))} />
            </>}
    </div>
}

export const OptinField: React.FC<{ fieldIndex: number }> = ({ fieldIndex }) => {
    const dispatch = useAppDispatch();
    const field = useAppSelector(getField(fieldIndex));
    const fStyle = useAppSelector(getFormStyle);
    InitFieldEdit(fieldIndex);

    return <div className='field'>
        {!field?.isEdit
            ? <>
                <EditFieldButton fieldIndex={fieldIndex} />

                <div className='d-flex align-items-start'>
                    {field?.isCheckable && <div><FormCheck type='checkbox' /></div>}
                    <div className='ms-1'>
                        <small className='optin' dangerouslySetInnerHTML={{ __html: field?.content ? field.content : '' }} />
                    </div>
                </div>
            </>
            : <>
                <FieldActions index={fieldIndex} type={'Optin'} actions={<>
                    <FormCheck type='switch' label='Case à cocher' defaultChecked={field.isCheckable}
                        onChange={(e) => dispatch(setField({ fieldIndex, field: { ...field, isCheckable: e.target.checked } }))} />
                </>}

                />
                <RichEditor initVal={field.content ? field.content : ' '}
                    onBlur={(content) => dispatch(setField({ fieldIndex, field: { ...field, content: content } }))} />
            </>}
    </div>
}

export const OneChoiceField: React.FC<{ fieldIndex: number }> = ({ fieldIndex }) => {
    const dispatch = useAppDispatch();
    const field = useAppSelector(getField(fieldIndex));
    const choices = useAppSelector(getChoices(fieldIndex));
    InitFieldEdit(fieldIndex);

    return <div className='field'>
        {!field?.isEdit
            ? <>
                <EditFieldLabel title={field?.title} fieldIndex={fieldIndex} />

                {field?.display === 'radio' && field.choices?.map((c, i) => <FormCheck key={`choice-${fieldIndex}-${i}`} name={field.code} type='radio' label={c.title} value={c.code as string} />)}
                {(field?.display === 'select' || field?.display === 'autocomplete') && <>
                    <FormSelect name={field.code}>
                        {field.choices?.map((c, i) => <option key={`option-${fieldIndex}-${i}`} value={c.code as string}>{c.title}</option>)}
                    </FormSelect>
                </>}
                <div>
                    <small className='text-muted'>{field?.helper}</small>
                </div>
            </>
            : <>
                <FieldActions index={fieldIndex} type='Choix unique' actions={<>
                    <FormCheck type='radio' label='Radio' name='display' className='' defaultChecked={field.display === 'radio'}
                        onClick={() => dispatch(setField({ fieldIndex, field: { ...field, display: 'radio' } }))} />
                    <FormCheck type='radio' label='Sélecteur' name='display' className='' defaultChecked={field.display === 'select'}
                        onClick={() => dispatch(setField({ fieldIndex, field: { ...field, display: 'select' } }))} />
                    <FormCheck type='radio' label='Autocomplete' name='display' className='' defaultChecked={field.display === 'autocomplete'}
                        onClick={() => dispatch(setField({ fieldIndex, field: { ...field, display: 'autocomplete' } }))} />
                </>
                } />

                <FieldName fieldIndex={fieldIndex} />
                <FormControl size='sm' placeholder="Texte d'aide" className='mt-1' defaultValue={field.helper}
                    onBlur={(e: any) => dispatch(setField({ fieldIndex, field: { ...field, helper: e.target.value } }))} />

                <Row>
                    {choices?.map((c, j) => <ChoiceControl key={`choice-${fieldIndex}-${j}`} choice={c} index={j} fieldIndex={fieldIndex} size={choices.length} />)}
                </Row>

                <div className='d-flex justify-content-center'>
                    <Button className='ms-2' size='sm' onClick={() => dispatch(addChoice({ fieldIndex }))}>+ ajouter choix</Button>
                </div>
            </>}
    </div>
}

export const MultipleChoicesField: React.FC<{ fieldIndex: number }> = ({ fieldIndex }) => {
    const dispatch = useAppDispatch();
    const field = useAppSelector(getField(fieldIndex));
    const choices = useAppSelector(getChoices(fieldIndex));
    InitFieldEdit(fieldIndex);

    return <>
        {!field?.isEdit
            ? <>
                <EditFieldLabel title={field?.title} fieldIndex={fieldIndex} />
                {field?.choices?.map(c => <FormCheck name={field.code} type='checkbox' label={c.title} value={c.code as string} />)}
            </>
            : <>
                <FieldActions type='Choix multiple (checkbox)' index={fieldIndex} />
                <FieldName fieldIndex={fieldIndex} />

                <Row>
                    {choices?.map((c, j) => <ChoiceControl key={`choiceControl-${fieldIndex}-${j}`} choice={c} index={j} fieldIndex={fieldIndex} size={choices.length} />)}
                </Row>
                <div className='d-flex justify-content-center'>

                    <Button className='ms-2' size='sm' onClick={() => dispatch(addChoice({ fieldIndex }))}>+ ajouter choix</Button>
                </div>
            </>
        }
    </>
}

export const BooleanField: React.FC<{ fieldIndex: number }> = ({ fieldIndex }) => {
    const dispatch = useAppDispatch();
    const field = useAppSelector(getField(fieldIndex));
    InitFieldEdit(fieldIndex);
    return <div className='field'>
        {field?.isEdit
            ? <>
                <FieldActions type='Vrai/Faux' index={fieldIndex} actions={<>
                    <FormCheck type='radio' label='Case à cocher' name='display' className='m-0' defaultChecked={field.display === 'checkbox'}
                        onClick={() => dispatch(setField({ fieldIndex, field: { ...field, display: 'checkbox' } }))} />
                    <FormCheck type='radio' label='Bouton' name='display' className='m-0 ' defaultChecked={field.display === 'button'}
                        onClick={() => dispatch(setField({ fieldIndex, field: { ...field, display: 'button' } }))} />
                </>} />
                <FieldName fieldIndex={fieldIndex} />
                <FormControl size='sm' placeholder="Texte d'aide" className='mt-1' defaultValue={field.helper}
                    onBlur={(e: any) => dispatch(setField({ fieldIndex, field: { ...field, helper: e.target.value } }))} />
            </>
            : <>
                {field?.display === 'button' && <>
                    <EditFieldLabel title={field.title} fieldIndex={fieldIndex} />
                    <div className="d-flex">
                        <label className="radio cursor-pointer mb-0">
                            <input className="d-none" type="radio" name="{{ f.code }}" value="1" checked />
                            <div className="d-flex flex-wrap justify-content-center align-items-center py-1 px-2 border-0 rounded">
                                <span>Oui</span>
                            </div>
                        </label>
                        <label className="radio cursor-pointer mb-0">
                            <input className="d-none" type="radio" name="{{ f.code }}" value="0" />
                            <div className="d-flex flex-wrap justify-content-center align-items-center py-1 px-2 border-0 rounded">
                                <span>Non</span>
                            </div>
                        </label>
                    </div>

                </>}

                {field?.display === 'checkbox' && <div className='d-flex align-items-center'>
                    <FormCheck type='checkbox' />
                    <EditFieldLabel className='m-0 ms-2' title={field.title} fieldIndex={fieldIndex} />
                </div>}

                <div>
                    <small className='text-muted'>{field?.helper}</small>
                </div>
            </>}
    </div>
}

export const ChoiceControl: React.FC<{ choice: Partial<Choice>, index: number, fieldIndex: number, size: number }> = props => {
    const dispatch = useAppDispatch();

    const updateChoice = (control: keyof Choice, value: boolean | string) => {
        dispatch(setChoice({ fieldIndex: props.fieldIndex, choiceIndex: props.index, choice: { ...props.choice, [`${control}`]: value } as Choice }))
    }

    useEffect(() => {
        if (typeof props.choice.active === 'undefined') { updateChoice('active', true) }
    }, [props]);

    return <>
        <Col sm={6}>
            <div className="d-flex align-items-center mb-3 mt-2 p-2" style={{ border: '1px solid #cecece' }}>
                <div className='ms-1 w-100'>
                    <div className='d-flex justify-content-between mb-1'>
                        <div>
                            <small>
                                <FormCheck type='switch' className='m-0' label='Actif'
                                    onChange={(e) => updateChoice('active', e.target.checked)}
                                    defaultChecked={props.choice.active} />
                                <FormCheck type='radio' name={'default-' + props.fieldIndex} label='Par défaut'
                                    defaultChecked={props.choice.default}
                                    onChange={(e) => updateChoice('default', e.target.checked)} />
                            </small>
                        </div>
                        {props.size > 2 && props.index > 1 && <>
                            <Button size='sm' variant="danger" onClick={() => dispatch(deleteChoice({ fieldIndex: props.fieldIndex, choiceIndex: props.index }))}>
                                <Icon code='x' />
                            </Button>
                        </>}
                    </div>
                    <Row className='d-flex align-items-center justify-content-start'>
                        <Col sm={12}>
                            <FormControl placeholder={`Label choix ${props.index + 1}`} defaultValue={props.choice.title}
                                onBlur={(e) => updateChoice('title', e.target.value)} />
                        </Col>
                    </Row>
                    <Row className='d-flex align-items-center'>
                        <Col sm={12}>
                            <FormControl size="sm" placeholder='Valeur' className='mt-1' defaultValue={props.choice.code as string}
                                readOnly={props.choice.code ? true : false}
                                onBlur={(e) => updateChoice('code', e.target.value)} />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12}>

                        </Col>
                    </Row>
                </div>
            </div>
        </Col>
    </>
}
export const FieldActions: React.FC<{ type: string, actions?: JSX.Element, index: number }> = ({ type, actions, index }) => {

    const dispatch = useAppDispatch();
    const length = useAppSelector(getFormLength);
    const field = useAppSelector(getField(index));

    return <div className='d-flex align-items-center justify-content-between mb-1'>
        {
            field && <>
                <div>
                    <small className='text-muted'>{type}</small>
                    <div className='ms-2'>
                        <small>
                            <div className=''>
                                {actions}
                            </div>
                            <FormCheck type='switch' label='Obligatoire' defaultChecked={field.mandatory}
                                onChange={(e) => dispatch(setField({ fieldIndex: index, field: { ...field, mandatory: e.target.checked } }))} />
                        </small>
                    </div>
                </div>
                <div className='d-flex justify-content-end align-items-center'>
                    <div className='d-flex align-items-center field-actions'>
                        <Button size='sm' variant='' onClick={() => dispatch(deleteField({ fieldIndex: index }))}>
                            <Icon code='trash' /></Button>
                        {index > 0 && <>
                            <Button size='sm' variant='' onClick={() => dispatch(moveField({ fieldIndex: index, direction: -1 }))}>
                                <Icon code='chevron-up' /></Button>
                        </>}
                        {index + 1 < length && <>
                            <Button size='sm' variant='' onClick={() => dispatch(moveField({ fieldIndex: index, direction: 1 }))}>
                                <Icon code='chevron-down' /></Button>
                        </>}
                    </div>
                    <div className='ms-2'>
                        <Button size='sm' variant='primary' onClick={() => dispatch(setFieldIsEdit({ fieldIndex: index, isFieldEdit: false }))}>
                            <Icon code='check' /></Button>
                    </div>
                </div>
            </>}
    </div>
}

export const FieldName: React.FC<{ fieldIndex: number }> = ({ fieldIndex }) => {
    const dispatch = useAppDispatch();
    const field = useAppSelector(getField(fieldIndex));
    return <>
        <Row>
            <Col sm={7}>
                <FormControl placeholder='Label' defaultValue={field?.title}
                    onBlur={(e) => dispatch(setField({ fieldIndex, field: { ...field, title: e.target.value } as Fields }))}
                />
            </Col>
            <Col>
                <FormControl size="sm" placeholder='Code API' className='mt-1' defaultValue={field?.code} readOnly={field?.code ? true : false}
                    onBlur={(e) => dispatch(setField({ fieldIndex, field: { ...field, code: e.target.value } as Fields }))}
                />
            </Col>
        </Row>
    </>
}

export const KeyField: React.FC<{ fieldIndex: number }> = ({ fieldIndex }) => {
    InitFieldEdit(fieldIndex);
    const dispatch = useAppDispatch();
    const field = useAppSelector(getField(fieldIndex));
    const formStyle = useAppSelector(getFormStyle);
    return <div className='field'>
        {field?.isEdit
            ? <>
                <FieldActions type='Clé unique' index={fieldIndex} />
                <FieldName fieldIndex={fieldIndex} />
                <FormControl size='sm' placeholder='Clé de validation' className='mt-1' defaultValue={field.validation_pattern}
                    onBlur={(e) => dispatch(setField({ fieldIndex, field: { ...field, validation_pattern: e.target.value } }))} />

                {field && <>
                    <FormControl size='sm' placeholder="Texte d'aide" className='mt-1' defaultValue={field?.helper}
                        onBlur={(e: any) => dispatch(setField({ fieldIndex, field: { ...field, helper: e.target.value } }))} />
                </>}
                <small className='text-muted'>
                    {`{x}`} : lettre, {`{0}`} : numéro <br />
                    Ex : A{`{x}`}{`{x}`}{`{0}`}{`{0}`}5BD, valeur correcte: AUI215BD
                </small>
            </>
            : <>
                {(!formStyle?.labels_display || formStyle.labels_display === 'label' || formStyle.labels_display === 'no-border-label')
                    && <FormLabel>
                        {field?.title}
                        {field?.mandatory && "*"}
                    </FormLabel>}
                <div className='d-flex align-items-center justify-content-between'>
                    <div className='w-100'>
                        <FormControl type={'text'} name={field?.code} className={formStyle?.labels_display === 'no-border-label' || formStyle?.labels_display === 'no-border-placeholder' ? 'no-border' : ''}
                            placeholder={formStyle?.labels_display === 'placeholder' || formStyle?.labels_display === 'no-border-placeholder'
                                ? field?.title : ''} />

                        <div>
                            <small className='text-muted'>{field?.helper}</small>
                        </div>

                    </div>
                    <EditFieldButton fieldIndex={fieldIndex} />
                </div>
            </>}
    </div>
}