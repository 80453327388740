import Urls from "../../assets/js/Urls";
import { MenuGroup } from "../../types";

const Menu = [
    {
        title:'Management', icon:'kanban', items: [
            { icon: 'graph-up', link: Urls.dash, name: "Reporting" },
            { icon: 'people', link: Urls.companies.list, name: "Clients" },
        ]
    },
    {
        title: 'Création', icon:'brush', items: [
            { icon: 'grid-1x2', link: Urls.landing.list, name: "Landing Pages" },
            { icon: 'input-cursor-text', link: Urls.fields, name: "Champs" },
            { icon: 'tags', link: Urls.categories.list, name: "Catégories & Thèmes" },
            { icon: 'images', link: Urls.medias.list, name: "Galerie" }
        ]
    },
    {
        title: 'Diffusion', icon:'lightning-charge', items: [
            { icon: 'globe', link: Urls.sites.list, name: "Sites et domaines" },
            { icon: 'facebook', link: Urls.fb.forms, name: "Formulaires facebook" },
        ]
    },
    {
        title: 'Data', icon:'server', items: [
            { icon: 'person', link: Urls.leads.list, name: "Leads" },
            { icon: 'card-text', link: Urls.leads.export, name: "Export" },
            { icon: 'app-indicator', link: Urls.coregistration.list, name: "Co-registration" },
            { icon: 'envelope', link: Urls.databases.list, name: "Bases email" },
            { icon: 'patch-check', link: Urls.captainVerify, name: "Captain Verify" }
        ]
    },
    {
        title: 'Admin', icon:'wrench-adjustable-circle', items: [
            { icon: 'ui-radios', link: Urls.forms.list, name: "Formulaires" },
            { icon: 'person-workspace', link: Urls.users.list, name: "Utilisateurs" },                
            { icon: 'tools', link: Urls.settings, name: "Configuration" },
            { icon: 'key', link: Urls.linkKeys.list, name: "Clés de liaison" },
            { icon: 'code', link: Urls.dev, name: "Développement" }
        ]
    }
]

export function getActiveKey(menu:Array<MenuGroup>) {
    const path = window.location.pathname;
    let eventKey = '';
    menu.forEach((i, key) => {
        i.items.map(i => {
            if (path.startsWith(i.link)) {
                eventKey = key.toString();
            } 
        })
    })
    return eventKey;
}

export default Menu;