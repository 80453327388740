import { useEffect, useRef, useState } from "react";
import { Badge, Card, Col, FormCheck, FormControl, Row } from "react-bootstrap";
import { Typeahead } from "react-bootstrap-typeahead";
import { useAppDispatch } from "../../app/hooks";
import globalApi from "../../app/services/secured/global";
import manageApi from "../../app/services/secured/manage";
import { addNotification } from "../../app/slice/notifs";
import { CompanyAccountForm, Customer } from "../../types";
import { FormInputGroup } from "../misc/Blocks";
import { BtnIconLoad, Icon } from "../misc/Buttons";
import SimpleForm from "../misc/SimpleForm";

const FormsPane: React.FC<Customer & { company: number, callback: () => void }> = account => {
    
    const dispatch = useAppDispatch();
    const forms = globalApi.useGetFormsListQuery();
    const [customerForms, setCustomerForms] = useState<Array<Partial<CompanyAccountForm>>>(account.forms);
    const [updateForms, updateFormsRes] = manageApi.useUpdateCompanyAccountMutation();
    const [selectLanding, setSelectLanding] = useState<Array<{ label: string, id: number }>>([]);
    const formRef = useRef<HTMLFormElement>(null);

    useEffect(() => {
        if (updateFormsRes.isSuccess) {
            const newForms = [...updateFormsRes.data.forms];
            newForms.sort((a, b) => b.id - a.id);
            setCustomerForms(newForms);
            account.callback();
            dispatch(addNotification({ type: 'success', message: 'Succès ajout du formulaire.' }));
        }
    }, [updateFormsRes.isSuccess])

    return <>
        <SimpleForm innerRef={formRef} onSubmit={(forms) => updateForms({ company: { id: account.company }, account: { id: account.id }, data: forms })}>
            {forms.data && <>
                <Row className='d-flex align-items-center mb-3'>
                    <Col sm={9}>
                        <Typeahead placeholder='Ajouter une landing page' id='select-form' className='h-100'
                            options={forms.data
                                .filter(f => customerForms.findIndex(ff => ff.form?.code === f.code) === -1)
                                .map((f) => { return { label: '[' + f.id + '] ' + f.name, id: f.id } })}
                            selected={selectLanding}
                            onChange={(s) => {
                                setSelectLanding(s as any);
                                if (s[0]) {
                                    const selected = s[0] as { label: string, id: number };
                                    const form = forms.data?.find(f => f.id === selected.id);

                                    const newForms = [...customerForms];
                                    if (form) {
                                        newForms.unshift({ id: form.id, form: { code: form.code, name: form.name, id: form.id.toString() } });
                                    }
                                    setCustomerForms(newForms);
                                    setSelectLanding([]);
                                }
                            }}
                        />
                    </Col>
                    <Col className='d-flex justify-content-center'>
                        <BtnIconLoad data={updateFormsRes} variant='success'>Enregister modifications</BtnIconLoad>
                    </Col>
                </Row>
            </>}
            <Row>
                {customerForms && customerForms.map(f => <AccountForm key={Math.random()} {...f} />)}
            </Row>
        </SimpleForm>

    </>
}

const AccountForm: React.FC<Partial<CompanyAccountForm>> = form => {
    return <Col sm={6}>
        <Card className='card-form mb-3'>
            <Card.Header>
                <div className='d-flex justify-content-between align-items-center'>
                    <div>
                        <Badge bg='secondary' className='fs-14'>{form.form?.id}</Badge>
                        <span className="ms-2">{form.form?.name}</span>
                    </div>
                    <div className='mt-2'>
                        <div className="fs-12">
                            <FormCheck type='switch' label='Actif' name={`formStatuses[${form?.form?.code}]`} defaultChecked={form.gateways ? form.enabled : true} />
                            <FormCheck type='switch' label='Leads privés' name={`formPrivate[${form?.form?.code}]`} className='' defaultChecked={form?.isPrivate} />
                        </div>
                    </div>
                </div>
            </Card.Header>
            <Card.Body>
                <Row className='d-flex'>
                    <Col sm={9}>
                    <FormInputGroup label='Code custom'>
                        <FormControl type='text' placeholder='Code custom' name={`formCustomCode[${form?.form?.code}]`} defaultValue={form?.customCode} />
                    </FormInputGroup>
                    </Col>
                    <Col>
                    <FormInputGroup label='Poids' className="ms-1">
                        <FormControl type='number' placeholder='Poids' name={`formWeights[${form?.form?.code}]`} defaultValue={form?.weight} />
                    </FormInputGroup>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    </Col>
}
export default FormsPane;