import React from 'react';
import { Form } from 'react-bootstrap';
import authApi from '../../app/services/public/auth';
import AppPublic from '../../AppPublic';
import Urls from '../../assets/js/Urls';
import { LoginArgs } from '../../types';
import { BlockError, BlockSuccess } from '../misc/Blocks';
import { BtnIconLoad } from '../misc/Buttons';
import SimpleForm from '../misc/SimpleForm';
import AuthLP from './AuthLP';

const Forgot: React.FC = () => {
    const [forgot, forgotRes] = authApi.useForgotMutation();
    return <>
        <AppPublic>
            <AuthLP>
                <h2>Mot de passe oublié</h2>
                <SimpleForm onSubmit={(form) => forgot(form as Pick<LoginArgs, 'email'>)}>
                    <label htmlFor="email">E-mail</label>
                    <Form.Control name='email'  id="email" type='text' placeholder='Entrez votre adresse e-mail' required />
                    <BtnIconLoad data={forgotRes} variant='primary' type='submit'>Réinitialiser</BtnIconLoad>
                    <div className='mt-4 text-center'>
                        <a href={Urls.login}>Connexion</a>
                    </div>
                </SimpleForm>
                <BlockSuccess data={forgotRes}>Vous allez recevoir un email pour créer un nouveau mot de passe.</BlockSuccess>
                <BlockError data={forgotRes} />
            </AuthLP>
        </AppPublic>
    </>
}

export default Forgot;