import { Badge } from "react-bootstrap";
import dataApi from "../../app/services/secured/data";
import AppSecured from "../../AppSecured";
import Urls from "../../assets/js/Urls";
import { Coregistration } from "../../types";
import { PageHeader } from "../App/PageHeader";
import { LoadingBlock, OnSuccess } from "../misc/Blocks";
import { ButtonIcon } from "../misc/Buttons";
import TableGenelead, { RowNoData } from "../misc/Table";

const Coreg = () => {
    const coregs = dataApi.useGetCoregListQuery();
    return <>
        <AppSecured>
            <PageHeader action={
                <ButtonIcon href={Urls.coregistration.edit('new')} code={"plus-circle"} size={"sm"} variant={"primary"}>
                    Ajouter co-registration    
                </ButtonIcon>}
            />
            <LoadingBlock data={coregs}>
                {coregs.isSuccess && <>
                    <TableGenelead hasActions={true} header={[
                        {name: 'nom', icon:'person'},
                        {name: 'paiement', icon:'cart'},
                        {name: 'formulaire', icon:'ui-radios'},
                        {name: 'pack', icon:'file'},
                    ]}>
                        <RowNoData list={coregs.data}  colspan={5} />
                        <OnSuccess data={coregs}>
                            {coregs.data.map(c => <CoregRow key={c.id} {...c} /> )}
                        </OnSuccess>
                    </TableGenelead>
                </>}
            </LoadingBlock>
        </AppSecured>
    </>
}

const CoregRow:React.FC<Coregistration> = coreg => {
    return <tr>
        <td> {coreg.name} </td>
        <td> {coreg.payout} </td>
        <td>
            { coreg.formCodes.map(f => <Badge key={Math.random()} bg='primary' className='me-1 fs-12'>{f}</Badge>) }
        </td>
        <td>{<>{coreg.allowedPacks}</>} </td>
        <td className="text-end">
            <ButtonIcon href={Urls.coregistration.doc(coreg.accessToken)} target='_blank' code={"receipt"} size={"lg"} variant={"secondary"} />
            <ButtonIcon href={Urls.coregistration.edit(coreg.id.toString())} 
                code={"pen"} size={"lg"} variant={"primary"} className='ms-1 alt-primary' />
        </td>
    </tr>
}

export default Coreg;