import { useEffect, useRef, useState } from "react";
import { Button, FormControl, FormSelect } from "react-bootstrap";
import { Icon } from "./Buttons";

export interface FiltersInterface<T> {
    currentFilter: T,
    onFilterChange: (filter: T) => void,
}

export const addToSearch = (filters: any, newFilter: any) => {
    if (newFilter) {
        Object.keys(newFilter).map(k => {
            const newValue = newFilter[`${k}`];
            if (k && newValue) {
                newFilter = { ...filters, [`${k}`]: newValue }
            } 
            else if (!newValue || newValue === '') {
                newFilter = {...filters};
                delete newFilter[`${k}`];
            }
        })
    }
    return newFilter;
}

type FilterSelectProps = {
    title: string,
    name: string,
    onSelectChange: (newFilter: Record<string, any>) => void,
    children: any,
    className?: string
    placeholder?: string
    value?: string
}

export const FilterSelect: React.FC<FilterSelectProps> = ({ onSelectChange, children, name, title, className, placeholder, value=''}) => {
    const [hasFilter, setHasFilter] = useState(false);
    const [val, setVal] = useState(value);

    useEffect(() => {
        setVal('');
        setHasFilter(false);
    }, [value])

    const onReset = (e: any) => {
        setVal('');
        setHasFilter(false);
        return onSelectChange({[`${name}`]:''});
    }

    const onChange = (e: any) => {
        const val = e.currentTarget.value;
        setVal(e.currentTarget.value);
        setHasFilter(true);
        return onSelectChange(val ? { [`${name}`]: e.currentTarget.value } : {} )
    }

    return <>
        <FormSelect data-live-search='true' className={className} onChange={onChange} value={val}>
        {/*<FormSelect data-live-search='true' className={className} onChange={onChange} value={val} placeholder={placeholder}>*/}
            <option value=''>{title}</option>
            {children}
        </FormSelect>
        {hasFilter && <Button variant="outline-primary" onClick={onReset}><Icon code='x-circle' /></Button>}
    </>
}

export const FilterSearch:React.FC<{search: (search: string) => void}> = ({search}) => {
    const searchInput = useRef<HTMLInputElement>(null);
    return <>
        <form onSubmit={(e) => {
            e.preventDefault();
            search(searchInput.current?.value as string);
        }}>
            <FormControl ref={searchInput} type='text' name='search' placeholder='Recherche' />
        </form>
    </>
}